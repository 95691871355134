@charset "UTF-8";
body {
  display: none;
}

#jpreOverlay {
  background-color: #1e2024;
}

#jpreLoader {
  text-align: center;
  width: 260px;
  margin: 0 auto;
}

#jprePercentage {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.03em;
  color: #38a9ff;
  text-align: center;
  width: 260px;
  margin: 0 auto;
}

#jprePercentage::before {
  content: "Loading...";
  color: #fff;
}

#jprePercentage::after {
  content: "Alchemists Template";
  display: block;
  font-size: 8px;
  line-height: 1.5em;
  color: #fff;
  letter-spacing: 0;
}

/*
// #Typography
// --------------------------------------------------
*/
/* Headings */
h1, h2, h3, h4, h5, h6 {
  color: #31404b;
  margin: 0 0 .75em 0;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.02em;
}

h1 {
  font-size: 48px;
  font-style: normal;
  text-transform: uppercase;
}

h2 {
  font-size: 32px;
  font-style: normal;
  text-transform: uppercase;
}

h3 {
  font-size: 24px;
  font-style: normal;
  text-transform: uppercase;
}

h4 {
  font-size: 22px;
  text-transform: none;
  font-style: normal;
}

h5 {
  font-size: 16px;
  font-style: normal;
  text-transform: uppercase;
}

h6 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 2em;
  line-height: 26px;
  font-size: normal;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* Paragraphs */
p {
  margin-bottom: 1.5em;
}

.lead {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75em;
}

/* Links */
a {
  color: #1892ed;
  text-decoration: none;
}

a:hover, a:focus {
  color: #38a9ff;
  text-decoration: none;
  outline: none;
}

/* Lists */
ol.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5em;
  counter-reset: counter;
}

ol.list li {
  position: relative;
  padding: 0 0 .5em 30px;
}

ol.list li::before {
  display: inline-block;
  /* block would also work */
  position: absolute;
  /* move this out of the way of the text */
  left: 0;
  /* move the counter label into the space from the padding */
  content: counter(counter) ". ";
  counter-increment: counter;
  font-weight: bold;
  width: 20px;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

ul.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5em;
}

ul.list li {
  position: relative;
  padding: 0 0 .5em 30px;
}

ul.list li::before {
  display: inline-block;
  /* block would also work */
  position: absolute;
  /* move this out of the way of the text */
  left: 0;
  /* move the counter label into the space from the padding */
  width: 20px;
  color: #38a9ff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  content: "\f00c";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

ul.list.list--circle li::before {
  top: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #38a9ff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  vertical-align: middle;
  color: #31404b;
}

/* Content Title */
.content-title {
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .content-title {
    padding: 25px 0;
    margin-bottom: 35px;
  }
}

.content-title__h {
  line-height: 1.2em;
  margin-bottom: .75em;
  font-size: 18px;
}

@media (min-width: 768px) {
  .content-title__h {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .content-title__h {
    font-size: 32px;
  }
}

.content-title__subtitle {
  font-style: normal;
  font-size: 11px;
  line-height: 1.2em;
  color: #9a9da2;
}

.content-title__h + .content-title__subtitle {
  margin-top: -1.75em;
  margin-bottom: 3em;
}

/* Blockquotes */
.blockquote--default {
  text-align: center;
  border: none;
  padding: 20px;
  position: relative;
  margin-bottom: 2em;
}

@media (min-width: 992px) {
  .blockquote--default {
    padding: 50px 20px 20px 20px;
  }
}

.blockquote--default::before {
  content: "\201C";
  display: block;
  position: absolute;
  left: 50%;
  top: -0.17em;
  transform: translateX(-50%);
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  opacity: .05;
  font-size: 300px;
  line-height: 1em;
  pointer-events: none;
}

@media (min-width: 992px) {
  .blockquote--default::before {
    font-size: 600px;
  }
}

@media (min-width: 1199px) {
  .blockquote--default::before {
    font-size: 700px;
  }
}

.blockquote--default .blockquote__content {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #31404b;
  margin-bottom: .75em;
}

@media (min-width: 992px) {
  .blockquote--default .blockquote__content {
    font-size: 24px;
  }
}

.blockquote--default .blockquote__footer::before {
  display: none !important;
}

.blockquote--default .blockquote__cite {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #9a9da2;
  font-style: normal !important;
}

@media (min-width: 992px) {
  .blockquote--default .blockquote__cite {
    font-size: 14px;
  }
}

.blockquote--default .blockquote__author-name {
  display: block;
}

.blockquote--default .blockquote__author-info {
  font-size: 9px;
  display: block;
}

@media (min-width: 992px) {
  .blockquote--default .blockquote__author-info {
    font-size: 10px;
  }
}

.text-success {
  color: #24d9b0 !important;
}

.text-danger {
  color: #f34141 !important;
}

.text-warning {
  color: #ff7e1f !important;
}

.text-info {
  color: #0cb2e2 !important;
}

address strong {
  color: #31404b;
  font-weight: 400;
}

dt {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-bottom: .2em;
}

dd {
  margin-bottom: 1.5em;
}

/* Images
/* ------------------------- */
img {
  max-width: 100%;
  height: auto;
}

.aligncenter {
  text-align: center;
  margin-bottom: 20px;
}

.aligncenter figcaption {
  padding: 14px;
  font-size: 13px;
}

figure {
  margin: 0;
}

.df-icon {
  width: 28px;
  height: 28px;
  fill: none;
  stroke: #c2ff1f;
}

.df-icon-stack {
  position: relative;
  width: 28px;
  height: 28px;
}

.df-icon-stack .df-icon {
  position: absolute;
}

.df-icon--basketball {
  width: 28px;
  height: 28px;
  stroke-width: 1.6px;
}

.df-icon--jersey {
  width: 22px;
  height: 24px;
  stroke-width: 1.8px;
  fill-rule: evenodd;
}

.df-icon--points {
  width: 37px;
  height: 32px;
  stroke-width: 1.6px;
}

.df-icon--bag {
  width: 22px;
  height: 24px;
  stroke-width: 1.6px;
}

.df-icon--bag-handle {
  width: 12px;
  height: 11px;
  stroke-width: 1.6px;
}

.df-icon-stack--bag .df-icon--bag {
  left: 50% !important;
  top: 5px;
  transform: translate(-50%, 0);
}

.df-icon-stack--bag .df-icon--bag-handle {
  left: 50% !important;
  transform: translate(-50%, 0);
}

.df-icon--ppg {
  width: 37px;
  height: 32px;
  stroke-width: 1.6px;
}

.df-icon--ppg > path {
  fill: #31404b;
}

.df-icon--apg {
  width: 50px;
  height: 28px;
  stroke-width: 1.6px;
}

.df-icon--apg > path {
  fill: #31404b;
}

.df-icon--rpg {
  width: 41px;
  height: 33px;
  stroke-width: 1.6px;
}

.df-icon--rpg > path {
  fill: #31404b;
}

.df-icon-stack--3pts {
  margin: 0 auto;
  width: 33px;
  height: 32px;
  text-align: center;
}

.df-icon-stack--3pts .df-icon--basketball {
  left: 0;
  top: 4px;
}

.df-icon-stack--3pts .df-icon--txt {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1em;
}

.df-icon--trophy {
  width: 22px;
  height: 18px;
  stroke: none;
  fill: #31404b;
}

/* Soccer Icons */
.df-icon--whistle {
  width: 30px;
  height: 32px;
  stroke-width: 1.8px;
}

.df-icon--soccer-ball {
  width: 28px;
  height: 28px;
  stroke-width: 1.8px;
}

.df-icon-plus {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  overflow: hidden;
}

.df-icon-plus::before {
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #fff;
}

.df-icon-plus::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  height: 2px;
  background-color: #fff;
}

/* Tables
/* ------------------------- */
table {
  background-color: transparent;
  color: #9a9da2;
}

caption {
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  color: #9a9da2;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 6.5px;
  vertical-align: top;
  border-top: 1px solid #e4e7ed;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  line-height: 1.42857143em;
}

@media (min-width: 992px) {
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    padding: 13px 10px;
    font-size: 11px;
  }
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #e4e7ed;
  font-weight: 700;
  color: #31404b;
  font-style: normal;
  text-transform: uppercase;
}

.table > tbody > tr > th {
  color: #31404b;
  font-weight: normal;
}

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #e4e7ed;
}

.table--lg > thead > tr > th,
.table--lg > thead > tr > td,
.table--lg > tbody > tr > th,
.table--lg > tbody > tr > td,
.table--lg > tfoot > tr > th,
.table--lg > tfoot > tr > td {
  padding: 8.5px;
}

@media (min-width: 992px) {
  .table--lg > thead > tr > th,
  .table--lg > thead > tr > td,
  .table--lg > tbody > tr > th,
  .table--lg > tbody > tr > td,
  .table--lg > tfoot > tr > th,
  .table--lg > tfoot > tr > td {
    padding: 17px 12px;
  }
}

.table-thead-color > thead > tr > th {
  background-color: #f5f7f9;
}

.table-top-border > thead:first-child > tr:first-child > th {
  border-top: 1px solid #e4e7ed;
}

.table > tbody > tr.highlighted {
  background-color: #f5f7f9;
}

td.highlight {
  color: #31404b;
}

.table-hover > tbody > tr {
  transition: background-color 0.2s ease;
}

.table-hover > tbody > tr:hover {
  color: #9a9da2;
  background-color: #f5f7f9;
}

.table-bordered {
  border: 1px solid #e4e7ed;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #e4e7ed;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}

.table-wrap-bordered {
  border: 1px solid #e4e7ed;
}

@media (max-width: 767px) {
  .table-responsive {
    border: none;
    margin-bottom: 0;
  }
}

.table--no-border > thead > tr > th,
.table--no-border > thead > tr > td,
.table--no-border > tbody > tr > th,
.table--no-border > tbody > tr > td,
.table--no-border > tfoot > tr > th,
.table--no-border > tfoot > tr > td {
  border: none;
}

.table__cell-center {
  text-align: center;
}

.table__cell-center > thead > tr > th {
  text-align: center;
}

.table__cell-center > thead > tr > th:first-child {
  text-align: left;
}

.table-stats > thead > tr > th,
.table-stats > thead > tr > td,
.table-stats > tbody > tr > th,
.table-stats > tbody > tr > td,
.table-stats > tfoot > tr > th,
.table-stats > tfoot > tr > td {
  text-align: center;
  vertical-align: middle;
}

.table-stats > thead > tr > th.text-left,
.table-stats > thead > tr > td.text-left,
.table-stats > tbody > tr > th.text-left,
.table-stats > tbody > tr > td.text-left,
.table-stats > tfoot > tr > th.text-left,
.table-stats > tfoot > tr > td.text-left {
  text-align: left;
}

.table-stats > thead > tr > th.text-right,
.table-stats > thead > tr > td.text-right,
.table-stats > tbody > tr > th.text-right,
.table-stats > tbody > tr > td.text-right,
.table-stats > tfoot > tr > th.text-right,
.table-stats > tfoot > tr > td.text-right {
  text-align: right;
}

@media (min-width: 992px) {
  .table-stats > thead > tr > th:first-child,
  .table-stats > tbody > tr > td:first-child {
    padding-left: 24px;
  }
}

@media (min-width: 992px) {
  .table-stats > thead > tr > th:last-child,
  .table-stats > tbody > tr > td:last-child {
    padding-right: 24px;
  }
}

.table-stats th.table-stats__subheading {
  text-align: left;
  font-weight: bold;
  font-style: normal;
  padding-left: 24px;
}

.table-stats th.table-stats__subheading--highlight {
  box-shadow: inset 6px 0 0 #38a9ff;
}

.table-stats th.table-stats__subheading--highlight-info {
  box-shadow: inset 6px 0 0 #0cb2e2;
}

/* Error 404 */
.error-404 {
  text-align: center;
  padding: 10px 15px 20px 15px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 992px) {
  .error-404 {
    padding: 20px 0 40px 0;
  }
}

.error-404__figure {
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
  max-width: 75%;
}

@media (min-width: 480px) {
  .error-404__figure {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .error-404__figure {
    margin-bottom: 55px;
  }
}

.error-404__figure::before, .error-404__figure::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 120px;
  height: 25px;
  background-color: #38a9ff;
  border-radius: 3px;
}

.error-404__figure::before {
  transform: translate(50px, -70px) rotate(45deg);
}

.error-404__figure::after {
  transform: translate(50px, -70px) rotate(-45deg);
}

.error__header {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .error__header {
    margin-bottom: 40px;
  }
}

.error__title {
  margin-bottom: 0.33em;
  font-size: 24px;
}

@media (min-width: 992px) {
  .error__title {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .error__title {
    font-size: 38px;
  }
}

.error__subtitle {
  font-size: 18px;
}

@media (min-width: 1200px) {
  .error__subtitle {
    font-size: 22px;
  }
}

.error__description {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .error__description {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .error__cta .btn {
    margin: 0 10px;
  }
}

@media (max-width: 767px) {
  .error__cta .btn {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 5px 0;
  }
}

/* Spacers */
.spacer {
  height: 30px;
  overflow: hidden;
}

.spacer-sm {
  height: 15px;
  overflow: hidden;
}

.spacer-xs {
  height: 10px;
  overflow: hidden;
}

.spacer-lg {
  height: 45px;
  overflow: hidden;
}

.spacer-xlg {
  height: 60px;
  overflow: hidden;
}

.spacer-xxlg {
  height: 90px;
  overflow: hidden;
}

.svg-filters {
  height: 0;
  left: -9999em;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 0;
}

/* Color Pallete */
.df-color-pallete {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .df-color-pallete {
    flex-wrap: wrap;
  }
}

.df-color-pallete__item {
  height: 120px;
  flex-grow: 1;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  line-height: 1.2em;
  text-align: center;
  line-height: 120px;
}

@media (max-width: 767px) {
  .df-color-pallete__item {
    flex-basis: 25%;
  }
}

@media (max-width: 479px) {
  .df-color-pallete__item {
    flex-basis: 50%;
  }
}

.df-color-pallete__item.color-dark {
  background-color: #1e2024;
}

.df-color-pallete__item.color-dark-lighten {
  background-color: #292c31;
}

.df-color-pallete__item.color-2 {
  background-color: #31404b;
}

.df-color-pallete__item.color-3 {
  background-color: #07e0c4;
}

.df-color-pallete__item.color-4 {
  background-color: #c2ff1f;
}

.df-color-pallete__item.color-gray {
  background-color: #9a9da2;
}

.df-color-pallete__item.color-primary {
  background-color: #38a9ff;
}

.df-color-pallete__item.color-primary-darken {
  background-color: #1892ed;
}

.gfont-item {
  margin-bottom: 1rem;
}

.gfont-item__body {
  font-size: 110px;
  line-height: 1em;
  color: #31404b;
}

.gfont-item__name {
  color: #31404b;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 400 !important;
}

.gfont-item__name::before {
  display: block;
}

.gfont-item__type {
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 400 !important;
}

.gfont-item__type::before {
  display: block;
}

.gfont-item--style1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-style: italic;
}

.gfont-item--style1 .gfont-item__name {
  text-transform: uppercase;
}

.gfont-item--style1 .gfont-item__name::before {
  content: "Montserrat, sans-serif";
}

.gfont-item--style1 .gfont-item__type {
  text-transform: uppercase;
}

.gfont-item--style1 .gfont-item__type::before {
  content: "Bold Italic";
}

.gfont-item--style2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.gfont-item--style2 .gfont-item__name {
  text-transform: uppercase;
}

.gfont-item--style2 .gfont-item__name::before {
  content: "Montserrat, sans-serif";
}

.gfont-item--style2 .gfont-item__type {
  text-transform: uppercase;
}

.gfont-item--style2 .gfont-item__type::before {
  content: "Bold";
}

.gfont-item--style3 {
  font-family: "Montserrat", sans-serif;
}

.gfont-item--style3 .gfont-item__name::before {
  content: "Montserrat, sans-serif";
}

.gfont-item--style3 .gfont-item__type::before {
  content: "Regular";
}

.gfont-item--style4 {
  font-family: "Source Sans Pro", sans-serif;
}

.gfont-item--style4 .gfont-item__name::before {
  content: "Montserrat, sans-serif";
}

.gfont-item--style4 .gfont-item__type::before {
  content: "Regular";
}

.team-color--1 {
  color: #00ff5b;
}

.team-color--2 {
  color: #ffe71b;
}

.team-color--3 {
  color: #ff3f5a;
}

.team-color--4 {
  color: #00fff6;
}

.team-color--5 {
  color: #fff7ca;
}

.team-color--6 {
  color: #9979ff;
}

.team-color--7 {
  color: #00deff;
}

/* Duotone Effect
/* ------------------------- */
.effect-duotone {
  position: relative;
}

.effect-duotone__layer::before, .effect-duotone__layer::after {
  content: "";
}

.effect-duotone__layer, .effect-duotone__layer::before, .effect-duotone__layer::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.effect-duotone__layer::before {
  background-color: #000;
  mix-blend-mode: color;
  z-index: 1;
}

.effect-duotone__layer::after {
  mix-blend-mode: lighten;
  z-index: 3;
}

.effect-duotone__layer-inner {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  mix-blend-mode: multiply;
}

@supports (position: -ms-page) {
  .effect-duotone .effect-duotone__layer::before {
    opacity: .3;
  }
  .effect-duotone .effect-duotone__layer::after {
    opacity: .3;
  }
  .effect-duotone .effect-duotone__layer-inner {
    opacity: .45;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .effect-duotone .effect-duotone__layer::before {
    opacity: .3;
  }
  .effect-duotone .effect-duotone__layer::after {
    opacity: .3;
  }
  .effect-duotone .effect-duotone__layer-inner {
    opacity: .5;
  }
}

.effect-duotone--base .effect-duotone__layer::after {
  background-color: #1e2024;
  mix-blend-mode: screen;
}

.effect-duotone--base .effect-duotone__layer-inner {
  background-color: #292c31;
}

/**
 *
 * Duotone dynamic colors
 *
 */
.effect-duotone--primary .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--primary .effect-duotone__layer-inner {
  background-color: #38a9ff;
}

.effect-duotone--tertiary .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--tertiary .effect-duotone__layer-inner {
  background-color: #07e0c4;
}

.effect-duotone--quaternary .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--quaternary .effect-duotone__layer-inner {
  background-color: #9fe900;
}

.effect-duotone--info .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--info .effect-duotone__layer-inner {
  background-color: #0cb2e2;
}

/**
 *
 * Duotone Static colors
 *
 */
.effect-duotone--blue .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--blue .effect-duotone__layer-inner {
  background-color: #4439e4;
}

.effect-duotone--red .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--red .effect-duotone__layer-inner {
  background-color: #ff1c5c;
}

.effect-duotone--grey .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--grey .effect-duotone__layer-inner {
  background-color: #dadada;
}

.effect-duotone--yellow .effect-duotone__layer::after {
  background-color: #1e2024;
}

.effect-duotone--yellow .effect-duotone__layer-inner {
  background-color: #ffcc00;
}

/* Accordion
/* ------------------------- */
.accordion .card {
  margin-bottom: 0;
}

.accordion .card:first-of-type .card__header {
  border-top-right-radius: 4px;
}

.accordion .card:last-of-type .card__header {
  border-bottom-right-radius: 4px;
}

.accordion .card__header {
  padding: 0;
  border-radius: 0;
}

.accordion .card__header::before {
  display: none;
}

.accordion .accordion__header-link {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  position: relative;
  padding: 0;
  font-style: normal;
  font-size: 14px;
  padding: 24px 94px 24px 24px;
  color: #31404b;
}

.accordion .accordion__header-link:hover {
  color: #1892ed;
}

.accordion .accordion__header-link:not(.collapsed) .accordion__header-link-icon {
  background-color: #38a9ff;
  border-bottom-right-radius: 0;
}

.accordion .accordion__header-link:not(.collapsed) .accordion__header-link-icon::after {
  opacity: 0;
}

.accordion .accordion__header-link-icon {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 70px;
  background-color: #9a9da2;
  border-radius: 0;
}

.accordion .accordion__header-link-icon::before, .accordion .accordion__header-link-icon::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: opacity .2s ease;
}

.accordion .accordion__header-link-icon::before {
  width: 12px;
  height: 2px;
}

.accordion .accordion__header-link-icon::after {
  width: 2px;
  height: 12px;
}

.accordion .card__content p:last-child {
  margin-bottom: 0;
}

.accordion--space-between .card {
  margin-bottom: 15px;
}

.accordion--space-between .card:last-child {
  margin-bottom: 0;
}

.accordion--space-between .card .card__header, .accordion--space-between .card:first-of-type .card__header, .accordion--space-between .card:last-of-type .card__header {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
}

.accordion--space-between .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid #e4e7ed;
  border-radius: 4px;
}

.accordion--space-between .card:not(:first-of-type) .card-header:first-child {
  border-radius: 4px;
}

.accordion--space-between .card:first-of-type {
  border-bottom: 1px solid #e4e7ed;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.accordion--space-between .card:last-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.accordion--space-between .accordion__header-link:not(.collapsed) .accordion__header-link-icon {
  border-bottom-right-radius: 0;
}

.accordion--space-between .accordion__header-link.collapsed .accordion__header-link-icon {
  border-bottom-right-radius: 4px;
}

/* Alerts
/* ------------------------- */
.alert {
  padding: 17px;
  padding-left: 85px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  font-size: 13px;
  position: relative;
  color: #9a9da2;
}

.alert::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px 0 0 4px;
  width: 60px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.alert strong {
  color: #31404b;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.alert-dismissible {
  padding-right: 37px;
}

.alert-dismissible .close {
  top: 17px;
  right: 17px;
  color: inherit;
  text-shadow: none;
  padding: 0;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  opacity: .2;
}

.alert-btn-right {
  position: relative;
  float: right;
  top: 0;
  right: 0;
}

.alert-success {
  background-color: #fff;
  border-color: #e4e7ed;
}

.alert-success hr {
  border-top-color: #d5d9e3;
}

.alert-success::before {
  background-color: #24d9b0;
}

.alert-success::before {
  background-size: 14px 12px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='%23fff' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E");
}

.alert-info {
  background-color: #fff;
  border-color: #e4e7ed;
}

.alert-info hr {
  border-top-color: #d5d9e3;
}

.alert-info::before {
  background-color: #0cb2e2;
}

.alert-info::before {
  background-size: 5px 18px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='%23fff' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E");
}

.alert-warning {
  background-color: #fff;
  border-color: #e4e7ed;
}

.alert-warning hr {
  border-top-color: #d5d9e3;
}

.alert-warning::before {
  background-color: #1892ed;
}

.alert-warning::before {
  background-size: 5px 18px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='%23fff' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E");
}

.alert-danger {
  background-color: #fff;
  border-color: #e4e7ed;
}

.alert-danger hr {
  border-top-color: #d5d9e3;
}

.alert-danger::before {
  background-color: #f34141;
}

.alert-danger::before {
  background-size: 11px 11px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'%3E%3Cpath transform='translate(-190.844 -2353.84)' fill='%23fff' d='M190.843,2355.96l2.121-2.12,9.193,9.2-2.122,2.12Zm9.192-2.12,2.122,2.12-9.193,9.2-2.121-2.12Z'/%3E%3C/svg%3E");
}

/* Badges
/* ------------------------- */
.badge-default {
  background-color: #9a9da2;
  color: #fff;
}

.badge-primary {
  background-color: #31404b;
  color: #fff;
}

/* Buttons */
.btn {
  font-weight: 700;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  transition: all 0.3s ease;
  padding: 13px 42px;
  font-size: 11px;
  line-height: 1.5em;
  border-radius: 2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #fff;
}

.btn:active, .btn.active {
  box-shadow: none;
}

.btn-default {
  color: #fff;
  background-color: #9a9da2;
  border-color: transparent;
}

.btn-default:focus, .btn-default.focus {
  color: #fff;
  background-color: #868a91;
  border-color: rgba(0, 0, 0, 0);
}

.btn-default:hover {
  color: #fff;
  background-color: #868a91;
  border-color: rgba(0, 0, 0, 0);
}

.btn-default:active, .btn-default.active,
.open > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #868a91;
  border-color: rgba(0, 0, 0, 0);
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #6e7177;
  border-color: rgba(0, 0, 0, 0);
}

.btn-default:active, .btn-default.active,
.open > .btn-default.dropdown-toggle {
  background-image: none;
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus {
  background-color: #9a9da2;
  border-color: transparent;
}

.btn-default .badge {
  color: #9a9da2;
  background-color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #31404b;
  border-color: transparent;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1892ed;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary:hover {
  color: #fff;
  background-color: #1892ed;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1892ed;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #0f1317;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #31404b;
  border-color: transparent;
}

.btn-primary .badge {
  color: #31404b;
  background-color: #fff;
}

.btn-primary-inverse {
  color: #fff;
  background-color: #38a9ff;
  border-color: transparent;
}

.btn-primary-inverse:focus, .btn-primary-inverse.focus {
  color: #fff;
  background-color: #1892ed;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary-inverse:hover {
  color: #fff;
  background-color: #1892ed;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary-inverse:active, .btn-primary-inverse.active,
.open > .btn-primary-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1892ed;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary-inverse:active:hover, .btn-primary-inverse:active:focus, .btn-primary-inverse:active.focus, .btn-primary-inverse.active:hover, .btn-primary-inverse.active:focus, .btn-primary-inverse.active.focus,
.open > .btn-primary-inverse.dropdown-toggle:hover,
.open > .btn-primary-inverse.dropdown-toggle:focus,
.open > .btn-primary-inverse.dropdown-toggle.focus {
  color: #fff;
  background-color: #007fe0;
  border-color: rgba(0, 0, 0, 0);
}

.btn-primary-inverse:active, .btn-primary-inverse.active,
.open > .btn-primary-inverse.dropdown-toggle {
  background-image: none;
}

.btn-primary-inverse.disabled:hover, .btn-primary-inverse.disabled:focus, .btn-primary-inverse.disabled.focus, .btn-primary-inverse[disabled]:hover, .btn-primary-inverse[disabled]:focus, .btn-primary-inverse[disabled].focus,
fieldset[disabled] .btn-primary-inverse:hover,
fieldset[disabled] .btn-primary-inverse:focus,
fieldset[disabled] .btn-primary-inverse.focus {
  background-color: #38a9ff;
  border-color: transparent;
}

.btn-primary-inverse .badge {
  color: #38a9ff;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #9fe900;
  border-color: transparent;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #95da00;
  border-color: rgba(0, 0, 0, 0);
}

.btn-success:hover {
  color: #fff;
  background-color: #95da00;
  border-color: rgba(0, 0, 0, 0);
}

.btn-success:active, .btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #95da00;
  border-color: rgba(0, 0, 0, 0);
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #649200;
  border-color: rgba(0, 0, 0, 0);
}

.btn-success:active, .btn-success.active,
.open > .btn-success.dropdown-toggle {
  background-image: none;
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
  background-color: #9fe900;
  border-color: transparent;
}

.btn-success .badge {
  color: #9fe900;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #0cb2e2;
  border-color: transparent;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #0ba7d3;
  border-color: rgba(0, 0, 0, 0);
}

.btn-info:hover {
  color: #fff;
  background-color: #0ba7d3;
  border-color: rgba(0, 0, 0, 0);
}

.btn-info:active, .btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0ba7d3;
  border-color: rgba(0, 0, 0, 0);
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #087190;
  border-color: rgba(0, 0, 0, 0);
}

.btn-info:active, .btn-info.active,
.open > .btn-info.dropdown-toggle {
  background-image: none;
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
  background-color: #0cb2e2;
  border-color: transparent;
}

.btn-info .badge {
  color: #0cb2e2;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #ff7e1f;
  border-color: transparent;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ff7510;
  border-color: rgba(0, 0, 0, 0);
}

.btn-warning:hover {
  color: #fff;
  background-color: #ff7510;
  border-color: rgba(0, 0, 0, 0);
}

.btn-warning:active, .btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff7510;
  border-color: rgba(0, 0, 0, 0);
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #c75500;
  border-color: rgba(0, 0, 0, 0);
}

.btn-warning:active, .btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  background-image: none;
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #ff7e1f;
  border-color: transparent;
}

.btn-warning .badge {
  color: #ff7e1f;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #f34141;
  border-color: transparent;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #f23333;
  border-color: rgba(0, 0, 0, 0);
}

.btn-danger:hover {
  color: #fff;
  background-color: #f23333;
  border-color: rgba(0, 0, 0, 0);
}

.btn-danger:active, .btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f23333;
  border-color: rgba(0, 0, 0, 0);
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #d00d0d;
  border-color: rgba(0, 0, 0, 0);
}

.btn-danger:active, .btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  background-image: none;
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #f34141;
  border-color: transparent;
}

.btn-danger .badge {
  color: #f34141;
  background-color: #fff;
}

.btn-instagram {
  background-color: #1e78dd;
  color: #fff;
}

.btn-instagram:hover, .btn-instagram:active, .btn-instagram:focus {
  background-color: #3185e3;
}

.btn-instagram i {
  font-size: 6px;
  position: relative;
  top: -1px;
}

.btn-instagram.btn-sm {
  padding-left: 24px;
  padding-right: 24px;
}

.btn-facebook {
  color: #fff;
  background-color: #3B5998;
  border-color: transparent;
}

.btn-facebook:focus, .btn-facebook.focus {
  color: #fff;
  background-color: #4264aa;
  border-color: rgba(0, 0, 0, 0);
}

.btn-facebook:hover {
  color: #fff;
  background-color: #4264aa;
  border-color: rgba(0, 0, 0, 0);
}

.btn-facebook:active, .btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #4264aa;
  border-color: rgba(0, 0, 0, 0);
}

.btn-facebook:active:hover, .btn-facebook:active:focus, .btn-facebook:active.focus, .btn-facebook.active:hover, .btn-facebook.active:focus, .btn-facebook.active.focus,
.open > .btn-facebook.dropdown-toggle:hover,
.open > .btn-facebook.dropdown-toggle:focus,
.open > .btn-facebook.dropdown-toggle.focus {
  color: #fff;
  background-color: #23345a;
  border-color: rgba(0, 0, 0, 0);
}

.btn-facebook:active, .btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-image: none;
}

.btn-facebook.disabled:hover, .btn-facebook.disabled:focus, .btn-facebook.disabled.focus, .btn-facebook[disabled]:hover, .btn-facebook[disabled]:focus, .btn-facebook[disabled].focus,
fieldset[disabled] .btn-facebook:hover,
fieldset[disabled] .btn-facebook:focus,
fieldset[disabled] .btn-facebook.focus {
  background-color: #3B5998;
  border-color: transparent;
}

.btn-facebook .badge {
  color: #3B5998;
  background-color: #fff;
}

.btn-twitter {
  color: #fff;
  background-color: #1DA1F2;
  border-color: transparent;
}

.btn-twitter:focus, .btn-twitter.focus {
  color: #fff;
  background-color: #35abf3;
  border-color: rgba(0, 0, 0, 0);
}

.btn-twitter:hover {
  color: #fff;
  background-color: #35abf3;
  border-color: rgba(0, 0, 0, 0);
}

.btn-twitter:active, .btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #35abf3;
  border-color: rgba(0, 0, 0, 0);
}

.btn-twitter:active:hover, .btn-twitter:active:focus, .btn-twitter:active.focus, .btn-twitter.active:hover, .btn-twitter.active:focus, .btn-twitter.active.focus,
.open > .btn-twitter.dropdown-toggle:hover,
.open > .btn-twitter.dropdown-toggle:focus,
.open > .btn-twitter.dropdown-toggle.focus {
  color: #fff;
  background-color: #0a70ae;
  border-color: rgba(0, 0, 0, 0);
}

.btn-twitter:active, .btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-image: none;
}

.btn-twitter.disabled:hover, .btn-twitter.disabled:focus, .btn-twitter.disabled.focus, .btn-twitter[disabled]:hover, .btn-twitter[disabled]:focus, .btn-twitter[disabled].focus,
fieldset[disabled] .btn-twitter:hover,
fieldset[disabled] .btn-twitter:focus,
fieldset[disabled] .btn-twitter.focus {
  background-color: #1DA1F2;
  border-color: transparent;
}

.btn-twitter .badge {
  color: #1DA1F2;
  background-color: #fff;
}

.btn-gplus {
  color: #fff;
  background-color: #DC4E41;
  border-color: transparent;
}

.btn-gplus:focus, .btn-gplus.focus {
  color: #fff;
  background-color: #e06257;
  border-color: rgba(0, 0, 0, 0);
}

.btn-gplus:hover {
  color: #fff;
  background-color: #e06257;
  border-color: rgba(0, 0, 0, 0);
}

.btn-gplus:active, .btn-gplus.active,
.open > .btn-gplus.dropdown-toggle {
  color: #fff;
  background-color: #e06257;
  border-color: rgba(0, 0, 0, 0);
}

.btn-gplus:active:hover, .btn-gplus:active:focus, .btn-gplus:active.focus, .btn-gplus.active:hover, .btn-gplus.active:focus, .btn-gplus.active.focus,
.open > .btn-gplus.dropdown-toggle:hover,
.open > .btn-gplus.dropdown-toggle:focus,
.open > .btn-gplus.dropdown-toggle.focus {
  color: #fff;
  background-color: #a72a1f;
  border-color: rgba(0, 0, 0, 0);
}

.btn-gplus:active, .btn-gplus.active,
.open > .btn-gplus.dropdown-toggle {
  background-image: none;
}

.btn-gplus.disabled:hover, .btn-gplus.disabled:focus, .btn-gplus.disabled.focus, .btn-gplus[disabled]:hover, .btn-gplus[disabled]:focus, .btn-gplus[disabled].focus,
fieldset[disabled] .btn-gplus:hover,
fieldset[disabled] .btn-gplus:focus,
fieldset[disabled] .btn-gplus.focus {
  background-color: #DC4E41;
  border-color: transparent;
}

.btn-gplus .badge {
  color: #DC4E41;
  background-color: #fff;
}

.btn-linkedin {
  color: #fff;
  background-color: #0077B5;
  border-color: transparent;
}

.btn-linkedin:focus, .btn-linkedin.focus {
  color: #fff;
  background-color: #0088cf;
  border-color: rgba(0, 0, 0, 0);
}

.btn-linkedin:hover {
  color: #fff;
  background-color: #0088cf;
  border-color: rgba(0, 0, 0, 0);
}

.btn-linkedin:active, .btn-linkedin.active,
.open > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #0088cf;
  border-color: rgba(0, 0, 0, 0);
}

.btn-linkedin:active:hover, .btn-linkedin:active:focus, .btn-linkedin:active.focus, .btn-linkedin.active:hover, .btn-linkedin.active:focus, .btn-linkedin.active.focus,
.open > .btn-linkedin.dropdown-toggle:hover,
.open > .btn-linkedin.dropdown-toggle:focus,
.open > .btn-linkedin.dropdown-toggle.focus {
  color: #fff;
  background-color: #003e5e;
  border-color: rgba(0, 0, 0, 0);
}

.btn-linkedin:active, .btn-linkedin.active,
.open > .btn-linkedin.dropdown-toggle {
  background-image: none;
}

.btn-linkedin.disabled:hover, .btn-linkedin.disabled:focus, .btn-linkedin.disabled.focus, .btn-linkedin[disabled]:hover, .btn-linkedin[disabled]:focus, .btn-linkedin[disabled].focus,
fieldset[disabled] .btn-linkedin:hover,
fieldset[disabled] .btn-linkedin:focus,
fieldset[disabled] .btn-linkedin.focus {
  background-color: #0077B5;
  border-color: transparent;
}

.btn-linkedin .badge {
  color: #0077B5;
  background-color: #fff;
}

.btn-vk {
  color: #fff;
  background-color: #6383A8;
  border-color: transparent;
}

.btn-vk:focus, .btn-vk.focus {
  color: #fff;
  background-color: #7390b1;
  border-color: rgba(0, 0, 0, 0);
}

.btn-vk:hover {
  color: #fff;
  background-color: #7390b1;
  border-color: rgba(0, 0, 0, 0);
}

.btn-vk:active, .btn-vk.active,
.open > .btn-vk.dropdown-toggle {
  color: #fff;
  background-color: #7390b1;
  border-color: rgba(0, 0, 0, 0);
}

.btn-vk:active:hover, .btn-vk:active:focus, .btn-vk:active.focus, .btn-vk.active:hover, .btn-vk.active:focus, .btn-vk.active.focus,
.open > .btn-vk.dropdown-toggle:hover,
.open > .btn-vk.dropdown-toggle:focus,
.open > .btn-vk.dropdown-toggle.focus {
  color: #fff;
  background-color: #415874;
  border-color: rgba(0, 0, 0, 0);
}

.btn-vk:active, .btn-vk.active,
.open > .btn-vk.dropdown-toggle {
  background-image: none;
}

.btn-vk.disabled:hover, .btn-vk.disabled:focus, .btn-vk.disabled.focus, .btn-vk[disabled]:hover, .btn-vk[disabled]:focus, .btn-vk[disabled].focus,
fieldset[disabled] .btn-vk:hover,
fieldset[disabled] .btn-vk:focus,
fieldset[disabled] .btn-vk.focus {
  background-color: #6383A8;
  border-color: transparent;
}

.btn-vk .badge {
  color: #6383A8;
  background-color: #fff;
}

.btn-odnoklassniki {
  color: #fff;
  background-color: #F4731C;
  border-color: transparent;
}

.btn-odnoklassniki:focus, .btn-odnoklassniki.focus {
  color: #fff;
  background-color: #f58234;
  border-color: rgba(0, 0, 0, 0);
}

.btn-odnoklassniki:hover {
  color: #fff;
  background-color: #f58234;
  border-color: rgba(0, 0, 0, 0);
}

.btn-odnoklassniki:active, .btn-odnoklassniki.active,
.open > .btn-odnoklassniki.dropdown-toggle {
  color: #fff;
  background-color: #f58234;
  border-color: rgba(0, 0, 0, 0);
}

.btn-odnoklassniki:active:hover, .btn-odnoklassniki:active:focus, .btn-odnoklassniki:active.focus, .btn-odnoklassniki.active:hover, .btn-odnoklassniki.active:focus, .btn-odnoklassniki.active.focus,
.open > .btn-odnoklassniki.dropdown-toggle:hover,
.open > .btn-odnoklassniki.dropdown-toggle:focus,
.open > .btn-odnoklassniki.dropdown-toggle.focus {
  color: #fff;
  background-color: #b14c09;
  border-color: rgba(0, 0, 0, 0);
}

.btn-odnoklassniki:active, .btn-odnoklassniki.active,
.open > .btn-odnoklassniki.dropdown-toggle {
  background-image: none;
}

.btn-odnoklassniki.disabled:hover, .btn-odnoklassniki.disabled:focus, .btn-odnoklassniki.disabled.focus, .btn-odnoklassniki[disabled]:hover, .btn-odnoklassniki[disabled]:focus, .btn-odnoklassniki[disabled].focus,
fieldset[disabled] .btn-odnoklassniki:hover,
fieldset[disabled] .btn-odnoklassniki:focus,
fieldset[disabled] .btn-odnoklassniki.focus {
  background-color: #F4731C;
  border-color: transparent;
}

.btn-odnoklassniki .badge {
  color: #F4731C;
  background-color: #fff;
}

.btn-link {
  color: rgba(49, 64, 75, 0.4);
}

.btn-link:hover {
  text-decoration: none;
  color: #31404b;
}

.btn-lg {
  padding: 16px 80px;
  font-size: 13px;
  line-height: 1.45em;
  border-radius: 2px;
}

.btn-sm {
  padding: 10px 38px;
  font-size: 11px;
  line-height: 1.5em;
  border-radius: 2px;
}

.btn-xs {
  padding: 5px 13px;
  font-size: 9px;
  line-height: 1.5em;
  border-radius: 2px;
}

.btn-outline {
  background-color: transparent;
}

.btn-outline.btn-default {
  border-color: #dbdfe6;
  color: #9a9da2;
}

.btn-outline.btn-default:hover {
  background-color: #9a9da2;
  border-color: #9a9da2;
  color: #fff;
}

.btn-outline.btn-default:hover.btn-circle {
  background-color: #9a9da2;
}

.btn-outline.btn-primary {
  border-color: #e4e7ed;
  color: #31404b;
}

.btn-outline.btn-primary:hover {
  background-color: #1892ed;
  border-color: #1892ed;
  color: #fff;
}

.btn-outline.btn-primary:hover.btn-circle {
  background-color: #1892ed;
}

.btn-outline.btn-inverse {
  border-color: #fff;
  color: #fff;
  border-width: 2px;
}

.btn-outline.btn-inverse:hover {
  background-color: #1892ed;
  border-color: #1892ed;
  color: #fff;
}

.btn-outline.btn-inverse:hover i {
  color: #fff;
}

.btn-outline.btn-inverse:hover.btn-circle {
  background-color: #1892ed;
}

.btn-outline.btn-success {
  border-color: #9fe900;
  color: #9a9da2;
  border-width: 2px;
}

.btn-outline.btn-success:hover {
  background-color: #95da00;
  border-color: #95da00;
  color: #fff;
}

.btn-outline.btn-success:hover i {
  color: #fff;
}

.btn-outline.btn-success:hover.btn-circle {
  background-color: #95da00;
}

.btn-outline.btn-info {
  border-color: #0cb2e2;
  color: #9a9da2;
  border-width: 2px;
}

.btn-outline.btn-info:hover {
  background-color: #0ba7d3;
  border-color: #0ba7d3;
  color: #fff;
}

.btn-outline.btn-info:hover i {
  color: #fff;
}

.btn-outline.btn-info:hover.btn-circle {
  background-color: #0ba7d3;
}

.btn-outline.btn-warning {
  border-color: #ff7e1f;
  color: #9a9da2;
  border-width: 2px;
}

.btn-outline.btn-warning:hover {
  background-color: #ff7510;
  border-color: #ff7510;
  color: #fff;
}

.btn-outline.btn-warning:hover i {
  color: #fff;
}

.btn-outline.btn-warning:hover.btn-circle {
  background-color: #ff7510;
}

.btn-outline.btn-danger {
  border-color: #f34141;
  color: #9a9da2;
  border-width: 2px;
}

.btn-outline.btn-danger:hover {
  background-color: #f23333;
  border-color: #f23333;
  color: #fff;
}

.btn-outline.btn-danger:hover i {
  color: #fff;
}

.btn-outline.btn-danger:hover.btn-circle {
  background-color: #f23333;
}

.btn-icon i {
  font-size: 1em;
  margin-right: .5em;
}

.btn-icon-right i {
  margin-right: 0;
  margin-left: 10px;
}

.btn-single-icon {
  padding-left: 0;
  padding-right: 0;
  width: 47px;
  text-align: center;
}

.btn-single-icon i {
  margin: 0;
  font-size: 13px;
  line-height: 1.2em;
}

.btn-circle {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  line-height: 38px;
  padding: 0;
  text-align: center;
}

.btn-circle i {
  font-size: 15px;
}

.btn-circle.btn-outline {
  border-width: 2px;
}

.btn-circle.btn-xs {
  width: 24px;
  height: 24px;
  line-height: 22px;
}

.btn-circle.btn-xs i {
  font-size: 9px;
}

.btn-block {
  padding-left: 0;
  padding-right: 0;
}

.btn-condensed.btn-sm {
  padding: 13px 28px;
}

.btn-condensed.btn-sm.btn-icon-right {
  padding-right: 22px;
}

.btn-fab {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background-color: #1892ed;
  border-radius: 50%;
}

.btn-fab::before, .btn-fab::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: transform 0.25s ease;
}

.btn-fab::before {
  width: 17px;
  height: 3px;
}

.btn-fab::after {
  width: 3px;
  height: 17px;
}

.btn-fab:hover::before, .btn-fab:hover::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.btn-fab--clean {
  background-color: transparent;
}

.btn-fab--clean::before, .btn-fab--clean::after {
  background-color: #38a9ff;
}

.btn-fab--sm {
  width: 30px;
  height: 30px;
}

.btn-fab--sm::before {
  width: 10px;
  height: 2px;
}

.btn-fab--sm::after {
  width: 2px;
  height: 10px;
}

/* Card
/* ------------------------- */
.card {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 15px;
}

.card--row {
  flex-direction: row;
}

.card--block {
  display: block;
}

.card--clean {
  background: none;
  border: none;
}

.card--clean .card__header {
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.card--clean > .card__content {
  padding: 15px 0 0 0;
}

.card--has-table > .card__content {
  padding: 0;
}

.card--has-table > .card__content .table {
  margin-bottom: 0;
}

.card--no-paddings > .card__content {
  padding: 0;
}

.card--squared-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card__header {
  padding: 27px 23px;
  border-radius: 4px 4px 0 0;
  position: relative;
  border-bottom: 1px solid #e4e7ed;
  overflow: hidden;
  background-color: transparent;
}

.card__header ~ .card__header {
  border-radius: 0;
}

.card__header ~ .card__header::before {
  border-radius: 0;
}

.card__header::before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #38a9ff;
  border-radius: 3px 0 0 0;
}

.card__header > h4 {
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  text-transform: uppercase;
  font-style: normal;
}

@media (min-width: 992px) {
  .card__header .card-header__button {
    float: right;
  }
}

.card__header--has-btn {
  padding-top: 24px;
  padding-bottom: 24px;
}

.card__header--has-btn > h4 {
  margin-bottom: .5em;
}

@media (min-width: 480px) {
  .card__header--has-btn > h4 {
    display: inline-block;
    margin-bottom: 0;
  }
}

.card__header--has-filter {
  padding-top: 24px;
  padding-bottom: 24px;
}

.card__header--has-filter > h4 {
  display: inline-block;
}

.card__header--has-filter .category-filter {
  padding-top: 5px;
}

@media (min-width: 480px) {
  .card__header--has-filter .category-filter {
    float: right;
    padding-top: 0;
  }
}

.card__header--has-legend {
  padding-top: 24px;
  padding-bottom: 24px;
}

.card__header--has-legend > h4 {
  display: inline-block;
}

.card__header--has-legend .chart-legend {
  padding-top: 5px;
}

@media (min-width: 480px) {
  .card__header--has-legend .chart-legend {
    float: right;
    padding-top: 0;
  }
}

.card__header--has-checkbox {
  padding-top: 24px;
  padding-bottom: 24px;
}

.card__header--has-checkbox > h4 {
  margin-bottom: .5em;
}

@media (min-width: 480px) {
  .card__header--has-checkbox > h4 {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media (min-width: 480px) {
  .card__header--has-checkbox .checkbox {
    float: right;
    padding-top: 0;
    font-size: 10px;
    font-weight: bold;
  }
}

.card__header--has-toggle {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
}

.card__header--has-toggle .switch {
  flex-grow: 1;
  text-align: right;
}

.card__header--has-arrows .slick-prev,
.card__header--has-arrows .slick-next {
  top: 25px;
}

.card__header--no-highlight::before {
  display: none;
}

.card__content {
  padding: 24px 24px;
}

.card__content-inner {
  padding: 20px;
}

.card__content--header-bg {
  background-color: transparent;
}

.card__subheader {
  background: #f5f7f9;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
  text-align: center;
  padding: 8px 0;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .card__subheader {
    padding: 11px 0;
    margin-bottom: 30px;
  }
}

.card__subheader h5 {
  margin-bottom: 0;
  font-style: normal;
  text-transform: uppercase;
}

.card__subheader--nomargins {
  margin: 0;
}

.card__subheader--sm h5 {
  font-size: 11px;
  line-height: 1.2em;
}

.card__subheader--side {
  text-align: left;
  border-top: 0;
  background-color: transparent;
  padding: 15px 24px;
}

.card__subheader--side h5 {
  font-style: italic;
}

.card__subheader--flex {
  display: flex;
  border-top: 1px solid #e4e7ed;
}

.card__subheader--flex :last-child {
  margin-left: auto;
}

.card__subheader--flex h5 {
  font-style: normal;
}

@media (min-width: 992px) {
  .card--lg .card__content {
    padding: 43px 43px;
  }
}

@media (min-width: 992px) {
  .card--xlg .card__content {
    padding: 70px 70px;
  }
}

.card--alt-color .card__header::before {
  background-color: #9fe900;
}

.card__header--shop-filter {
  padding: 0;
}

.card--section {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.card--info .card__content p {
  font-size: .86em;
  line-height: 1.83em;
}

.card--info .contact-info {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #e4e7ed;
}

.card--info .contact-info__item {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  margin: -1px 0 0 -1px;
  text-align: center;
  padding: 25px 10px;
  border-left: 1px solid #e4e7ed;
  border-top: 1px solid #e4e7ed;
}

.card--info .contact-info__icon {
  color: #38a9ff;
  font-size: 18px;
  margin-bottom: 20px;
}

.card--info .contact-info__label {
  font-size: .86em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #31404b;
}

.card--info .contact-info__label a {
  color: #31404b;
}

.card--info .contact-info__label a:hover {
  color: #38a9ff;
}

/* Labels
/* ------------------------- */
.label {
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .5em 1.1em;
  font-size: 8px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
}

a.label:hover, a.label:focus {
  color: #fff;
}

.label-default {
  background-color: #9a9da2;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #7f838a;
}

.label-primary {
  background-color: #38a9ff;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #0593ff;
}

.label-success {
  background-color: #24d9b0;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #1dad8d;
}

.label-info {
  background-color: #0cb2e2;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #098cb2;
}

.label-warning {
  background-color: #ff7e1f;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #eb6400;
}

.label-danger {
  background-color: #f34141;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #f01111;
}

/* Forms
/* ------------------------- */
label {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  margin-bottom: 9px;
  font-weight: bold;
}

label .required {
  color: #38a9ff;
}

.form-control {
  appearance: none;
  padding: 12px 16px;
  line-height: 1.73333;
  font-size: 15px;
  color: #31404b;
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 2px;
  box-shadow: none;
  transition: border-color ease-in-out .15s;
}

.form-control:not(textarea) {
  height: 52px;
}

.form-control:focus {
  color: #31404b;
  background-color: #fff;
  border-color: #38a9ff;
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: rgba(154, 157, 162, 0.6);
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: rgba(154, 157, 162, 0.6);
}

.form-control::-webkit-input-placeholder {
  color: rgba(154, 157, 162, 0.6);
}

.form-control[disabled], .form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}

.form-control[disabled]::-moz-placeholder, .form-control[readonly]::-moz-placeholder,
fieldset[disabled] .form-control::-moz-placeholder {
  color: rgba(154, 157, 162, 0.4);
  opacity: 1;
}

.form-control[disabled]:-ms-input-placeholder, .form-control[readonly]:-ms-input-placeholder,
fieldset[disabled] .form-control:-ms-input-placeholder {
  color: rgba(154, 157, 162, 0.4);
}

.form-control[disabled]::-webkit-input-placeholder, .form-control[readonly]::-webkit-input-placeholder,
fieldset[disabled] .form-control::-webkit-input-placeholder {
  color: rgba(154, 157, 162, 0.4);
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #31404b;
}

.has-success .form-control {
  border-color: #24d9b0;
  box-shadow: none;
  background-position: center right 0.9em;
  background-repeat: no-repeat;
  background-size: 20px 16px;
  padding-right: 2.2em;
}

.has-success .form-control:focus {
  border-color: #24d9b0;
  box-shadow: none;
}

.has-success .input-group-addon {
  color: #24d9b0;
  border-color: #24d9b0;
  background-color: #fff;
}

.has-success .form-control-feedback {
  color: #24d9b0;
}

.has-success .form-control {
  background-size: 15px 12px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='%2324d9b0' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E");
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #31404b;
}

.has-warning .form-control {
  border-color: #1892ed;
  box-shadow: none;
  background-position: center right 0.9em;
  background-repeat: no-repeat;
  background-size: 20px 16px;
  padding-right: 2.2em;
}

.has-warning .form-control:focus {
  border-color: #1892ed;
  box-shadow: none;
}

.has-warning .input-group-addon {
  color: #1892ed;
  border-color: #1892ed;
  background-color: #fff;
}

.has-warning .form-control-feedback {
  color: #1892ed;
}

.has-warning .form-control {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff7e1f' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #31404b;
}

.has-error .form-control {
  border-color: #f34141;
  box-shadow: none;
  background-position: center right 0.9em;
  background-repeat: no-repeat;
  background-size: 20px 16px;
  padding-right: 2.2em;
}

.has-error .form-control:focus {
  border-color: #f34141;
  box-shadow: none;
}

.has-error .input-group-addon {
  color: #f34141;
  border-color: #f34141;
  background-color: #fff;
}

.has-error .form-control-feedback {
  color: #f34141;
}

.has-error .form-control {
  background-size: 11px 11px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'%3E%3Cpath transform='translate(-190.844 -2353.84)' fill='%23f34141' d='M190.843,2355.96l2.121-2.12,9.193,9.2-2.122,2.12Zm9.192-2.12,2.122,2.12-9.193,9.2-2.121-2.12Z'/%3E%3C/svg%3E");
}

select.form-control {
  border-radius: 2px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%2331404b' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-position: right 1em center;
  background-repeat: no-repeat;
  padding-right: 2.2em;
  background-size: 6px 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: rgba(154, 157, 162, 0.4);
}

select.form-control::-ms-expand {
  display: none;
}

select.form-control option {
  background-color: #fff;
  color: #31404b;
}

select.form-control.input-sm {
  height: 45px;
  padding-top: 7px;
  padding-bottom: 7px;
}

select.form-control:not([size]):not([multiple]) {
  height: 52px;
}

select.form-control--clean {
  font-size: 15px;
  height: 28px !important;
  line-height: 1.2em;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: transparent;
}

.input-sm {
  padding: 10px 14px;
  font-size: 13px;
}

.input-sm:not([size]):not([multiple]):not(textarea) {
  height: 45px;
}

.form-group {
  background: none;
  margin-bottom: 22px;
}

.form-group--sm {
  margin-bottom: 15px;
}

.form-group--xs {
  margin-bottom: 10px;
}

.form-group--lg {
  margin-bottom: 34px;
}

.form-group--submit {
  margin-bottom: 0;
  padding-top: 12px;
}

.form-group--password-forgot {
  display: flex;
  padding: 6px 0;
}

.form-group--password-forgot label {
  flex-grow: 1;
}

.form-group--password-forgot .password-reminder {
  font-size: 11px;
  text-transform: uppercase;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
}

.form-group--upload {
  overflow: hidden;
}

@media (min-width: 768px) {
  .form-group__avatar {
    float: left;
    width: 240px;
    width: 240px;
  }
}

.form-group__avatar img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px;
  border-radius: 50%;
}

.form-group__avatar-wrapper {
  font-style: normal;
}

.form-group__avatar-wrapper:hover {
  cursor: pointer;
}

.form-group__avatar-wrapper .form-group__avatar-img {
  position: relative;
  display: inline-block;
}

.form-group__avatar-wrapper .form-group__avatar-img::before {
  content: "\f067";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #31404b;
  color: #31404b;
  font-family: 'Font Awesome 5 Free';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.form-group__avatar--center {
  text-align: center;
}

@media (min-width: 768px) {
  .form-group__avatar--center {
    float: none;
    width: 50%;
    margin: 0 auto;
  }
}

.form-group__avatar--center img {
  margin: 0 0 13px 0;
  border: 1px solid #e4e7ed;
}

.form-group__avatar--center .form-group__label {
  display: block;
  text-align: center;
}

.form-group__avatar--center .form-group__label h6 {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: .35em;
}

.form-group__avatar--center .form-group__label span {
  display: block;
  font-size: 10px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #9a9da2;
}

.form-group__label {
  display: inline-block;
  vertical-align: middle;
}

.form-group__label h6 {
  font-size: 11px;
  line-height: 1.2em;
  margin-bottom: .2em;
}

.form-group__label span {
  display: block;
  font-size: 11px;
  line-height: 1.2em;
  color: #c2c4c7;
}

.form-group__upload {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .form-group__upload {
    padding-top: 15px;
  }
}

.checkbox {
  position: relative;
  margin: 0;
  font-weight: bold;
}

.widget--sidebar .checkbox {
  font-size: 10px;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox input[type="checkbox"]:checked + .checkbox-indicator {
  background-color: #1892ed;
  border-color: #1892ed;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.checkbox .checkbox-indicator {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d8dce5;
  border-radius: 2px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
}

.checkbox.checkbox-inline {
  padding-left: 24px;
}

.checkbox input[type="checkbox"]:disabled + .checkbox-indicator {
  background-color: #e4e7ed;
  border-color: #d8dce5;
}

.checkbox-table {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-table .checkbox--cell {
  margin: 0 0 -1px -1px;
}

.checkbox--cell {
  width: 44px;
  height: 44px;
  position: relative;
}

.checkbox--cell input[type="checkbox"]:checked + .checkbox-indicator {
  background-image: none !important;
  background-color: #1892ed;
  background-image: none;
  border-color: #d8dce5;
  color: #fff;
}

.checkbox--cell .checkbox-indicator {
  content: "";
  width: 44px;
  height: 44px;
  background-color: #fff;
  border: 1px solid #d8dce5;
  border-radius: 0;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
  transition: all 0.2s ease;
  text-align: center;
  line-height: 42px;
}

.radio {
  position: relative;
  margin: 0;
}

.radio input[type="radio"] {
  display: none;
}

.radio input[type="radio"]:checked + .radio-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2338a9ff'/%3E%3C/svg%3E");
}

.radio .radio-indicator {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d8dce5;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 8px 8px;
}

.radio.radio-inline {
  padding-left: 24px;
}

.radio input[type="radio"]:disabled + .radio-indicator {
  background-color: #e4e7ed;
  border-color: #d8dce5;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%239a9da2'/%3E%3C/svg%3E");
}

.radio--word {
  position: relative;
  padding: 0 !important;
}

.radio--word + .radio--word {
  margin-left: 20px;
}

.radio--word input[type="radio"]:checked + .radio-label {
  color: #31404b;
}

.radio--word .radio-label {
  color: #bfc2c4;
  font-weight: 700;
}

.radio--word-lg {
  font-size: 15px;
  line-height: 1.2em;
}

.inline-form .input-group-prepend .btn,
.inline-form .input-group-append .btn {
  padding-left: 28px;
  padding-right: 28px;
}

abbr.required {
  border-bottom: none;
  color: #1892ed;
  margin-left: .2em;
  text-decoration: none;
}

.quantity-control {
  overflow: hidden;
}

.quantity-control .btn {
  display: inline-block;
  height: 30px;
  padding: 0 8px;
  background: none;
  border: none;
  color: #adb3b7;
  vertical-align: middle;
}

.quantity-control .btn:hover, .quantity-control .btn:focus, .quantity-control .btn:active {
  color: #31404b;
}

.quantity-control .form-control {
  display: inline-block;
  width: 34px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  appearance: textfield;
  border: none;
  text-align: center;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  vertical-align: middle;
  background-color: transparent;
  color: #31404b;
}

.quantity-control .form-control::-webkit-outer-spin-button, .quantity-control .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product-quantity-control {
  width: 80px;
  margin: 0 auto;
}

.form-desc--sm {
  font-size: 14px;
  line-height: 22px;
}

.switch {
  white-space: nowrap;
}

.switch__label-txt {
  display: inline-block;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  vertical-align: middle;
  margin-right: .35em;
  color: #31404b;
}

@media (max-width: 479px) {
  .switch__label-txt {
    display: none;
  }
}

.switch-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
  margin: 0;
}

.switch-toggle + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
  vertical-align: middle;
  padding: 2px;
  width: 48px;
  height: 24px;
  margin: 0;
  background-color: #e4e7ed;
  border-radius: 22px;
  transition: background 0.4s;
  border: 1px solid #e4e7ed;
}

.switch-toggle + label::before, .switch-toggle + label::after {
  display: block;
  position: absolute;
  content: "";
}

.switch-toggle + label::after {
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 18px;
  background-color: #fff;
  background-image: linear-gradient(to right, #e9e9ed, #e9e9ed 2px, transparent 2px), linear-gradient(to right, #e9e9ed, #e9e9ed 2px, transparent 2px);
  background-size: 2px 8px, 2px 8px;
  background-position: 6px 5px, 10px 5px;
  background-repeat: no-repeat, no-repeat;
  border-radius: 50%;
  transition: margin 0.4s, background 0.4s;
}

.switch-toggle:checked + label {
  background-color: #24d9b0;
}

.switch-toggle:checked + label::after {
  margin-left: 26px;
}

/* Tooltips
/* ------------------------- */
.tooltip {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  background-color: #38a9ff;
  border-radius: 2px;
}

.bs-tooltip-top .arrow::before {
  border-top-color: #38a9ff;
}

.bs-tooltip-right .arrow::before {
  border-right-color: #38a9ff;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #38a9ff;
}

.bs-tooltip-left .arrow::before {
  border-left-color: #38a9ff;
}

.tooltip--card .tooltip-inner {
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 18px 24px;
  max-width: 240px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.tooltip--card-sm .tooltip-inner {
  padding: 16px 18px;
}

.alc-circular-tooltip-stats {
  font-size: 10px;
  line-height: 1.2em;
  font-style: italic;
  text-align: left;
}

.alc-circular-tooltip-stats + .alc-circular-tooltip-stats {
  margin-top: 6px;
}

.alc-circular-tooltip-stat__circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.alc-circular-tooltip-stat__value {
  display: inline-block;
  margin-right: 0.15em;
}

.alc-circular-tooltip-stat__label {
  color: #9a9da2;
  display: inline-block;
}

/* Countdown
/* ------------------------- */
.countdown__content {
  padding: 15px 0 5px 0;
}

.countdown-counter {
  overflow: hidden;
}

.countdown-counter__item {
  font-size: 26px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-align: center;
  float: left;
  width: 25%;
  position: relative;
}

.countdown-counter__item + .countdown-counter__item::before {
  content: ":";
  display: block;
  position: absolute;
  left: 0;
  top: -3px;
  font-size: 26px;
  line-height: 1.2em;
  font-weight: normal;
}

.countdown-counter__label {
  display: block;
  font-size: 10px;
  line-height: 1.2em;
  padding-top: 3px;
  color: #9a9da2;
}

/* Breadcrumbs
/* ------------------------- */
.breadcrumb {
  padding: 3px 0;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 5px;
  color: #c2ff1f !important;
}

.breadcrumb-item > a {
  transition: color 0.2s ease;
}

.breadcrumb-item > a:hover {
  color: #c2ff1f !important;
}

.breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.5);
}

.page-heading__breadcrumb {
  justify-content: center;
}

@media (min-width: 768px) {
  .align-self-end .page-heading__breadcrumb {
    justify-content: flex-end;
  }
}

/* Content Navigation
/* ------------------------- */
.btn-nav {
  display: inline-block;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 3px;
  background-color: #9a9da2;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  transition: all 0.3s ease;
}

.btn-nav:hover {
  color: #fff;
  background-color: #31404b;
}

/* Pagination
/* ------------------------- */
.pagination {
  flex-wrap: wrap;
  margin: 20px 0;
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus {
  background-color: #1892ed;
  border-color: #1892ed;
  color: #fff;
}

.page-link {
  margin: 0 4px;
  padding: 0;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  background-color: #fff;
  border-radius: 3px;
  border-color: #e4e7ed;
  color: #31404b;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  transition: all 0.2s ease;
  position: static;
}

.page-link:hover {
  background-color: #1892ed;
  border-color: #1892ed;
  color: #fff;
}

.pagination--condensed .page-item:first-child .page-link, .pagination--condensed .page-item:last-child .page-link {
  border-radius: 0;
}

.pagination--condensed .page-link {
  border-radius: 0;
  margin: 0;
  margin-left: -1px;
}

.pagination--lg .page-link {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.pagination--circle .page-item:first-child .page-link, .pagination--circle .page-item:last-child .page-link {
  border-radius: 50%;
}

.pagination--circle .page-link {
  width: 32px;
  height: 32px;
  line-height: 30px;
  border-radius: 50%;
  margin: 0 2px;
}

@media (min-width: 992px) {
  .pagination--circle .page-link {
    width: 46px;
    height: 46px;
    line-height: 44px;
    margin: 0 5px;
  }
}

/* Progress Bars
/* ------------------------- */
.progress-stats {
  overflow: hidden;
  position: relative;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
}

.progress-stats .progress {
  flex-grow: 1;
  margin-bottom: 0;
}

.progress {
  overflow: hidden;
  height: 6px;
  margin-bottom: 10px;
  background-color: #ecf0f6;
  border-radius: 3px;
  box-shadow: none;
  position: relative;
  top: 2px;
}

.progress__bar {
  float: left;
  width: 0%;
  height: 100%;
  line-height: 6px;
  color: #fff;
  background-color: #38a9ff;
  box-shadow: none;
  border-radius: 3px;
}

.progress__label,
.progress__number {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1em;
}

.progress__label {
  color: #31404b;
}

.progress__label + .progress {
  margin-left: 10px;
}

.progress__label.progress__label--color-default {
  color: #9a9da2;
}

.progress__number {
  font-weight: normal;
}

.progress__number.progress__number--color-highlight {
  color: #31404b;
}

.progress + .progress__number {
  margin-left: 10px;
}

.progress__label-top {
  display: block;
  text-align: right;
}

.progress__bar--secondary {
  background-color: #c2ff1f;
}

.progress__bar--success {
  background-color: #9fe900;
}

.progress__bar--info {
  background-color: #0cb2e2;
}

.progress__bar--warning {
  background-color: #ff7e1f;
}

.progress__bar--danger {
  background-color: #f34141;
}

.progress__bar--aqua-gradient {
  background-color: transparent;
  background-image: linear-gradient(to right, #0683d3, #3ffeca 100%);
}

.progress__bar--blue-pink {
  background-color: transparent;
  background-image: linear-gradient(to right, #189aff, #f21956 100%);
}

.progress__bar--custom-color-1 {
  background-color: #fff7ca;
}

.progress-double-wrapper + .progress-double-wrapper {
  margin-top: 12px;
}

.progress-double-wrapper .progress--battery {
  margin-bottom: 0;
}

.progress-double-wrapper--fullwidth .progress--battery {
  background-image: none !important;
}

.progress-inner-holder {
  display: table;
  width: 100%;
  padding-bottom: 3px;
}

.progress__digit {
  display: table-cell;
  width: 1%;
  vertical-align: middle;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  font-weight: 400;
}

.progress__digit--40 {
  width: 40px;
}

.progress__digit--40.progress__digit--left {
  padding-right: 0;
}

.progress__digit--40.progress__digit--right {
  text-align: right;
  padding-left: 0;
}

.progress__digit--38 {
  width: 38px;
}

.progress__digit--38.progress__digit--left {
  padding-right: 0;
}

.progress__digit--38.progress__digit--right {
  text-align: right;
  padding-left: 0;
}

.progress__digit--highlight {
  color: #31404b;
}

.progress__digit--left {
  padding-right: 20px;
}

.progress__digit--right {
  padding-left: 20px;
}

.progress-title {
  font-size: 10px;
  line-height: 1.2em;
  margin-bottom: 0.6em;
  text-align: center;
}

.progress__double {
  display: table-cell;
}

.progress__double .progress {
  width: 50%;
  float: left;
  margin-bottom: 0;
  top: 3px;
}

.progress__double .progress:first-child {
  border-radius: 3px 0 0 3px;
}

.progress__double .progress:first-child .progress__bar {
  margin-left: auto;
  border-radius: 3px 0 0 3px;
}

.progress__double .progress:last-child {
  border-radius: 0 3px 3px 0;
}

.progress__double .progress:last-child .progress__bar {
  border-radius: 0 3px 3px 0;
}

.progress__double .progress.progress--battery:first-child {
  transform: scale(-1, 1);
  margin-right: 2px;
  width: calc(50% - 2px);
}

.progress__double .progress.progress--battery:first-child .progress__bar {
  margin-left: 0;
}

.progress__double .progress.progress--battery:last-child {
  margin-left: 2px;
  width: calc(50% - 2px);
}

.progress__double--label-center .progress-title {
  float: left;
  width: 38%;
}

.progress__double--label-center .progress {
  width: 31%;
}

.progress-stats--top-labels {
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: visible;
}

.progress-stats--top-labels .progress__label {
  order: 0;
}

.progress-stats--top-labels .progress {
  flex-basis: 100%;
  order: 2;
  margin-left: 0 !important;
  margin-top: 12px;
  margin-bottom: 10px;
}

.progress-stats--top-labels .progress__number {
  order: 1;
  font-weight: 700;
}

.progress--lg {
  height: 9px;
  border-radius: 5px;
}

.progress--lg .progress__bar {
  border-radius: 5px;
}

.progress--lg .progress__double .progress:first-child {
  border-radius: 5px 0 0 5px;
}

.progress--lg .progress__double .progress:first-child .progress__bar {
  border-radius: 5px 0 0 5px;
}

.progress--lg .progress__double .progress:last-child {
  border-radius: 0 5px 5px 0;
}

.progress--lg .progress__double .progress:last-child .progress__bar {
  border-radius: 0 5px 5px 0;
}

/* Progress - Battery */
.progress--battery {
  height: 20px;
  margin-bottom: 18px;
  top: 0;
  border-radius: 0;
  background-color: transparent;
  background-image: radial-gradient(circle, #ecf0f6, #ecf0f6 2px, transparent 2px, transparent), radial-gradient(circle, #ecf0f6, #ecf0f6 2px, transparent 2px, transparent), linear-gradient(to right, #ecf0f6, #ecf0f6 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x, repeat-x;
}

.progress--battery .progress__bar {
  line-height: 20px;
  border-radius: 0;
  position: relative;
  z-index: 1;
  background-color: transparent;
  background-image: radial-gradient(circle, #38a9ff, #38a9ff 2px, transparent 2px, transparent), radial-gradient(circle, #38a9ff, #38a9ff 2px, transparent 2px, transparent), linear-gradient(to right, #38a9ff, #38a9ff 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x;
}

.progress--battery .progress__bar--secondary {
  line-height: 20px;
  border-radius: 0;
  position: relative;
  z-index: 1;
  background-color: transparent;
  background-image: radial-gradient(circle, #c2ff1f, #c2ff1f 2px, transparent 2px, transparent), radial-gradient(circle, #c2ff1f, #c2ff1f 2px, transparent 2px, transparent), linear-gradient(to right, #c2ff1f, #c2ff1f 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x;
}

.progress--battery .progress__bar--success {
  background-color: transparent;
  background-image: radial-gradient(circle, #9fe900, #9fe900 2px, transparent 2px, transparent), radial-gradient(circle, #9fe900, #9fe900 2px, transparent 2px, transparent), linear-gradient(to right, #9fe900, #9fe900 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x;
}

.progress--battery .progress__bar--info {
  background-color: transparent;
  background-image: radial-gradient(circle, #0cb2e2, #0cb2e2 2px, transparent 2px, transparent), radial-gradient(circle, #0cb2e2, #0cb2e2 2px, transparent 2px, transparent), linear-gradient(to right, #0cb2e2, #0cb2e2 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x;
}

.progress--battery .progress__bar--warning {
  background-color: transparent;
  background-image: radial-gradient(circle, #ff7e1f, #ff7e1f 2px, transparent 2px, transparent), radial-gradient(circle, #ff7e1f, #ff7e1f 2px, transparent 2px, transparent), linear-gradient(to right, #ff7e1f, #ff7e1f 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x;
}

.progress--battery .progress__bar--danger {
  background-color: transparent;
  background-image: radial-gradient(circle, #f34141, #f34141 2px, transparent 2px, transparent), radial-gradient(circle, #f34141, #f34141 2px, transparent 2px, transparent), linear-gradient(to right, #f34141, #f34141 4px, transparent 4px, transparent 8px);
  background-size: 8px 8px, 8px 8px, 8px 16px;
  background-position: -2px -2px, -2px 14px, 0 2px;
  background-repeat: repeat-x, repeat-x, repeat-x;
}

/* Progress - Dashed */
.progress--dashed {
  height: 4px;
  border-radius: 0;
}

.progress--dashed::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  transform: scaleY(-1);
  background-image: linear-gradient(45deg, #fff 0%, #fff 3px, transparent 3px, transparent 12%, #fff 12%, #fff calc(12% + 3px), transparent calc(12% + 3px), transparent 25%, #fff 25%, #fff calc(25% + 3px), transparent calc(25% + 3px), transparent 41%, #fff 41%, #fff calc(41% + 3px), transparent calc(41% + 3px), transparent 57%, #fff 57%, #fff calc(57% + 3px), transparent calc(57% + 3px), transparent 77%, #fff 77%, #fff calc(77% + 3px), transparent calc(77% + 3px), transparent calc(100% - 3px), #fff calc(100% - 3px), #fff 100%);
}

@supports (position: -ms-page) {
  .progress--dashed::before {
    background-image: linear-gradient(45deg, #fff 0%, #fff 3px, transparent 3px, transparent 12%, #fff 12%, #fff 13%, transparent 13%, transparent 25%, #fff 25%, #fff 26%, transparent 26%, transparent 41%, #fff 41%, #fff 42%, transparent 42%, transparent 57%, #fff 57%, #fff 58%, transparent 58%, transparent 77%, #fff 77%, #fff 78%, transparent 78%, transparent 99%, #fff 99%, #fff 100%);
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .progress--dashed::before {
    background-image: linear-gradient(45deg, #fff 0%, #fff 3px, transparent 3px, transparent 12%, #fff 12%, #fff 13%, transparent 13%, transparent 25%, #fff 25%, #fff 26%, transparent 26%, transparent 41%, #fff 41%, #fff 42%, transparent 42%, transparent 57%, #fff 57%, #fff 58%, transparent 58%, transparent 77%, #fff 77%, #fff 78%, transparent 78%, transparent 99%, #fff 99%, #fff 100%);
  }
}

.progress--dashed .progress__bar {
  border-radius: 0;
}

/* Progress - Lines */
.progress--lines {
  height: 20px;
  border-radius: 0;
  padding-left: 1px;
  padding-right: 1px;
  position: relative;
  transform: skewX(-10deg);
}

.progress--lines::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: transparent;
  background-image: linear-gradient(to right, #fff, #fff 3px, transparent 3px, transparent 6px);
  background-size: 6px 20px;
  background-position: 0 0;
  background-repeat: repeat-x;
}

.progress--lines .progress__bar {
  line-height: 20px;
  border-radius: 0;
  position: relative;
  z-index: 1;
}

/* Progress Table */
.progress-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  padding: 0;
  border-spacing: 0;
  font-size: 12px;
  line-height: 1.2em;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.progress-table tr + tr {
  border-top: 36px solid transparent;
}

.progress-table td {
  vertical-align: middle;
}

.progress-table--sm-space tr + tr {
  border-top-width: 28px;
}

.progress-table__progress-bar {
  width: 27%;
}

.progress-table__progress-bar .progress {
  margin-bottom: 0;
  top: 0;
}

.progress-table__progress-bar--first .progress__bar {
  margin-left: auto;
}

.progress-table__progress-label {
  width: 34px;
  text-align: center;
  font-size: 11px;
  padding: 0 10px;
  color: #9a9da2;
}

.progress-table__progress-label--highlight {
  color: #31404b;
}

.progress-table__title {
  width: 1px;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
}

/* Progress Table: one progress bar */
.progress-table--simple tr + tr {
  border-top-width: 12px;
}

@media (min-width: 992px) {
  .progress-table--simple tr + tr {
    border-top-width: 18px;
  }
}

.progress-table--simple .progress-table__progress-bar {
  width: 100%;
}

.progress-table--simple .progress-table__title {
  text-align: left;
  padding: 0 14px 0 0;
}

/* Progress Table: Full Width */
.progress-table--fullwidth .progress-table__progress-label:first-child {
  text-align: left;
  padding-left: 0;
}

.progress-table--fullwidth .progress-table__progress-label:last-child {
  text-align: right;
  padding-right: 0;
}

.progress-table--fullwidth .progress-table__progress-bar {
  width: 43%;
}

.progress__bar-width-100 {
  width: 100%;
}

.progress__bar-width-90 {
  width: 90%;
}

.progress__bar-width-80 {
  width: 80%;
}

.progress__bar-width-70 {
  width: 70%;
}

.progress__bar-width-60 {
  width: 60%;
}

.progress__bar-width-50 {
  width: 50%;
}

.progress__bar-width-40 {
  width: 40%;
}

.progress__bar-width-30 {
  width: 30%;
}

.progress__bar-width-20 {
  width: 20%;
}

.progress__bar-width-10 {
  width: 10%;
}

/* Circular Bars
/* ------------------------- */
.circular {
  margin: 10px 0;
}

@media (min-width: 992px) {
  .circular {
    margin: 0;
  }
}

.circular__bar {
  position: relative;
  margin: 0 auto;
  width: 70px;
  height: 70px;
}

.circular__bar + .circular__label {
  margin-top: 1.2em;
}

@media (min-width: 992px) {
  .circular__bar {
    width: 90px;
    height: 90px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .circular__bar {
    width: 80px;
    height: 80px;
  }
}

.circular__bar canvas {
  width: 100% !important;
  height: 100% !important;
  vertical-align: top;
}

.circular__percents {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #31404b;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .circular__percents {
    font-size: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .circular__percents {
    font-size: 14px;
  }
}

.circular__percents small {
  font-size: 10px;
}

@media (min-width: 992px) {
  .circular__percents small {
    font-size: 12px;
  }
}

.circular__percents .circular__label {
  display: block;
  font-size: 9px;
  line-height: 1.2em;
  color: #9a9da2;
}

@media (min-width: 992px) {
  .circular__percents--lg {
    font-size: 24px;
  }
}

.circular__label {
  display: block;
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  text-align: center;
}

@media (min-width: 992px) {
  .circular__label {
    font-size: 10px;
  }
}

.circular--size-70 .circular__bar {
  width: 50px;
  height: 50px;
}

@media (min-width: 992px) {
  .circular--size-70 .circular__bar {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .circular--size-70 .circular__bar {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 992px) {
  .circular--size-70 .circular__bar .circular__percents {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .circular--size-70 .circular__bar .circular__percents {
    font-size: 14px;
  }
}

.circular--size-80 .circular__bar {
  width: 55px;
  height: 55px;
}

@media (min-width: 992px) {
  .circular--size-80 .circular__bar {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .circular--size-80 .circular__bar {
    width: 65px;
    height: 65px;
  }
}

.circular--size-80 .circular__bar .circular__percents .circular__label {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: none;
  font-weight: 400;
}

.circular--size-40 .circular__bar {
  width: 32px;
  height: 32px;
}

@media (min-width: 768px) {
  .circular--size-40 .circular__bar {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .circular--size-40 .circular__bar {
    width: 32px;
    height: 32px;
  }
}

.circular--size-40 .circular__bar .df-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Lightbox Holder
/* ------------------------- */
.lightbox-holder {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
}

.lightbox-holder__link {
  display: block;
  position: relative;
}

.lightbox-holder__link::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #171d21;
  opacity: .7;
  z-index: 4;
  transition: opacity 0.3s ease;
}

.lightbox-holder__link:hover::before {
  opacity: .5;
}

.lightbox-holder__link--play::before {
  content: "\f04b";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 26px;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  border: 2px solid #38a9ff;
  background-color: rgba(237, 239, 244, 0.6);
  border-radius: 50%;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  text-align: center;
  text-indent: 3px;
  opacity: 1;
}

@media (min-width: 992px) {
  .lightbox-holder__link--play::before {
    width: 80px;
    height: 80px;
    line-height: 68px;
    border-width: 6px;
    font-size: 28px;
    text-indent: 5px;
  }
}

@media (min-width: 1199px) {
  .lightbox-holder__link--play .lightbox-holder__overlay h3 {
    width: 70%;
  }
}

.lightbox-holder__overlay {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px 10px 10px;
  z-index: 4;
}

@media (min-width: 992px) {
  .lightbox-holder__overlay {
    padding: 0 30px 35px 30px;
  }
}

.lightbox-holder__overlay h3 {
  font-size: 12px;
  line-height: 1em;
  color: #fff;
  margin-bottom: .5em;
}

@media (min-width: 992px) {
  .lightbox-holder__overlay h3 {
    font-size: 24px;
    margin-bottom: .4em;
  }
}

.lightbox-holder__overlay time {
  display: block;
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #9a9da2;
}

@media (min-width: 992px) {
  .lightbox-holder__overlay time {
    font-size: 10px;
  }
}

.lightbox-holder__overlay--video {
  padding-left: 40px;
}

@media (min-width: 992px) {
  .lightbox-holder__overlay--video {
    padding-left: 90px;
  }
}

.lightbox-holder__overlay--video::before {
  content: "\f04b";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 16px;
  left: 10px;
  top: 0;
  border: 2px solid #38a9ff;
  border-radius: 50%;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: 900;
  text-align: center;
  text-indent: 3px;
}

@media (min-width: 992px) {
  .lightbox-holder__overlay--video::before {
    left: 30px;
    width: 40px;
    height: 40px;
    line-height: 32px;
    border-width: 4px;
    font-size: 14px;
    text-indent: 5px;
  }
}

.lightbox-holder.posts__item--category-1 .lightbox-holder__link--play::before,
.lightbox-holder.posts__item--category-1 .lightbox-holder__overlay--video::before {
  border-color: #38a9ff;
}

.lightbox-holder.posts__item--category-2 .lightbox-holder__link--play::before,
.lightbox-holder.posts__item--category-2 .lightbox-holder__overlay--video::before {
  border-color: #07e0c4;
}

.lightbox-holder.posts__item--category-3 .lightbox-holder__link--play::before,
.lightbox-holder.posts__item--category-3 .lightbox-holder__overlay--video::before {
  border-color: #9fe900;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Custom Select
/* ------------------------- */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  width: 100%;
  user-select: none;
}

div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}

.cs-select {
  /* Placeholder and selected option */
  /* Options */
  /* Optgroup and optgroup label */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 26px 24px 12px 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .cs-select span {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

.cs-select > span {
  padding-right: 3em;
}

.cs-select > span::after {
  speak: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  right: 23px;
  display: block;
  width: 6px;
  height: 4px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%2331404b' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-size: 6px 4px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 0.2s ease;
}

.cs-select.cs-active > span::after {
  transform: translateY(-50%) rotate(180deg);
}

.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  width: 100%;
}

.cs-select ul span {
  padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

div.cs-active {
  z-index: 101;
}

/* Skin */
div.cs-skin-border {
  background: transparent;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}

div.cs-skin-border > span {
  border-bottom: 1px solid #e4e7ed;
  color: #31404b;
  transition: background 0.2s, border-color 0.2s;
}

@media (min-width: 992px) {
  div.cs-skin-border > span {
    border-bottom: 0;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
  }
}

div.cs-skin-border ul span::after {
  content: '';
  opacity: 0;
}

div.cs-skin-border .cs-placeholder {
  font-style: normal;
}

div.cs-skin-border .cs-selected span::after {
  content: '\e00e';
  color: #ddd9c9;
  font-size: 1.5em;
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

div.cs-skin-border.cs-active > span {
  color: #31404b;
  background-color: #fff;
}

div.cs-skin-border .cs-options {
  border: 1px solid #e4e7ed;
  border-top: none;
  color: rgba(49, 64, 75, 0.4);
  font-size: 11px;
  font-style: normal;
  opacity: 0;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

div.cs-skin-border.cs-active .cs-options {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

div.cs-skin-border ul span {
  padding: 5px 24px;
  backface-visibility: hidden;
  transition: color 0.2s ease;
}

div.cs-skin-border .cs-options li span:hover,
div.cs-skin-border li.cs-focus span {
  color: #31404b;
}

/* Slick */
.slick-slide {
  outline: none;
}

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 20px;
  padding: 0;
  border: none;
  outline: none;
  background-color: #999ea6;
  transition: background-color 0.2s ease;
  border-radius: 2px;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background-color: #1892ed;
  color: transparent;
}

.slick-prev:hover::before, .slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  opacity: 1;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: 0.25;
}

.slick-prev::before,
.slick-next::before {
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  right: 50px;
}

[dir="rtl"] .slick-prev {
  left: 50px;
  right: auto;
}

.slick-prev::before {
  content: "";
}

[dir="rtl"] .slick-prev::before {
  content: "";
}

.slick-next {
  right: 20px;
}

[dir="rtl"] .slick-next {
  left: 20px;
  right: auto;
}

.slick-next::before {
  content: "";
}

[dir="rtl"] .slick-next::before {
  content: "";
}

/* Arrows */
.slick-arrows--rounded .slick-prev,
.slick-arrows--rounded .slick-next {
  border-radius: 50%;
}

.slick-vertical .slick-prev::before {
  content: "\f106";
}

.slick-vertical .slick-next::before {
  content: "\f107";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  padding: 0;
  background: transparent;
  border: none;
  display: block;
  height: 10px;
  width: 10px;
  line-height: 10px;
  overflow: hidden;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  border-radius: 50%;
  background-color: #d7d8da;
  transition: background-color 0.2s ease;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  opacity: 1;
}

.slick-dots li button:hover {
  background-color: #31404b;
}

.slick-dots li.slick-active button {
  background-color: #38a9ff;
  opacity: 0.75;
}

/* Featured News */
.slick-featured img {
  border-radius: 4px;
}

/* Featured Slider */
.posts--slider-featured .slick-prev,
.posts--slider-featured .slick-next {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
}

/* Featured Slider - Center */
.posts-slider--center .slick-prev,
.posts-slider--center .slick-next {
  top: auto;
  bottom: 20px;
}

@media (min-width: 992px) {
  .posts-slider--center .slick-prev,
  .posts-slider--center .slick-next {
    bottom: 50px;
  }
}

.posts-slider--center .slick-prev {
  right: 50%;
  margin-right: 4px;
}

.posts-slider--center .slick-next {
  right: auto;
  left: 50%;
  margin-left: 4px;
}

/* Featured Carousel, Team Roster, Awards Slider (change prev/next button) */
.featured-carousel .slick-prev,
.featured-carousel .slick-next,
.team-roster--slider .slick-prev,
.team-roster--slider .slick-next,
.awards--slider .slick-prev,
.awards--slider .slick-next {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid #404650;
  background-color: transparent;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -9px;
  transition: all 0.3s ease;
}

.featured-carousel .slick-prev::before,
.featured-carousel .slick-next::before,
.team-roster--slider .slick-prev::before,
.team-roster--slider .slick-next::before,
.awards--slider .slick-prev::before,
.awards--slider .slick-next::before {
  opacity: 1;
}

.featured-carousel .slick-prev:hover, .featured-carousel .slick-prev:focus,
.featured-carousel .slick-next:hover,
.featured-carousel .slick-next:focus,
.team-roster--slider .slick-prev:hover,
.team-roster--slider .slick-prev:focus,
.team-roster--slider .slick-next:hover,
.team-roster--slider .slick-next:focus,
.awards--slider .slick-prev:hover,
.awards--slider .slick-prev:focus,
.awards--slider .slick-next:hover,
.awards--slider .slick-next:focus {
  background-color: transparent;
  border-color: #1892ed;
}

.featured-carousel .slick-prev,
.team-roster--slider .slick-prev,
.awards--slider .slick-prev {
  left: 50%;
  margin-left: -20%;
}

.featured-carousel .slick-prev::before,
.team-roster--slider .slick-prev::before,
.awards--slider .slick-prev::before {
  content: "\f053";
  margin-left: -2px;
}

.featured-carousel .slick-next,
.team-roster--slider .slick-next,
.awards--slider .slick-next {
  right: 50%;
  margin-right: -20%;
}

.featured-carousel .slick-next::before,
.team-roster--slider .slick-next::before,
.awards--slider .slick-next::before {
  content: "\f054";
  margin-left: 2px;
}

/* Featured Carousel */
.featured-carousel .slick-dots,
.player-info .slick-dots {
  bottom: 20px;
}

.featured-carousel .slick-dots li,
.player-info .slick-dots li {
  width: 30px;
  height: 4px;
  margin: 0 3px;
}

.featured-carousel .slick-dots li button,
.player-info .slick-dots li button {
  width: 30px;
  height: 4px;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
}

.featured-carousel .slick-dots li button::before,
.player-info .slick-dots li button::before {
  display: none;
}

.featured-carousel .slick-dots li.slick-active button,
.player-info .slick-dots li.slick-active button {
  background-color: #1892ed;
}

.player-info .slick-dots {
  bottom: -20px;
}

/* Awards Slider */
.awards--slider .slick-prev,
.awards--slider .slick-next {
  border-color: #aeb0b4;
  opacity: .2;
  margin-top: 0;
  top: 40%;
}

.awards--slider .slick-prev::before,
.awards--slider .slick-next::before {
  opacity: 1;
  color: #aeb0b4;
}

.awards--slider .slick-prev:hover, .awards--slider .slick-prev:focus,
.awards--slider .slick-next:hover,
.awards--slider .slick-next:focus {
  opacity: 1;
  border-color: #aeb0b4;
}

.awards--slider .slick-prev {
  left: 0;
  margin-left: 0;
}

.awards--slider .slick-next {
  right: 0;
  margin-right: 0;
}

.awards--slider.slick-vertical {
  margin-bottom: 0;
}

.awards--slider.slick-vertical .slick-dots {
  width: 10px;
  height: auto;
  position: absolute;
  right: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.awards--slider.slick-vertical .slick-dots li {
  height: auto;
}

/* Team Roster - Card Slider */
.team-roster--card-slider,
.js-team-roster--card-compact {
  margin-bottom: 14px;
}

@media (min-width: 992px) {
  .team-roster--card-slider,
  .js-team-roster--card-compact {
    padding-bottom: 70px;
    margin-bottom: 34px;
  }
}

.team-roster--card-slider .slick-prev,
.team-roster--card-slider .slick-next,
.js-team-roster--card-compact .slick-prev,
.js-team-roster--card-compact .slick-next {
  top: auto;
  bottom: 0;
  border: 1px solid #e4e7ed;
  border-radius: 0;
  width: 50px;
  height: 50px;
  background-color: #fff;
}

@media (max-width: 991px) {
  .team-roster--card-slider .slick-prev,
  .team-roster--card-slider .slick-next,
  .js-team-roster--card-compact .slick-prev,
  .js-team-roster--card-compact .slick-next {
    display: none;
  }
}

.team-roster--card-slider .slick-prev::before,
.team-roster--card-slider .slick-next::before,
.js-team-roster--card-compact .slick-prev::before,
.js-team-roster--card-compact .slick-next::before {
  color: #31404b;
}

.team-roster--card-slider .slick-prev:hover:not(.slick-disabled),
.team-roster--card-slider .slick-next:hover:not(.slick-disabled),
.js-team-roster--card-compact .slick-prev:hover:not(.slick-disabled),
.js-team-roster--card-compact .slick-next:hover:not(.slick-disabled) {
  background-color: #38a9ff;
}

.team-roster--card-slider .slick-prev:hover:not(.slick-disabled)::before,
.team-roster--card-slider .slick-next:hover:not(.slick-disabled)::before,
.js-team-roster--card-compact .slick-prev:hover:not(.slick-disabled)::before,
.js-team-roster--card-compact .slick-next:hover:not(.slick-disabled)::before {
  color: #fff;
}

.team-roster--card-slider .slick-prev.slick-disabled,
.team-roster--card-slider .slick-next.slick-disabled,
.js-team-roster--card-compact .slick-prev.slick-disabled,
.js-team-roster--card-compact .slick-next.slick-disabled {
  cursor: default;
}

.team-roster--card-slider .slick-prev,
.js-team-roster--card-compact .slick-prev {
  right: auto;
  left: 50%;
  margin: 0 0 0 -50px;
}

.team-roster--card-slider .slick-next,
.js-team-roster--card-compact .slick-next {
  right: auto;
  left: 50%;
  margin: 0 0 0 -1px;
}

/* Team Roster - with select Players */
.team-roster--slider-with-nav .slick-prev,
.team-roster--slider-with-nav .slick-next {
  top: auto;
  bottom: 6px;
  border-radius: 0;
  width: 50px;
  height: 50px;
  background-color: transparent;
}

.team-roster--slider-with-nav .slick-prev::before,
.team-roster--slider-with-nav .slick-next::before {
  color: #31404b;
}

.team-roster--slider-with-nav .slick-prev:hover:not(.slick-disabled),
.team-roster--slider-with-nav .slick-next:hover:not(.slick-disabled) {
  background-color: transparent;
}

.team-roster--slider-with-nav .slick-prev:hover:not(.slick-disabled)::before,
.team-roster--slider-with-nav .slick-next:hover:not(.slick-disabled)::before {
  color: #38a9ff;
}

.team-roster--slider-with-nav .slick-prev.slick-disabled,
.team-roster--slider-with-nav .slick-next.slick-disabled {
  cursor: default;
}

.team-roster--slider-with-nav .slick-prev {
  right: 55px;
  left: auto;
  margin: 0;
}

.team-roster--slider-with-nav .slick-prev::before {
  content: "\f053";
}

.team-roster--slider-with-nav .slick-next {
  right: 6px;
  left: auto;
  margin: 0;
}

.team-roster--slider-with-nav .slick-next::before {
  content: "\f054";
}

/* Team Roster - case Slider */
.team-roster--case-slider {
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .team-roster--case-slider {
    padding-bottom: 90px;
    margin-bottom: 44px;
  }
}

.team-roster--case-slider .slick-prev,
.team-roster--case-slider .slick-next {
  top: auto;
  bottom: 0;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  width: 46px;
  height: 46px;
  background-color: #fff;
}

.team-roster--case-slider .slick-prev::before,
.team-roster--case-slider .slick-next::before {
  color: #31404b;
}

.team-roster--case-slider .slick-prev:hover,
.team-roster--case-slider .slick-next:hover {
  background-color: #38a9ff;
}

.team-roster--case-slider .slick-prev:hover::before,
.team-roster--case-slider .slick-next:hover::before {
  color: #fff;
}

.team-roster--case-slider .slick-prev {
  right: auto;
  left: 50%;
  margin: 0 0 0 -52px;
}

.team-roster--case-slider .slick-next {
  right: auto;
  left: 50%;
  margin: 0 0 0 6px;
}

/* Team Roster - Grid Slider */
.js-team-roster--grid-sm {
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .js-team-roster--grid-sm {
    padding-bottom: 50px;
    margin-bottom: 34px;
  }
}

.js-team-roster--grid-sm .slick-prev,
.js-team-roster--grid-sm .slick-next {
  top: auto;
  bottom: 0;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background-color: #fff;
}

.js-team-roster--grid-sm .slick-prev::before,
.js-team-roster--grid-sm .slick-next::before {
  color: #31404b;
}

.js-team-roster--grid-sm .slick-prev:hover:not(.slick-disabled),
.js-team-roster--grid-sm .slick-next:hover:not(.slick-disabled) {
  background-color: #38a9ff;
}

.js-team-roster--grid-sm .slick-prev:hover:not(.slick-disabled)::before,
.js-team-roster--grid-sm .slick-next:hover:not(.slick-disabled)::before {
  color: #fff;
}

.js-team-roster--grid-sm .slick-prev.slick-disabled,
.js-team-roster--grid-sm .slick-next.slick-disabled {
  cursor: default;
}

.js-team-roster--grid-sm .slick-prev {
  right: auto;
  left: 50%;
  margin: 0 0 0 -42px;
}

.js-team-roster--grid-sm .slick-next {
  right: auto;
  left: 50%;
  margin: 0 0 0 6px;
}

/* Featured News - Football */
.posts--slider-var-width .slick-prev,
.posts--slider-var-width .slick-next {
  top: -63px;
}

/* Hero Slider - Football */
.posts--slider-top-news {
  margin-bottom: 0;
}

.posts--slider-top-news .slick-dots {
  position: absolute;
  right: 10px;
  top: 50%;
  bottom: auto;
  width: 15px;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .posts--slider-top-news .slick-dots {
    right: 40px;
  }
}

.posts--slider-top-news .slick-dots li {
  margin: 5px 0;
  counter-increment: step-counter;
}

@media (min-width: 480px) {
  .posts--slider-top-news .slick-dots li {
    margin: 8px 0;
  }
}

@media (min-width: 992px) {
  .posts--slider-top-news .slick-dots li {
    margin: 13px 0;
  }
}

.posts--slider-top-news .slick-dots li button {
  font-size: 0;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 0;
  width: auto;
  height: auto;
  margin: 0;
  color: #fff;
  background-color: transparent;
  border-radius: 0;
  text-align: center;
}

.posts--slider-top-news .slick-dots li button::before {
  font-size: 10px;
  line-height: 1.2em;
  content: counters(step-counter, ".", decimal-leading-zero);
}

.posts--slider-top-news .slick-dots li.slick-active button {
  opacity: 1;
  color: #38a9ff;
}

/* Carousel Wrapper */
.slick-carousel-wrapper {
  overflow: hidden;
}

.slick-carousel-wrapper .slick-slider {
  margin: 0 -8px;
  width: calc(100% + 16px);
}

/* Post Categories Filter
/* ------------------------- */
/* Post Filter */
.category-filter {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-filter__item {
  display: inline;
}

.category-filter__link {
  display: inline-block;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: rgba(49, 64, 75, 0.4);
  transition: color 0.3s ease;
  padding: 0 6px;
  background: none;
  border: none;
}

.category-filter__link:hover {
  color: #31404b;
}

@media (min-width: 992px) {
  .category-filter__link {
    font-size: 11px;
    padding: 0 12px;
  }
}

.category-filter__link--active {
  color: #31404b;
}

@media (min-width: 992px) {
  .category-filter--extra-space {
    margin-right: 66px;
  }
}

.modal-dialog {
  margin: 10px;
}

.modal-content {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: none;
}

.modal-backdrop {
  background-color: #000;
}

.modal-backdrop.in {
  opacity: 0.9;
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.2em;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 830px;
  }
}

.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 12px;
  height: 12px;
  left: -6px;
  top: -4px;
}

.noUi-vertical {
  width: 4px;
}

.noUi-vertical .noUi-handle {
  width: 12px;
  height: 12px;
  left: -4px;
  top: -6px;
}

/* Styling;
 */
.slider-range-wrapper {
  padding-top: 18px;
}

.noUi-target {
  background-color: #dbdfe6;
  border-radius: 2px;
}

.noUi-connect {
  background-color: #9fe900;
  transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  border: 4px solid #31404b;
  border-radius: 50%;
  background-color: #fff;
  cursor: default;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Labels */
.slider-range-label {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
}

.slider-range + .slider-range-label {
  padding-top: 26px;
}

/* Icobox */
.icobox {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .icobox {
    margin-bottom: 0;
  }
}

.icobox__icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: relative;
  color: #38a9ff;
}

.icobox__icon i {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.icobox__icon--lg {
  width: 90px;
  height: 90px;
  font-size: 32px;
}

.icobox__icon--border {
  border: 2px solid #eaeced;
  background-color: #fff;
}

.icobox__icon--filled {
  background-color: #38a9ff;
  color: #fff;
}

.icobox__icon--circle {
  border-radius: 50%;
}

.icobox__title {
  font-size: 24px;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 1.25em;
}

.icobox__title--lg {
  margin-bottom: 1.75em;
}

.icobox__icon--color--primary {
  color: #38a9ff;
}

.icobox__icon--color--primary.icobox__icon--filled {
  background-color: #38a9ff;
  color: #fff;
}

.icobox__icon--color--secondary {
  color: #c2ff1f;
}

.icobox__icon--color--secondary.icobox__icon--filled {
  background-color: #c2ff1f;
  color: #fff;
}

.icobox--center {
  text-align: center;
}

.icobox--center .icobox__icon {
  margin-bottom: 15px;
}

.icobox--center .icobox__icon.icobox__icon--lg {
  margin-bottom: 26px;
}

/* Icon */
.alc-icon {
  display: inline-block;
  color: #fff;
  background-color: #fff;
  position: relative;
}

.alc-icon i,
.alc-icon svg {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.alc-icon--circle {
  border-radius: 50%;
}

.alc-icon--xl {
  width: 80px;
  height: 80px;
}

.alc-icon--xl .df-icon {
  transform: translate(-50%, -50%) scale(1.75);
}

.alc-icon--sm {
  width: 40px;
  height: 40px;
}

.alc-icon--xs {
  width: 20px;
  height: 20px;
}

.alc-icon--xs i {
  font-size: 8px;
}

.alc-icon--outline {
  background-color: transparent;
  border: 1px solid #38a9ff;
}

.alc-icon--outline-md {
  border-width: 4px;
}

.alc-icon--outline-md.alc-icon--sm {
  line-height: 32px;
}

.alc-icon--outline-md.alc-icon--xs {
  border-width: 2px;
}

.alc-icon--outline-xl {
  border-width: 8px;
}

.alc-icon--disabled {
  border-color: #fff;
  color: #fff;
  opacity: .1;
}

/* Alerts
/* ------------------------- */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #e4e7ed;
}

@media (min-width: 992px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron--card {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.jumbotron--style1 {
  padding: 0;
  position: relative;
}

@media (min-width: 992px) {
  .jumbotron--style1 {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .jumbotron--style1::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("../images/page-heading-pattern.png");
    background-size: auto;
    background-position: 100% 0;
    background-repeat: no-repeat;
    opacity: 0.4;
  }
}

.jumbotron--style1 .jumbotron__title {
  line-height: 1em;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .jumbotron--style1 .jumbotron__title {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .jumbotron--style1 .jumbotron__title {
    font-size: 72px;
  }
}

@media (min-width: 1200px) {
  .jumbotron--style1 .jumbotron__title {
    font-size: 90px;
  }
}

.jumbotron--style1 .jumbotron__title .highlight {
  color: #38a9ff;
}

.jumbotron--style1 .jumbotron__subtitle {
  margin-bottom: .25em;
}

@media (max-width: 479px) {
  .jumbotron--style1 .jumbotron__subtitle {
    font-size: 9px;
  }
}

@media (min-width: 1200px) {
  .jumbotron--style1 .jumbotron__subtitle {
    font-size: 14px;
  }
}

.jumbotron--style1 .jumbotron__desc {
  padding-top: .25em;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .jumbotron--style1 .jumbotron__desc {
    font-size: 10px;
    line-height: 1.2em;
  }
}

.jumbotron--style1 .jumbotron__content {
  padding: 2rem 1rem;
}

@media (min-width: 992px) {
  .jumbotron--style1 .jumbotron__content {
    padding: 4rem 2rem;
  }
}

/* Ratings */
.ratings .fa {
  color: #ffdc11;
}

.ratings .empty {
  color: #dbdfe6;
}

/* Timeline
/* ------------------------- */
.timeline {
  list-style: none;
  padding: 0;
  margin: 2em 0 0 0;
  position: relative;
}

.timeline__event {
  padding: 0 0 2.2em 46px;
  margin: 0;
  position: relative;
}

.timeline__event::before {
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e4e7ed;
}

.timeline__event::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #38a9ff;
  border: 2px solid #38a9ff;
  box-shadow: inset 0 0 0 5px #fff;
}

.timeline__event:last-child {
  padding-bottom: 0;
}

.timeline__event:last-child::before {
  display: none;
}

.timeline__title {
  font-size: 14px;
  line-height: 1.2em;
  text-transform: none;
}

/* Google Map
/* ------------------------- */
.gm-map {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 240px;
}

@media (min-width: 992px) {
  .gm-map {
    min-height: 350px;
  }
}

.gm-map--lg {
  min-height: 300px;
}

@media (min-width: 992px) {
  .gm-map--lg {
    min-height: 460px;
  }
}

/* Marquee
/* ------------------------- */
.marquee-wrapper {
  width: 100%;
  background-color: #fff;
  height: 38px;
  position: relative;
  overflow: hidden;
}

.marquee-wrapper .container {
  position: relative;
}

.marquee-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background-color: #38a9ff;
}

.marquee-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background-color: #c2ff1f;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  line-height: 38px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .marquee::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
    width: 135px;
    transform: skew(-25deg);
    background-color: #c2ff1f;
    z-index: 1;
  }
  .marquee::after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    right: 107px;
    top: 0;
    bottom: 0;
    background-color: #fff;
    transform: skew(-25deg);
    z-index: 1;
  }
}

.marquee-label {
  display: none;
}

@media (min-width: 768px) {
  .marquee-label {
    display: block;
    white-space: nowrap;
    position: absolute;
    left: 8px;
    padding: 0 10px 0 0;
    top: 0;
    line-height: 38px;
    z-index: 2;
    font-size: 10px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #31404b;
    background-color: #38a9ff;
  }
  .marquee-label::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #38a9ff;
    transform: skew(-25deg);
    width: 35px;
    right: -26px;
  }
  .marquee-label::after {
    content: "";
    display: block;
    position: absolute;
    right: -22px;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: 4px;
    transform: skew(-25deg);
  }
}

.marquee-wrapper--boxed {
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  height: 46px;
  overflow: hidden;
  margin-bottom: 20px;
}

.marquee-wrapper--boxed::before, .marquee-wrapper--boxed::after {
  background-color: transparent;
}

@media (min-width: 992px) {
  .marquee-wrapper--boxed {
    margin-bottom: 40px;
  }
}

.marquee-wrapper--boxed .container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.marquee-wrapper--boxed .marquee {
  line-height: 43px;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .marquee-wrapper--boxed .marquee::before {
    background-color: transparent;
  }
}

.marquee-wrapper--boxed .marquee-label {
  left: 0;
  font-style: italic;
}

.marquee-wrapper--boxed .marquee-label .fa {
  color: #38a9ff;
  margin-right: .3em;
  font-style: normal;
  font-weight: 400;
}

@media (min-width: 768px) {
  .marquee-wrapper--boxed .marquee-label {
    line-height: 44px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 13px;
    background-color: transparent;
  }
  .marquee-wrapper--boxed .marquee-label::before {
    width: 50px;
    right: -38px;
    background-color: transparent;
  }
  .marquee-wrapper--boxed .marquee-label::after {
    right: -34px;
  }
}

.icon-svg {
  font-size: 10px;
}

.icon-svg:before {
  content: ' ';
  vertical-align: middle;
  display: inline-block;
  background-image: url("../images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 13em 51.2em;
}

.no-svg .icon-svg:before {
  background-image: url("../images/sprite.svg");
}

.icon-svg.icon-corner-flag:before {
  background-position: 0em 0em;
  width: 1.5em;
  height: 2em;
}

.icon-svg.icon-crosshair:before {
  background-position: 0em -2em;
  width: 2.1em;
  height: 2.1em;
}

.icon-svg.icon-in:before {
  background-position: 0em -4.1em;
  width: 0.9em;
  height: 1.2em;
}

.icon-svg.icon-injury:before {
  background-position: 0em -5.3em;
  width: 1.6em;
  height: 1.6em;
}

.icon-svg.icon-keepers-glove:before {
  background-position: 0em -6.9em;
  width: 1.6em;
  height: 2.2em;
}

.icon-svg.icon-offside-flag:before {
  background-position: 0em -9.1em;
  width: 2.2em;
  height: 1.8em;
}

.icon-svg.icon-out:before {
  background-position: 0em -10.9em;
  width: 0.8em;
  height: 1.1em;
}

.icon-svg.icon-red-card:before {
  background-position: 0em -12em;
  width: 1.2em;
  height: 1.6em;
}

.icon-svg.icon-shirt:before {
  background-position: 0em -13.6em;
  width: 3.2em;
  height: 3.2em;
}

.icon-svg.icon-shirt-alt:before {
  background-position: 0em -16.8em;
  width: 3.2em;
  height: 3.2em;
}

.icon-svg.icon-soccer-ball:before {
  background-position: 0em -20em;
  width: 1.6em;
  height: 1.6em;
}

.icon-svg.icon-soccer-ball-missed-penalty:before {
  background-position: 0em -21.6em;
  width: 1.8em;
  height: 1.8em;
}

.icon-svg.icon-soccer-ball-own-goal:before {
  background-position: 0em -23.4em;
  width: 1.6em;
  height: 1.6em;
}

.icon-svg.icon-soccer-ball-penalty:before {
  background-position: 0em -25em;
  width: 1.8em;
  height: 1.8em;
}

.icon-svg.icon-soccer-gate:before {
  background-position: 0em -26.8em;
  width: 13em;
  height: 12.2em;
}

.icon-svg.icon-soccer-shoe:before {
  background-position: 0em -39em;
  width: 2em;
  height: 1.7em;
}

.icon-svg.icon-soccer-shots:before {
  background-position: 0em -40.7em;
  width: 1.4906000000000001em;
  height: 1.638em;
}

.icon-svg.icon-stopwatch:before {
  background-position: 0em -42.4em;
  width: 1.7em;
  height: 1.9em;
}

.icon-svg.icon-substitution:before {
  background-position: 0em -44.3em;
  width: 1.8em;
  height: 1.7em;
}

.icon-svg.icon-trophy:before {
  background-position: 0em -46em;
  width: 1.8em;
  height: 1.9em;
}

.icon-svg.icon-whistle:before {
  background-position: 0em -47.9em;
  width: 1.6em;
  height: 1.7em;
}

.icon-svg.icon-yellow-card:before {
  background-position: 0em -49.6em;
  width: 1.2em;
  height: 1.6em;
}

/* Isotope Filter */
.isotope-filter {
  display: flex;
  padding: 0 2px;
  margin: 0 0 24px 0;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .isotope-filter {
    margin-bottom: 47px;
  }
}

.isotope-filter__item {
  margin: 0 13px;
}

@media (max-width: 991px) {
  .isotope-filter__item {
    margin: 0 15px 0 0;
  }
}

.isotope-filter__item:first-child {
  margin-left: 0;
}

.isotope-filter__item:last-child {
  margin-right: 0;
}

.isotope-filter__link {
  padding: 0 2px;
  color: #31404b;
  font-style: normal;
}

.isotope-filter__link:hover {
  color: #38a9ff;
}

.isotope-filter__link--active {
  color: #38a9ff;
}

/* Video Playlist */
.alc-video-player-col {
  padding-right: 0;
}

@media (min-width: 992px) {
  .alc-video-playlist-col {
    border-left: 1px solid #e4e7ed;
    padding-left: 0;
  }
}

.alc-video-player__header {
  border-bottom: 1px solid #e4e7ed;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
  position: relative;
}

@media (max-width: 991px) {
  .alc-video-player__header {
    border-top: 1px solid #e4e7ed;
  }
}

.alc-video-player__header .slick-prev,
.alc-video-player__header .slick-next {
  top: 50%;
  transform: translateY(-50%);
}

.alc-video-player__title {
  font-size: 11px;
  line-height: 1.2em;
  margin-bottom: 0;
}

.alc-video-player .posts__thumb--video {
  float: none;
  margin: 0;
}

.alc-video-player .posts__thumb--video img {
  width: 100%;
}

.alc-video-player__video-list {
  position: relative;
}

.alc-video-player__video-list .posts__item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 18px;
  padding-bottom: 18px;
}

.alc-video-player__video-list .posts__item:hover {
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .alc-video-player__video-list .posts__item .posts__title {
    font-size: 12px;
  }
}

.alc-embeded-player {
  position: relative;
  overflow: hidden;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border: 1px solid #e4e7ed;
}

.card--no-paddings .alc-embeded-player {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
}

.alc-embeded-player__overlay {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.alc-embeded-player__overlay:hover {
  cursor: pointer;
}

.alc-embeded-player__overlay:hover .alc-embeded-player__icon {
  opacity: .5;
}

.alc-embeded-player__icon {
  display: block;
  color: #fff;
  font-size: 30px;
  line-height: 1em;
  transition: opacity .3s ease;
}

.alc-img-object {
  display: flex;
  align-items: center;
}

.alc-img-object__img {
  overflow: hidden;
  margin-right: 12px;
  width: 30px;
  height: 30px;
}

.alc-img-object__img--hexagon {
  position: relative;
  border: none !important;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.alc-img-object__img--hexagon img {
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  border: none;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.alc-img-object__img--hexagon-sm {
  width: 28px;
  height: 32px;
}

.alc-img-object__body {
  flex: 1;
}

.alc-img-object__title {
  text-transform: none;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 0;
  font-style: normal;
}

.alc-img-object__desc {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  line-height: 1.2em;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden;
}

body {
  background-color: #edeff4;
  color: #9a9da2;
  font-size: 15px;
  line-height: 26px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

/* Font Size on Mobile devices */
@media (max-width: 479px) {
  body {
    font-size: 13px;
    line-height: 22px;
  }
}

.site-wrapper {
  backface-visibility: hidden;
  transition: all 0.4s ease-in-out;
  overflow-x: hidden;
}

.site-wrapper--has-overlay {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
}

.site-overlay {
  left: 0;
  top: 0;
  z-index: 102;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.4s linear;
}

@media (max-width: 991px) {
  .site-wrapper--has-overlay .site-overlay {
    visibility: visible;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@media (min-width: 992px) {
  .site-wrapper--has-overlay-pushy .site-overlay {
    visibility: visible;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

/*
// #Header
// --------------------------------------------------
*/
.header {
  background-color: #1e2024;
  position: relative;
  z-index: 4;
}

@media (max-width: 991px) {
  .header {
    z-index: 103;
  }
}

/* Header Top Bar */
.header__top-bar {
  background-color: #292c31;
  display: none;
}

@media (min-width: 992px) {
  .header__top-bar {
    display: block;
  }
}

.header__top-bar-inner {
  display: flex;
  align-items: center;
}

.nav-account {
  display: none;
}

@media (min-width: 992px) {
  .nav-account {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
    width: auto;
  }
  .nav-account .nav-account__item {
    font-size: 9px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
  }
  .nav-account .nav-account__item::before {
    content: "\2F";
    display: inline-block;
    color: #6b6d70;
    margin: 0 16px 0 14px;
  }
  .nav-account .nav-account__item > a {
    color: #fff;
    transition: all 0.3s ease;
    display: inline-block;
    height: 48px;
    line-height: 48px;
  }
  .nav-account .nav-account__item > a:hover {
    color: #38a9ff;
  }
  .nav-account .nav-account__item > a:focus {
    outline: none;
  }
  .nav-account .nav-account__item > a .highlight {
    color: #38a9ff;
  }
  .nav-account .nav-account__item:first-child::before {
    display: none;
  }
  .nav-account .nav-account__item.has-children {
    position: relative;
  }
  .nav-account .nav-account__item.has-children::after {
    content: "";
    display: inline-block;
    margin-left: 9px;
    width: 6px;
    height: 4px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
    background-size: 6px 4px;
    background-repeat: no-repeat;
    background-position: center center;
    top: -2px;
    position: relative;
  }
  .nav-account .nav-account__item.has-children ul {
    list-style: none;
    top: 40px;
    right: 0;
    min-width: 110px;
    padding: 14px 0;
    margin: 0;
    border: 1px solid #e4e7ed;
    box-shadow: none;
    background-color: #fff;
    transform-style: preserve-3d;
    transform: translateX(50%) rotateX(-75deg);
    visibility: hidden;
  }
  .nav-account .nav-account__item.has-children ul li a {
    font-size: 9px;
    padding: 6px 24px;
    border-bottom: none;
    color: #31404b;
    transition: color 0.2s ease;
  }
  .nav-account .nav-account__item.has-children ul li a:hover {
    color: #38a9ff;
    background-color: transparent;
  }
  .nav-account .nav-account__item.has-children:hover ul {
    display: block;
    transform: translateX(50%) rotateX(0deg);
    opacity: 1;
    visibility: visible;
  }
  .nav-account .nav-account__item--logout > a {
    color: #6b6d70;
  }
}

/* Header Secondary */
.header__secondary {
  background-color: #1e2024;
  display: none;
}

@media (min-width: 992px) {
  .header__secondary {
    display: block;
    text-align: right;
  }
}

/* Header Primary */
.header__primary {
  background-color: #1e2024;
  position: relative;
}

.header--layout-1 .header__primary::after,
.header--layout-2 .header__primary::after,
.header--layout-3 .header__primary::after,
.header--layout-4 .header__primary::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #292c31;
}

.header--layout-1 .header__primary::after {
  width: 50%;
}

.header--layout-2 .header__primary::after,
.header--layout-3 .header__primary::after,
.header--layout-4 .header__primary::after {
  width: 100%;
}

.header__primary-inner {
  position: relative;
  z-index: 1;
  background-color: #292c31;
}

.header--layout-1 .header__primary-inner::before {
  content: "";
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  width: 210px;
  background-color: #1e2024;
  left: -25px;
  margin-left: 0;
  transform: skew(-25deg);
}

@media (min-width: 768px) {
  .header--layout-1 .header__primary-inner::before {
    display: block;
  }
}

@media (max-width: 991px) {
  .header--layout-1 .header__primary-inner::before {
    width: 80px;
  }
}

@media (max-width: 991px) {
  .main-nav .social-links--main-nav {
    display: none;
  }
}

/* Header Logo */
.header-logo {
  position: absolute;
  z-index: 1001;
  bottom: 21px;
  left: 0;
  display: block;
}

@media (max-width: 991px) {
  .header-logo {
    display: none;
  }
}

.header-logo__img {
  position: relative;
}

/* Header Mobile */
.header-mobile {
  display: none;
  background-color: #1e2024;
}

/* Header Mobile Search */
.header-search-form {
  padding: 24px 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 1;
  vertical-align: top;
}

@media (max-width: 991px) {
  .header-search-form {
    background-color: #1e2024;
  }
}

@media (min-width: 992px) {
  .header-search-form {
    display: inline-block;
    width: 360px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-search-form {
    width: 200px;
  }
}

.header-search-form .search-form {
  position: relative;
}

.main-nav .header-search-form {
  padding: 11px 0;
  background-color: transparent;
}

.main-nav .header-search-form.header-search-form--right {
  float: right;
}

.header__secondary .header-search-form.header-search-form--left {
  float: left;
}

/* Search Input */
input.header-mobile__search-control {
  background-color: #292c31;
  border-color: #292c31;
  border-radius: 20px;
  height: 38px;
  padding: 6px 60px 6px 20px;
  font-size: 12px;
}

@media (min-width: 992px) {
  input.header-mobile__search-control {
    background-color: #292c31;
    border-color: #292c31;
  }
}

input.header-mobile__search-control:focus {
  color: #fff;
  background-color: #292c31;
  border-color: #292c31;
}

@media (min-width: 992px) {
  input.header-mobile__search-control:focus {
    background-color: #292c31;
    border-color: #292c31;
  }
}

/* Submit Button */
.header-mobile__search-submit {
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 38px;
  line-height: 38px;
  padding: 0;
  border: none;
  font-size: 16px;
  color: #fff;
  transition: color, background-color 0.3s ease;
  border-radius: 0 20px 20px 0;
}

.header-mobile__search-submit:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 991px) {
  .header-mobile {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
  .header-mobile__inner {
    overflow: hidden;
    position: relative;
    z-index: 2;
    height: 100px;
    background-color: #1e2024;
    transition: all 0.4s ease-in-out;
  }
  /* Header Mobile Logo */
  .header-mobile__logo {
    text-align: center;
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translate(-50%, 0);
    top: 5px;
  }
  .main-nav .header-mobile__logo {
    top: 0;
  }
  .header-mobile__logo-img {
    width: 97px;
    height: auto;
  }
  /* Header Mobile Burger Icon */
  .burger-menu-icon {
    z-index: 102;
    transition: all 0.4s ease-in-out;
    display: block;
    position: absolute;
    top: 40px;
    left: 34px;
    cursor: pointer;
    width: 26px;
    height: 19px;
  }
  .burger-menu-icon__line, .burger-menu-icon__line::before, .burger-menu-icon__line::after {
    content: '';
    cursor: pointer;
    height: 3px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    display: block;
    border-radius: 1px;
  }
  .burger-menu-icon__line::before {
    top: 8px;
  }
  .burger-menu-icon__line::after {
    top: 16px;
  }
  /* Mobile Search Icon */
  .header-mobile__search-icon {
    display: block;
    position: absolute;
    right: 34px;
    top: 39px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 22px;
    color: #fff;
    text-align: center;
  }
  .header-mobile__search-icon::before, .header-mobile__search-icon::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
  }
  .header-mobile__search-icon::before {
    content: "\f002";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
  .header-mobile__search-icon::after {
    content: "";
    transform: translate(-50%, -50%), scale(0);
    opacity: 0;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3E%3Cpath transform='translate(-267.219 -40.219)' fill='%23fff' d='M267.62,56.258L283.258,40.62a1.5,1.5,0,0,1,2.121,2.121L269.741,58.379A1.5,1.5,0,0,1,267.62,56.258Zm15.638,2.122L267.62,42.742a1.5,1.5,0,0,1,2.121-2.121l15.638,15.638A1.5,1.5,0,0,1,283.258,58.38Z'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: 19px 19px;
    background-repeat: no-repeat;
  }
  .header-mobile__search-icon.header-mobile__search-icon--close::before {
    transform: translate(-50%, -50%), scale(0);
    opacity: 0;
  }
  .header-mobile__search-icon.header-mobile__search-icon--close::after {
    transform: translate(-50%, -50%), scale(1);
    opacity: 1;
  }
  .header-mobile__search-icon:hover {
    cursor: pointer;
  }
  /* Mobile Search */
  .header-search-form {
    padding: 0 25px;
    height: 0;
    top: -62px;
  }
  .header-mobile--expanded .header-search-form {
    top: 0;
    height: 63px;
  }
  /* Search Input */
  input.header-mobile__search-control {
    margin-top: 12px;
  }
  /* Submit Button */
}

/* Header Banner */
.header-banner {
  display: inline-block;
  vertical-align: middle;
  margin-left: 38px;
}

@media (min-width: 992px) {
  .header-banner {
    margin-left: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-banner img {
    width: 254px;
    height: auto;
  }
}

@media (min-width: 1200px) {
  .header-banner {
    margin-left: 38px;
  }
}

/**
 *
 * Header Layouts
 *
 */
/* Header Layout 3 */
@media (min-width: 992px) {
  .header--layout-3 .header__primary-inner {
    display: flex;
    align-items: center;
  }
}

.header--layout-3 .header__primary-spacer {
  margin-left: auto;
}

@media (min-width: 992px) {
  .header--layout-3 .header-logo {
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }
}

@media (min-width: 992px) {
  .header--layout-3 .header-search-form {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (min-width: 992px) {
  .header--layout-3 .info-block--header .info-block__item {
    padding-top: 10px;
    padding-bottom: 22px;
  }
  .header--layout-3 .info-block--header .info-block__item .df-icon {
    top: 14px;
  }
  .header--layout-3 .info-block--header .info-block__item .info-block__menu-icon {
    top: 11px !important;
  }
}

/* Header Layout 4 */
.header--layout-4 .header__primary::after {
  background-color: #292c31;
}

@media (min-width: 992px) {
  .header--layout-4 .header__primary--center .header__primary-inner {
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .header--layout-4 .header__primary--center .main-nav {
    margin-right: 0;
    flex-basis: 100%;
  }
}

@media (min-width: 992px) {
  .header--layout-4 .header__primary--center .main-nav__list {
    display: flex;
    justify-content: space-evenly;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .header--layout-4 .header__primary--center .main-nav__list > li:nth-of-type(1) > a {
    padding: 0 34px !important;
  }
}

.header--layout-4 .header__primary-inner {
  background-color: #292c31;
}

@media (min-width: 992px) {
  .header--layout-4 .header__primary-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  .header--layout-4 .header-logo {
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }
}

@media (min-width: 992px) {
  .header--layout-4 .main-nav {
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .header--layout-4 .header-search-form {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (min-width: 992px) {
  .header--layout-4 .info-block--header .info-block__item {
    padding-top: 10px;
    padding-bottom: 22px;
  }
  .header--layout-4 .info-block--header .info-block__item .df-icon {
    top: 14px;
  }
  .header--layout-4 .info-block--header .info-block__item .info-block__menu-icon {
    top: 11px !important;
  }
}

.header--layout-4 .header__secondary {
  position: relative;
  z-index: 3;
}

.header--layout-4 .header__secondary-inner {
  position: relative;
  z-index: 1;
}

/*
// #Header Navigation
// --------------------------------------------------
*/
/* Navigation - Desktop */
.main-nav {
  text-align: right;
}

.main-nav .header-mobile__logo {
  display: none;
}

.main-nav__list {
  display: inline-block;
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none;
  /* 1st Level */
}

@media (min-width: 992px) {
  .main-nav__list {
    border-left: none;
  }
}

.main-nav__list > li {
  text-align: center;
  display: inline-block;
  padding: 0;
}

@media (min-width: 992px) {
  .main-nav__list > li {
    margin-left: -0.25em;
    border-right: none;
  }
  .main-nav__list > li:first-of-type {
    margin-left: 0;
  }
}

.main-nav__list > li > a {
  display: block;
  position: relative;
  color: #fff;
  padding: 0 34px;
  line-height: 62px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-nav__list > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-nav__list > li > a::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: -1;
}

.main-nav__list > li > a:hover {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
}

.main-nav__list > li > a:hover::before {
  background-color: #38a9ff;
}

.main-nav__list > li.active > a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.main-nav__list > li.active > a::before {
  background-color: #38a9ff;
}

.main-nav__list > li.has-children > a::after {
  content: "";
  display: inline-block;
  margin-left: 9px;
  width: 6px;
  height: 4px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-size: 6px 4px;
  background-repeat: no-repeat;
  background-position: center center;
  top: -2px;
  position: relative;
}

/* Megamenu */
.main-nav__megamenu {
  display: flex;
  width: 100%;
  left: 0;
  position: absolute;
  top: 62px;
  z-index: 1000;
  margin: 0;
  text-align: left;
  padding: 43px 55px;
  font-size: 15px;
  border: solid 1px #292c31;
  background-color: #1e2024;
  opacity: 0;
  transform-origin: 0 0;
  transition: transform .3s, opacity .3s;
}

.main-nav__megamenu .main-nav__title {
  font-size: 14px;
  letter-spacing: -0.03em;
  padding: 3px 0;
  margin-bottom: 19px;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.main-nav__megamenu .row {
  width: 100%;
}

/* Lists inside Megamenu */
.main-nav__ul:not([class*="col-"]) {
  padding-left: 0;
}

.main-nav__ul li {
  display: block;
  white-space: nowrap;
  text-align: left;
  text-transform: uppercase;
}

.main-nav__ul li a {
  display: block;
  font-size: 11px;
  line-height: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-align: left;
  padding: 5px 0;
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.3s ease;
}

.main-nav__ul li a:hover {
  color: #fff;
  text-decoration: none;
}

@media (min-width: 992px) {
  .main-nav__ul-2cols,
  .main-nav__ul-3cols {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
  }
  .main-nav__ul-2cols li,
  .main-nav__ul-3cols li {
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .main-nav__ul-2cols li.main-nav__title,
  .main-nav__ul-3cols li.main-nav__title {
    flex-basis: 100%;
    align-items: center;
  }
  .main-nav__ul-2cols li a,
  .main-nav__ul-3cols li a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (min-width: 992px) {
  .main-nav__ul-2cols li {
    flex-basis: 50%;
  }
}

@media (min-width: 992px) {
  .main-nav__ul-3cols li {
    flex-basis: 33.3%;
  }
}

/* Animation for Navigation */
.main-nav__list li > .main-nav__sub,
.main-nav__list li > .main-nav__megamenu,
.main-nav__list li > .main-nav__sub-2,
.main-nav__list li > .main-nav__sub-3 {
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
  visibility: hidden;
}

.main-nav__list li:hover > .main-nav__sub,
.main-nav__list li:hover > .main-nav__megamenu,
.main-nav__list li:hover > .main-nav__sub-2,
.main-nav__list li:hover > .main-nav__sub-3 {
  transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

/* Megamenu Banner */
.main-nav__megamenu .main-nav-banner {
  border: 1px solid #292c31;
  background-color: #1e2024;
  padding: 0;
  margin-bottom: 28px;
  border-radius: 4px;
  display: flex;
  min-height: 86px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.main-nav__megamenu .main-nav-banner .main-nav-banner__link {
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  flex-wrap: wrap;
  padding: 0 0 0 66px;
}

.main-nav__megamenu .main-nav-banner .main-nav-banner__title {
  width: 100%;
  font-size: 20px;
  line-height: 1.1em;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-nav__megamenu .main-nav-banner .main-nav-banner__subtitle {
  font-size: 12px;
  color: #9a9da2;
  line-height: 1.2em;
  font-style: normal;
  text-transform: uppercase;
}

.main-nav__megamenu .main-nav-banner--img-1 {
  background-image: url("../images/esports/samples/megamenu-banner-1.jpg");
}

.main-nav__megamenu .main-nav-banner--img-2 {
  background-image: url("../images/esports/samples/megamenu-banner-2.jpg");
}

.main-nav__megamenu .main-nav-banner--img-3 {
  background-image: url("../images/esports/samples/megamenu-banner-3.jpg");
}

@media (min-width: 992px) {
  .main-nav__megamenu--no-paddings {
    padding: 0;
  }
  .main-nav__megamenu--no-paddings .row {
    margin: 0;
  }
  .main-nav__megamenu--no-paddings .row [class*="col-"] {
    padding: 32px 35px;
  }
  .main-nav__megamenu--no-paddings .row [class*="col-"]:not(:first-child) {
    border-left: 1px solid #292c31;
  }
  .main-nav__megamenu--no-paddings .main-nav-banner--top {
    margin-top: -33px;
    margin-left: -36px;
    margin-right: -36px;
    flex-basis: calc(100% + 72px) !important;
    border-radius: 0;
  }
}

@media (min-width: 992px) {
  .main-nav__megamenu--has-bg {
    background-image: url("../images/esports/samples/megamenu-bg.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    padding-right: 142px;
  }
}

/* Submenu */
.main-nav__sub {
  position: absolute;
  top: 62px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  min-width: 240px;
  background-color: #1e2024;
  border: solid 1px #292c31;
  opacity: 0;
  transform-origin: 0% 0%;
  transition: transform 0.3s, opacity 0.3s;
}

.main-nav__sub > li > a {
  text-decoration: none;
}

.main-nav__sub > li.has-children > a::after {
  content: "";
  display: block;
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -2px;
  width: 6px;
  height: 4px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-size: 6px 4px;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(-90deg);
}

.main-nav__sub li {
  position: relative;
  margin: 0;
  padding: 0;
}

.main-nav__sub li a {
  display: block;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 11px;
  color: #fff;
  text-align: left;
  line-height: 1.5em;
  padding: 16px 30px;
  letter-spacing: normal;
  border-bottom: 1px solid #292c31;
  transition: background-color 0.3s ease;
}

.main-nav__sub li a:hover {
  color: #c2ff1f;
  background-color: transparent;
}

.main-nav__sub li:last-child > a {
  border-bottom: none;
}

.main-nav__sub > li.active > a {
  color: #c2ff1f;
  background-color: transparent;
}

.main-nav__list li:hover .main-nav__sub {
  display: block;
}

/* Submenu */
.main-nav__sub li {
  position: relative;
  padding: 0;
  margin: 0;
  display: block;
}

.main-nav__sub li:hover .main-nav__sub-2 {
  display: block;
  opacity: 1;
  list-style: none;
  padding: 0;
  background-color: #1e2024;
}

/* 2nd Submenu */
.main-nav__sub-2 {
  min-width: 240px;
  border: solid 1px #292c31;
  position: absolute;
  left: 100%;
  top: -1px;
  margin: 0;
  padding: 0;
  opacity: 0;
  transform-origin: 0% 0%;
  transition: transform 0.4s, opacity 0.4s;
}

.main-nav__sub-2 li:hover .main-nav__sub-3 {
  opacity: 1;
  list-style: none;
  padding: 0;
  border: solid 1px #292c31;
  background-color: #1e2024;
}

/* 3rd Submenu */
.main-nav__sub-3 {
  min-width: 240px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transform-origin: 0% 0%;
  transition: transform 0.4s, opacity 0.4s;
}

.main-nav__toggle,
.main-nav__toggle-2 {
  display: none;
}

@media only screen and (min-width: 992px) {
  .main-nav__list li:hover > .main-nav__sub,
  .main-nav__sub li:hover > .main-nav__sub-2,
  .main-nav__sub-2 li:hover > .main-nav__sub-3 {
    display: block;
  }
  .main-nav__list li:hover > .main-nav__megamenu {
    display: flex;
  }
  .main-nav__list .nav-account__item {
    display: none;
  }
  .main-nav__list .social-links__item {
    display: none;
  }
  .main-nav__list .main-nav__item--social-links {
    display: none;
  }
  .main-nav .main-nav__item--shopping-cart {
    display: none;
  }
}

/* Navigation - Mobile */
@media only screen and (max-width: 991px) {
  .main-nav {
    width: 270px;
    left: 0;
    transform: translateX(-270px);
    height: 100vh;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 103;
    overflow: auto;
    transition: all 0.4s ease-in-out;
    background-color: #1e2024;
  }
  .site-wrapper--has-overlay .main-nav {
    transform: translateX(0);
    transition: all 0.4s ease-in-out;
  }
  .main-nav .header-mobile__logo {
    position: relative;
    padding: 5px 0 18px 0;
    display: block;
    border-bottom: 1px solid #292c31;
  }
  .main-nav .main-nav__item--shopping-cart .info-block__link-wrapper {
    overflow: hidden;
    font-size: 11px;
    line-height: 1.5em;
    padding-right: 20px;
  }
  .main-nav .main-nav__item--shopping-cart .df-icon-stack--bag,
  .main-nav .main-nav__item--shopping-cart .df-icon--shopping-cart {
    display: none;
  }
  .main-nav .main-nav__item--shopping-cart .info-block__heading {
    margin-bottom: 0;
    float: left;
    font-size: 11px;
    line-height: 1.5em;
  }
  .main-nav .main-nav__item--shopping-cart .info-block__cart-sum {
    float: right;
    font-size: 11px;
    line-height: 1.5em;
    color: #38a9ff;
  }
  .main-nav .main-nav__back {
    width: 49px;
    height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 8'%3E%3Cpath transform='translate(-238.75 -17.75)' fill='%23fff' d='M240,21h12v2H240V21Zm0.171-.415,4.243,4.243L243,26.242,238.757,22Zm4.243-1.414-4.243,4.242L238.757,22,243,17.757Z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 13px 8px;
  }
  .main-nav .main-nav__back:hover {
    cursor: pointer;
  }
  .main-nav .main-nav__list > li > a::before {
    top: 0;
    bottom: 0;
    width: 4px;
    height: auto;
  }
  .main-nav .main-nav__list > li > a .highlight {
    color: #38a9ff;
  }
  .main-nav .main-nav__list > li.has-children > a::after {
    display: none;
  }
  .main-nav .main-nav__list > li.nav-account__item--logout > a {
    color: #6b6d70;
  }
  .main-nav .main-nav__list > li.nav-account__item--wishlist > a {
    padding-right: 20px;
  }
  .main-nav .main-nav__list > li.nav-account__item--wishlist .highlight {
    float: right;
  }
  .main-nav .main-nav__list > li.main-nav__item--social-links {
    text-align: left;
    padding: 0 15px 20px 15px;
  }
  .main-nav .main-nav__list > li.main-nav__item--social-links > a {
    display: inline-block;
    border-bottom: none;
    padding: 13px;
  }
  .main-nav .main-nav__list > li.main-nav__item--social-links > a::before {
    display: none;
  }
  .main-nav__list li:hover .main-nav__sub {
    display: none;
  }
  .main-nav__list li:hover .main-nav__sub .main-nav__sub-2 {
    display: none;
  }
  .main-nav__list .main-nav__sub li.has-children > a::after {
    display: none;
  }
  .main-nav__list .main-nav__sub .main-nav__sub-2 li:hover .main-nav__sub-3 {
    display: none;
  }
  /* Mobile click to drop arrow */
  .main-nav__toggle {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 10px;
    display: block;
    cursor: pointer;
    z-index: 1;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
    background-size: 6px 4px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.4s ease;
  }
  .main-nav__toggle-2 {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 15px;
    display: block;
    cursor: pointer;
    z-index: 1;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
    background-size: 6px 4px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.4s ease;
  }
  .main-nav__toggle--rotate {
    transform: rotate(180deg);
  }
  /* 2nd Level Submenu */
  .main-nav__sub-2 {
    width: 100%;
    position: static;
    left: 100%;
    top: 0;
    display: none;
    margin: 0;
    padding: 0 !important;
    border: solid 0px;
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .main-nav__sub-2 li {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .main-nav__sub-2 li a {
    display: block;
    padding: 10px 25px 10px 25px;
  }
  .main-nav__sub-2 li:hover > a {
    color: #fff;
  }
  /* 3rd Level Submenu */
  .main-nav__sub-3 {
    width: 100%;
    position: static;
    left: 100%;
    top: 0;
    display: none;
    margin: 0;
    padding: 0 !important;
    border: solid 0px;
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .main-nav__sub-3 li {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .main-nav__sub-3 li a {
    display: block;
    padding: 10px 25px 10px 25px;
  }
  .main-nav__sub-3 li:hover > a {
    color: #fff;
  }
  .main-nav .main-nav__list {
    border-top: 1px solid #292c31;
    height: 100%;
    overflow-y: auto;
    display: block;
  }
  .main-nav .main-nav__list > li {
    width: 100%;
    display: block;
    float: none;
    border-right: none;
    background-color: transparent;
    position: relative;
    white-space: inherit;
  }
  .main-nav .main-nav__list > li > a {
    padding: 16px 30px;
    font-size: 11px;
    text-align: left;
    border-right: solid 0px;
    background-color: transparent;
    color: #fff;
    line-height: 1.5em;
    border-bottom: 1px solid #292c31;
    position: static;
  }
  .main-nav .main-nav__list li.has-children > a::after {
    transform: none;
    top: 13px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  }
  .main-nav__list .main-nav__sub {
    transform: none;
    opacity: 1;
    display: none;
    position: relative;
    top: 0;
    background-color: #292c31;
    border: none;
    padding: 15px 0;
    visibility: visible;
  }
  .main-nav__list .main-nav__sub li a {
    line-height: 1.5em;
    font-size: 10px;
    padding: 7px 0 7px 40px;
    color: #9a9da2;
    transition: color 0.3s ease;
    border-bottom: none;
    display: block;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
  .main-nav__list .main-nav__sub li a:hover {
    color: #fff;
  }
  .main-nav__list .main-nav__sub li:hover > a {
    background-color: transparent;
    color: #fff;
  }
  .main-nav__list .main-nav__sub li:hover .main-nav__sub-2 {
    background-color: transparent;
  }
  .main-nav__list .main-nav__sub li .main-nav__sub-2 {
    padding: 15px 0;
  }
  .main-nav__list .main-nav__sub li .main-nav__sub-2 li a {
    padding-left: 50px;
  }
  .main-nav__list .main-nav__sub li .main-nav__sub-2 li a:hover {
    background-color: none;
  }
  .main-nav__list .main-nav__sub li .main-nav__sub-2 li .main-nav__sub-3 li a {
    padding-left: 60px;
  }
  .main-nav__list .main-nav__megamenu {
    background-color: #292c31;
    color: #9a9da2;
    display: none;
    position: relative;
    top: 0;
    padding: 15px 0;
    border: none;
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .main-nav__list .main-nav__megamenu [class^="col-"] {
    padding-bottom: 15px;
  }
  .main-nav__list .main-nav__megamenu [class^="col-"]:first-child .main-nav-banner {
    margin-top: 0;
  }
  .main-nav__list .main-nav__megamenu div[class^="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
  .main-nav__list .main-nav__megamenu .row {
    margin: 0;
  }
  .main-nav__list .main-nav__megamenu .main-nav__title {
    color: #fff;
    font-size: 11px;
    padding: 10px 30px;
    margin: 0;
  }
  .main-nav__list .main-nav__megamenu .main-nav-banner {
    margin: 15px;
    padding: 0;
    min-height: 56px;
  }
  .main-nav__list .main-nav__megamenu .main-nav-banner .main-nav-banner__link {
    padding: 15px 20px 15px 56px;
  }
  .main-nav__list .main-nav__megamenu .main-nav-banner .main-nav-banner__subtitle {
    font-size: 11px;
  }
  .main-nav__list .main-nav__megamenu .main-nav-banner .main-nav-banner__title {
    font-size: 18px;
  }
  .main-nav__list .main-nav__megamenu > ul,
  .main-nav__list .main-nav__megamenu .main-nav__ul {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  .main-nav__list .main-nav__megamenu > ul > li > a,
  .main-nav__list .main-nav__megamenu .main-nav__ul > li > a {
    padding: 7px 20px 7px 40px;
    line-height: 1.5em;
    font-size: 10px;
    color: #9a9da2;
  }
  .main-nav__list .main-nav__megamenu > ul > li > a:hover,
  .main-nav__list .main-nav__megamenu .main-nav__ul > li > a:hover {
    color: #fff;
  }
  .main-nav__list li:hover .main-nav__megamenu {
    display: none;
    position: relative;
    top: 0;
  }
  /* Animation None */
  .main-nav__list li > .main-nav__sub,
  .main-nav__list li:hover > .main-nav__sub,
  .main-nav__sub li > .main-nav__sub-2,
  .main-nav__sub li:hover > .main-nav__sub-2,
  .main-nav__sub-2 li > .main-nav__sub-3,
  .main-nav__sub-2 li:hover > .main-nav__sub-3,
  .main-nav__list li > .main-nav__megamenu,
  .main-nav__list li:hover > .main-nav__megamenu {
    transform: none;
    visibility: visible;
  }
  .main-nav__sub li:hover > .main-nav__sub-2,
  .main-nav__sub-2 li > .main-nav__sub-3,
  .main-nav__sub-2 li:hover > .main-nav__sub-3,
  .main-nav__list li > .main-nav__megamenu,
  .main-nav__list li:hover > .main-nav__megamenu {
    opacity: 1;
  }
}

/*
// #Hero Unit
// --------------------------------------------------
*/
.hero-unit {
  background: #27313b url("../images/header_bg.jpg") 50% 0 no-repeat;
  background-size: cover;
}

.hero-unit__content--left-center {
  text-align: center;
}

@media (min-width: 992px) {
  .hero-unit__content--left-center {
    width: 50%;
  }
}

.hero-unit__container {
  height: 400px;
  position: relative;
}

@media (min-width: 1199px) {
  .hero-unit__container {
    height: 640px;
  }
}

.hero-unit__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.hero-unit__title {
  color: #fff;
  margin-bottom: 0.75em;
  font-size: 30px;
}

@media (min-width: 1199px) {
  .hero-unit__title {
    font-size: 64px;
    margin-bottom: 0.25em;
  }
}

.hero-unit__subtitle {
  color: #fff;
  margin-bottom: 0.25em;
  font-size: 12px;
}

@media (min-width: 992px) {
  .hero-unit__subtitle {
    font-size: 18px;
  }
}

.hero-unit__desc {
  margin-bottom: 3em;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .hero-unit__desc {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .hero-unit__desc {
    width: 80%;
  }
}

@media (min-width: 1199px) {
  .hero-unit__desc {
    width: 70%;
  }
}

.hero-unit__decor {
  display: block;
  color: #ffdc11;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .hero-unit__decor {
    margin-bottom: 11px;
  }
}

.hero-unit__decor .fa {
  margin: 0 6px;
}

.hero-unit__img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 50%;
}

.hero-unit__img img {
  max-height: 100%;
}

@media (max-width: 991px) {
  .hero-unit__img {
    display: none;
  }
}

/*
// #Hero Slider
// --------------------------------------------------
*/
.hero-slider-wrapper {
  position: relative;
}

.hero-slider {
  overflow: hidden;
  background-color: #27313b;
}

.hero-slider,
.hero-slider__item {
  height: 320px;
}

@media (min-width: 767px) {
  .hero-slider,
  .hero-slider__item {
    height: 480px;
  }
}

@media (min-width: 992px) {
  .hero-slider,
  .hero-slider__item {
    height: 640px;
  }
}

@media (min-width: 1200px) {
  .hero-slider,
  .hero-slider__item {
    height: 720px;
  }
}

.hero-slider__item {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.hero-slider__item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 2;
}

.hero-slider__item--img1 {
  background-image: url("../images/soccer/samples/hero-slide-1.jpg");
}

.hero-slider__item--img2 {
  background-image: url("../images/soccer/samples/hero-slide-2.jpg");
}

.hero-slider__item--img3 {
  background-image: url("../images/soccer/samples/hero-slide-3.jpg");
}

.hero-slider__item-container {
  display: block;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.hero-slider__item-container .post__meta-block--top {
  padding-bottom: 0;
}

.hero-slider__item-container .page-heading__title .highlight {
  color: #c2ff1f;
}

.hero-slider-thumbs-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

@media (max-width: 991px) {
  .hero-slider-thumbs-wrapper {
    display: none;
  }
}

.hero-slider-thumbs .posts__title {
  color: #fff;
  font-size: 15px;
}

.hero-slider-thumbs .posts__cat {
  margin-bottom: 18px;
}

.hero-slider-thumbs .posts__cat-label {
  font-size: 10px;
}

.hero-slider-thumbs .posts__inner {
  padding: 0 30% 37px 0;
}

.hero-slider-thumbs__item {
  opacity: .3;
  transition: opacity 0.3s ease;
}

.hero-slider-thumbs__item.slick-current {
  opacity: 1;
}

.hero-slider-thumbs__item:hover {
  cursor: pointer;
}

/* Pushy Panel */
.pushy-panel__toggle {
  display: none;
  width: 62px;
  height: 62px;
  line-height: 62px;
  background-color: #38a9ff;
  vertical-align: top;
  margin-left: 27px;
  position: relative;
}

@media (min-width: 992px) {
  .pushy-panel__toggle {
    display: inline-block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pushy-panel__toggle {
    margin-left: 18px;
  }
}

.pushy-panel__toggle:hover .pushy-panel__line {
  transform: rotate(180deg);
}

.pushy-panel__toggle:hover .pushy-panel__line::before, .pushy-panel__toggle:hover .pushy-panel__line::after {
  width: 50%;
}

.pushy-panel__toggle:hover .pushy-panel__line::before {
  top: 0;
  transform: translateX(-3px) translateY(1px) rotate(-45deg);
}

.pushy-panel__toggle:hover .pushy-panel__line::after {
  bottom: 0;
  transform: translateX(-3px) translateY(-1px) rotate(45deg);
}

.pushy-panel__line {
  top: 50%;
  left: 23px;
  right: 24px;
  width: 16px;
  transition: transform 0.3s;
}

.pushy-panel__line, .pushy-panel__line::before, .pushy-panel__line::after {
  display: block;
  position: absolute;
  height: 2px;
  background-color: #fff;
}

.pushy-panel__line::before, .pushy-panel__line::after {
  content: "";
  width: 100%;
}

.pushy-panel__line::before {
  top: -5px;
  transform-origin: top left;
  transition: transform 0.3s, width 0.3s, top 0.3s;
}

.pushy-panel__line::after {
  bottom: -5px;
  transform-origin: bottom left;
  transition: transform 0.3s, width 0.3s, bottom 0.3s;
}

.pushy-panel {
  position: fixed;
  z-index: 1000;
  right: -380px;
  top: 0;
  bottom: 0;
  width: 380px;
  text-align: left;
  transition: all 0.4s ease-in-out;
}

.site-wrapper--has-overlay-pushy .pushy-panel {
  right: 0;
}

@media (max-width: 991px) {
  .pushy-panel {
    display: none;
  }
}

.pushy-panel .widget--side-panel .widget__title > h4 {
  color: #31404b;
}

.pushy-panel .btn-outline {
  color: #3f4653;
}

.pushy-panel .posts--simple-list .posts__title + .posts__date {
  margin-top: .4em;
}

.pushy-panel .posts--simple-list .posts__item {
  margin-bottom: 44px;
}

.pushy-panel .posts--simple-list .posts__title a {
  color: #31404b;
}

.pushy-panel .posts--simple-list .posts__excerpt {
  overflow: hidden;
  clear: both;
}

.pushy-panel--active {
  right: 0;
}

.pushy-panel__inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.pushy-panel__back-btn {
  display: block;
  width: 49px;
  height: 44px;
  position: absolute;
  right: 32px;
  top: 22px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 8'%3E%3Cpath transform='translate(-238.75 -17.75)' fill='%2331404b' d='M240,21h12v2H240V21Zm0.171-.415,4.243,4.243L243,26.242,238.757,22Zm4.243-1.414-4.243,4.242L238.757,22,243,17.757Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 13px 8px;
}

.pushy-panel__back-btn:hover {
  cursor: pointer;
}

.pushy-panel__header {
  padding: 28px 30px 60px 30px;
  text-align: center;
}

.pushy-panel__content {
  padding: 0 20px;
}

@media (min-width: 992px) {
  .pushy-panel__content {
    padding: 0 40px;
  }
}

.pushy-panel__logo img {
  width: 94px;
  height: auto;
}

.pushy-panel--dark .pushy-panel__inner {
  background-color: #292c31;
}

.pushy-panel--dark .widget--side-panel .widget__title > h4 {
  color: #fff;
}

.pushy-panel--dark .posts--simple-list .posts__title a {
  color: #fff;
}

.pushy-panel--dark .posts--simple-list .posts__title a:hover {
  color: #fff;
}

.pushy-panel--dark .btn-outline {
  border-color: #3f4653;
  color: #fff;
}

.pushy-panel--dark .pushy-panel__back-btn {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 8'%3E%3Cpath transform='translate(-238.75 -17.75)' fill='%23ffffff' d='M240,21h12v2H240V21Zm0.171-.415,4.243,4.243L243,26.242,238.757,22Zm4.243-1.414-4.243,4.242L238.757,22,243,17.757Z'/%3E%3C/svg%3E");
}

/*
// #Page Heading
// --------------------------------------------------
*/
.page-heading {
  background-color: #1e2024;
  background-image: url("../images/soccer/page-heading.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: center;
  padding: 50px 0;
  position: relative;
}

@media (min-width: 992px) {
  .page-heading {
    padding: 110px 0 106px 0;
  }
}

.page-heading .breadcrumb {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  padding: 0;
}

@media (min-width: 992px) {
  .page-heading .breadcrumb {
    font-size: 11px;
    padding: 3px 0;
  }
}

.page-heading .breadcrumb > li + li::before {
  color: #9a9da2;
  font-weight: normal;
}

.page-heading .breadcrumb > li > a {
  color: #fff;
}

.page-heading .breadcrumb > li > a:hover {
  color: #38a9ff;
}

.page-heading::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("../images/page-heading-pattern.png");
  background-size: auto;
  background-position: 100% 0;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.page-heading.effect-duotone .container {
  position: relative;
  z-index: 3;
}

.page-heading--horizontal {
  text-align: inherit;
}

@media (max-width: 767px) {
  .page-heading--horizontal .row {
    display: block;
    text-align: center;
  }
}

.page-heading__title {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: -0.02em;
  font-size: 24px;
  margin-bottom: 0;
  font-style: normal;
}

@media (min-width: 992px) {
  .page-heading__title {
    font-size: 30px;
  }
}

.page-heading__title .highlight {
  color: #c2ff1f;
}

.page-heading--post-bg {
  background-image: url("../images/soccer/samples/_soccer_single-post-img5.jpg");
}

.page-heading--overlay {
  position: relative;
  overflow: hidden;
}

.page-heading--overlay::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.page-heading--duotone {
  position: relative;
  overflow: hidden;
}

.page-heading--duotone::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: none;
  background-color: transparent;
}

.page-heading--simple {
  height: 240px;
}

.page-heading--simple::before {
  display: none;
}

@media (min-width: 768px) {
  .page-heading--simple {
    height: 320px;
  }
}

@media (min-width: 992px) {
  .page-heading--simple {
    height: 360px;
  }
}

@media (min-width: 1200px) {
  .page-heading--simple {
    height: 420px;
  }
}

/*
// #Content
// --------------------------------------------------
*/
.site-content,
.section {
  padding: 30px 0;
}

@media (min-width: 992px) {
  .site-content,
  .section {
    padding: 60px 0;
  }
}

.section--bg1 {
  background-color: #292c31;
}

.section--no-paddings {
  padding: 0;
}

@media (min-width: 992px) {
  .section--no-paddings {
    padding: 0;
  }
}

/*
// #Footer
// --------------------------------------------------
*/
.footer {
  font-size: 14px;
  line-height: 22px;
  color: rgba(154, 157, 162, 0.6);
}

/* Footer Widgets */
.footer-widgets {
  background: #1e2024;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.footer-widgets [class^="col-"]:last-child .widget--footer {
  margin-bottom: 0;
}

.footer-widgets__inner {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .footer-widgets__inner {
    padding: 60px 0 70px;
  }
}

@media (min-width: 992px) {
  .footer-widgets__inner {
    padding: 80px 0 96px;
  }
}

.footer-col-inner {
  padding: 0 7px;
}

/* Footer Logo */
.footer-logo {
  padding: 0 0 50px 0;
  text-align: center;
  width: 40%;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .footer-logo {
    width: auto;
    margin: 0;
    padding: 30px 0;
  }
}

.footer-logo__img {
  max-width: 100%;
  height: auto;
}

.footer-logo--has-txt {
  text-align: left;
  width: 100%;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .footer-logo--has-txt {
    padding: 0 0 25px 0;
    margin-top: -25px;
  }
}

.footer-logo--has-txt .footer-logo__img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

.footer-logo--has-txt .footer-logo__heading {
  display: inline-block;
  vertical-align: middle;
}

.footer-logo--has-txt .footer-logo__txt {
  margin-bottom: 0;
  color: #fff;
}

.footer-logo--has-txt .footer-logo__tagline {
  display: block;
  color: #9a9da2;
  font-size: 12px;
}

/* Footer Secondary */
.footer-secondary {
  background-color: #16171a;
}

@media (min-width: 768px) {
  .footer-secondary--has-decor {
    background-color: #38a9ff;
  }
}

.footer-secondary--has-decor .footer-secondary__inner {
  background-color: #16171a;
  padding: 12px 0 26px 0;
}

@media (min-width: 992px) {
  .footer-secondary--has-decor .footer-secondary__inner {
    padding: 12px 0 26px 0;
  }
}

.footer-secondary--has-decor .footer-secondary__inner::before, .footer-secondary--has-decor .footer-secondary__inner::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 120px;
  background-color: #38a9ff;
  display: none;
}

@media (min-width: 768px) {
  .footer-secondary--has-decor .footer-secondary__inner::before, .footer-secondary--has-decor .footer-secondary__inner::after {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer-secondary--has-decor .footer-secondary__inner::before, .footer-secondary--has-decor .footer-secondary__inner::after {
    width: 80px;
  }
}

.footer-secondary--has-decor .footer-secondary__inner::before {
  left: -20px;
  transform: skew(20deg);
}

.footer-secondary--has-decor .footer-secondary__inner::after {
  right: -20px;
  transform: skew(-20deg);
}

.footer-secondary__inner {
  background-color: #16171a;
  padding: 24px 0;
  position: relative;
  border-top: 0 solid transparent;
}

@media (min-width: 992px) {
  .footer-secondary__inner {
    padding: 24px 0;
  }
}

.footer-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
}

.footer-nav--right {
  text-align: center;
}

@media (min-width: 992px) {
  .footer-nav--right {
    text-align: right;
  }
  .footer-nav--right .footer-nav__item {
    padding-right: 0 !important;
  }
}

.footer-nav--sm {
  font-size: 9px;
}

.footer-nav__item {
  display: inline-block;
  padding: 0 2px;
}

@media (max-width: 479px) {
  .footer-nav__item {
    float: left;
    width: 33.3%;
    text-align: left;
    padding: .25em 0;
  }
}

@media (min-width: 480px) {
  .footer-nav__item {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .footer-nav__item {
    padding: 0 16px;
  }
}

@media (min-width: 992px) {
  .footer-nav__item {
    padding: 0 24px;
  }
}

@media (min-width: 1200px) {
  .footer-nav__item {
    padding: 0 32px;
  }
}

.footer-nav__item > a {
  color: #fff;
  display: inline-block;
  padding: .5em;
  transition: color 0.2s ease;
}

.footer-nav__item > a:hover {
  color: #38a9ff;
}

@media (max-width: 479px) {
  .footer-nav--condensed .footer-nav__item {
    display: inline-block;
    padding: 0;
    text-align: center;
    width: auto;
    float: none;
  }
}

@media (min-width: 480px) {
  .footer-nav--condensed .footer-nav__item {
    padding: 0 8px;
  }
}

@media (min-width: 768px) {
  .footer-nav--condensed .footer-nav__item {
    padding: 0 10px;
  }
}

@media (min-width: 992px) {
  .footer-nav--condensed .footer-nav__item {
    padding: 0 12px;
  }
}

@media (min-width: 1200px) {
  .footer-nav--condensed .footer-nav__item {
    padding: 0 15px;
  }
}

/* Footer Sponsors */
.sponsors {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #2e2f31;
  padding: 24px 0 24px 0;
  margin: 0;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .sponsors {
    margin-top: -16px;
    flex-wrap: nowrap;
    padding: 35px 0 47px 0;
  }
}

@media (min-width: 992px) {
  .sponsors {
    margin-top: -24px;
  }
}

.sponsors-title {
  font-size: 9px;
  line-height: 1.2em;
  color: #fff;
  margin: 0 0 12px 0;
}

@media (min-width: 768px) {
  .sponsors-title {
    margin: 0 25px 0 0;
  }
}

.sponsors-logos {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .sponsors-logos {
    flex-wrap: wrap;
  }
}

.sponsors__item {
  text-align: center;
}

@media (max-width: 1199px) {
  .sponsors__item {
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  .sponsors__item {
    width: 33.3%;
    padding: 10px;
  }
}

@media (max-width: 479px) {
  .sponsors__item {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .sponsors__item {
    margin: 0 12px;
  }
}

@media (min-width: 1200px) {
  .sponsors__item {
    margin: 0 25px;
  }
}

.sponsors__item > a {
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.sponsors__item > a:hover {
  opacity: 1;
}

/* Footer Info */
.footer-info {
  background-color: #1e2024;
}

@media (min-width: 992px) {
  .footer-info .footer-logo--has-txt {
    width: auto;
    padding: 0;
    margin-top: 0;
    margin-right: 30px;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .footer-info .footer-logo--has-txt {
    margin-right: 58px;
  }
}

.footer-info .footer-logo--has-txt .footer-logo__img {
  margin-right: 8px;
}

.footer-info .footer-logo--has-txt .footer-logo__txt {
  font-size: 16px;
  text-transform: uppercase;
}

.footer-info__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0 solid transparent;
  padding: 20px 15px;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .footer-info__inner {
    padding: 44px 0 50px 0;
    flex-wrap: nowrap;
  }
}

/* Footer Copyright */
.footer-copyright {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #9a9da2;
  text-align: center;
}

@media (min-width: 992px) {
  .footer-copyright {
    text-align: left;
  }
}

.footer-copyright a {
  color: #fff;
  transition: color 0.2s ease;
}

.footer-copyright a:hover {
  color: #38a9ff;
}

/*
// #Blog
// --------------------------------------------------
*/
/*
// #Posts
// --------------------------------------------------
*/
ul.posts {
  list-style-type: none;
  padding: 0;
}

ul.posts:not(.post-grid) {
  margin: 0;
}

.posts__item {
  margin-bottom: 1em;
}

.posts__item:last-child {
  margin-bottom: 0;
}

.posts__cat {
  display: block;
  overflow: hidden;
  margin-bottom: 8px;
  padding-right: 5px;
  z-index: 1;
}

.posts__cat-label {
  background-color: #1892ed;
  display: block;
  float: left;
  font-style: normal;
}

.posts__cat-label + .posts__title {
  margin-top: 0.55em;
}

.posts__cat-label + .posts__cat-label {
  margin-left: .5em;
}

.posts__item--category-1 .posts__cat-label,
.posts__item--category-1 .posts__cta {
  background-color: #38a9ff;
}

.posts__item--category-1 .posts__title--color-hover > a:hover {
  color: #38a9ff;
}

.posts__item--category-1 .posts__thumb--hover::before {
  background-color: #38a9ff;
}

.posts__item--category-2 .posts__cat-label,
.posts__item--category-2 .posts__cta {
  background-color: #07e0c4;
}

.posts__item--category-2 .posts__title--color-hover > a:hover {
  color: #07e0c4;
}

.posts__item--category-2 .posts__thumb--hover::before {
  background-color: #07e0c4;
}

.posts__item--category-3 .posts__cat-label,
.posts__item--category-3 .posts__cta {
  background-color: #9fe900;
}

.posts__item--category-3 .posts__title--color-hover > a:hover {
  color: #9fe900;
}

.posts__item--category-3 .posts__thumb--hover::before {
  background-color: #9fe900;
}

.posts__item--category-4 .posts__cat-label,
.posts__item--category-4 .posts__cta {
  background-color: #f34141;
}

.posts__item--category-4 .posts__title--color-hover > a:hover {
  color: #f34141;
}

.posts__item--category-4 .posts__thumb--hover::before {
  background-color: #f34141;
}

.posts__item .posts__cat-label--category-1 {
  background-color: #38a9ff;
}

.posts__item .posts__cat-label--category-2 {
  background-color: #07e0c4;
}

.posts__item .posts__cat-label--category-3 {
  background-color: #9fe900;
}

.posts__item .posts__cat-label--category-4 {
  background-color: #f34141;
}

.posts__thumb {
  float: left;
  margin: 0 10px 0 0;
}

.posts__thumb--hover {
  position: relative;
}

.posts__thumb--hover::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #38a9ff;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.posts__thumb--hover > a {
  position: relative;
  display: block;
}

.posts__thumb--hover > a::before, .posts__thumb--hover > a::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  transition: all 0.3s ease;
}

.posts__thumb--hover > a::before {
  height: 4px;
  width: 18px;
}

.posts__thumb--hover > a::after {
  height: 18px;
  width: 4px;
}

.posts__thumb--hover:hover::before {
  opacity: 0.8;
}

.posts__thumb--hover:hover > a::before, .posts__thumb--hover:hover > a::after {
  transform: translate(-50%, -50%) scale(1);
}

.posts__thumb--video {
  position: relative;
}

.posts__thumb--video::before {
  content: "\f04b";
  display: block;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50% !important;
  color: #fff;
  font-size: 28px;
  text-indent: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  text-rendering: auto;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
  transition: transform 0.3s ease;
}

.posts__thumb--video:hover::before {
  transform: scale(1.1);
}

.posts__thumb--video-sm::before {
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  line-height: 32px;
  font-size: 14px;
  text-indent: 2px;
}

.posts__title {
  text-transform: uppercase;
  letter-spacing: -0.03em;
  font-style: normal;
}

.posts__title + .posts__date {
  margin-top: 0.7em;
}

.posts__title a {
  color: #31404b;
  transition: color 0.2s ease-in-out;
}

.posts__title a:hover {
  color: #4f6779;
}

.posts__date {
  display: inline-block;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2em;
}

/* Simple Posts List */
.posts--simple-list .posts__item {
  overflow: hidden;
  margin-bottom: 18px;
}

.card .posts--simple-list .posts__item {
  border-bottom: 1px solid #e4e7ed;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.posts--simple-list .posts__item:last-child,
.card .posts--simple-list .posts__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.card .posts--simple-list.posts--simple-list-numbered .posts__item {
  padding-left: 100px;
}

.card .posts--simple-list.posts--simple-list-numbered .posts__item::before {
  width: 100px;
  text-align: center;
  margin-top: -16px;
}

.card .posts--simple-list.posts--simple-list-numbered .posts__item:last-child::before {
  margin-top: -4px;
}

.card .posts--simple-list.posts--simple-list-numbered .posts__item .posts__title {
  font-size: 14px;
}

.posts--simple-list .posts__inner {
  overflow: hidden;
}

.posts--simple-list .posts__cat-label + .posts__title {
  margin-top: 0.55em;
}

.posts--simple-list .posts__title {
  margin-bottom: 0;
  line-height: 1em;
}

.widget--footer .posts--simple-list .posts__title {
  font-size: 12px;
  line-height: 1em;
  font-style: normal;
}

.widget--footer .posts--simple-list .posts__title > a {
  transition: color 0.2s ease;
}

.widget--footer .posts--simple-list .posts__title > a:not(:hover) {
  color: #fff;
}

.widget--footer .posts--simple-list .posts__title:not(.posts__title--color-hover) > a:hover {
  color: #38a9ff;
}

.widget--footer .posts--simple-list .posts__title + .posts__date {
  margin-top: 0.25em;
}

.main-nav__megamenu .posts--simple-list .posts__title {
  font-size: 12px;
}

@media (max-width: 991px) {
  .main-nav__megamenu .posts--simple-list .posts__title {
    font-size: 11px;
  }
  .main-nav__megamenu .posts--simple-list .posts__title > a {
    color: #fff;
  }
}

@media (min-width: 992px) {
  .main-nav__megamenu .posts--simple-list .posts__title > a:not(:hover) {
    color: #fff;
  }
}

.posts--simple-list .posts__thumb {
  border-radius: 0;
}

.posts--simple-list .posts__thumb::before {
  border-radius: 0;
}

.posts--simple-list .posts__thumb img {
  border-radius: 0;
}

.posts--simple-list .posts__date,
.posts--simple-list .posts__comments {
  font-size: 10px;
  line-height: 1.2em;
  vertical-align: middle;
  display: inline-block;
}

@media (max-width: 991px) {
  .main-nav__megamenu .posts--simple-list .posts__date, .main-nav__megamenu
  .posts--simple-list .posts__comments {
    font-size: 8px;
  }
}

.widget--footer .posts--simple-list .posts__date, .widget--footer
.posts--simple-list .posts__comments {
  font-size: 9px;
}

.posts--simple-list .posts__comments i {
  font-size: 12px;
  margin-right: 3px;
  margin-left: 1px;
  display: inline-block;
  vertical-align: middle;
}

.widget--footer .posts--simple-list .posts__comments {
  min-width: 57px;
}

.posts--simple-list .posts__excerpt {
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
}

@media (max-width: 991px) {
  .main-nav__megamenu .posts--simple-list .posts__excerpt {
    font-size: 11px;
    line-height: 17px;
  }
}

.posts--simple-list .posts__excerpt--space {
  padding: 20px 0;
}

.posts--simple-list .posts__excerpt--space-sm {
  padding-top: 10px;
}

.posts--simple-list .posts__footer {
  padding-top: 17px;
}

.card .posts--simple-list .posts__footer {
  padding: 17px 24px;
  border-top: 1px solid #e4e7ed;
  overflow: hidden;
  margin-left: -24px;
  margin-right: -24px;
}

.posts--simple-list .post-author {
  float: left;
  width: 50%;
}

@media (max-width: 479px) {
  .posts--simple-list .post-author {
    width: 40%;
  }
}

.posts--simple-list .post-author__avatar {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
}

.posts--simple-list .post-author__avatar img {
  vertical-align: top;
}

@media (max-width: 479px) {
  .posts--simple-list .post-author__avatar {
    display: none;
  }
}

.posts--simple-list .post-author__name {
  font-size: 10px;
  text-transform: uppercase;
  color: #9a9da2;
  font-weight: 400;
}

.posts--simple-list .post__meta {
  width: 50%;
  float: right;
  text-align: right;
}

@media (max-width: 479px) {
  .posts--simple-list .post__meta {
    width: 60%;
  }
}

.posts--simple-list .posts__more {
  padding: 20px 0 0 0;
}

/* Simple Posts List - Condensed */
.card .posts--simple-list-condensed .posts__item {
  padding-bottom: 16px;
  margin-bottom: 18px;
}

/* Simple Post List - Numbered */
.posts--simple-list-numbered {
  counter-reset: list;
}

.posts--simple-list-numbered .posts__item {
  padding-left: 100px;
  position: relative;
  counter-increment: list;
}

.widget--footer .posts--simple-list-numbered .posts__item {
  padding-left: 38px;
}

.posts--simple-list-numbered .posts__item::before {
  content: counter(list, decimal-leading-zero);
  font-family: "Montserrat", sans-serif;
  font-size: 44px;
  line-height: 1em;
  font-style: italic;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: .2;
}

.widget--footer .posts--simple-list-numbered .posts__item::before {
  font-size: 24px;
}

/* Simple Posts List - Large */
.widget--side-panel .posts--simple-list--lg .posts__item {
  margin-bottom: 50px;
}

.widget--side-panel .posts--simple-list--lg .posts__item:last-child {
  margin-bottom: 0;
}

.main-nav__megamenu .posts--simple-list--lg .posts__item {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .main-nav__megamenu .posts--simple-list--lg .posts__item {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .main-nav__megamenu .posts--simple-list--lg .posts__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .main-nav__megamenu .posts--simple-list--lg .posts__item .posts__title {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .main-nav__megamenu .posts--simple-list--lg .posts__item .posts__title {
    font-size: 22px;
  }
}

.posts--simple-list--lg .posts__cat {
  margin-bottom: 14px;
}

@media (min-width: 992px) {
  .posts--simple-list--lg .posts__cat-label {
    font-size: 10px;
  }
}

.posts--simple-list--lg .posts__title {
  margin-bottom: .2em;
}

@media (min-width: 992px) {
  .posts--simple-list--lg .posts__title {
    font-size: 18px;
    line-height: 1em;
  }
  .widget--side-panel .posts--simple-list--lg .posts__title {
    font-size: 22px;
  }
}

.posts--simple-list--lg .posts__excerpt {
  font-size: 15px;
  line-height: 26px;
}

/* Simple Posts List - XLarge */
.posts--simple-list--xlg .posts__cat {
  margin-bottom: 14px;
}

@media (min-width: 992px) {
  .posts--simple-list--xlg .posts__cat-label {
    font-size: 10px;
  }
}

.posts--simple-list--xlg .posts__title {
  margin-bottom: .1em;
}

@media (min-width: 768px) {
  .posts--simple-list--xlg .posts__title {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .posts--simple-list--xlg .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 1199px) {
  .posts--simple-list--xlg .posts__title {
    font-size: 30px;
    line-height: 28px;
  }
}

.posts--simple-list--xlg .posts__date {
  font-size: 9px;
}

@media (min-width: 992px) {
  .posts--simple-list--xlg .posts__date {
    font-size: 11px;
  }
}

.posts--simple-list--xlg .posts__excerpt {
  font-size: 15px;
  line-height: 26px;
}

@media (min-width: 1199px) {
  .posts--simple-list--xlg .posts__excerpt {
    margin-top: 13px;
  }
}

@media (min-width: 992px) {
  .posts--simple-list--xlg .posts__more {
    padding-top: 30px;
  }
}

@media (min-width: 1199px) {
  .posts--simple-list--xlg .posts__more {
    padding-top: 38px;
  }
}

/* Simple Posts List - Search Result */
/* Posts List - Horizontal */
.posts-layout-horizontal {
  display: flex;
}

@media (max-width: 991px) {
  .posts-layout-horizontal {
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .main-nav__megamenu .posts-layout-horizontal {
    padding-bottom: 22px;
    margin-bottom: 30px !important;
    margin-left: -55px !important;
    margin-right: -55px !important;
    padding-left: 47px;
    padding-right: 47px;
    border-bottom: 1px solid #292c31;
  }
}

.posts-layout-horizontal .posts__item {
  padding-right: 8px;
  padding-left: 8px;
}

@media (max-width: 991px) {
  .posts-layout-horizontal .posts__item {
    padding-left: 0;
    padding-right: 0;
  }
  .main-nav__megamenu .posts-layout-horizontal .posts__item .posts__thumb {
    max-width: 35%;
  }
}

@media (min-width: 992px) {
  .posts-layout-horizontal .posts__item {
    padding-right: 20px;
  }
}

@media (min-width: 1199px) {
  .posts-layout-horizontal .posts__item {
    padding-right: 30px;
  }
}

.posts-layout-horizontal--3cols .posts__item {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .posts-layout-horizontal--3cols .posts__item {
    flex-basis: 33.3%;
  }
}

/* Post: Cards */
.posts__item--card-row {
  flex-direction: row;
}

.posts__item--card, .posts__item--card:last-child {
  margin-bottom: 15px;
}

.posts__item--card .posts__thumb {
  float: none;
  margin: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.posts__item--card .posts__thumb:first-child {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.posts__item--card .posts__thumb:last-child {
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.posts__item--card .posts__thumb img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.posts__item--card .posts__thumb:hover img {
  transform: scale(1.1);
}

.posts__item--card.posts__item--card-condensed {
  margin-bottom: 0;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.posts__item--card .posts__cta {
  display: block;
  position: absolute;
  top: 0;
  right: 23px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  transform: translateY(-50%);
}

.posts__item--card .posts__cta::before, .posts__item--card .posts__cta::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: transform 0.25s ease;
}

.posts__item--card .posts__cta::before {
  width: 17px;
  height: 3px;
}

.posts__item--card .posts__cta::after {
  width: 3px;
  height: 17px;
}

.posts__item--card .posts__cta:hover::before, .posts__item--card .posts__cta:hover::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.posts__item--card .posts__cat {
  display: block;
  position: absolute;
  left: 23px;
  top: 23px;
}

.posts__item--card .posts__inner .posts__cat {
  position: static;
  margin-bottom: 18px;
}

.posts__item--card .posts__cat-label {
  font-size: 10px;
  padding-top: .6em;
}

.posts__item--card .posts__date {
  font-size: 10px;
  line-height: 1.2em;
}

.posts__item--card .posts__date + .posts__title {
  margin-top: 5px;
}

@media (min-width: 992px) {
  .posts__item--card .posts__date + .posts__title {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .posts__item--card .posts__date {
    font-size: 11px;
  }
}

.posts__item--card .posts__title {
  font-size: 18px;
  line-height: 1em;
  margin-bottom: 0.2em;
}

@media (min-width: 992px) {
  .posts__item--card .posts__title {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .posts__item--card .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .posts__item--card .posts__title--sm {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .posts__item--card .posts__title--sm + .posts__excerpt {
    padding: 10px 0 0 0;
  }
}

.posts__item--card .posts__excerpt {
  padding: 13px 0 0 0;
}

@media (min-width: 992px) {
  .posts__item--card .posts__excerpt {
    padding: 23px 0 8px 0;
  }
}

.posts__item--card .posts__inner {
  padding-top: 18px;
  position: relative;
}

.posts__item--card .posts__footer {
  padding: 17px 24px;
  border-top: 1px solid #e4e7ed;
  display: table;
  width: 100%;
  height: 100%;
}

.posts__item--card .post-author {
  display: table-cell;
  width: 50%;
  text-align: left;
}

@media (max-width: 479px) {
  .posts__item--card .post-author {
    width: 40%;
  }
}

.posts__item--card .post-author__avatar {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
}

.posts__item--card .post-author__avatar img {
  vertical-align: top;
}

@media (max-width: 479px) {
  .posts__item--card .post-author__avatar {
    display: none;
  }
}

.posts__item--card .post-author__name {
  font-size: 10px;
  text-transform: uppercase;
  color: #9a9da2;
  font-weight: 400;
  font-style: normal;
}

.posts__item--card .post__meta {
  display: table-cell;
  width: 50%;
  text-align: right;
}

/* Posts: Cards Compact (video) */
.posts--card-compact .posts__item, .posts--card-compact .posts__item:last-child {
  margin-bottom: 15px;
}

.posts--card-compact .posts__item .posts__thumb {
  float: none;
  margin: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.posts--card-compact .posts__item .posts__thumb:first-child {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.posts--card-compact .posts__item .posts__thumb:last-child {
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.posts--card-compact .posts__item .posts__thumb img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.posts--card-compact .posts__item .posts__cat {
  margin-bottom: 12px;
}

.posts--card-compact .posts__item .posts__title {
  font-size: 18px;
  line-height: 1.11em;
  margin-bottom: 0.4em;
}

.posts--card-compact .posts__item--lg .posts__cat-label {
  font-size: 10px;
}

@media (min-width: 992px) {
  .posts--card-compact .posts__item--lg .posts__title {
    font-size: 26px;
    line-height: 1em;
  }
}

/* Post: Tile */
.main-nav__megamenu .posts--tile {
  margin: -44px -65px -44px 0;
}

@media only screen and (max-width: 991px) {
  .main-nav__megamenu .posts--tile {
    margin: 0;
  }
  .main-nav__megamenu .posts--tile .posts__footer,
  .main-nav__megamenu .posts--tile .posts__cta {
    display: none;
  }
}

.main-nav__megamenu .posts--tile .posts__item--tile {
  margin-bottom: 0 !important;
  border-radius: 0;
}

.main-nav__megamenu .posts--tile .posts__thumb img {
  border-radius: 0;
}

.posts__item--tile {
  position: relative;
  margin-bottom: 15px !important;
}

.posts__item--tile .posts__thumb {
  margin: 0;
  float: none;
  position: relative;
}

.posts__item--tile .posts__thumb img {
  border-radius: 4px;
  width: 100%;
  height: auto;
}

.posts__item--tile .posts__thumb::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

.posts__item--tile .posts__inner {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}

.posts__item--tile .posts__inner--centered {
  top: 50%;
  transform: translateY(-50%);
}

.posts__item--tile .posts__cat .posts__cat-label {
  float: none;
  display: inline-block;
  font-size: 10px;
}

.posts__item--tile .posts__title {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: .5em;
  line-height: 1em;
}

@media (min-width: 992px) {
  .posts__item--tile .posts__title {
    font-size: 24px;
    line-height: 1.0833em;
  }
}

.posts__item--tile .posts__cta {
  display: block;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 4;
}

.posts__item--tile .posts__cta::before, .posts__item--tile .posts__cta::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: transform 0.25s ease;
}

.posts__item--tile .posts__cta::before {
  width: 17px;
  height: 3px;
}

.posts__item--tile .posts__cta::after {
  width: 3px;
  height: 17px;
}

.posts__item--tile .posts__cta:hover::before, .posts__item--tile .posts__cta:hover::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.posts__item--tile .posts__date {
  font-size: 11px;
  color: #fff;
  margin-bottom: 2.5em;
}

.posts__item--tile .posts__footer {
  border-top: 1px solid #e4e7ed;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
}

.posts__item--tile .post-author {
  display: inline-block;
  margin-right: 14px;
  position: relative;
  top: -2px;
}

.posts__item--tile .post-author__avatar {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
  width: 24px;
  height: 24px;
  float: none;
  margin-right: 5px;
}

.posts__item--tile .post-author__name {
  margin: 0;
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  color: #9a9da2;
}

.posts__item--tile .post__meta {
  display: inline-block;
}

.posts--tile-alt .posts__thumb img {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.posts--tile-alt .posts__thumb::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 20%;
  opacity: .6;
  background-image: linear-gradient(to bottom, transparent 0%, #000 100%);
}

.posts--tile-alt .posts__inner {
  text-align: left;
  padding: 12px 24px;
}

.posts--tile-alt .posts__cat {
  margin-bottom: 10px;
}

.posts--tile-alt .posts__title {
  padding-left: 0;
  padding-right: 0;
  line-height: 1em;
  margin-bottom: .15em;
  font-size: 20px;
}

@media (min-width: 992px) {
  .posts--tile-alt .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .posts--tile-alt .posts__title {
    font-size: 30px;
  }
}

.posts--tile-alt .posts__date {
  margin-bottom: 0;
  font-weight: bold;
}

.posts--tile-alt .posts__footer {
  text-align: left;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
}

@media (max-width: 991px) {
  .posts--tile-alt .posts__footer {
    flex-wrap: wrap;
  }
}

.posts--tile-alt .post__meta {
  margin-left: auto;
}

@media (min-width: 992px) {
  .posts__item--tile-sm .posts__title {
    font-size: 22px;
  }
}

.posts--tile-alt-nopaddings {
  margin-left: 0;
  margin-right: 0;
}

.posts--tile-alt-nopaddings .post-grid__item {
  padding-left: 0;
  padding-right: 0;
}

.posts--tile-alt-nopaddings .posts__item {
  margin-bottom: 0 !important;
}

.posts--tile-alt-nopaddings .posts__thumb img {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.posts--tile-meta-position .posts__title + .posts__date {
  margin-top: 0;
  margin-right: 10px;
}

@media (min-width: 992px) {
  .posts--tile-meta-position .meta__item + .meta__item {
    margin-left: 8px;
  }
}

.posts--tile-thumb-bordered .posts__thumb {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
}

.posts--tile-hero-post-grid {
  padding-top: 20px;
}

@media (min-width: 992px) {
  .posts--tile-hero-post-grid {
    padding-top: 30px;
  }
}

@media (min-width: 1200px) {
  .posts--tile-hero-post-grid {
    padding-top: 40px;
  }
}

@media (min-width: 992px) {
  .posts--tile-hero-post-grid .posts__title {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .posts--tile-hero-post-grid .posts__title {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .posts--tile-hero-post-grid .posts__title--lg {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .posts__item--tile-lg .posts__title {
    font-size: 34px;
  }
}

.posts--tile-alt-noborder .posts__item--tile {
  border: 0;
  border-radius: 0;
  margin-bottom: 0 !important;
}

.posts--tile-alt-noborder .posts__thumb {
  border-radius: 0;
}

.posts--tile-alt-noborder .posts__thumb img {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .posts--tile-alt-noborder .posts__title {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .posts--tile-alt-noborder .posts__inner {
    padding: 40px;
  }
}

/* Post Tile - Event */
.posts__item--tile-event .posts__inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 12.855px;
  line-height: 1.833em;
  color: #31404b;
}

@media (min-width: 992px) {
  .posts__item--tile-event .posts__inner {
    padding-top: 45px;
    padding-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .posts__item--tile-event .posts__inner {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

.posts__item--tile-event .posts__thumb::after {
  top: 0;
  background-image: linear-gradient(120deg, #31404b 0%, #07e0c4 100%);
  opacity: .4;
}

.posts__item--tile-event .posts__excerpt {
  padding: 10px 0;
}

@media (min-width: 992px) {
  .posts__item--tile-event .posts__excerpt {
    padding: 10px 20px;
  }
}

.posts__item--tile-event .posts__countdown {
  margin-top: auto;
  margin-bottom: auto;
}

.posts__item--tile-event .posts__logo img {
  width: auto;
  height: auto;
  margin: 0 auto;
}

.posts__item--tile-event .countdown-counter__label {
  color: #31404b;
}

/* Post Grid */
@media (max-width: 479px) {
  .post-grid--2cols .post-grid__item {
    width: 100%;
  }
}

.post-grid--featured {
  padding-top: 15px;
}

/* Post: Cards Thumb Left */
.posts--cards-thumb-left .posts__item--card {
  overflow: hidden;
}

.posts--cards-thumb-left .posts__thumb {
  width: 100%;
  float: none;
  overflow: hidden;
  height: 200px;
}

@media (min-width: 375px) {
  .posts--cards-thumb-left .posts__thumb {
    height: 240px;
  }
}

@media (min-width: 768px) {
  .posts--cards-thumb-left .posts__thumb {
    float: left;
    margin: 0;
    max-width: 325px;
    height: auto;
  }
  .posts--cards-thumb-left .posts__thumb:first-child {
    border-radius: 4px 0 0 4px;
  }
}

@media (min-width: 992px) {
  .posts--cards-thumb-left .posts__thumb {
    max-width: 220px;
  }
}

@media (min-width: 1199px) {
  .posts--cards-thumb-left .posts__thumb {
    max-width: 380px;
  }
}

.posts--cards-thumb-left .posts__inner {
  overflow: hidden;
  padding-top: 0;
}

@media (min-width: 992px) {
  .posts--cards-thumb-left .posts__inner {
    padding-top: 8px;
  }
}

.posts--cards-thumb-left .posts__cta {
  position: absolute;
  left: 20px;
  top: 20px;
  transform: none;
}

.posts--cards-thumb-left .posts__cta:hover {
  transform: rotate(90deg);
}

/* Post: Cards Thumb Large */
@media (min-width: 992px) {
  .posts--cards-thumb-lg .posts__inner {
    padding-top: 30px;
  }
}

.posts--cards-thumb-lg .posts__cat {
  position: static;
}

@media (min-width: 992px) {
  .posts--cards-thumb-lg .posts__cat {
    margin-bottom: 17px;
  }
}

@media (min-width: 992px) {
  .posts--cards-thumb-lg .posts__title {
    font-size: 28px;
    line-height: 1.07em;
  }
}

/* Post: Blockquote */
.posts__item--quote {
  background-color: #194676;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  min-width: 304px;
}

@media (min-width: 992px) {
  .posts__item--quote {
    min-height: 482px;
  }
}

@media (min-width: 1199px) {
  .posts__item--quote {
    min-height: 488px;
  }
}

.posts__item--quote::before {
  content: "\201C";
  display: block;
  position: absolute;
  left: -0.09em;
  top: -0.18em;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  opacity: .1;
  font-size: 300px;
  line-height: 1em;
  pointer-events: none;
}

@media (min-width: 992px) {
  .posts__item--quote::before {
    font-size: 600px;
  }
}

@media (min-width: 1199px) {
  .posts__item--quote::before {
    font-size: 700px;
  }
}

.blockquote--card {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.blockquote--card .blockquote__content {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1em;
  color: #fff;
  font-style: normal;
  margin: 0;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .blockquote--card .blockquote__content {
    font-size: 24px;
  }
}

@media (min-width: 1199px) {
  .blockquote--card .blockquote__content {
    font-size: 36px;
  }
}

.blockquote--card .blockquote-stress {
  display: block;
  color: #c2ff1f;
}

.blockquote--card .blockquote__footer {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  line-height: 1.2em;
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.blockquote--card .blockquote__footer::before {
  display: none;
}

@media (min-width: 992px) {
  .blockquote--card .blockquote__footer {
    text-align: right;
  }
}

.blockquote--card .blockquote__cite {
  font-style: normal;
}

.blockquote--card .blockquote__author-name {
  display: block;
  font-size: 11px;
}

@media (min-width: 992px) {
  .blockquote--card .blockquote__author-name {
    font-size: 14px;
  }
}

.blockquote--card .blockquote__author-info {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
}

@media (min-width: 992px) {
  .blockquote--card .blockquote__author-info {
    font-size: 10px;
  }
}

/* Posts: Featured Slider */
.posts--slider-featured:hover .posts__thumb img {
  transform: scale(1.1);
}

.posts--slider-featured .posts__item {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.posts--slider-featured .posts__thumb {
  margin: 0;
}

.posts--slider-featured .posts__thumb img {
  border-radius: 4px;
  position: relative;
  transition: transform 0.3s ease-in-out;
  backface-visibility: hidden;
}

.posts--slider-featured .posts__inner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-image: linear-gradient(to bottom, transparent, #000);
  border-radius: 0 0 4px 4px;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 992px) {
  .posts--slider-featured .posts__inner {
    padding: 30px 23% 30px 40px;
  }
}

@media (min-width: 992px) {
  .posts--slider-featured .posts__cat {
    margin-bottom: 13px;
  }
}

@media (min-width: 992px) {
  .posts--slider-featured .posts__cat-label {
    font-size: 10px;
  }
}

.posts--slider-featured .posts__title {
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 0.4em;
  color: #fff;
}

@media (min-width: 768px) {
  .posts--slider-featured .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .posts--slider-featured .posts__title {
    font-size: 32px;
  }
}

@media (min-width: 1199px) {
  .posts--slider-featured .posts__title {
    font-size: 38px;
    line-height: 34px;
  }
}

.posts--slider-featured .posts__title .text-primary {
  display: block;
}

.posts--slider-featured .posts__title-higlight {
  color: #9fe900;
}

.posts--slider-featured .post-author {
  color: #fff;
}

.posts--slider-featured .post-author__avatar {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

@media (min-width: 768px) {
  .posts--slider-featured .post-author__avatar {
    margin-right: 8px;
  }
}

.posts--slider-featured .post-author__name,
.posts--slider-featured .posts__date {
  font-size: 9px;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .posts--slider-featured .post-author__name,
  .posts--slider-featured .posts__date {
    font-size: 11px;
  }
}

.posts--slider-featured .posts__date::before {
  content: "\2013";
  margin-right: 0.25em;
}

/* Featured Slider - Center */
.posts-slider--center .posts__thumb {
  float: none;
  position: relative;
}

.posts-slider--center .posts__thumb::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 1;
}

.posts-slider--center .posts__inner {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  right: auto;
  background: none;
  text-align: center;
  width: 80%;
  padding: 0;
  z-index: 2;
}

@media (min-width: 992px) {
  .posts-slider--center .posts__inner {
    padding: 0;
    width: 60%;
  }
}

.posts-slider--center .posts__cat-label {
  float: none;
  display: inline-block;
}

.posts-slider--center .posts__title {
  font-size: 14px;
}

@media (min-width: 768px) {
  .posts-slider--center .posts__title {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .posts-slider--center .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 1199px) {
  .posts-slider--center .posts__title {
    font-size: 32px;
    line-height: 32px;
  }
}

/* Featured Carousel */
.posts--carousel-featured .posts__item {
  position: relative;
}

.posts--carousel-featured .posts__title {
  color: #fff;
  margin-bottom: .25em;
  font-size: 15px;
}

@media (min-width: 480px) {
  .posts--carousel-featured .posts__title {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .posts--carousel-featured .posts__title {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .posts--carousel-featured .posts__title {
    font-size: 15px;
  }
}

@media (min-width: 1199px) {
  .posts--carousel-featured .posts__title {
    font-size: 18px;
    line-height: 1.11em;
  }
}

@media (min-width: 992px) {
  .posts--carousel-featured .posts__cat {
    margin-bottom: 10px;
  }
}

@media (min-width: 1199px) {
  .posts--carousel-featured .posts__cat {
    margin-bottom: 17px;
  }
}

.posts--carousel-featured .posts__cat-label {
  float: none;
  display: inline-block;
}

@media (min-width: 992px) {
  .posts--carousel-featured .posts__cat-label {
    font-size: 10px;
  }
}

.posts--carousel-featured .posts__thumb {
  float: none;
  position: relative;
  margin: 0;
}

.posts--carousel-featured .posts__thumb img {
  width: 100%;
  vertical-align: top;
}

.posts--carousel-featured .posts__inner {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15%;
  text-align: center;
}

@media (max-width: 768px) and (min-width: 992px) {
  .posts--carousel-featured .posts__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.posts--carousel-featured .posts__date {
  color: #9a9da2;
  font-size: 9px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .posts--carousel-featured .posts__date {
    font-size: 11px;
  }
}

.posts--carousel-featured .post__meta {
  padding-top: 5px;
}

@media (min-width: 992px) {
  .posts--carousel-featured .post__meta {
    padding-top: 10px;
  }
}

@media (min-width: 1199px) {
  .posts--carousel-featured .post__meta {
    padding-top: 22px;
  }
}

.posts--carousel-featured .meta__item {
  color: #fff;
}

/* Posts - Horizontal */
.posts--horizontal {
  display: flex;
}

@media (max-width: 767px) {
  .posts--horizontal {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .posts--horizontal .posts__item,
  .card .posts--horizontal .posts__item {
    padding-bottom: 0;
    border-left: 1px solid #e4e7ed;
    border-bottom: none;
    margin: -24px 0 !important;
  }
  .posts--horizontal .posts__item:first-child,
  .card .posts--horizontal .posts__item:first-child {
    border-left: none;
    padding-left: 0;
  }
  .posts--horizontal .posts__item:first-child .posts__inner,
  .card .posts--horizontal .posts__item:first-child .posts__inner {
    padding-left: 0;
  }
  .posts--horizontal .posts__item:last-child,
  .card .posts--horizontal .posts__item:last-child {
    padding-right: 0;
  }
  .posts--horizontal .posts__item:last-child .posts__inner,
  .card .posts--horizontal .posts__item:last-child .posts__inner {
    padding-right: 0;
  }
  .posts--horizontal .posts__item .posts__inner,
  .card .posts--horizontal .posts__item .posts__inner {
    padding: 24px 0;
  }
}

/* Posts - Carousel Video Slideshow */
.posts--carousel .posts__item {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0;
}

.posts--carousel .posts__link-wrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 8px;
  text-align: left;
}

.posts--carousel .posts__link-wrapper:hover .posts__inner {
  transform: translateY(0);
}

.posts--carousel .posts__link-wrapper:hover::before {
  transform: translateY(-100%);
}

.posts--carousel .posts__link-wrapper::before {
  content: "\f04b";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  background-color: rgba(23, 29, 33, 0.6);
  border-radius: 50%;
  border: 4px solid #38a9ff;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  text-indent: 3px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform 0.3s ease;
  pointer-events: none;
}

.posts--carousel .posts__inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 15px 18px 15px;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
  pointer-events: none;
}

.posts--carousel .posts__title {
  color: #fff;
  margin-bottom: .25em;
  font-size: 12px;
  line-height: 1em;
}

@media (min-width: 480px) {
  .posts--carousel .posts__title {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .posts--carousel .posts__title {
    font-size: 13px;
  }
}

@media (min-width: 1199px) {
  .posts--carousel .posts__title {
    font-size: 14px;
  }
}

.posts--carousel .posts__thumb {
  float: none;
  margin: 0;
  background-color: #171d21;
  border-radius: 4px;
}

.posts--carousel .posts__thumb img {
  border-radius: 4px;
  position: relative;
  transition: opacity 0.3s ease-in-out;
  backface-visibility: hidden;
  opacity: .6;
}

.posts--carousel .posts__thumb:hover img {
  opacity: .3;
}

.posts--carousel .posts__date {
  color: #9a9da2;
  font-size: 9px;
  font-weight: 700;
  display: block;
  line-height: 1em;
}

@media (min-width: 992px) {
  .posts--carousel .posts__date {
    font-size: 10px;
  }
}

.posts--carousel .slick-prev,
.posts--carousel .slick-next {
  top: -62px;
}

.posts--carousel .slick-prev:hover,
.posts--carousel .slick-next:hover {
  background-color: #31404b;
}

/* Posts: Inline (marquee) */
.posts--inline {
  margin: 0;
}

.posts--inline .posts__item,
.posts--inline .posts__title,
.posts--inline .posts__cat,
.posts--inline .posts__excerpt {
  display: inline-block;
  margin: 0;
  font-size: 12px;
}

.posts--inline .posts__title {
  margin-right: .5em;
}

.posts--inline .posts__title::before {
  content: "//";
  margin-right: 1em;
  color: #c2ff1f;
}

.posts--inline .posts__cat {
  position: relative;
  top: 3px;
}

.posts--inline .posts__cat-label {
  margin-bottom: 0;
}

.posts--inline .posts__excerpt {
  margin-right: 1em;
}

/* Featured News - Football */
.posts--slider-var-width .row {
  display: block;
}

.posts--slider-var-width .row [class*="col-"] {
  float: left;
}

.posts__item--desc-center .posts__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  right: auto;
  background: none;
  text-align: center;
  width: 80%;
  padding: 0;
  z-index: 2;
}

@media (min-width: 992px) {
  .posts__item--desc-center .posts__inner {
    padding: 0;
    width: 60%;
  }
}

.posts__item--desc-center .posts__thumb {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e4e7ed;
  float: none;
  margin: 0 0 15px 0;
  transform: translate3d(0, 0, 0);
}

.posts__item--desc-center .posts__thumb img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.posts__item--desc-center .posts__cat {
  overflow: visible;
}

.posts__item--desc-center .posts__cat-label {
  float: none;
  display: inline-block;
  font-size: 10px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.posts__item--desc-center .posts__title {
  font-size: 14px;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .posts__item--desc-center .posts__title {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .posts__item--desc-center .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 1199px) {
  .posts__item--desc-center .posts__title {
    font-size: 34px;
    line-height: 1em;
  }
}

.posts__item--desc-center .post-author__avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.posts__item--desc-center .posts__date {
  font-size: 11px;
  color: #31404b;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.posts__item--desc-center .posts__title-higlight {
  color: #38a9ff;
}

.posts__item--desc-center:hover .posts__thumb img {
  transform: scale(1.1);
}

.posts__item--desc-left {
  position: relative;
}

.posts__item--desc-left .posts__inner {
  position: absolute;
  bottom: 16px;
  left: 24px;
  background: none;
  width: 80%;
  padding: 0;
  z-index: 2;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 992px) {
  .posts__item--desc-left .posts__inner {
    padding: 0;
    width: 85%;
  }
}

@media (min-width: 1186px) {
  .posts__item--desc-left .posts__inner {
    width: 75%;
  }
}

.posts__item--desc-left .posts__thumb {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e4e7ed;
  float: none;
  margin: 0 0 14px 0;
  transform: translate3d(0, 0, 0);
}

.posts__item--desc-left .posts__thumb img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.posts__item--desc-left .posts__cat {
  overflow: visible;
}

.posts__item--desc-left .posts__cat-label {
  display: inline-block;
  float: none;
  font-size: 10px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.posts__item--desc-left .posts__title {
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 0.1em;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .posts__item--desc-left .posts__title {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .posts__item--desc-left .posts__title {
    font-size: 14px;
  }
}

@media (min-width: 1199px) {
  .posts__item--desc-left .posts__title {
    font-size: 20px;
    line-height: 1em;
  }
}

.posts__item--desc-left .posts__date {
  font-size: 10px;
  color: #31404b;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.posts__item--desc-left .posts__title-higlight {
  color: #38a9ff;
}

.posts__item--desc-left:hover .posts__thumb img {
  transform: scale(1.1);
}

/* Slider Featured */
.posts--slider-top-news {
  margin-bottom: 0 !important;
}

.posts--slider-top-news .slick-list,
.posts--slider-top-news .slick__slide {
  max-height: 660px !important;
}

.posts--slider-top-news .posts__thumb {
  float: none;
  margin: 0;
}

.posts--slider-top-news .posts__thumb img {
  width: 100%;
  height: auto;
}

.posts--slider-top-news .posts__inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  z-index: 4;
}

@media (min-width: 480px) {
  .posts--slider-top-news .posts__inner {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .posts--slider-top-news .posts__inner {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .posts--slider-top-news .posts__inner {
    width: 620px;
  }
}

.posts--slider-top-news .posts__cat {
  visibility: visible;
}

@media (min-width: 992px) {
  .posts--slider-top-news .posts__cat {
    margin-bottom: 13px;
  }
}

.posts--slider-top-news .posts__cat-label {
  display: inline-block;
  float: none;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 992px) {
  .posts--slider-top-news .posts__cat-label {
    font-size: 10px;
  }
}

.posts--slider-top-news .posts__title {
  font-size: 18px;
  line-height: 1em;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .posts--slider-top-news .posts__title {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .posts--slider-top-news .posts__title {
    font-size: 34px;
  }
}

@media (min-width: 1200px) {
  .posts--slider-top-news .posts__title {
    font-size: 42px;
  }
}

.posts--slider-top-news .posts__title > a:hover {
  color: #fff !important;
}

.posts--slider-top-news .posts__title-higlight {
  color: #9fe900;
}

@media (max-width: 479px) {
  .posts--slider-top-news .posts__footer {
    display: none;
  }
}

.posts--slider-top-news .post-author {
  display: inline-block;
}

.posts--slider-top-news .post-author__avatar {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .posts--slider-top-news .post-author__avatar {
    margin-right: 8px;
  }
}

.posts--slider-top-news .posts__date {
  color: #fff;
  font-size: 11px;
}

.posts--slider-top-news .post__meta {
  display: inline-block;
  margin-left: 1em;
  color: #fff;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .posts--slider-top-news .post__meta {
    margin-left: 2.5em;
  }
}

.posts--slider-top-news .meta__item a {
  color: #fff;
}

@media (min-width: 768px) {
  .posts--slider-top-news .posts__item-has-img .posts__inner {
    text-align: left;
    transform: translate(-50%, -50%) translateX(35%);
  }
}

.posts--slider-top-news .posts__item-has-img .posts__img-player {
  position: absolute;
  top: 0;
  bottom: 0;
  max-width: 45%;
  z-index: 4;
}

@media (max-width: 767px) {
  .posts--slider-top-news .posts__item-has-img .posts__img-player {
    display: none;
  }
}

@media (min-width: 1200px) {
  .posts--slider-top-news .posts__item-has-img .posts__img-player {
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translate(-585px, 0);
  }
}

/* Posts - Video Grid */
.posts--video-grid .posts__item {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.posts--video-grid .posts__item.posts__item--category-1 .posts__link-wrapper::before {
  border-color: #38a9ff;
}

.posts--video-grid .posts__item.posts__item--category-2 .posts__link-wrapper::before {
  border-color: #07e0c4;
}

.posts--video-grid .posts__item.posts__item--category-3 .posts__link-wrapper::before {
  border-color: #9fe900;
}

@media (min-width: 992px) {
  .posts--video-grid .posts__item--lg .posts__link-wrapper::before {
    width: 80px;
    height: 80px;
    border-width: 6px;
    line-height: 68px;
    font-size: 32px;
  }
  .posts--video-grid .posts__item--lg .posts__title {
    font-size: 24px;
    line-height: 1.08333em;
  }
  .posts--video-grid .posts__item--lg .posts__cat-label {
    font-size: 10px;
  }
}

.posts--video-grid .posts__link-wrapper {
  display: block;
  vertical-align: top;
  position: relative;
  text-align: left;
}

.posts--video-grid .posts__link-wrapper:hover .posts__inner {
  transform: translateY(0);
}

.posts--video-grid .posts__link-wrapper:hover::before {
  transform: translateY(-100%);
}

.posts--video-grid .posts__link-wrapper::before {
  content: "\f04b";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  background-color: rgba(23, 29, 33, 0.6);
  border-radius: 50%;
  border: 4px solid #38a9ff;
  z-index: 4;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 52px;
  text-indent: 3px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform 0.3s ease;
  pointer-events: none;
}

.posts--video-grid .posts__inner {
  position: absolute;
  left: 0;
  width: 75%;
  bottom: 0;
  padding: 0 24px 18px 24px;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
  pointer-events: none;
  z-index: 4;
}

.posts--video-grid .posts__title {
  color: #fff;
  margin-bottom: .25em;
  font-size: 12px;
  line-height: 1em;
}

@media (min-width: 480px) {
  .posts--video-grid .posts__title {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .posts--video-grid .posts__title {
    font-size: 13px;
  }
}

@media (min-width: 1199px) {
  .posts--video-grid .posts__title {
    font-size: 14px;
  }
}

.posts--video-grid .posts__thumb {
  float: none;
  margin: 0;
  background-color: #171d21;
  border-radius: 4px;
}

.posts--video-grid .posts__thumb img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  position: relative;
  transition: opacity 0.3s ease-in-out;
  backface-visibility: hidden;
}

.posts--video-grid .posts__date {
  color: #9a9da2;
  font-size: 9px;
  font-weight: 700;
  display: block;
  line-height: 1em;
}

@media (min-width: 992px) {
  .posts--video-grid .posts__date {
    font-size: 10px;
  }
}

/* Single Post
/* ------------------------- */
.post--single .post__thumbnail {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.post--single .post__thumbnail--square {
  border-radius: 0;
}

.post--single .post__category {
  overflow: hidden;
  margin-bottom: 15px;
}

.post--single .post__title {
  font-size: 18px;
  line-height: 1.0625em;
  margin-bottom: 0.55em;
}

@media (min-width: 992px) {
  .post--single .post__title {
    font-size: 32px;
  }
}

.post--single .post__meta {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .post--single .post__meta {
    margin-bottom: 34px;
  }
}

.post--single .post__content-wrapper {
  position: relative;
}

@media (min-width: 992px) {
  .post--single .post__content--inner-left {
    padding-left: 80px;
  }
  .post--single .post__content--inner-left .alignleft {
    margin-left: -80px;
  }
}

@media (min-width: 1200px) {
  .post--single .post__content--inner-left {
    padding-left: 100px;
  }
  .post--single .post__content--inner-left .alignleft {
    margin-left: -100px;
  }
}

.post--single .post__footer {
  padding: 30px 0 10px 0;
}

.post--single .post__tags .btn {
  margin: 0 5px 8px 0;
}

.post--single .post__tags--simple a {
  display: inline-block;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  margin-right: .35em;
}

.post--single .post__tags--simple a::before {
  content: "#";
  color: #38a9ff;
}

.post--single .post__tags--simple a:hover {
  color: #38a9ff;
}

@media (min-width: 992px) {
  .post--single .posts__cat-label {
    font-size: 10px;
  }
}

.post--single-sm .post__title {
  margin-bottom: 0.35em;
}

@media (min-width: 992px) {
  .post--single-sm .post__title {
    font-size: 26px;
  }
}

.post--single-sm .post__meta {
  margin-bottom: 0;
}

.post--single-sm .post__meta .meta__item--date {
  color: #31404b;
}

@media (min-width: 992px) {
  .post--single-sm .post__meta {
    margin-bottom: 0;
  }
}

.post-sharing {
  margin-bottom: 10px;
  display: flex;
}

.post-sharing .btn {
  margin: 0 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 15px;
}

.post-sharing .btn:first-child {
  margin-left: 0;
}

.post-sharing .btn:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .post-sharing .btn {
    padding-left: 0;
    padding-right: 0;
  }
  .post-sharing .btn .fa {
    margin-right: 0;
  }
}

.post-sharing-compact {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

@media (min-width: 992px) {
  .post-sharing-compact {
    padding-top: 40px;
  }
}

@media (min-width: 1200px) {
  .post-sharing-compact {
    padding-top: 70px;
  }
}

.post-sharing-compact .btn {
  margin: 0 10px;
  padding: 5px 0;
  width: 80px;
  text-align: center;
}

.post-sharing-compact .btn .fa {
  margin: 0;
}

.post-sharing-compact.stacked {
  justify-content: left;
  padding: 0 0 20px 0;
}

@media (min-width: 992px) {
  .post-sharing-compact.stacked {
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    flex-direction: column;
  }
  .post-sharing-compact.stacked .btn {
    margin: 0 0 10px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post-sharing-compact.stacked .btn {
    width: 60px;
  }
}

@media (min-width: 1200px) {
  .post-sharing-compact.stacked {
    padding-top: 0;
  }
}

.post-sharing-compact--footer {
  display: flex;
  justify-content: flex-start;
  padding-top: 0;
}

@media (min-width: 992px) {
  .post-sharing-compact--footer {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .post-sharing-compact--footer {
    padding-top: 0;
  }
}

.post-sharing-compact--footer .btn {
  margin-left: 0;
  margin-right: 20px;
}

.post-sharing-compact--footer .btn:last-child {
  margin-right: 0;
}

.post--extra-top {
  margin-top: -100px;
  z-index: 4;
}

@media (min-width: 992px) {
  .post--extra-top {
    margin-top: -160px;
  }
}

/* Post Author
/* ------------------------- */
.post-author__header {
  overflow: hidden;
  margin-bottom: 26px;
}

.post-author__avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 14px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.post-author__avatar img {
  max-width: 100%;
  height: auto;
}

.post-author__info {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.post-author__name {
  margin-bottom: .2em;
}

.post-author__slogan {
  display: block;
  font-size: 11px;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  color: #9a9da2;
  font-weight: 400;
}

.post-author__social-links.social-links {
  padding: 10px 0 0 0;
}

@media (min-width: 992px) {
  .post-author__social-links.social-links {
    padding: 0;
    float: right;
    margin-right: -2px;
  }
}

.post-author--avatar-left .post-author__header {
  margin-bottom: 12px;
  padding-top: 5px;
}

.post-author--avatar-left .post-author__avatar {
  float: left;
  margin-right: 24px;
}

/* Post Meta */
.meta {
  list-style: none;
  padding: 0;
  margin: 0;
}

.meta__item {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1.2em;
  display: inline-block;
  vertical-align: middle;
}

.meta__item + .meta__item {
  margin-left: 8px;
}

@media (min-width: 992px) {
  .meta__item + .meta__item {
    margin-left: 15px;
  }
}

.meta__item::before {
  font-size: 13px;
  line-height: 1em;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-family: 'simple-line-icons';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 4px;
  position: relative;
  top: -1px;
}

@media (min-width: 992px) {
  .meta__item::before {
    margin-right: 8px;
  }
}

.meta__item a {
  color: #9a9da2;
  transition: color 0.2s ease;
}

.meta__item a:hover {
  color: #31404b;
}

.meta__item--date::before {
  display: none;
}

.meta__item--views::before {
  content: "\e087";
}

.meta__item--likes::before {
  display: none;
}

.meta__item--likes .meta-like {
  vertical-align: middle;
  margin-right: 6px;
  font-size: 12px;
}

.meta__item--likes .meta-like--active {
  color: #f1365e;
}

.meta__item--comments::before {
  content: "\e07d";
  font-size: 12px;
}

.meta__item--author img {
  border-radius: 50%;
  margin-right: 6px;
}

/* Side Meta Block */
.post__meta-block--side {
  padding: 24px;
}

.post__meta-block--side .post-author__name {
  font-size: 13px;
}

.post__meta-block--side .social-links--btn {
  margin-left: 74px;
}

.post__meta-block--side .post__meta {
  display: none;
}

@media (min-width: 992px) {
  .post__meta-block--side {
    padding: 0;
    float: left;
    width: 60px;
    margin: 43px 0 0 24px;
  }
  .post__meta-block--side + .card__content {
    overflow: hidden;
    padding-left: 24px;
  }
  .post__meta-block--side .post-author {
    text-align: center;
    padding-bottom: 34px;
  }
  .post__meta-block--side .post-author__avatar {
    float: none;
    margin: 0 auto 8px auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .post__meta-block--side .post-author__name {
    font-size: 10px;
    line-height: 1.2em;
    margin-bottom: 0.75em;
  }
  .post__meta-block--side .post-author__slogan {
    font-size: 9px;
  }
  .post__meta-block--side .post__meta {
    display: block;
  }
  .post__meta-block--side .meta__item {
    display: block;
    text-align: center;
    margin: 0 0 21px 0;
  }
  .post__meta-block--side .meta__item::before {
    display: block;
    margin: 0 auto 6px auto;
  }
  .post__meta-block--side .meta__item--likes .meta-like {
    display: block;
    margin: 0 auto 6px auto;
  }
  .post__meta-block--side .social-links--btn {
    text-align: center;
    padding-bottom: 20px;
    margin-left: 0;
  }
  .post__meta-block--side .social-links--btn .social-links__item {
    display: block;
    margin: 0 auto 12px auto;
  }
}

.post__meta-block--top {
  padding-bottom: 65px;
}

.post__meta-block--top .post__category {
  margin-bottom: 22px;
}

.post__meta-block--top .posts__cat-label {
  display: inline-block;
  float: none;
  font-size: 10px;
}

.post__meta-block--top .page-heading__title {
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 0.475em;
}

@media (min-width: 992px) {
  .post__meta-block--top .page-heading__title {
    font-size: 42px;
  }
}

.post__meta-block--top .page-heading__title > a {
  color: #fff;
}

.post__meta-block--top .post__meta {
  color: #fff;
  margin-bottom: 22px;
}

.post__meta-block--top .meta__item a {
  color: #fff;
}

.post__meta-block--top .post-author {
  text-align: center;
}

.post__meta-block--top .post-author__avatar {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px auto;
}

.post__meta-block--top .post-author__name {
  font-size: 13px;
  color: #fff;
}

.post__meta-block--top .post-author__slogan {
  font-size: 10px;
  font-weight: 400;
}

.post__meta-block-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .post__meta-block-wrapper {
    height: 450px;
  }
}

@media (min-width: 1200px) {
  .post__meta-block-wrapper {
    height: 545px;
  }
}

.post__meta-block-wrapper .post__meta-block--top {
  padding-bottom: 0;
}

/* Side Meta Block - Wide */
.post__meta-block--side-wide {
  padding: 24px;
}

.post__meta-block--side-wide .post__title {
  font-size: 18px;
  line-height: 1.0833em;
}

@media (min-width: 992px) {
  .post__meta-block--side-wide .post__title {
    font-size: 24px;
  }
}

.post__meta-block--side-wide .post__meta {
  margin-bottom: 20px;
}

.post__meta-block--side-wide .post-author {
  margin-bottom: 18px;
}

.post__meta-block--side-wide .post-author__avatar {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 5px;
}

.post__meta-block--side-wide .post-author__name {
  font-size: 10px;
  line-height: 1.2em;
  color: #9a9da2;
  font-weight: 400;
  font-style: normal;
}

@media (min-width: 992px) {
  .post__meta-block--side-wide {
    padding: 0;
    float: left;
    width: 190px;
    margin: 43px 0 0 24px;
  }
  .post__meta-block--side-wide + .card__content {
    overflow: hidden;
    padding-left: 60px;
  }
  .post__meta-block--side-wide .social-links--btn-block {
    padding-right: 20px;
  }
}

/* Related Posts */
.post-related > .card {
  margin-bottom: 0;
}

.post-related__prev {
  position: relative;
}

.post-related__prev .card__content {
  position: relative;
  text-align: center;
}

@media (min-width: 992px) {
  .post-related__prev .card__content {
    text-align: left;
    padding-left: 93px;
  }
}

.post-related__prev .posts {
  display: none;
}

@media (min-width: 992px) {
  .post-related__prev .posts {
    display: block;
  }
}

@media (min-width: 992px) {
  .post-related__prev .btn-nav {
    position: absolute;
    left: 23px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.post-related__next {
  text-align: right;
  position: relative;
}

.post-related__next .card__content {
  position: relative;
  text-align: center;
}

@media (min-width: 992px) {
  .post-related__next .card__content {
    padding-right: 93px;
    padding-left: 30px;
    text-align: right;
  }
  .post-related__next .card__content .posts__cat-label {
    float: right;
  }
}

.post-related__next .card__content .posts {
  display: none;
}

@media (min-width: 992px) {
  .post-related__next .card__content .posts {
    display: block;
  }
}

@media (min-width: 992px) {
  .post-related__next .btn-nav {
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Comments
/* ------------------------- */
.post-comments {
  overflow: hidden;
}

.comments {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comments__item {
  position: relative;
  padding: 0;
  margin: 0;
}

.comments__inner {
  position: relative;
  border-bottom: 1px solid #e4e7ed;
  padding-top: 36px;
  padding-bottom: 36px;
}

.card .comments__inner {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
}

.card--lg .comments__inner {
  margin-left: -43px;
  margin-right: -43px;
  padding-left: 43px;
  padding-right: 43px;
}

.comments > .comments__item:first-child > .comments__inner {
  padding-top: 0;
}

.comment__header {
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
}

.comment__author-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
}

.comment__author-avatar--sm {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.comment__author-avatar--md {
  width: 46px;
  height: 46px;
  margin-right: 12px;
}

.comment__author-avatar--rounded {
  border-radius: 4px;
}

.comment__author-info {
  display: inline-block;
  vertical-align: middle;
}

.comment__author-name {
  text-transform: none;
  font-size: 16px;
  margin-bottom: .25em;
}

.comment__post-date {
  font-size: 11px;
  line-height: 1.2em;
  display: block;
  font-family: "Montserrat", sans-serif;
}

.comment__reply {
  margin-top: 5px;
}

@media (min-width: 992px) {
  .comment__reply {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

/* Nested Comments */
.comments--children {
  padding-left: 0;
  list-style: none;
}

@media (min-width: 992px) {
  .card .comments--children .comments__item .comments__inner, .comments--children .comments__item .comments__inner {
    padding-left: 48px;
  }
  .card .comments--children .comments__item .comments__inner::before, .comments--children .comments__item .comments__inner::before {
    content: "";
    display: block;
    position: absolute;
    left: 15px;
    top: 43px;
    width: 14px;
    height: 20px;
    border-left: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }
  .card .comments--children .comments--children .comments__item .comments__inner, .comments--children .comments--children .comments__item .comments__inner {
    padding-left: 72px;
  }
  .card .comments--children .comments--children .comments__item .comments__inner::before, .comments--children .comments--children .comments__item .comments__inner::before {
    left: 39px;
  }
  .card .comments--children .comments--children .comments--children .comments__item .comments__inner, .comments--children .comments--children .comments--children .comments__item .comments__inner {
    padding-left: 96px;
  }
  .card .comments--children .comments--children .comments--children .comments__item .comments__inner::before, .comments--children .comments--children .comments--children .comments__item .comments__inner::before {
    left: 63px;
  }
  .card--lg .comments--children .comments__item .comments__inner {
    padding-left: 86px;
  }
  .card--lg .comments--children .comments__item .comments__inner::before {
    content: "";
    display: block;
    position: absolute;
    left: 53px;
    top: 43px;
    width: 14px;
    height: 20px;
    border-left: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }
  .card--lg .comments--children .comments--children .comments__item .comments__inner {
    padding-left: 129px;
  }
  .card--lg .comments--children .comments--children .comments__item .comments__inner::before {
    left: 96px;
  }
  .card--lg .comments--children .comments--children .comments--children .comments__item .comments__inner {
    padding-left: 172px;
  }
  .card--lg .comments--children .comments--children .comments--children .comments__item .comments__inner::before {
    left: 139px;
  }
}

.comments--left-thumb .comments__inner {
  border-bottom: none;
  display: flex;
  padding-top: 16px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .comments--left-thumb .comments__inner {
    flex-wrap: nowrap;
  }
}

.comments--left-thumb .comment__header {
  text-align: center;
  flex: 0 0 100%;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .comments--left-thumb .comment__header {
    flex: 0 0 160px;
  }
}

@media (min-width: 992px) {
  .comments--left-thumb .comment__header {
    flex: 0 0 180px;
    padding: 0 20px;
  }
}

@media (min-width: 1200px) {
  .comments--left-thumb .comment__header {
    flex: 0 0 200px;
  }
}

.comments--left-thumb .comment__author-avatar {
  display: block;
  margin: 0 auto 10px auto;
}

.comments--left-thumb .comment__author-name {
  font-size: 11px;
}

@media (min-width: 992px) {
  .comments--left-thumb .comment__author-name {
    font-size: 13px;
  }
}

.comments--left-thumb .comment__ratings .fa {
  font-size: 10px;
  margin: 0 1px;
}

.comments--left-thumb .comment__title {
  font-size: 12px;
  margin-bottom: 1.5em;
  padding-top: .33em;
}

@media (min-width: 768px) {
  .comments--left-thumb .comment__title {
    font-size: 18px;
  }
}

.post__comments-pagination {
  text-align: center;
  margin-left: -24px;
  margin-right: -24px;
  padding: 20px 24px 0 24px;
}

.post__comments-pagination .pagination {
  margin: 0;
}

.card--lg .post__comments-pagination {
  margin-left: -43px;
  margin-right: -43px;
  padding: 36px 43px 0 43px;
}

.post__comments-load-more {
  text-align: center;
  padding: 24px 24px;
}

.card .post__comments-load-more {
  background-color: transparent;
}

.card .post-comments__content + .post__comments-load-more {
  margin-top: -24px;
}

.comments--thumb-top .comment__author-avatar {
  border-radius: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.comments--thumb-top .comment__author-name {
  font-size: 13px;
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .comments--thumb-top .comment__author-name {
    font-size: 16px;
  }
}

.comments--thumb-top .comment__ratings {
  line-height: 1em;
}

.comments--thumb-top .comment__ratings .fa {
  font-size: 10px;
}

.comments--thumb-top .comment__post-date {
  display: inline-block;
}

.comments--thumb-top .comment__title {
  font-size: 12px;
  margin-bottom: 1em;
  padding-top: .33em;
}

@media (min-width: 768px) {
  .comments--thumb-top .comment__title {
    font-size: 18px;
  }
}

.comments--alt .comments__inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.comments--alt .comment__inner-wrap {
  flex: 1;
}

.comments--alt .comment__author-info {
  margin-bottom: 4px;
}

.comments--alt .comment__author-name {
  display: inline-block;
  margin-right: .3em;
  font-size: 14px;
  margin-bottom: 0;
  font-style: normal;
}

.comments--alt .comment__post-date {
  display: inline-block;
}

.comments--alt .comment__body {
  font-size: 12.855px;
  line-height: 1.8333em;
}

.comments--alt .comment__reply {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  line-height: 1.2em;
  padding-top: 11px;
}

@media (min-width: 992px) {
  .comments--alt .comment__reply {
    position: static;
    float: none;
  }
}

.comments--alt .comment__reply-link {
  color: #31404b;
}

.comments--alt .comment__reply-link:hover {
  color: #38a9ff;
}

@media (min-width: 992px) {
  .card .comments--alt .comments--children .comments__item .comments__inner, .comments--alt .comments--children .comments__item .comments__inner {
    padding-left: 72px;
  }
  .card .comments--alt .comments--children .comments__item .comments__inner::before, .comments--alt .comments--children .comments__item .comments__inner::before {
    display: none;
  }
  .card .comments--alt .comments--children .comments--children .comments__item .comments__inner, .comments--alt .comments--children .comments--children .comments__item .comments__inner {
    padding-left: 96px;
  }
  .card .comments--alt .comments--children .comments--children .comments--children .comments__item .comments__inner, .comments--alt .comments--children .comments--children .comments--children .comments__item .comments__inner {
    padding-left: 120px;
  }
}

/* Post Filter
/* ------------------------- */
.post-filter {
  background-color: #fff;
  border-bottom: 1px solid #e4e7ed;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .post-filter .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.post-filter__form {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .post-filter__form {
    flex-wrap: nowrap;
  }
}

.post-filter__select {
  position: relative;
  width: 100%;
}

@media (min-width: 992px) {
  .post-filter__select {
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }
  .post-filter__select + .post-filter__select div.cs-skin-border > span {
    margin-left: -1px;
  }
}

.post-filter__select .post-filter__label {
  display: block;
  position: absolute;
  z-index: 2;
  left: 24px;
  top: 20px;
  line-height: 1.2em;
  color: #9a9da2;
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}

@media (min-width: 992px) {
  .post-filter__select .post-filter__label {
    z-index: 102;
    top: 28px;
  }
}

.post-filter__submit {
  display: flex;
  width: 100%;
}

@media (min-width: 992px) {
  .post-filter__submit {
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }
}

.post-filter__submit .btn {
  border-radius: 0;
  border: none;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.post-filter--boxed {
  background-color: transparent;
  border-bottom: 0;
  padding-top: 30px;
}

@media (min-width: 992px) {
  .post-filter--boxed {
    padding-top: 60px;
    margin-bottom: -20px;
  }
}

.post-filter--boxed .post-filter__form {
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.post-filter--boxed .post-filter__form .post-filter__select:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.post-filter--boxed .post-filter__form .post-filter__select:first-child div.cs-skin-border > span {
  border-left: 0;
}

.post-filter--boxed .cs-select .cs-options {
  left: -1px;
  width: calc(100% + 1px);
  border-top: 1px solid #e4e7ed;
  transition: all .2s ease;
  overflow: visible;
}

.post-filter--boxed .cs-select .cs-options::before, .post-filter--boxed .cs-select .cs-options::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

.post-filter--boxed .cs-select .cs-options::before {
  right: 18px;
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e4e7ed;
}

.post-filter--boxed .cs-select .cs-options::after {
  right: 20px;
  top: -7px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
}

.post-filter--boxed .cs-select.cs-active .cs-options {
  transition: all .2s ease;
  transform: translateY(-2px);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

@media (min-width: 992px) {
  .post-filter--boxed .cs-select.cs-active .cs-options {
    transform: translateY(16px);
  }
}

.post-filter--boxed .post-filter__submit .btn {
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media (min-width: 992px) {
  .post-filter--boxed .post-filter__submit .btn {
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
  }
}

/* Blog Load More Posts */
.blog-load-more {
  padding: 30px 0 20px 0;
}

@media (min-width: 992px) {
  .blog-load-more {
    padding: 65px 0 40px 0;
  }
}

.blog-load-more__btn {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .blog-load-more__btn {
    width: calc(33.3% + 60px);
  }
}

/*
// #Widgets
// --------------------------------------------------
*/
/* Base Widget */
.widget__title {
  text-transform: uppercase;
}

.widget__content-inner {
  padding: 24px 24px;
}

.widget__content--bottom-decor {
  padding-bottom: 30px;
  background-image: linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(0, 0, 0, 0.1) 8px, transparent 8px), linear-gradient(to right, rgba(0, 0, 0, 0.11) 8px, transparent 8px);
  background-repeat: repeat-x;
  background-size: 72px 24px, 72px 30px, 72px 34px, 72px 40px, 72px 37px, 72px 29px, 144px 30px, 216px 37px;
  background-position: 0 100%, 12px 100%, 24px 100%, 36px 100%, 48px 100%, 60px 100%, 12px 100%, 48px 100%;
}

/* Pushy Panel Widget */
.widget--side-panel {
  margin-bottom: 62px;
}

.widget--side-panel .widget__title {
  margin-bottom: 30px;
}

.widget--side-panel .widget__title > h4 {
  font-size: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
}

/* Sidebar Widget */
.widget--sidebar {
  margin-bottom: 15px;
}

.widget--sidebar .widget__content {
  font-size: 14px;
  line-height: 22px;
}

/* Footer Widget */
.widget--footer {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .widget--footer {
    margin-bottom: 0;
  }
}

.widget--footer .widget__title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 3.5em;
}

.widget--footer + .widget--footer {
  margin-top: 30px;
}

/* Widget: Lists */
.widget__list {
  list-style: none;
  padding: 0;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  margin-bottom: -24px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

.widget__list > li + li {
  border-top: 1px solid #e4e7ed;
}

.widget__list > li > a {
  display: block;
  padding: 22px 24px;
  color: #31404b;
  transition: color 0.3s ease;
}

.widget__list > li > a:hover {
  color: #1892ed;
}

.widget__list > li.has-children {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%2331404b' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-position: right 24px top 24px;
  background-repeat: no-repeat;
  background-size: 6px 4px;
}

.widget__list ul {
  border-top: 1px solid #e4e7ed;
  list-style: none;
  padding: 18px 24px;
}

.widget__list ul li {
  padding: 10px 0;
}

.widget__list ul li a {
  color: #9a9da2;
  transition: color 0.3s ease;
}

.widget__list ul li a:hover {
  color: #31404b;
}

/*
// #Widget - Instagram
// --------------------------------------------------
*/
.widget-instagram {
  position: relative;
}

.widget-instagram__list {
  list-style-type: none;
  padding: 0;
  margin: 0 -2px -14px -2px;
  overflow: hidden;
}

.widget-instagram__list + .btn-instagram {
  margin-top: 23px;
}

@media (min-width: 768px) {
  .widget-instagram__list {
    margin-left: -7px;
    margin-right: -8px;
  }
}

.widget-instagram__item {
  float: left;
  width: 16.66%;
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .widget-instagram__item {
    width: 33.3%;
    padding-left: 7px;
    padding-right: 8px;
  }
  .widget-instagram__list--4cols .widget-instagram__item {
    width: 25%;
  }
}

.widget-instagram__img {
  vertical-align: top;
  margin: 0;
  width: 100%;
  height: auto;
}

.widget-instagram__link-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 0;
}

.widget-instagram__link-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #1892ed;
  border-radius: 0;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
  transform: translate3d(0, 0, 0);
}

.widget-instagram__link-wrapper:hover::before {
  opacity: 0.8;
}

.widget-instagram__link-wrapper:hover .widget-instagram__plus-sign::before, .widget-instagram__link-wrapper:hover .widget-instagram__plus-sign::after {
  transform: translate(-50%, -50%) scale(1);
}

.widget-instagram__plus-sign {
  position: relative;
  display: block;
}

.widget-instagram__plus-sign::before, .widget-instagram__plus-sign::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  transition: all 0.3s ease;
}

.widget-instagram__plus-sign::before {
  height: 4px;
  width: 18px;
}

.widget-instagram__plus-sign::after {
  height: 18px;
  width: 4px;
}

.widget-instagram__list-section {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

@media (min-width: 768px) {
  .widget-instagram__list-section {
    margin-left: 0;
    margin-right: 0;
  }
}

.widget-instagram__list-section .widget-instagram__item {
  width: 20%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .widget-instagram__list-section .widget-instagram__item {
    width: 10%;
    padding-left: 0;
    padding-right: 0;
  }
}

.widget-instagram__list-section .widget-instagram__link-wrapper {
  border-radius: 0;
}

.widget-instagram__list-section .widget-instagram__link-wrapper::before {
  background-color: #31404b;
  border-radius: 0;
}

.widget-instagram__list-section + .btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/*
// #Widget - Contact Info
// --------------------------------------------------
*/
.widget-contact-info__desc {
  margin-bottom: 32px;
}

/* Widget: Quick Contact */
.footer-widgets .widget-contact .form-control {
  background-color: #2b2e33;
  color: #fff;
}

.footer-widgets .widget-contact .form-control:not(:focus) {
  border-color: #2b2e33;
}

/* Widget: Social */
.widget-social--condensed {
  display: flex;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
}

.widget-social--condensed .btn-social-counter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  width: 33.3%;
  max-width: 33.3%;
}

.widget-social--condensed .btn-social-counter + .btn-social-counter {
  margin-top: 0;
}

.widget-social--condensed .btn-social-counter__icon {
  position: relative;
  width: 100%;
  padding: 35px 0 10px 0;
  background-color: transparent !important;
}

.widget-social--condensed .btn-social-counter__title {
  font-size: 10px;
  padding: 0 12px 10px 12px;
}

.widget-social--condensed .btn-social-counter__add-icon {
  position: relative;
  right: auto;
  top: 0;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.widget-social--condensed .btn-social-counter__count {
  order: 9;
  padding: 10px 5px;
}

.widget-social--grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 5px;
}

.widget-social--grid .btn-social-counter {
  padding: 0;
  text-align: center;
  flex-basis: calc(50% - 5px);
  max-width: calc(50% - 5px);
  margin-bottom: 10px;
  text-align: left;
  padding-left: 46px;
  padding-top: 20px;
  padding-right: 38px;
}

.widget-social--grid .btn-social-counter + .btn-social-counter {
  margin-top: 0;
}

.widget-social--grid .btn-social-counter .btn-social-counter__icon {
  background-color: transparent;
  width: 46px;
  text-indent: 8px;
}

.widget-social--grid .btn-social-counter .btn-social-counter__title {
  font-size: 12px;
  line-height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-social--grid .btn-social-counter .btn-social-counter__count {
  font-size: 10px;
  line-height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-social--grid .btn-social-counter .btn-social-counter__add-icon {
  border: none;
  width: 34px;
  top: 0;
  bottom: 0;
  right: 0;
  height: auto;
  margin: 0;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.widget-social--buttons {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -8px;
}

.widget-social--buttons .btn-social-counter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  text-align: center;
  width: calc(33.3% - 16px);
  max-width: calc(33.3% - 16px);
  border: none;
  margin: 0 8px 16px 8px;
}

.widget-social--buttons .btn-social-counter + .btn-social-counter {
  margin-top: 0;
}

.widget-social--buttons .btn-social-counter__icon {
  position: relative;
  width: 100%;
  padding: 45px 0 0 0;
  background-color: transparent !important;
}

.widget-social--buttons .btn-social-counter__title {
  display: none;
}

.widget-social--buttons .btn-social-counter__add-icon {
  width: 18px;
  height: 18px;
  right: 8px;
  top: 8px;
  margin-top: 0;
}

.widget-social--buttons .btn-social-counter__count {
  order: 9;
  padding: 4px 5px 14px 5px;
}

/* Widget: Tag Cloud */
.widget-tagcloud .tagcloud .btn {
  margin: 0 5px 9px 0;
}

.widget-tagcloud.widget--sidebar .widget__content {
  padding-bottom: 18px;
}

/* Widget: Banner */
.widget-banner.widget--sidebar .widget__content {
  padding: 38px;
}

.widget-banner__img {
  text-align: center;
}

.widget-banner__img img {
  max-width: 100%;
  height: auto;
}

/* Widget: Tabbed */
.widget-tabbed .nav.widget-tabbed__nav {
  margin: -24px -24px 26px -24px;
  border-bottom: 1px solid #e4e7ed;
  width: auto;
}

.widget-tabbed .nav.widget-tabbed__nav .nav-link {
  border: none;
  border-radius: 0;
  font-size: 11px;
  font-style: normal;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding: 12px 6px;
  color: rgba(49, 64, 75, 0.4);
  font-weight: bold;
  letter-spacing: -0.02em;
  transition: color 0.2s ease-in-out;
}

.widget-tabbed .nav.widget-tabbed__nav .nav-link:hover {
  color: #31404b;
  background-color: transparent;
  border: none;
}

.widget-tabbed .nav.widget-tabbed__nav .nav-link.active {
  color: #31404b;
  background-color: transparent;
  border: none;
}

/* Widget: Newsletter */
.widget-newsletter .widget-newsletter__subtitle {
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 1.4em;
}

.widget-newsletter .widget-newsletter__desc {
  margin-bottom: 20px;
}

.widget-newsletter .widget-newsletter__desc p:last-child {
  margin-bottom: 0;
}

.widget-newsletter .inline-form {
  padding: 8px 0;
}

/* Widget: Preview */
.widget-preview .match-preview__countdown {
  margin: 32px -24px 0 -24px;
}

/* Widget: Comments */
.comments-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comments-list__item {
  overflow: hidden;
  margin-bottom: 18px;
}

.card .comments-list__item {
  border-bottom: 1px solid #e4e7ed;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.comments-list__item:last-child,
.card .comments-list__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.comments-list__header {
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
  padding-right: 40px;
}

.comments-list__avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.comments-list__info {
  display: inline-block;
  vertical-align: middle;
}

.comments-list__author-name {
  text-transform: none;
  font-size: 13px;
  line-height: 1.2em;
  margin-bottom: 0.1em;
}

@media (min-width: 992px) {
  .comments-list__author-name {
    font-size: 15px;
  }
}

.comments-list__date {
  display: block;
  font-size: 11px;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
}

.comments-list__link {
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
  color: #c2c4c7;
  font-size: 14px;
  transition: color 0.2s ease;
  width: 30px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.comments-list__link:hover {
  color: #31404b;
}

.comments-list--alt .comments-list__item--category-1 .comments-list__post::before {
  background-color: #38a9ff;
}

.comments-list--alt .comments-list__item--category-2 .comments-list__post::before {
  background-color: #c2ff1f;
}

.comments-list--alt .comments-list__item--category-3 .comments-list__post::before {
  background-color: #07e0c4;
}

.comments-list--alt .comments-list__avatar {
  float: left;
  margin-right: 10px;
  border-radius: 4px;
}

.comments-list--alt .comments-list__info {
  display: block;
}

.comments-list--alt .comments-list__author-name {
  text-transform: uppercase;
  padding-top: .3em;
}

.comments-list--alt .comments-list__post {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  line-height: 1.2em;
  font-style: italic;
  margin-bottom: 0;
}

.comments-list--alt .comments-list__post::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: baseline;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  margin-right: 5px;
}

.comments-list--alt .comments-list__link {
  top: 0;
}

.comments-list--alt .comments-list__body {
  font-size: 13px;
  margin-bottom: 17px;
}

/* Widget: Twitter Feed */
.twitter-feed {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.twitter-feed__item {
  overflow: hidden;
  margin-bottom: 18px;
}

.card .twitter-feed__item {
  border-bottom: 1px solid #e4e7ed;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.twitter-feed__item:last-child,
.card .twitter-feed__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.twitter-feed__header {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.twitter-feed__header .twitter-feed__timestamp {
  display: none;
}

.twitter-feed__header .twitter-feed__timestamp::before {
  display: none;
}

.twitter-feed__thumb {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 10px;
  vertical-align: middle;
}

.twitter-feed__info {
  flex: 1 0 auto;
}

.twitter-feed__name {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  margin-bottom: 0.1em;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .twitter-feed__name {
    font-size: 15px;
  }
}

.twitter-feed__username {
  color: #9a9da2;
  font-size: 11px;
  line-height: 1.2em;
  margin-bottom: 0;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
}

.twitter-feed__username > a {
  color: #9a9da2;
  text-decoration: none;
  transition: color 0.2s ease;
}

.twitter-feed__username > a:hover {
  color: #31404b;
  text-decoration: none;
}

.twitter-feed__body {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
}

.twitter-feed__body a {
  color: #2bd0ff;
}

.twitter-feed__body a:hover {
  color: #00acde;
}

.twitter-feed__footer {
  overflow: hidden;
}

.twitter-feed__timestamp {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: none;
  float: left;
}

.twitter-feed__timestamp::before {
  content: '\f099';
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  text-rendering: auto;
  font-size: 14px;
  color: #3fcef2;
  margin-right: 7px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.twitter-feed__timestamp > a {
  color: #9a9da2;
  text-decoration: none;
  transition: color 0.2s ease;
}

.twitter-feed__timestamp > a:hover {
  color: #31404b;
  text-decoration: none;
}

.twitter-feed__actions {
  float: right;
}

.twitter-feed__reply {
  display: inline-block;
  margin-right: 5px;
  width: 18px;
  height: 14px;
  position: relative;
  top: -2px;
  background-size: 12px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3E%3Cpath transform='translate(-1241 -2200)' fill='%23c2c4c7' d='M1247,2203h6v2h-6v-2Zm4,0h2v5h-2v-5Zm-10,1,6-3.99v7.98Z'/%3E%3C/svg%3E");
  transition: opacity 0.3s ease;
}

.twitter-feed__reply:hover {
  opacity: 0.7;
}

.twitter-feed__retweet {
  display: inline-block;
  margin: 0 5px;
  width: 18px;
  height: 14px;
  position: relative;
  top: -2px;
  background-size: 16px 11px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 11'%3E%3Cpath transform='translate(-1270 -2199)' fill='%23c2c4c7' d='M1272,2204h2v5h-2v-5Zm0,3h6v2h-6v-2Zm1-8,3.01,5h-6.02Zm11,6h-2v-5h2v5Zm0-3h-6v-2h6v2Zm-1,8-3.01-5h6.02Z'/%3E%3C/svg%3E");
  transition: opacity 0.3s ease;
}

.twitter-feed__retweet:hover {
  opacity: 0.7;
}

.twitter-feed__favorite {
  display: inline-block;
  margin-left: 5px;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
  background-size: 12px 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 15'%3E%3Cpath fill='%23c2c4c7' d='M15.021875,1.25 C15.9072961,2.16146289 16.35,3.27473301 16.35,4.58984375 C16.35,5.90495449 15.9072961,7.01822461 15.021875,7.9296875 L8.225,15 L1.428125,7.9296875 C0.542703906,7.01822461 0.1,5.90495449 0.1,4.58984375 C0.1,3.27473301 0.542703906,2.16146289 1.428125,1.25 C2.20937891,0.4166625 3.16640059,0 4.29921875,0 C5.43203691,0 6.38905859,0.4166625 7.1703125,1.25 L8.225,2.34375 L9.31875,1.25 C10.1000039,0.4166625 11.0505152,0 12.1703125,0 C13.2901098,0 14.2406211,0.4166625 15.021875,1.25 Z'/%3E%3C/svg%3E");
  transition: opacity 0.3s ease;
}

.twitter-feed__favorite:hover {
  opacity: 0.7;
}

/* Widget Twitter - Footer */
.widget--footer .twitter-feed__thumb {
  width: 25px;
  height: 25px;
}

.widget--footer .twitter-feed__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
}

@media (min-width: 992px) {
  .widget--footer .twitter-feed__name {
    font-size: 12px;
  }
}

.widget--footer .twitter-feed__username {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.widget--footer .twitter-feed__username > a:hover {
  color: #38a9ff;
}

.widget--footer .twitter-feed__body {
  margin-bottom: 1em;
}

.widget--footer .twitter-feed__body a {
  color: #4cd3ff;
}

.widget--footer .twitter-feed__body a:hover {
  color: #fff;
}

.widget--footer .twitter-feed__footer {
  display: none;
}

/* Widget Twitter - Alt */
.widget-twitter--alt .twitter-feed__header .twitter-feed__timestamp {
  display: block;
}

.widget-twitter--alt .twitter-feed__footer .twitter-feed__timestamp {
  display: none;
}

.widget-twitter--alt .twitter-feed__footer .twitter-feed__actions {
  float: none;
}

.widget-twitter--alt .twitter-feed__reply {
  margin-right: 10px;
}

.widget-twitter--alt .twitter-feed__retweet {
  margin: 0 10px;
}

.widget-twitter--alt .twitter-feed__favorite {
  margin-left: 10px;
}

/* Widget: Featured */
.widget-featured .card__header::before {
  background-color: #ef2e5b;
}

.widget-featured .widget__content.card__content {
  padding-bottom: 0;
}

.widget-featured .posts__thumb {
  position: relative;
  overflow: hidden;
}

.widget-featured .posts__thumb::before {
  content: "!";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(239, 46, 91, 0.8);
  font-size: 54px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* Widget: Standings */
.widget-standings .widget__content {
  padding: 0;
}

/* Widget: Featured Player */
.widget-player .widget__content {
  background-color: #27313b;
  color: #fff;
  height: 158px;
  overflow: hidden;
  position: relative;
  padding: 0 20px 0 140px;
  background-image: radial-gradient(at 0% 0%, rgba(255, 255, 255, 0.3), transparent);
  border-radius: 4px;
}

@media (min-width: 375px) {
  .widget-player .widget__content {
    padding-left: 158px;
  }
}

@media (min-width: 992px) {
  .widget-player .widget__content {
    height: 156px;
    padding-left: 50%;
  }
}

@media (min-width: 1200px) {
  .widget-player .widget__content {
    height: 198px;
  }
}

.widget-player .widget__title + .widget__content {
  border-radius: 0;
  border-top: none;
}

.widget-player__team-logo {
  display: block;
  position: absolute;
  left: -25%;
  top: -25%;
  width: 80%;
  height: auto;
  opacity: .1;
  filter: grayscale(1);
  z-index: 1;
}

.widget-player__team-logo img {
  width: 100%;
  height: auto;
}

.widget-player__photo {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 50%;
  z-index: 4;
}

.widget-player__header {
  padding-top: 20px;
  margin-bottom: 8px;
  position: relative;
  z-index: 4;
}

@media (min-width: 992px) {
  .widget-player__header {
    padding-top: 14px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .widget-player__header {
    padding-top: 16px;
    margin-bottom: 10px;
  }
}

.widget-player__number {
  font-size: 36px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1em;
  float: left;
  margin-right: .1em;
  margin-left: -0.5em;
}

@media (min-width: 992px) {
  .widget-player__number {
    font-size: 42px;
    margin-left: -0.8em;
  }
}

@media (min-width: 1200px) {
  .widget-player__number {
    font-size: 56px;
  }
}

.widget-player__name {
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 4px;
  z-index: 1;
}

@media (min-width: 992px) {
  .widget-player__name {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .widget-player__name {
    font-size: 24px;
    top: 6px;
  }
}

.widget-player__first-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-player__last-name {
  display: block;
  color: #38a9ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-player__content {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  padding: 12px 0;
}

.widget-player__content-inner {
  width: 100%;
  padding-left: 140px;
  padding-right: 24px;
}

@media (min-width: 375px) {
  .widget-player__content-inner {
    padding-left: 160px;
  }
}

@media (min-width: 992px) {
  .widget-player__content-inner {
    padding-left: 50%;
  }
}

.widget-player__stat {
  float: left;
  width: 33.3%;
  text-align: center;
}

@media (min-width: 376px) and (max-width: 991px) {
  .widget-player__stat {
    width: 60px;
  }
}

.widget-player__stat-label {
  font-size: 8px;
  margin-bottom: 0.5em;
  line-height: 1.2em;
  color: #fff;
  font-weight: 400;
}

@media (min-width: 992px) {
  .widget-player__stat-label {
    font-size: 8px;
    margin-bottom: 1em;
  }
}

@media (min-width: 1200px) {
  .widget-player__stat-label {
    font-weight: 700;
    font-size: 10px;
  }
}

.widget-player__stat-number {
  font-size: 12px;
  line-height: 1.2em;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}

@media (min-width: 992px) {
  .widget-player__stat-number {
    font-size: 18px;
  }
}

.widget-player__stat-legend {
  font-size: 7px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #9a9da2;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .widget-player__stat-legend {
    font-size: 8px;
  }
}

.widget-player__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #38a9ff;
  z-index: 1;
  text-align: right;
  line-height: 10px;
}

.widget-player__footer-txt {
  display: inline-block;
  position: relative;
  background-color: #182027;
  color: #fff;
  padding: 0 24px;
  line-height: 20px;
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}

@media (min-width: 992px) {
  .widget-player__footer-txt {
    font-size: 9px;
    padding-left: 26px;
  }
}

@media (min-width: 1200px) {
  .widget-player__footer-txt {
    font-size: 10px;
    line-height: 30px;
  }
}

.widget-player__footer-txt .fa {
  font-size: 12px;
  margin-right: 0.5em;
  color: #38a9ff;
}

.widget-player__footer-txt::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  background-color: #38a9ff;
}

.widget-player__footer-txt::before {
  left: -10px;
  transform: skew(-30deg);
}

.widget-player__details {
  display: flex;
  flex-wrap: wrap;
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: #e4e7ed;
  margin: -1px;
}

.widget-player__details__item {
  flex: 0 1 50%;
  max-width: 50%;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: #e4e7ed;
  padding: 8px 12px;
}

@media (min-width: 992px) {
  .widget-player__details__item {
    padding: 6px 12px;
  }
}

@media (min-width: 1200px) {
  .widget-player__details__item {
    padding: 12px 24px;
  }
}

.widget-player__details__item:first-child {
  border-left: none;
}

.widget-player__details__item:last-child {
  border-right: none;
}

.widget-player__details-desc-wrapper {
  display: table;
  width: 100%;
}

.widget-player__details-holder {
  display: table-cell;
  vertical-align: middle;
  width: 70%;
}

.widget-player__details-label {
  display: block;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  font-size: 10px;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .widget-player__details-label {
    font-size: 12px;
  }
}

.widget-player__details-desc {
  display: block;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
}

@media (min-width: 992px) {
  .widget-player__details-desc {
    font-size: 9px;
  }
}

.widget-player__details-value {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  width: 30%;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  font-size: 10px;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .widget-player__details-value {
    font-size: 11px;
  }
}

/* Widget: Featured Player Alternative Styling */
.widget-player--alt .widget__content {
  background-color: #38a9ff;
  background-image: linear-gradient(to bottom, #38a9ff, #f29400);
}

.widget-player--alt .widget-player__photo {
  filter: grayscale(1);
}

.widget-player--alt .widget-player__last-name {
  color: #31404b;
}

.widget-player--alt .widget-player__content {
  background-color: #27313b;
}

.widget-player--alt .widget-player__stat-number {
  color: #38a9ff;
}

.widget-player--alt .widget-player__stat-legend {
  color: #fff;
}

.widget-player--alt .widget-player__footer-txt {
  background-color: #27313b;
}

/* Widget: Featured Player - Small (eSports) */
.widget-player--sm .widget__content {
  background-color: transparent;
  background-image: none;
  height: 106px;
  padding: 0 20px 0 120px;
  display: flex;
  align-items: center;
}

@media (min-width: 375px) {
  .widget-player--sm .widget__content {
    padding-left: 120px;
  }
}

@media (min-width: 992px) {
  .widget-player--sm .widget__content {
    height: 106px;
    padding-left: 120px;
  }
}

@media (min-width: 1200px) {
  .widget-player--sm .widget__content {
    height: 106px;
  }
}

.widget-player--sm .widget-player__overlay {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 1px;
}

.widget-player--sm .widget-player__header {
  padding-top: 0;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .widget-player--sm .widget-player__header {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .widget-player--sm .widget-player__header {
    padding-top: 0;
    margin-bottom: 0;
  }
}

.widget-player--sm .widget-player__name {
  font-size: 14px;
  line-height: 1em;
  margin-bottom: .1em;
  position: static;
  text-transform: uppercase;
  flex: 1;
}

@media (min-width: 992px) {
  .widget-player--sm .widget-player__name {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .widget-player--sm .widget-player__name {
    font-size: 26px;
  }
}

.widget-player--sm .widget-player__first-name {
  display: inline-block;
  overflow: visible;
}

.widget-player--sm .widget-player__last-name {
  display: inline-block;
  overflow: visible;
}

.widget-player--sm .widget-player__content {
  position: static;
  padding: 0;
}

.widget-player--sm .widget-player__content-inner {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 375px) {
  .widget-player--sm .widget-player__content-inner {
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .widget-player--sm .widget-player__content-inner {
    padding-left: 0;
  }
}

.widget-player--sm .widget-player__info {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.2em;
  font-style: italic;
  font-weight: 700;
  color: #9a9da2;
}

.widget-player--sm .widget-player__info + .widget-player__rating {
  margin-left: .3em;
}

.widget-player--sm .widget-player__rating {
  display: inline-block;
  font-size: 9px;
  line-height: 1.2em;
  vertical-align: middle;
}

/* Widget: Featured Player - Soccer */
.widget-player--soccer {
  position: relative;
}

.widget-player--soccer .widget-player__ribbon {
  content: "\f005";
  display: block;
  position: absolute;
  width: 26px;
  height: 28px;
  background-color: #ffcf11;
  top: 0;
  right: 20px;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.widget-player--soccer .widget-player__ribbon::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  border-top: 6px solid #ffcf11;
  border-right: 13px solid transparent;
}

.widget-player--soccer .widget-player__ribbon::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  right: 0;
  border-top: 6px solid #ffcf11;
  border-left: 13px solid transparent;
}

.widget-player--soccer .widget__content {
  background-color: #fff;
  background-image: none;
  color: #9a9da2;
  height: 168px;
}

@media (min-width: 992px) {
  .widget-player--soccer .widget__content {
    height: 160px;
  }
}

@media (min-width: 1200px) {
  .widget-player--soccer .widget__content {
    height: 198px;
  }
}

.widget-player--soccer .widget-player__header {
  position: static;
}

.widget-player--soccer .widget-player__number {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin-left: 0;
  margin-top: -0.2em;
  text-indent: -0.15em;
  width: 144px;
  background-color: #38a9ff;
  color: rgba(255, 255, 255, 0.1);
  font-size: 150px;
  line-height: 1em;
  overflow: hidden;
}

.widget-player--soccer .widget-player__number::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 200px solid #fff;
  border-left: 30px solid transparent;
}

.widget-player--soccer .widget-player__name {
  color: #31404b;
}

@media (min-width: 1200px) {
  .widget-player--soccer .widget-player__name {
    margin-left: -35px;
  }
}

.widget-player--soccer .widget-player__first-name {
  font-size: 14px;
  line-height: 1em;
}

@media (min-width: 1200px) {
  .widget-player--soccer .widget-player__first-name {
    font-size: 18px;
  }
}

.widget-player--soccer .widget-player__last-name {
  font-size: 24px;
  line-height: 1em;
}

@media (min-width: 1200px) {
  .widget-player--soccer .widget-player__last-name {
    font-size: 36px;
  }
}

.widget-player--soccer .widget-player__content-inner {
  overflow: hidden;
  margin-bottom: 15px;
}

.widget-player--soccer .widget-player__stat {
  width: 25%;
}

.widget-player--soccer .widget-player__stat-number {
  font-size: 18px;
  color: #31404b;
}

.widget-player--soccer .widget-player__stat-label {
  color: #9a9da2;
  font-size: 8px;
}

.widget-player--soccer .widget-player__content-alt {
  padding: 0 24px 0 50%;
}

@media (min-width: 375px) {
  .widget-player--soccer .widget-player__content-alt {
    padding-left: 160px;
  }
}

@media (min-width: 992px) {
  .widget-player--soccer .widget-player__content-alt {
    padding-left: 50%;
  }
}

.widget-player--soccer .widget-player__content-alt .progress {
  margin-bottom: 3px;
}

.widget-player--soccer .widget-player__content-alt .progress__label {
  font-size: 8px;
  color: #9a9da2;
}

.widget-player--soccer .widget-player__content-alt .progress__number {
  font-size: 8px;
  font-weight: 700;
  color: #31404b;
}

.widget-player__stats--center {
  display: flex;
  justify-content: center;
}

.widget-player__stat-item {
  text-align: center;
}

.widget-player__stat--value {
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  margin-bottom: 0.25em;
  color: #31404b;
}

.widget-player__stat--value-primary {
  color: #38a9ff;
}

.widget-player__stat--label {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  line-height: 1.2em;
  color: #31404b;
  margin-bottom: .1em;
}

.widget-player__stat--desc {
  font-size: 9px;
  line-height: 1.2em;
}

.widget-player__stat-item--number-side {
  display: flex;
  text-align: left;
  align-items: center;
}

.widget-player__stat-item--number-side .widget-player__stat--value {
  margin-right: 8px;
  font-size: 28px;
  margin-bottom: 0;
}

.widget-player__stat-item--number-side .widget-player__stat-inner {
  flex: 1;
}

/* Widget: Game Result */
.widget-game-result .widget__content {
  padding: 0;
}

.widget-game-result__section-inner {
  padding: 24px 24px;
}

.widget-game-result__section-inner-alt {
  padding: 0;
}

.widget-game-result__section-inner-alt .table-responsive {
  margin-bottom: 0;
}

.widget-game-result__extra-stats {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.widget-game-result__extra-stats.active {
  max-height: 400px;
}

.widget-game-result__header {
  text-align: center;
  margin-bottom: 15px;
}

.widget-game-result__title {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: .25em;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .widget-game-result__title {
    font-size: 14px;
  }
}

.widget-game-result__date {
  display: block;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .widget-game-result__date {
    font-size: 10px;
  }
}

.widget-game-result__header--alt {
  display: flex;
  text-align: left;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  margin-bottom: 0;
  border-bottom: 1px solid #e4e7ed;
  padding: 10px 24px;
}

.widget-game-result__header--alt .widget-game-result__title {
  font-size: 9px;
  font-style: normal;
  margin-bottom: 0;
  font-weight: 400;
}

@media (min-width: 992px) {
  .widget-game-result__header--alt .widget-game-result__title {
    font-size: 9px;
  }
}

.widget-game-result__header--alt .widget-game-result__date {
  font-size: 9px;
  flex-grow: 1;
  text-align: right;
  font-weight: 400;
}

@media (min-width: 992px) {
  .widget-game-result__header--alt .widget-game-result__date {
    font-size: 9px;
  }
}

.widget-game-result__main {
  margin-bottom: 6px;
  display: table;
  width: 100%;
}

.widget-game-result__team {
  display: table-cell;
  text-align: center;
  width: 26%;
}

.widget-game-result__team-logo {
  height: 70px;
  display: block;
  position: relative;
}

.widget-game-result__team-logo img {
  display: block;
  margin: 0 auto;
  max-height: 100%;
}

.widget-game-result__team-info {
  padding: 6px 0 0 0;
}

.widget-game-result__team-name {
  display: block;
  font-size: 11px;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80px;
  margin: 0 auto 0.1em auto;
}

@media (min-width: 480px) {
  .widget-game-result__team-name {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .widget-game-result__team-name {
    font-size: 13px;
    max-width: 85px;
  }
}

.widget-game-result__team-desc {
  display: block;
  font-size: 7px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

.widget-game-result__score-wrap {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 46%;
}

.widget-game-result__score {
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  letter-spacing: -0.04em;
}

@media (min-width: 768px) {
  .widget-game-result__score {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .widget-game-result__score {
    font-size: 22px;
    line-height: 1.2em;
    margin-bottom: 10px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__score {
    font-size: 26px;
  }
}

.widget-game-result__score-result {
  position: relative;
}

.widget-game-result__score-result--winner::before {
  content: "";
  display: block;
  position: absolute;
  left: -15px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 8px solid #38a9ff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .widget-game-result__score-result--winner::before {
    left: -10px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__score-result--winner::before {
    left: -20px;
  }
}

.widget-game-result__score-dash + .widget-game-result__score-result--winner::before {
  transform: translateY(-50%) rotate(180deg);
}

@media (min-width: 992px) {
  .widget-game-result__score-dash + .widget-game-result__score-result--winner::before {
    left: auto;
    right: -10px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__score-dash + .widget-game-result__score-result--winner::before {
    left: auto;
    right: -20px;
  }
}

.widget-game-result__score-result--loser {
  color: #9a9da2;
}

.widget-game-result__score-label {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
}

.widget-game-result__score-video-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  background-color: #9a9da2;
}

.widget-game-result__score-video-icon:hover {
  background-color: #1892ed;
  color: #fff;
}

.widget-game-result__score-video-icon .fa {
  margin-left: 2px;
}

.widget-game-result__main--vertical .widget-game-result__team {
  display: flex;
  width: auto;
  text-align: left;
  align-items: center;
  padding: 20px 12px;
  margin-left: -24px;
  margin-right: -24px;
}

.widget-game-result__main--vertical .widget-game-result__team + .widget-game-result__team {
  border-top: 1px solid #e4e7ed;
}

.widget-game-result__main--vertical .widget-game-result__team:last-child {
  padding-bottom: 0;
}

.widget-game-result__main--vertical .widget-game-result__team .widget-game-result__team-score {
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-style: normal;
  line-height: 1em;
}

@media (min-width: 1200px) {
  .widget-game-result__main--vertical .widget-game-result__team .widget-game-result__team-score {
    font-size: 48px;
  }
}

.widget-game-result__main--vertical .widget-game-result__team .widget-game-result__team-score--winner {
  color: #31404b;
}

.widget-game-result__main--vertical .widget-game-result__team .widget-game-result__team-score--winner::before {
  content: "";
  display: inline-block;
  position: relative;
  top: -0.15em;
  margin-right: 0.5em;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #38a9ff;
}

@media (min-width: 992px) {
  .widget-game-result__main--vertical .widget-game-result__team .widget-game-result__team-score--winner::before {
    margin-right: 0.2em;
  }
}

@media (min-width: 1200px) {
  .widget-game-result__main--vertical .widget-game-result__team .widget-game-result__team-score--winner::before {
    margin-right: 0.5em;
  }
}

@media (min-width: 480px) {
  .widget-game-result__main--vertical .widget-game-result__team {
    padding: 20px 24px;
  }
}

.widget-game-result__main--vertical .widget-game-result__team-logo {
  width: 80px;
  margin-right: 10px;
}

.widget-game-result__main--vertical .widget-game-result__team-info {
  flex-grow: 1;
}

.widget-game-result__main--vertical .widget-game-result__team-name {
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
}

@media (min-width: 992px) {
  .widget-game-result__main--vertical .widget-game-result__team-name {
    font-size: 14px;
    max-width: none;
  }
}

.widget-game-result__main--vertical .widget-game-result__team-desc {
  font-size: 10px;
  font-style: normal;
}

.widget-game-result__table-stats .table {
  margin-bottom: 0;
  border-top: 1px solid #e4e7ed;
}

.widget-game-result__section-inner .widget-game-result__table-stats .table {
  border-left: none;
  border-right: none;
}

@media (min-width: 992px) {
  .widget-game-result__table-stats .table > tbody > tr > td:first-child,
  .widget-game-result__table-stats .table > tbody > tr > th:first-child {
    font-size: 12px;
  }
}

.widget-game-result__table-stats .table > tbody > tr > th {
  text-transform: none;
}

.widget-game-result__table-stats .table > thead > tr > th:first-child,
.widget-game-result__table-stats .table > tbody > tr > td:first-child,
.widget-game-result__table-stats .table > tbody > tr > th:first-child {
  padding-left: 12px;
}

@media (min-width: 992px) {
  .widget-game-result__table-stats .table > thead > tr > th:first-child,
  .widget-game-result__table-stats .table > tbody > tr > td:first-child,
  .widget-game-result__table-stats .table > tbody > tr > th:first-child {
    padding-left: 24px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__table-stats .table > thead > tr > th:first-child,
  .widget-game-result__table-stats .table > tbody > tr > td:first-child,
  .widget-game-result__table-stats .table > tbody > tr > th:first-child {
    padding-left: 45px;
  }
}

.widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th:first-child,
.widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td:first-child,
.widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th:first-child {
  padding-left: 5px;
}

@media (min-width: 992px) {
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th:first-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td:first-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th:first-child {
    padding-left: 18px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th:first-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td:first-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th:first-child {
    padding-left: 24px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.widget-game-result__table-stats .table > thead > tr > th:last-child,
.widget-game-result__table-stats .table > tbody > tr > td:last-child,
.widget-game-result__table-stats .table > tbody > tr > th:last-child {
  padding-right: 12px;
  color: #31404b;
}

@media (min-width: 992px) {
  .widget-game-result__table-stats .table > thead > tr > th:last-child,
  .widget-game-result__table-stats .table > tbody > tr > td:last-child,
  .widget-game-result__table-stats .table > tbody > tr > th:last-child {
    padding-right: 24px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__table-stats .table > thead > tr > th:last-child,
  .widget-game-result__table-stats .table > tbody > tr > td:last-child,
  .widget-game-result__table-stats .table > tbody > tr > th:last-child {
    padding-right: 45px;
  }
}

.widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th:last-child,
.widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td:last-child,
.widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th:last-child {
  padding-right: 5px;
  color: #31404b;
}

@media (min-width: 992px) {
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th:last-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td:last-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th:last-child {
    padding-right: 18px;
  }
}

@media (min-width: 1199px) {
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > thead > tr > th:last-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > td:last-child,
  .widget-game-result__section-inner-alt .widget-game-result__table-stats .table > tbody > tr > th:last-child {
    padding-right: 24px;
  }
}

.player-details {
  display: table;
  width: 100%;
  height: 100%;
}

.player-details__info {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

.player-details__photo {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 9px;
  overflow: hidden;
  border-radius: 50%;
  vertical-align: middle;
}

@media (min-width: 375px) {
  .player-details__photo {
    width: 40px;
    height: 40px;
  }
}

.player-details__info-holder {
  display: inline-block;
  vertical-align: middle;
}

.player-details__name {
  font-size: 10px;
  line-height: 1.2em;
  text-transform: none;
  margin-bottom: 0;
  font-weight: 400;
}

@media (min-width: 992px) {
  .player-details__name {
    font-size: 12px;
  }
}

.player-details__name > a {
  color: #31404b;
  transition: color 0.2s ease;
}

.player-details__name > a:hover {
  color: #1892ed;
}

.player-details__position {
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  display: block;
  line-height: 1em;
}

@media (min-width: 992px) {
  .player-details__position {
    font-size: 9px;
  }
}

.player-details__stats {
  display: table-cell;
  width: 50%;
}

.player-details__circular {
  float: left;
  width: 33.3%;
  padding: 0 2px;
  vertical-align: top;
  margin: 0;
}

@media (min-width: 768px) {
  .player-details__circular {
    padding: 0 6px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-details__circular {
    padding: 0 1px;
  }
}

.player-details__circular .circular__bar {
  width: 100%;
  height: 100%;
}

.player-details__circular .circular__percents {
  line-height: 1em;
  font-size: 12px;
  font-weight: 400;
}

.player-details__circular .circular__percents small {
  display: block;
  text-transform: none;
  font-size: 8px;
  line-height: 1em;
  color: #9a9da2;
}

/* Vertical Timeline - Soccer */
.df-timeline-wrapper {
  overflow: hidden;
  margin-bottom: 1px;
}

.df-timeline {
  position: relative;
  padding-bottom: 15px;
}

.df-timeline::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 3px;
  bottom: 0;
  width: 2px;
  margin-left: -1px;
  background-color: #e4e7ed;
}

.df-timeline__event {
  position: relative;
  min-height: 26px;
  overflow: hidden;
  margin-bottom: 16px;
}

.df-timeline__event--start {
  position: relative;
  text-align: center;
}

.df-timeline__event--start::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  overflow: hidden;
  background-color: #e4e7ed;
}

.df-timeline__event--start .df-timeline__team-1 {
  text-align: center;
}

.df-timeline__event--start .df-timeline__time {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.df-timeline__team-shirt {
  position: relative;
  z-index: 1;
}

.df-timeline__event--empty {
  height: 8px;
  min-height: auto;
  overflow: hidden;
}

.df-timeline__event--empty::before {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  width: 8px;
  height: 8px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e4e7ed;
}

.df-timeline__team-1,
.df-timeline__team-2 {
  width: 50%;
}

.df-timeline__team-1 {
  float: left;
  text-align: right;
  padding-right: 28px;
}

.df-timeline__team-1 .df-timeline__event-icon {
  margin-left: 10px;
}

.df-timeline__team-2 {
  float: right;
  padding-left: 28px;
}

.df-timeline__team-2 .df-timeline__event-icon {
  margin-right: 10px;
}

.df-timeline__time {
  width: 26px;
  height: 26px;
  line-height: 24px;
  border: 1px solid #e4e7ed;
  border-radius: 50%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  color: #31404b;
  background-color: #fff;
  font-weight: 700;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.df-timeline__event-info {
  display: inline-block;
  vertical-align: middle;
}

.df-timeline__event-name {
  font-size: 10px;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2em;
}

.df-timeline__event-desc {
  font-size: 7px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

.df-timeline__event-icon {
  display: inline-block;
  vertical-align: middle;
}

.widget-game-result .progress-double-wrapper + .progress-double-wrapper {
  margin-top: 24px;
}

.widget-game-result__extra-stats .progress-double-wrapper {
  margin-top: 24px;
}

.widget-game-result__stack-item {
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 700;
  padding: 15px 24px;
  justify-content: space-between;
}

.widget-game-result__stack-item + .widget-game-result__stack-item {
  border-top: 1px solid #e4e7ed;
}

.widget-game-result__stack-item .widget-game-result__stack-value {
  color: #31404b;
}

.widget-game-result__stack-item--teams {
  justify-content: flex-start;
  align-items: center;
}

.widget-game-result__stack-team {
  display: flex;
  align-items: center;
  flex: 1;
}

.widget-game-result__stack-team--second {
  text-align: right;
  justify-content: flex-end;
}

.widget-game-result__stack-team--second .widget-game-result__stack-team-logo {
  order: 2;
  margin-right: 0;
  margin-left: 14px;
}

.widget-game-result__stack-team-logo {
  margin-right: 14px;
}

.widget-game-result__stack-team-name {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: 0;
}

.widget-game-result__stack-team-place {
  display: block;
  font-size: 9px;
  line-height: 1.2em;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

.widget-game-result__stack-result {
  font-size: 11px;
  text-align: center;
  flex: 1;
}

.widget-game-result__stack-result-team {
  display: inline-block;
  position: relative;
}

.widget-game-result__stack-result-team + .widget-game-result__stack-result-team::before {
  content: "-";
  display: inline-block;
  margin: 0 .3em 0 .05em;
  color: #31404b;
}

.widget-game-result__stack-result-team + .widget-game-result__stack-result-team::after {
  display: none;
  left: auto;
  right: -8px;
  border-left: none;
  border-right: 3px solid #38a9ff;
}

@media (min-width: 1200px) {
  .widget-game-result__stack-result-team + .widget-game-result__stack-result-team::after {
    right: -14px;
  }
}

.widget-game-result__stack-result-team.win {
  color: #31404b;
}

.widget-game-result__stack-result-team.win::after {
  display: block;
}

.widget-game-result__stack-result-team::after {
  content: "";
  position: absolute;
  left: -8px;
  top: 3px;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #38a9ff;
}

@media (min-width: 1200px) {
  .widget-game-result__stack-result-team::after {
    left: -14px;
  }
}

.widget-game-result__stack-result--divider-slash .widget-game-result__stack-result-team {
  color: #31404b;
}

.widget-game-result__stack-result--divider-slash .widget-game-result__stack-result-team + .widget-game-result__stack-result-team::before {
  content: "/";
}

/* Widget: Poll */
.poll-form__question {
  margin-bottom: 1.5em;
}

.poll-form__options {
  line-height: 26px;
}

.poll-form__options .radio-inline {
  margin-bottom: 9px;
  font-weight: 700;
  font-style: normal;
}

.poll-form__options .radio-wrapper {
  overflow: hidden;
}

.poll-form__options .radio-progress-value {
  float: right;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
}

/* Widget: Results */
.widget-results .card__content {
  padding: 0;
}

.widget-results__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-results__item {
  border-top: 1px solid #e4e7ed;
}

.widget-results__item:first-child {
  border-top: none;
}

.widget-results__header {
  display: flex;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.2em;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  color: #9a9da2;
  border-bottom: 1px solid #e4e7ed;
  padding: 10px 24px;
  margin-bottom: 0;
}

.widget-results__content {
  display: flex;
  align-items: center;
  padding: 12px 12px;
}

@media (min-width: 1200px) {
  .widget-results__content {
    padding: 16px 24px;
  }
}

.widget-results__team {
  flex-grow: 1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .widget-results__team {
    flex-basis: 25%;
  }
}

.widget-results__team-logo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 20px;
  text-align: center;
}

@media (min-width: 992px) {
  .widget-results__team-logo {
    margin-right: 8px;
    width: 28px;
  }
}

.widget-results__team--second .widget-results__team-logo {
  float: right;
  margin-right: 0;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .widget-results__team--second .widget-results__team-logo {
    margin-right: 0;
    margin-left: 8px;
  }
}

.widget-results__team-details {
  display: inline-block;
  vertical-align: middle;
}

.widget-results__team-name {
  text-transform: none;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.2em;
  font-style: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .widget-results__team-name {
    font-size: 12px;
  }
}

.widget-results__team-info {
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  display: block;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .widget-results__team-info {
    font-size: 9px;
  }
}

.widget-results__team--second {
  text-align: right;
}

.widget-results__result {
  flex-grow: 1;
  text-align: center;
}

.widget-results__score {
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  font-size: 10px;
  line-height: 1.2em;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .widget-results__score {
    font-size: 12px;
  }
}

.widget-results__score-winner:before {
  content: "";
  display: inline-block;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #38a9ff;
  border-left: 4px solid #38a9ff;
  width: 0;
  height: 0;
  margin-right: .5em;
}

.widget-results__score-loser ~ .widget-results__score-winner::before {
  display: none;
}

.widget-results__score-loser ~ .widget-results__score-winner::after {
  content: "";
  display: inline-block;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid #38a9ff;
  border-right: 4px solid #38a9ff;
  width: 0;
  height: 0;
  margin-left: .5em;
}

.widget-results__status {
  display: block;
  font-size: 8px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2em;
  color: #9a9da2;
}

@media (min-width: 992px) {
  .widget-results__status {
    font-size: 9px;
  }
}

.widget-results__dl {
  margin: -1px 0 0 0;
  padding: 0;
}

.widget-results__dt {
  font-size: normal-1px;
  font-style: normal;
  color: #31404b;
  margin: 0;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
  padding: 5px 12px;
  position: relative;
}

.widget-results__dt::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background-color: #38a9ff;
}

@media (min-width: 1200px) {
  .widget-results__dt {
    padding: 10px 24px;
  }
}

.widget-results__dt--category-1::before {
  background-color: #38a9ff;
}

.widget-results__dt--category-2::before {
  background-color: #07e0c4;
}

.widget-results__dt--category-3::before {
  background-color: #9fe900;
}

.widget-results__dt--category-4::before {
  background-color: #f34141;
}

.widget-results__dd {
  margin: 0;
  padding: 0;
}

/* Widget: Awards */
.awards--slider {
  position: relative;
}

.awards__item {
  text-align: center;
}

.awards__figure {
  display: block;
  text-align: center;
}

.awards__figure img {
  margin: 0 auto;
}

.awards__figure--space {
  padding: 37px 0;
}

.awards__desc {
  padding: 18px 0;
}

@media (min-width: 992px) {
  .awards__desc {
    padding: 27px 0;
  }
}

.awards__name {
  line-height: 1.2em;
  margin-bottom: .25em;
  font-size: 12px;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .awards__name {
    font-size: 14px;
  }
}

.awards__date {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .awards__date {
    font-size: 10px;
  }
}

.awards__stars {
  font-size: 10px;
  padding: 5px 0 0 0;
}

.awards__stars .fa {
  margin: 0 2px;
  color: #ffdc11;
}

.awards-filter {
  list-style: none;
  padding: 0 0 0 7px;
  margin-top: 0;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 3px;
  border-bottom: 1px solid #e4e7ed;
}

.awards-filter__item {
  display: inline;
}

.awards-filter__link {
  display: inline-block;
  padding: 9px 16px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #9a9da2;
  transition: color 0.3s ease;
}

.awards-filter__link:hover {
  color: #31404b;
}

.awards-filter__link--active {
  color: #31404b;
}

/* Widget: Games History */
.chart-legend ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
}

.chart-legend ul > li {
  display: inline-block;
  padding-left: 20px;
}

.chart-legend ul > li:first-child {
  padding-left: 0;
}

.chart-legend ul > li > span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-right: 10px;
}

.chart-legend--center {
  padding-top: 42px;
}

.chart-legend--center ul {
  display: flex;
  font-size: 12px;
  text-transform: none;
}

.chart-legend--center ul > li {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0;
  text-align: center;
}

.chart-legend--center ul > li > span {
  display: block;
  margin: 0 auto 10px auto;
}

/* Widget: Newslog */
.newslog {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card .newslog {
  margin-top: -24px;
  margin-bottom: -24px;
}

.newslog__item {
  border-bottom: 1px solid #e4e7ed;
  padding: 24px 24px;
  font-size: 11px;
  line-height: 17px;
  font-family: "Montserrat", sans-serif;
}

.card .newslog__item {
  margin-left: -24px;
  margin-right: -24px;
}

.newslog__item:last-child {
  border-bottom: none;
}

.newslog__item strong {
  font-weight: 400;
  color: #31404b;
}

.newslog__item--injury .newslog__item-inner::before {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ff5353;
  border: 1px solid #e54444;
  background-image: linear-gradient(to left, #fff, #fff), linear-gradient(to left, #fff, #fff);
  background-size: 8px 2px, 2px 8px;
  background-position: 3px 6px, 6px 3px;
}

.newslog__item--join .newslog__item-inner::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 12'%3E%3Cpath transform='translate(-983.875 -2316.34)' fill='%2314d6c6' stroke='none' d='M986,2327.66l-2.122-2.13,3.536-3.53-3.536-3.54,2.122-2.12,5.657,5.66Z'/%3E%3C/svg%3E");
  background-size: 8px 12px;
  width: 8px;
  height: 12px;
}

.newslog__item--exit .newslog__item-inner::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 12'%3E%3Cpath transform='translate(-983.875 -2316.34)' fill='%23ee5353' stroke='none' d='M986,2327.66l-2.122-2.13,3.536-3.53-3.536-3.54,2.122-2.12,5.657,5.66Z'/%3E%3C/svg%3E");
  background-size: 8px 12px;
  width: 8px;
  height: 12px;
  transform: rotate(-180deg);
}

.newslog__item--award .newslog__item-inner::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='19px'%3e%3cpath fill-rule='evenodd' fill='rgb(255, 211, 7)' d='M5.027,18.997 C5.021,18.938 5.017,18.878 5.017,18.817 C5.017,17.822 5.881,17.016 6.946,17.016 L11.100,17.016 C12.165,17.016 13.028,17.822 13.028,18.817 C13.028,18.878 13.025,18.938 13.018,18.997 L5.027,18.997 Z'/%3e%3cpath fill-rule='evenodd' fill='rgb(255, 181, 5)' d='M7.023,12.001 L11.018,12.001 L11.018,17.002 L7.023,17.002 L7.023,12.001 Z'/%3e%3cpath fill-rule='evenodd' fill='rgb(255, 211, 7)' d='M1.289,3.000 C1.289,3.000 1.515,8.859 4.740,8.885 L5.013,10.000 C0.006,10.000 0.333,1.993 0.333,1.993 L3.050,1.993 L3.297,3.000 L1.289,3.000 Z'/%3e%3cpath fill-rule='evenodd' fill='rgb(255, 211, 7)' d='M12.991,10.000 L13.283,8.885 C16.731,8.859 16.973,3.000 16.973,3.000 L14.825,3.000 L15.089,1.993 L17.995,1.993 C17.995,1.993 18.345,10.000 12.991,10.000 Z'/%3e%3cpath fill-rule='evenodd' fill='rgb(255, 211, 7)' d='M13.819,7.870 C12.819,10.809 11.183,12.834 9.002,13.007 C6.821,12.834 5.185,10.809 4.185,7.870 C3.185,4.931 3.004,1.003 3.004,1.003 L8.457,1.003 L9.547,1.003 L15.000,1.003 C15.000,1.003 14.819,4.931 13.819,7.870 Z'/%3e%3cpath fill-rule='evenodd' fill='rgb(255, 229, 106)' d='M2.203,0.008 L15.797,0.008 C15.911,0.008 16.002,0.108 16.002,0.232 L16.002,0.790 C16.002,0.913 15.911,1.013 15.797,1.013 L2.203,1.013 C2.089,1.013 1.998,0.913 1.998,0.790 L1.998,0.232 C1.998,0.108 2.089,0.008 2.203,0.008 Z'/%3e%3c/svg%3e");
  background-size: 18px 19px;
  width: 18px;
  height: 19px;
}

.newslog__item-inner {
  position: relative;
  padding-left: 36px;
}

.newslog__item-inner:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  width: 13px;
  height: 13px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E%3Cpath transform='translate(-978.5 -2710.5)' fill='none' stroke='%231bdfb8' d='M991,2719h-4v4h-4v-4h-4v-4h4v-4h4v4h4v4Z'/%3E%3C/svg%3E");
}

.newslog__date {
  margin-top: 0.5em;
  display: block;
  color: rgba(154, 157, 162, 0.4);
}

/* Widget: Team Leaders */
.team-leader {
  border-top: 1px solid #e4e7ed;
}

.table-responsive:first-child .team-leader {
  border-top: none;
}

.team-leader .team-leader__type,
.team-leader .team-leader__player {
  padding-left: 15px;
  vertical-align: middle;
}

@media (min-width: 1200px) {
  .team-leader .team-leader__type,
  .team-leader .team-leader__player {
    padding-left: 24px;
  }
}

@media (min-width: 1200px) {
  .team-leader .team-leader__avg {
    padding-right: 24px;
  }
}

.team-leader tbody > tr > td {
  vertical-align: middle;
}

th {
  text-transform: uppercase;
}

th.team-leader__total,
th.team-leader__gp,
th.team-leader__avg,
td.team-leader__total,
td.team-leader__gp,
td.team-leader__avg {
  text-align: center;
}

th.team-leader__goals,
td.team-leader__goals {
  text-align: center;
}

.team-leader__avg .circular {
  margin: 0;
}

.team-leader__avg .circular .circular__bar {
  width: 40px;
  height: 40px;
}

.team-leader__avg .circular .circular__percents {
  font-size: 11px;
}

.team-leader__player-img {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.team-leader__player-img--sm {
  width: 30px;
  height: 30px;
}

.team-leader__player-inner {
  display: inline-block;
  vertical-align: middle;
}

.team-leader__player-name {
  text-transform: none;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 0;
  font-style: normal;
}

.team-leader__player-position {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  line-height: 1.2em;
}

.nav-tabs--clean {
  width: auto;
  border-bottom: none !important;
}

.nav-tabs--clean .nav-link {
  border: none;
  border-radius: 0;
  font-size: 11px;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding: 12px 16px;
  color: rgba(49, 64, 75, 0.4);
  font-weight: bold;
  letter-spacing: -0.02em;
  transition: color 0.2s ease-in-out;
}

.nav-tabs--clean .nav-link:hover {
  color: #31404b;
  background-color: transparent;
  border: none;
}

.nav-tabs--clean .nav-link.active {
  color: #31404b;
  border: none;
  padding: 12px 16px;
}

/* Widget: Team Stats */
.team-stats-box {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  border-style: solid;
  border-color: #e4e7ed;
  border-width: 1px 0 0 1px;
  margin: -1px;
}

.team-stats__item {
  flex: 0 1 50%;
  max-width: 50%;
  border-style: solid;
  border-color: #e4e7ed;
  border-width: 0 1px 1px 0;
  text-align: center;
  padding: 24px 0 60px 0;
  background-image: linear-gradient(to right, rgba(236, 240, 246, 0.6) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 4px, transparent 4px), linear-gradient(to right, rgba(0, 0, 0, 0.1) 4px, transparent 4px), linear-gradient(to right, rgba(0, 0, 0, 0.11) 4px, transparent 4px);
  background-repeat: repeat-x;
  background-size: 36px 24px, 36px 30px, 36px 34px, 36px 40px, 36px 37px, 36px 29px, 72px 30px, 108px 37px;
  background-position: 0 100%, 6px 100%, 12px 100%, 18px 100%, 24px 100%, 30px 100%, 6px 100%, 24px 100%;
}

@media (min-width: 992px) {
  .team-stats__item {
    padding: 34px 0 80px 0;
  }
}

.team-stats__item--clean {
  background: none;
  padding: 24px 0 20px 0;
}

@media (min-width: 992px) {
  .team-stats__item--clean {
    padding: 26px 0 28px 0;
  }
}

.team-stats__icon {
  height: 37px;
}

.team-stats__icon--circle {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 auto 16px auto;
  background-color: #38a9ff;
  overflow: hidden;
}

.team-stats__icon--circle .icon-soccer-ball {
  font-size: 30px;
}

.team-stats__icon--circle-outline {
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #38a9ff;
}

.team-stats__icon--size-sm {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}

.team-stats__icon--centered .df-icon,
.team-stats__icon--centered .fa {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.team-stats__icon--centered .fa {
  display: block;
  font-size: 18px;
  color: #31404b;
}

.team-stats__indicator {
  display: block;
  position: absolute;
  right: -6px;
  top: -6px;
  border: 2px solid #38a9ff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: #fff;
}

.team-stats__icon--shots-ot {
  background-color: #a4ec32;
}

.team-stats__icon--shots-ot .team-stats__icon-primary {
  transform: translate(-50%, -50%) translate(-5px, -2px);
  z-index: 1;
}

.team-stats__icon--shots-ot .team-stats__icon-secondary {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(32px, 37px);
}

.team-stats__icon--shots {
  background-color: #f5d109;
}

.team-stats__icon--shots .team-stats__icon-primary {
  transform: translate(-50%, -50%) translate(-5px, 2px);
}

.team-stats__icon--shots .team-stats__icon-primary .icon-svg {
  font-size: 27px;
}

.team-stats__icon--shots .team-stats__icon-secondary {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(58px, 16px);
}

.team-stats__icon--assists {
  background-color: #3adfc2;
}

.team-stats__icon--assists .team-stats__icon-secondary {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  transform: translate(52px, 54px);
}

.team-stats__icon-primary {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team-stats__value {
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
  margin-bottom: .2em;
}

@media (min-width: 1200px) {
  .team-stats__value {
    font-size: 36px;
  }
}

.team-stats__label {
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-weight: 400;
  line-height: 1.2em;
}

@media (min-width: 768px) {
  .team-stats__label {
    font-size: 10px;
  }
}

.team-stats__label + .team-stats__circular {
  margin-top: 20px;
}

.team-stats__sublabel {
  font-size: 9px;
  line-height: 1.2em;
}

.team-stats__circular .circular__bar {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.team-stats__circular .circular__bar + .circular__label {
  margin-top: 0;
}

.team-stats__circular .circular__percents {
  font-size: 10px;
  line-height: 1em;
}

.team-stats__circular--minus {
  color: #f34141;
}

.team-stats__circular--minus .circular__percents {
  color: #f34141;
}

.team-stats__circular--plus {
  color: #24d9b0;
}

.team-stats__circular--plus .circular__percents {
  color: #24d9b0;
}

.team-stats-box--cell-bg {
  background-image: url("../images/football/graph-bg.png"), linear-gradient(to right, rgba(228, 231, 237, 0.4) 1px, transparent 1px), linear-gradient(to bottom, rgba(228, 231, 237, 0.4) 1px, transparent 1px);
  background-repeat: no-repeat, repeat-x, repeat-y;
  background-size: auto auto, 15px 100%, 100% 15px;
  background-position: 50% 100%, -7px 0, 0 5px;
}

/* Widget: Color Picker */
/* Widget: Filter Size */
.widget_filter-size .checkbox {
  font-style: normal;
}

.widget_filter-size .form-group {
  margin-bottom: 20px;
}

/* Widget: Filter Price */
/* Widget: Products */
.products-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card .products-list {
  margin-top: -24px;
  margin-bottom: -24px;
  margin-left: -24px;
  margin-right: -24px;
}

.products-list__item {
  position: relative;
  overflow: hidden;
  padding: 24px 24px 19px 24px;
}

.products-list__item + .products-list__item {
  border-top: 1px solid #e4e7ed;
}

.products-list__inner {
  overflow: hidden;
  padding: 5px 0 0 0;
}

.products-list__product-thumb {
  float: left;
  margin: 0 12px 0 0;
  overflow: hidden;
  border-radius: 0;
}

.products-list__product-thumb img {
  max-width: 100%;
  height: auto;
  transition: opacity 0.2s ease;
}

.products-list__product-thumb img:hover {
  opacity: 0.7;
}

.products-list__product-cat {
  display: block;
  font-size: 8px;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-bottom: .2em;
}

.products-list__product-name {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: .5em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-style: normal;
}

.products-list__product-name > a {
  color: #31404b;
  transition: color 0.3s ease;
}

.products-list__product-name > a:hover {
  color: #1892ed;
}

.products-list__product-ratings {
  font-size: 10px;
  line-height: 10px;
  margin: 2px 0;
}

.products-list__product-ratings .fa {
  margin-right: 2px;
  color: #ffdc11;
}

.products-list__product-ratings .fa.empty {
  color: #dbdfe6;
}

.products-list__product-sum {
  font-size: 12px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
}

/* Widget: Calendar */
.calendar_wrap {
  padding: 10px;
}

.calendar_wrap > table {
  width: 100%;
  background: none;
}

.calendar_wrap > table thead th {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  text-align: center;
  padding: 10px;
}

.calendar_wrap > table tbody td {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  text-align: center;
  padding: 8px 10px;
}

.calendar_wrap > table tbody td a {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #38a9ff;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin: 0 -4px;
}

.calendar_wrap > table .prev,
.calendar_wrap > table .next {
  padding-bottom: 14px;
}

.calendar_wrap > table .prev a,
.calendar_wrap > table .next a {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  font-size: 0;
  line-height: 0;
  transition: background-color 0.3s ease;
  vertical-align: middle;
  font-weight: 400;
  background-color: #9a9da2;
}

.calendar_wrap > table .prev a::before,
.calendar_wrap > table .next a::before {
  content: "";
  font-size: 14px;
  line-height: 20px;
  display: block;
  position: absolute;
  text-align: center;
  color: #fff;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.calendar_wrap > table .prev a:hover,
.calendar_wrap > table .next a:hover {
  background-color: #38a9ff;
}

.calendar_wrap > table .prev {
  text-align: left;
  padding-left: 14px;
}

.calendar_wrap > table .prev a::before {
  content: "\f104";
}

.calendar_wrap > table .next {
  text-align: right;
  padding-right: 14px;
}

.calendar_wrap > table .next a::before {
  content: "\f105";
}

.calendar_wrap caption {
  text-align: center;
  overflow: hidden;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #e4e7ed;
  margin: -10px -10px 10px -10px;
  padding-left: 14px;
  padding-right: 14px;
  vertical-align: middle;
  caption-side: top;
}

.calendar_wrap caption .prev {
  float: left;
  padding-bottom: 0;
}

.calendar_wrap caption .next {
  float: right;
  padding-bottom: 0;
}

/* Widget: Team Info */
.team-info-list {
  margin: 0;
}

.team-info__item {
  padding: 12px 24px;
  display: flex;
}

.team-info__item + .team-info__item {
  border-top: 1px solid #e4e7ed;
}

.team-info__label,
.team-info__value {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #31404b;
  font-weight: 700;
}

.team-info__value {
  margin-left: auto;
}

.team-info__value--active {
  color: #38a9ff;
}

.team-info__item--desc {
  display: block;
  padding-top: 24px;
  padding-bottom: 24px;
}

.team-info__item--desc .team-info__label {
  display: inline-block;
  margin: 0;
}

/* Widget: Event Calendar */
.alc-event-calendar {
  overflow: hidden;
}

.card .alc-event-calendar {
  margin: 0 -1px -1px -1px;
}

.alc-event-calendar caption {
  caption-side: top;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #31404b;
  text-align: center;
  padding: 12px 24px;
  border-bottom: 1px solid #e4e7ed;
}

.alc-event-calendar .alc-event-calendar__caption-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alc-event-calendar .alc-event-calendar__caption-nav {
  width: 20px;
  height: 20px;
}

.alc-event-calendar .alc-event-calendar__caption-nav-link {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #9a9da2;
}

.alc-event-calendar .alc-event-calendar__caption-nav-link::before {
  content: "";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alc-event-calendar .alc-event-calendar__caption-nav-link:hover {
  background-color: #38a9ff;
}

.alc-event-calendar .alc-event-calendar__caption-nav--prev .alc-event-calendar__caption-nav-link::before {
  content: "\f104";
}

.alc-event-calendar .alc-event-calendar__caption-nav--next .alc-event-calendar__caption-nav-link::before {
  content: "\f105";
}

.alc-event-calendar .table {
  margin-bottom: 0;
  border: none;
}

.alc-event-calendar thead > tr > th {
  border-left: none;
  border-right: none;
  font-size: 10px;
  font-style: normal;
  text-align: center;
}

@media (max-width: 991px) {
  .alc-event-calendar thead > tr > th::before {
    display: inline;
    content: attr(data-weekday-abbr);
  }
}

.alc-event-calendar tbody > tr > td {
  text-align: right;
  color: #31404b;
  width: 14.25%;
}

@media (min-width: 1200px) {
  .alc-event-calendar tbody > tr > td {
    height: 110px;
    padding: 8px 8px 8px 16px;
    font-size: 12px;
    font-weight: 700;
  }
}

@media (max-width: 991px) {
  .alc-event-calendar .weekday {
    display: none;
  }
}

.alc-event-calendar .day {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
}

.alc-event-calendar .day--muted {
  color: #9a9da2;
}

.alc-event-calendar .day--type-1 {
  background-color: #38a9ff;
}

.alc-event-calendar .day--type-2 {
  background-color: #07e0c4;
}

.alc-event-calendar .day--type-3 {
  background-color: #9fe900;
}

.alc-event-calendar .day--type-4 {
  background-color: #f34141;
}

.alc-event-calendar .alc-event-calendar__event {
  display: block;
  position: relative;
  font-size: 8px;
  text-transform: uppercase;
  line-height: 1.25em;
  text-align: left;
  padding: 0 0 0 8px;
  margin: 13px 0 0 0;
}

@media (max-width: 991px) {
  .alc-event-calendar .alc-event-calendar__event {
    display: none;
  }
}

.alc-event-calendar .alc-event-calendar__event::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #38a9ff;
}

.alc-event-calendar .alc-event-calendar__event a {
  color: #31404b;
  display: inline-block;
}

.alc-event-calendar .alc-event-calendar__event a:hover {
  color: #38a9ff;
}

.alc-event-calendar .alc-event-calendar__event--type-1::before {
  background-color: #38a9ff;
}

.alc-event-calendar .alc-event-calendar__event--type-2::before {
  background-color: #07e0c4;
}

.alc-event-calendar .alc-event-calendar__event--type-3::before {
  background-color: #9fe900;
}

.alc-event-calendar .alc-event-calendar__event--type-4::before {
  background-color: #f34141;
}

.widget--sidebar .alc-event-calendar caption {
  font-size: 13px;
}

.widget--sidebar .alc-event-calendar thead > tr > th {
  border-bottom: none;
}

@media (min-width: 1200px) {
  .widget--sidebar .alc-event-calendar thead > tr > th:first-child {
    padding-left: 30px;
  }
  .widget--sidebar .alc-event-calendar thead > tr > th:last-child {
    padding-right: 30px;
  }
}

.widget--sidebar .alc-event-calendar tbody > tr > td {
  text-align: center;
  width: 14.25%;
  border-top: none;
}

@media (min-width: 1200px) {
  .widget--sidebar .alc-event-calendar tbody > tr > td {
    height: auto;
    padding: 6px 8px;
    font-size: 11px;
  }
  .widget--sidebar .alc-event-calendar tbody > tr > td:first-child {
    padding-left: 30px;
  }
  .widget--sidebar .alc-event-calendar tbody > tr > td:last-child {
    padding-right: 30px;
  }
}

.widget--sidebar .alc-event-calendar tbody > tr:last-child > td {
  padding-bottom: 25px;
}

.alc-event-list {
  margin: 0;
}

.alc-event-calendar + .alc-event-list {
  margin-top: -1px;
}

.alc-event-list-item__header {
  font-size: 10px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  padding: 12px 24px;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
}

.alc-event-list-item__body {
  display: flex;
  padding: 24px 24px;
}

@media (max-width: 575px) {
  .alc-event-list-item__body {
    flex-wrap: wrap;
  }
  .alc-event-list-item__body .alc-event-list-item__time,
  .alc-event-list-item__body .alc-event-list-item__info,
  .alc-event-list-item__body .alc-event-list-item__cta {
    flex-basis: 100%;
  }
  .alc-event-list-item__body .alc-event-list-item__info {
    padding: 10px 0 16px 0;
  }
  .alc-event-list-item__body .alc-event-list-item__cta .btn {
    display: block;
  }
}

.alc-event-list-item__time {
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding-left: 12px;
  position: relative;
  color: #31404b;
}

.alc-event-list-item__time::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #38a9ff;
}

.alc-event-list-item__info {
  flex-grow: 1;
  padding: 0 0 0 20px;
}

.alc-event-list-item__title {
  font-size: 12px;
  line-height: 1.2em;
  text-transform: uppercase;
  margin-bottom: .5em;
}

.alc-event-list-item__title > a {
  color: #31404b;
}

.alc-event-list-item__title > a:hover {
  color: #38a9ff;
}

.alc-event-list-item__desc {
  font-size: 11px;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
}

.alc-event-list-item__cta {
  align-self: center;
}

.alc-event-list-item--type-1 .alc-event-list-item__time::before {
  background-color: #38a9ff;
}

.alc-event-list-item--type-2 .alc-event-list-item__time::before {
  background-color: #07e0c4;
}

.alc-event-list-item--type-3 .alc-event-list-item__time::before {
  background-color: #9fe900;
}

.alc-event-list-item--type-4 .alc-event-list-item__time::before {
  background-color: #f34141;
}

/* Team Filter */
/* Team Roster */
/* Team Roster - Grid */
.team-roster--grid .team-roster__item {
  margin-bottom: 15px;
}

@media (max-width: 479px) {
  .team-roster--grid .team-roster__item {
    width: 100%;
  }
}

@media (min-width: 375px) and (max-width: 479px) {
  .team-roster--grid .team-roster__item {
    width: 50%;
  }
}

.team-roster--grid .team-roster__holder {
  position: relative;
}

.team-roster--grid .team-roster__holder:hover .team-roster__member-number {
  background-color: #38a9ff;
}

.team-roster--grid .team-roster__img {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e4e7ed;
}

.team-roster--grid .team-roster__img::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
}

.team-roster--grid .team-roster__content {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 24px;
}

@media (min-width: 375px) and (max-width: 479px) {
  .team-roster--grid .team-roster__content {
    padding: 12px 12px;
  }
}

.team-roster--grid .team-roster__content-inner {
  display: table;
  width: 100%;
}

.team-roster--grid .team-roster__member-number {
  display: table-cell;
  width: 1%;
  vertical-align: middle;
  background-color: #bfc2c4;
  border-radius: 4px;
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 32px;
}

@media (min-width: 768px) {
  .team-roster--grid .team-roster__member-number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .team-roster--grid .team-roster__member-number {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .team-roster--grid .team-roster__member-number {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 48px;
  }
}

@media (min-width: 375px) and (max-width: 479px) {
  .team-roster--grid .team-roster__member-number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
  }
}

.team-roster--grid .team-roster__member-info {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

@media (min-width: 992px) {
  .team-roster--grid .team-roster__member-info {
    padding-left: 10px;
  }
}

@media (min-width: 1200px) {
  .team-roster--grid .team-roster__member-info {
    padding-left: 14px;
  }
}

.team-roster--grid .team-roster__member-name {
  color: #fff;
  margin-bottom: 0.15em;
  line-height: 1em;
  font-size: 24px;
}

@media (min-width: 768px) {
  .team-roster--grid .team-roster__member-name {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .team-roster--grid .team-roster__member-name {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .team-roster--grid .team-roster__member-name {
    font-size: 30px;
    line-height: 28px;
  }
}

@media (min-width: 375px) and (max-width: 479px) {
  .team-roster--grid .team-roster__member-name {
    font-size: 14px;
  }
}

.team-roster--grid .team-roster__member-first-name {
  display: block;
}

.team-roster--grid .team-roster__member-last-name {
  display: block;
  color: #38a9ff;
}

.team-roster--grid .team-roster__member-position {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  display: block;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .team-roster--grid .team-roster__member-position {
    font-size: 11px;
  }
}

@media (min-width: 375px) and (max-width: 479px) {
  .team-roster--grid .team-roster__member-position {
    font-size: 8px;
  }
}

.team-roster--grid .btn-fab {
  right: 20px;
  top: 20px;
}

/* Team Roster: Grid Small */
.team-roster--grid-sm .team-roster__item {
  margin-bottom: 20px;
}

.team-roster--grid-sm .team-roster__holder {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
}

.team-roster--grid-sm .team-roster__img {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

@media (max-width: 479px) {
  .team-roster--grid-sm .team-roster__img {
    width: 36%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }
}

.team-roster--grid-sm .team-roster__img > a {
  display: block;
  position: relative;
}

.team-roster--grid-sm .team-roster__img > a::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: #171d21;
}

.team-roster--grid-sm .team-roster__img > a:hover::before {
  opacity: .8;
}

.team-roster--grid-sm .team-roster__img > a:hover .btn-fab {
  transform: scale(1, 1);
}

.team-roster--grid-sm .btn-fab {
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -25px;
  z-index: 2;
  transform: scale(0, 0);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.team-roster--grid-sm .team-roster__content {
  padding: 24px 24px;
  min-width: 0;
}

.team-roster--grid-sm .team-roster__member-header {
  display: flex;
  align-items: center;
}

.team-roster--grid-sm .team-roster__member-number {
  font-size: 40px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #38a9ff;
  line-height: 1em;
  padding-right: 0.12em;
}

.team-roster--grid-sm .team-roster__member-name {
  font-size: 12px;
  margin-bottom: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-roster--grid-sm .team-roster__member-first-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-roster--grid-sm .team-roster__member-last-name {
  font-size: 20px;
  line-height: 1.1em;
  display: block;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-roster--grid-sm .team-roster__member-subheader {
  padding-top: 3px;
}

.team-roster--grid-sm .team-roster__member-position {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  display: block;
  font-weight: 400;
}

.team-roster--grid-sm .team-roster__member-details {
  padding: 20px 0 0 0;
  margin: 0;
}

.team-roster--grid-sm .team-roster__member-details-item {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  text-transform: none;
  color: #31404b;
  display: inline-block;
  padding: 0 13px 0 0;
}

.team-roster--grid-sm .team-roster__member-details-item .item-title {
  font-weight: 400;
  color: #9a9da2;
  text-transform: uppercase;
}

/* Team Roster: Table */
@media (min-width: 992px) {
  .team-roster-table thead > tr > th,
  .team-roster-table tbody > tr > td {
    padding-left: 23px;
  }
}

.team-roster-table__name {
  color: #31404b;
}

.team-roster-table__position {
  color: #31404b;
}

.team-roster-table__age {
  text-align: center;
}

.team-roster-table__height {
  text-align: center;
}

.team-roster-table__weight {
  text-align: center;
}

.team-roster-table__status {
  text-align: center;
}

.team-roster-table__foot,
.team-roster-table__goals,
.team-roster-table__assists,
.team-roster-table__fouls,
.team-roster-table__card-y,
.team-roster-table__card-r {
  text-align: center;
  vertical-align: middle;
}

.team-roster-table__header {
  text-transform: uppercase;
  background-color: #f5f7f9;
}

@media (min-width: 992px) {
  .team-roster-table__header {
    padding-left: 23px !important;
  }
}

/* Team Roster: Slider */
.team-roster--slider {
  background: #151719 url("../images/team-roster-slider-bg.jpg") 50% 50% no-repeat;
  background-size: cover;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  margin-bottom: 15px;
  padding-top: 40px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .team-roster--slider {
    padding: 54px 0 20px 0;
  }
}

@media (min-width: 992px) {
  .team-roster--slider {
    padding: 70px 0 20px 0;
  }
}

@media (min-width: 1200px) {
  .team-roster--slider {
    height: 720px;
    padding: 98px 0 0 0;
  }
}

.team-roster--slider .team-roster__item {
  outline: none;
  position: relative;
}

@media (min-width: 1200px) {
  .team-roster--slider .team-roster__item {
    padding: 0 10px;
  }
}

.team-roster--slider .team-roster__item a {
  outline: none;
}

.team-roster--slider .team-roster__img {
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
}

.team-roster--slider .team-roster__img img {
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.team-roster--slider .team-roster__img-ring-top,
.team-roster--slider .team-roster__img-ring-bottom {
  position: absolute;
  left: 0;
  right: 0;
  height: 26px;
  overflow: hidden;
}

.team-roster--slider .team-roster__img-ring-top::before,
.team-roster--slider .team-roster__img-ring-bottom::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  border: solid #38a9ff;
  border-width: 7px 32px;
  border-radius: 50%;
}

.team-roster--slider .team-roster__img-ring-top {
  bottom: 23px;
  transform: scale(-1, -1);
  z-index: 1;
}

.team-roster--slider .team-roster__img-ring-bottom {
  bottom: -3px;
  z-index: 3;
}

.team-roster--slider .team-roster__player-details {
  transition: all 0.3s ease;
  display: table;
  width: 100%;
  padding: 24px 0;
}

@media (min-width: 768px) {
  .team-roster--slider .team-roster__player-details {
    padding: 24px 0;
  }
}

@media (min-width: 992px) {
  .team-roster--slider .team-roster__player-details {
    padding: 40px 0;
  }
}

@media (min-width: 1200px) {
  .team-roster--slider .team-roster__player-details {
    padding: 50px 0;
  }
}

.team-roster--slider .team-roster__player-number {
  display: table-cell;
  width: 40%;
  text-align: right;
  vertical-align: top;
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1em;
  color: #fff;
  letter-spacing: -0.03em;
  font-size: 32px;
}

@media (min-width: 768px) {
  .team-roster--slider .team-roster__player-number {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .team-roster--slider .team-roster__player-number {
    font-size: 54px;
  }
}

@media (min-width: 1200px) {
  .team-roster--slider .team-roster__player-number {
    font-size: 70px;
    line-height: 54px;
  }
}

.team-roster--slider .team-roster__player-info {
  display: table-cell;
  width: 57%;
  padding-left: 3%;
  vertical-align: top;
  text-align: left;
}

.team-roster--slider .team-roster__player-name {
  color: #fff;
  margin-bottom: 0.2em;
  font-size: 18px;
}

@media (min-width: 768px) {
  .team-roster--slider .team-roster__player-name {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .team-roster--slider .team-roster__player-name {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .team-roster--slider .team-roster__player-name {
    font-size: 30px;
    line-height: 28px;
  }
}

.team-roster--slider .team-roster__player-last-name {
  color: #38a9ff;
}

.team-roster--slider .team-roster__player-position {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  display: block;
}

@media (min-width: 1200px) {
  .team-roster--slider .team-roster__player-position {
    font-size: 11px;
  }
}

.team-roster--slider .team-roster__item:not(.slick-current) .team-roster__img {
  filter: grayscale(1);
  opacity: 0.4;
  transform: scale(0.825);
}

.team-roster--slider .team-roster__item:not(.slick-current) .team-roster__player-details {
  opacity: 0;
  visibility: hidden;
}

.team-roster--slider .team-roster__item:not(.slick-current) .team-roster__player-fab {
  opacity: 0;
  visibility: hidden;
}

.team-roster--slider .team-roster__player-fab {
  position: absolute;
  top: 14%;
  right: 25%;
  z-index: 3;
  transition: all 0.3s ease-out;
}

@media (max-width: 767px) {
  .team-roster--slider .team-roster__player-fab {
    display: none;
  }
}

.team-roster--slider .team-roster__player-fab .btn-fab {
  position: absolute;
  left: 0;
  top: 0;
}

.team-roster--slider .team-roster__player-fab:hover .team-roster__player-fab-txt {
  opacity: 1;
  transform: translateX(0);
}

.team-roster--slider .team-roster__player-fab-txt {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #fff;
  display: block;
  position: absolute;
  left: 40px;
  top: 4px;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transform: translateX(-25%);
  transition: all 0.3s ease-out;
}

.team-roster--slider .team-roster__player-fab-txt::first-line {
  color: #9a9da2;
}

.team-roster--slider .slick-prev,
.team-roster--slider .slick-next {
  top: auto;
  margin-top: 0;
  bottom: 11%;
}

@media (min-width: 992px) {
  .team-roster--slider .slick-prev,
  .team-roster--slider .slick-next {
    bottom: 11%;
  }
}

@media (min-width: 1200px) {
  .team-roster--slider .slick-prev,
  .team-roster--slider .slick-next {
    bottom: 15%;
  }
}

/* Team Roster: Card */
.team-roster--card .team-roster__content-wrapper {
  display: flex;
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

@media (max-width: 991px) {
  .team-roster--card .team-roster__content-wrapper {
    flex-wrap: wrap;
  }
}

.team-roster--card .team-roster__player-img {
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
  text-align: center;
}

@media (max-width: 991px) {
  .team-roster--card .team-roster__player-img {
    flex-basis: 100%;
    max-height: 320px;
  }
}

@media (max-width: 479px) {
  .team-roster--card .team-roster__player-img {
    max-height: 200px;
  }
}

.team-roster--card .team-roster__player-shape {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #6bbfff;
  z-index: -1;
}

.team-roster--card .team-roster__player-shape::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url("../images/soccer/rosterv3_player_01-bg.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .12;
}

.team-roster--card .team-roster__player-shape::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -180px;
  width: 0;
  height: 0;
  border-bottom: 800px solid #fff;
  border-left: 420px solid transparent;
}

.team-roster--card .team-roster__content {
  padding: 20px;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__content {
    padding: 20px 40px 20px 0;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__content {
    padding: 25px 90px 20px 0;
  }
}

.team-roster--card .team-roster__player-details {
  position: relative;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__player-details {
    padding: 30px 0 0 0;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__player-details {
    padding: 50px 0 0 0;
  }
}

.team-roster--card .team-roster__player-number {
  font-size: 42px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1em;
  opacity: 0.2;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__player-number {
    display: block;
    position: absolute;
    top: 0;
    margin-left: -0.8em;
    opacity: 0.08;
    font-size: 110px;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__player-number {
    font-size: 138px;
  }
}

.team-roster--card .team-roster__player-name {
  margin-bottom: .75em;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__player-name {
    margin-bottom: 1.2em;
  }
}

.team-roster--card .team-roster__player-first-name {
  display: block;
  font-size: 24px;
  line-height: 1em;
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__player-first-name {
    font-size: 40px;
  }
}

.team-roster--card .team-roster__player-last-name {
  display: block;
  font-size: 40px;
  line-height: 1em;
  color: #38a9ff;
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__player-last-name {
    font-size: 78px;
  }
}

.team-roster--card .team-roster__player-excerpt {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__player-excerpt {
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__player-excerpt {
    margin-bottom: 47px;
  }
}

.team-roster--card .team-roster__player-stats {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__player-stats {
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__player-stats {
    margin-bottom: 47px;
  }
}

.team-roster--card .team-roster__player-stats .progress__label {
  width: 100px;
}

.team-roster--card .team-roster__player-footer .btn + .btn {
  margin-left: 7px;
}

@media (max-width: 991px) {
  .team-roster--card .team-roster__player-footer .btn:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.team-roster--card .team-roster__meta {
  border-left: 1px solid #e4e7ed;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__meta {
    flex-direction: column;
  }
}

.team-roster--card .team-roster__meta-item {
  text-align: center;
  padding: 20px 15px;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__meta-item {
    padding: 30px 40px;
  }
}

.team-roster--card .team-roster__meta-value {
  display: block;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__meta-value {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__meta-value {
    font-size: 27px;
  }
}

.team-roster--card .team-roster__meta-label {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__meta-label {
    font-size: 12px;
  }
}

.team-roster--card .team-roster__meta-item--lg .team-roster__meta-value {
  font-size: 24px;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__meta-item--lg .team-roster__meta-value {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .team-roster--card .team-roster__meta-item--lg .team-roster__meta-value {
    font-size: 36px;
  }
}

.team-roster--card .team-roster__meta-item--lg .team-roster__meta-label {
  font-size: 12px;
}

@media (min-width: 992px) {
  .team-roster--card .team-roster__meta-item--lg .team-roster__meta-label {
    font-size: 13px;
  }
}

/* Team Roster: Card Compact */
.team-roster--card-compact .team-roster__item {
  overflow: hidden;
}

.team-roster--card-compact .team-roster__item .card__content {
  padding: 20px;
  position: relative;
}

.team-roster--card-compact .team-roster__item .card__content::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 24px;
  background-color: #fff;
  z-index: 3;
}

.team-roster--card-compact .team-roster__item .team-roster__content-wrapper {
  background-image: url("../../assets/images/soccer/samples/roster_player_card-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  height: 411px;
}

@media (min-width: 480px) {
  .team-roster--card-compact .team-roster__item .team-roster__content-wrapper {
    height: 540px;
  }
}

@media (min-width: 768px) {
  .team-roster--card-compact .team-roster__item .team-roster__content-wrapper {
    height: 540px;
  }
}

@media (min-width: 992px) {
  .team-roster--card-compact .team-roster__item .team-roster__content-wrapper {
    height: 408px;
  }
}

@media (min-width: 1200px) {
  .team-roster--card-compact .team-roster__item .team-roster__content-wrapper {
    height: 535px;
  }
}

.team-roster--card-compact .team-roster__item .team-roster__content-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(135deg, transparent, transparent 57%, #e2ff2f 57%, #e2ff2f 78%, transparent 78%, transparent), linear-gradient(135deg, transparent, transparent 33%, rgba(255, 255, 255, 0.15) 33%, rgba(255, 255, 255, 0.15) 57%, transparent 57%, transparent), linear-gradient(135deg, rgba(24, 146, 237, 0.82), rgba(24, 146, 237, 0.82) 47%, #c2ff1f 47%, #c2ff1f);
  z-index: 1;
}

.team-roster--card-compact .team-roster__item .team-roster__content-wrapper:hover .team-roster__player-img img {
  transform: translateX(-24px);
  opacity: 0;
}

.team-roster--card-compact .team-roster__item .team-roster__content-wrapper:hover .team-roster__player-img::before {
  opacity: .4;
}

.team-roster--card-compact .team-roster__item .team-roster__content-wrapper:hover .team-roster__player-img::after {
  opacity: 1;
}

.team-roster--card-compact .team-roster__item .team-roster__content-wrapper:hover .team-roster__player-details {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.12s;
  transform: translateX(0);
}

.team-roster--card-compact .team-roster__item .team-roster__player-img {
  position: absolute;
  z-index: 2;
  margin: 0;
  text-align: center;
  width: 100%;
}

.team-roster--card-compact .team-roster__item .team-roster__player-img img {
  margin-top: -20px;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.4s ease;
}

.team-roster--card-compact .team-roster__item .team-roster__player-img::before, .team-roster--card-compact .team-roster__item .team-roster__player-img:after {
  content: "";
  display: block;
  position: absolute;
  top: 18px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #fff;
  transition: opacity .3s ease;
  opacity: 1;
}

.team-roster--card-compact .team-roster__item .team-roster__player-img::before {
  right: 38px;
}

.team-roster--card-compact .team-roster__item .team-roster__player-img::after {
  right: 18px;
  opacity: .4;
}

.team-roster--card-compact .team-roster__item .team-roster__player-info {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 80px;
  bottom: 50px;
  background-color: #fff;
  padding: 12px 0 10px 24px;
  height: 68px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .team-roster--card-compact .team-roster__item .team-roster__player-info {
    right: 110px;
  }
}

.team-roster--card-compact .team-roster__item .team-roster__player-info::before {
  content: "";
  display: block;
  position: absolute;
  right: -68px;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 68px solid transparent;
  border-left: 68px solid #fff;
}

.team-roster--card-compact .team-roster__item .team-roster__player-name {
  font-size: 14px;
  line-height: 1em;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .team-roster--card-compact .team-roster__item .team-roster__player-name {
    font-size: 16px;
  }
}

.team-roster--card-compact .team-roster__item .team-roster__player-first-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-roster--card-compact .team-roster__item .team-roster__player-last-name {
  display: block;
  font-size: 22px;
  line-height: 1em;
  color: #38a9ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .team-roster--card-compact .team-roster__item .team-roster__player-last-name {
    font-size: 30px;
  }
}

.team-roster--card-compact .team-roster__item .team-roster__player-number {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  color: rgba(154, 157, 162, 0.4);
  font-size: 32px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  padding-bottom: 0.3em;
}

.team-roster--card-compact .team-roster__item .team-roster__player-number::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 92px solid transparent;
  border-right: 92px solid #fff;
  z-index: -1;
}

.team-roster--card-compact .team-roster__item .team-roster__player-details {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  padding: 12px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transform: translateX(24px);
  transition: all 0.4s ease;
}

@media (min-width: 1200px) {
  .team-roster--card-compact .team-roster__item .team-roster__player-details {
    top: 56px;
    left: 36px;
    right: 36px;
    padding: 24px 24px;
  }
}

.team-roster--card-compact .team-roster__item .team-roster__player-details-body {
  flex-grow: 1;
  padding: 0 20px 0 6px;
}

.team-roster--card-compact .team-roster__item .team-roster__player-details-excerpt {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.team-roster--card-compact .team-roster__item .progress-stats {
  margin-bottom: 7px;
}

.team-roster--card-compact .team-roster__item .progress-stats .progress__label {
  font-size: 9px;
  color: #9a9da2;
}

.team-roster--card-compact .team-roster__item .progress-stats .progress__number {
  font-size: 9px;
  color: #31404b;
}

.team-roster--card-compact .team-roster__item .progress-stats .progress {
  margin-top: 7px;
}

.team-roster--card-compact .team-roster__item .team-roster__player-details-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;
}

.team-roster--card-compact .team-roster__item .team-roster__player-details-footer .btn-block {
  width: 100%;
}

.team-roster--card-compact .team-roster__item .team-roster__player-details-footer .btn-single-icon {
  margin-top: 10px;
  flex: 1 0;
}

.team-roster--card-compact .team-roster__item .team-roster__player-details-footer .btn-single-icon + .btn-single-icon {
  margin-left: 10px;
}

.team-roster--card-compact .team-roster__item .team-roster__meta {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-roster--card-compact .team-roster__item .team-roster__meta-item {
  text-align: center;
}

.team-roster--card-compact .team-roster__item .team-roster__meta-item--lg .team-roster__meta-value {
  font-size: 26px;
}

.team-roster--card-compact .team-roster__item .team-roster__meta-value {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #31404b;
}

.team-roster--card-compact .team-roster__item .team-roster__meta-label {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

/* Team Roster: Case */
.team-roster--case {
  margin-left: -8px;
  margin-right: -8px;
}

.team-roster--case .team-roster__item {
  margin: 0 8px;
}

.team-roster--case .team-roster__player-img {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.team-roster--case .team-roster__player-img img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.team-roster--case .team-roster__player-img:hover img {
  transform: scale(1.1);
}

.team-roster--case .team-roster__player-img + .card__header {
  border-radius: 0;
}

.team-roster--case .team-roster__player-img + .card__header::before {
  border-radius: 0;
}

.team-roster--case .team-roster__player-name {
  font-size: 16px;
  margin: 0;
  font-style: normal;
}

.team-roster--case .team-roster__meta {
  display: flex;
}

.team-roster--case .team-roster__meta-item {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  border-left: 1px solid #e4e7ed;
  padding: 26px 0;
}

.team-roster--case .team-roster__meta-item:first-child {
  border-left: none;
}

.team-roster--case .team-roster__meta-value {
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #fff;
  margin-bottom: .1em;
}

.team-roster--case .team-roster__meta-value--accent {
  color: #c2ff1f;
}

.team-roster--case .team-roster__meta-label {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  font-weight: 400;
}

/* Team Standings */
.table-standings {
  border: none;
  margin-bottom: 0;
}

.widget-standings .table-standings > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}

.widget-standings .table-standings > thead > tr > th:first-child {
  text-align: left;
  padding-left: 24px;
}

.widget-standings .table-standings > thead > tr > th:last-child {
  padding-right: 24px;
}

.widget-standings .table-standings > tbody > tr > td {
  vertical-align: middle;
  color: #31404b;
  text-align: center;
}

.widget-standings .table-standings > tbody > tr > td:first-child {
  padding-left: 24px;
}

.widget-standings .table-standings > tbody > tr > td:last-child {
  padding-right: 24px;
}

.widget-standings .table-standings > tbody {
  counter-reset: counter;
}

.widget-standings .table-standings > tbody > tr > td:first-child > .team-meta::before {
  display: inline-block;
  vertical-align: middle;
  content: counter(counter, decimal-leading-zero);
  counter-increment: counter;
  font-size: 11px;
  margin-right: 5px;
  font-family: "Montserrat", sans-serif;
  color: #9a9da2;
  text-transform: uppercase;
}

.team-meta {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-meta__logo {
  width: 35px;
  height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}

.team-meta__logo img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.team-meta__info {
  display: inline-block;
  vertical-align: middle;
}

.team-meta__name {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: 0;
  text-transform: none;
  font-weight: 400;
}

.team-meta__place {
  display: block;
  font-size: 9px;
  line-height: 1.2em;
  color: #9a9da2;
}

.team-meta__vs {
  display: inline-block;
  color: #31404b;
  padding: 0 20px;
}

/* Table Standings: Full */
.table-standings--full > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}

@media (min-width: 992px) {
  .table-standings--full > thead > tr > th:first-child {
    padding-left: 24px;
  }
}

@media (min-width: 992px) {
  .table-standings--full > thead > tr > th:last-child {
    padding-right: 24px;
  }
}

.table-standings--full > tbody > tr > td {
  vertical-align: middle;
  text-align: center;
}

@media (min-width: 992px) {
  .table-standings--full > tbody > tr > td:first-child {
    padding-left: 24px;
  }
}

@media (min-width: 992px) {
  .table-standings--full > tbody > tr > td:last-child {
    padding-right: 24px;
  }
}

.table-standings--full .team-standings__pos {
  vertical-align: middle;
}

@media (min-width: 992px) {
  .table-standings--full .team-standings__pos {
    padding-left: 24px;
    width: 60px;
  }
}

.table-standings--full .team-standings__team {
  text-align: left;
}

@media (min-width: 1200px) {
  .table-standings--full .team-standings__team {
    width: 170px;
  }
}

.table-standings--full .team-standings__win,
.table-standings--full .team-standings__lose,
.table-standings--full .team-standings__gb {
  color: #31404b;
}

.table-standings--full .team-standings__win,
.table-standings--full .team-standings__lose,
.table-standings--full .team-standings__pct,
.table-standings--full .team-standings__gb,
.table-standings--full .team-standings__home,
.table-standings--full .team-standings__road,
.table-standings--full .team-standings__div,
.table-standings--full .team-standings__ppg,
.table-standings--full .team-standings__op-ppg,
.table-standings--full .team-standings__diff,
.table-standings--full .team-standings__strk,
.table-standings--full .team-standings__lead {
  text-align: center;
  vertical-align: middle;
}

@media (min-width: 1200px) {
  .table-standings--full .team-standings__home,
  .table-standings--full .team-standings__road,
  .table-standings--full .team-standings__div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .table-standings--full .team-standings__lead {
    padding-right: 30px;
  }
}

/* Table Standings: Soccer */
.table-standings--full-soccer .team-standings__played,
.table-standings--full-soccer .team-standings__drawn,
.table-standings--full-soccer .team-standings__goals-for,
.table-standings--full-soccer .team-standings__goals-against,
.table-standings--full-soccer .team-standings__goals-diff,
.table-standings--full-soccer .team-standings__total-points,
.table-standings--full-soccer .team-standings__points-diff {
  text-align: center;
  vertical-align: middle;
}

.table-standings--full-soccer .team-standings__drawn,
.table-standings--full-soccer .team-standings__total-points {
  color: #31404b;
}

@media (min-width: 1200px) {
  .table-standings--full-soccer .team-standings__points-diff {
    padding-right: 30px;
  }
}

/* Team Glossary */
.glossary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.glossary__item {
  flex-basis: 25%;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2em;
  padding: 8px 0;
  font-size: 11px;
}

.glossary__abbr {
  font-weight: 700;
  color: #31404b;
  text-transform: uppercase;
}

/* Team Results */
.team-result > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}

.team-result > thead > tr > th:first-child {
  text-align: left;
  padding-left: 24px;
}

.team-result > thead > tr > th:last-child {
  padding-right: 24px;
}

.team-result > tbody > tr > td {
  vertical-align: middle;
  color: #31404b;
  color: #9a9da2;
  text-align: center;
}

.team-result > tbody > tr > td:first-child {
  padding-left: 24px;
}

.team-result > tbody > tr > td:last-child {
  padding-right: 24px;
}

.team-result .team-result__date,
.team-result .team-result__vs {
  text-align: left;
}

.team-result__game {
  color: #31404b;
}

/* Team Result Filter */
.team-result-filter {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 1200px) {
  .team-result-filter {
    float: right;
  }
}

.team-result-filter__item {
  padding-left: 22px;
  float: left;
}

@media (max-width: 479px) {
  .team-result-filter__item {
    padding: 5px 0 5px 0;
  }
}

.team-result-filter__item select.form-control {
  border: none;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  height: 24px !important;
  padding: 4px 18px 4px 10px;
  display: inline-block;
  vertical-align: top;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%2331404b' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-size: 6px 4px;
  color: #31404b;
}

@media (max-width: 479px) {
  .team-result-filter__item:last-child {
    float: none;
    clear: both;
  }
}

.team-result {
  /* Team Results - Soccer */
}

@media (min-width: 1200px) {
  .team-result .team-result__game-overview {
    padding-right: 23px;
  }
}

.team-result .team-result__ball-posession,
.team-result .team-result__shots,
.team-result .team-result__fouls,
.team-result .team-result__game-overview {
  text-align: center;
  vertical-align: middle;
}

.team-result__teams-wrapper {
  display: flex;
  align-items: center;
}

@media (min-width: 1199px) {
  .team-result__teams-wrapper {
    padding-right: 30px;
  }
}

.team-result__teams-wrapper .team-result__teams-score {
  padding: 0 14px;
  margin: 0 25px;
  color: #31404b;
  font-weight: 700;
}

.team-result__teams-wrapper .team-result__teams-score .team-result__score {
  display: inline-block;
  color: #9a9da2;
  position: relative;
}

.team-result__teams-wrapper .team-result__teams-score .team-result__score::before {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  transform: translateY(-50%);
}

.team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win {
  color: #31404b;
}

.team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win::before {
  display: block;
}

.team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win:first-of-type::before {
  border-left: 4px solid #38a9ff;
  left: -10px;
}

@media (min-width: 992px) {
  .team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win:first-of-type::before {
    left: -10px;
  }
}

@media (min-width: 1199px) {
  .team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win:first-of-type::before {
    left: -14px;
  }
}

.team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win:last-of-type::before {
  border-right: 4px solid #38a9ff;
  right: -10px;
}

@media (min-width: 992px) {
  .team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win:last-of-type::before {
    right: -10px;
  }
}

@media (min-width: 1199px) {
  .team-result__teams-wrapper .team-result__teams-score .team-result__score.team-result__score--win:last-of-type::before {
    right: -14px;
  }
}

.team-result__teams-wrapper .team-meta {
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
}

.team-result__teams-wrapper .team-meta--second .team-meta__logo {
  order: 2;
}

.team-result__teams-wrapper .team-meta--second .team-meta__info {
  order: 1;
  text-align: right;
}

.team-result__teams-wrapper .team-meta--second .team-meta__logo {
  margin-right: 0;
  margin-left: 5px;
}

.team-result__teams-wrapper .team-meta__info {
  flex: 1;
}

.team-result__teams-wrapper .team-meta__logo {
  margin-right: 5px;
}

/* Team Schedule & Tickets */
.team-schedule > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}

.team-schedule > thead > tr > th:first-child {
  text-align: left;
  padding-left: 24px;
}

.team-schedule > thead > tr > th:last-child {
  padding-right: 24px;
}

.team-schedule > tbody > tr > td {
  vertical-align: middle;
  text-align: center;
}

.team-schedule > tbody > tr > td:first-child {
  padding-left: 24px;
}

.team-schedule > tbody > tr > td:last-child {
  padding-right: 24px;
}

.team-schedule .team-schedule__date,
.team-schedule .team-schedule__versus,
.team-schedule .team-schedule__compet,
.team-schedule .team-schedule__venue {
  text-align: left;
}

.team-schedule td.team-schedule__prize {
  color: #38a9ff;
}

/* Player Heading */
.player-heading {
  background-color: #1e2024;
  background-image: url("../images/soccer/page-heading.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
}

.player-heading::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 24px;
  background-image: linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px), linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px), linear-gradient(to right, rgba(0, 0, 0, 0.1) 4px, transparent 4px), linear-gradient(to right, rgba(0, 0, 0, 0.11) 4px, transparent 4px);
  background-repeat: repeat-x;
  background-size: 36px 12px, 36px 15px, 36px 17px, 36px 20px, 36px 18px, 36px 15px, 72px 15px, 108px 18px;
  background-position: 0 100%, 6px 100%, 12px 100%, 18px 100%, 24px 100%, 30px 100%, 6px 100%, 24px 100%;
}

.player-heading::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-image: url("../images/page-heading-pattern.png");
  background-size: auto;
  background-position: 100% 0;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.player-heading .container {
  position: relative;
  z-index: 2;
}

.player-info__team-logo {
  position: absolute;
  left: -45%;
  top: -5%;
  opacity: .05;
  z-index: -1;
}

@media (min-width: 992px) {
  .player-info__team-logo {
    left: -10%;
    opacity: .1;
  }
}

.player-info {
  color: #fff;
  position: relative;
  width: 100%;
}

@media (min-width: 992px) {
  .player-info {
    display: flex;
  }
}

@media (max-width: 991px) {
  .player-info__item--photo img {
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .player-info__item--photo {
    flex-basis: 33%;
    width: 312px;
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .player-info__item--photo {
    width: 400px;
  }
}

.player-info__item--details {
  width: 400px;
}

@media (min-width: 992px) {
  .player-info__item--details {
    flex-basis: 38%;
    padding: 48px 0;
    width: 352px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-info__item--details {
    flex-basis: 45%;
    padding: 25px 0;
  }
}

@media (min-width: 1200px) {
  .player-info__item--details {
    width: 460px;
  }
}

.player-info__title {
  margin-bottom: 17px;
}

.player-info__title--mobile {
  padding: 35px 0 0 0;
  text-align: center;
  display: none;
}

@media (max-width: 991px) {
  .player-info__title--mobile {
    display: block;
  }
}

.player-info__title--mobile .player-info__first-name {
  text-align: left;
}

.player-info__title--desktop {
  display: none;
}

@media (min-width: 992px) {
  .player-info__title--desktop {
    display: block;
  }
}

.player-info__number {
  font-size: 54px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  line-height: 1em;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 992px) {
  .player-info__number {
    font-size: 64px;
    margin-left: -80px;
  }
}

@media (min-width: 1200px) {
  .player-info__number {
    font-size: 92px;
    margin-left: -123px;
    margin-right: 5px;
  }
}

.player-info__name {
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  line-height: 1em;
  color: #fff;
  margin: 0;
}

@media (min-width: 992px) {
  .player-info__name {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .player-info__name {
    font-size: 54px;
    line-height: 46px;
  }
}

.player-info__first-name {
  display: block;
  font-size: 16px;
  line-height: 1em;
}

@media (min-width: 992px) {
  .player-info__first-name {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .player-info__first-name {
    font-size: 26px;
  }
}

.player-info__last-name {
  color: #c2ff1f;
}

.player-info-details {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .player-info-details {
    padding: 0 17px;
  }
}

.player-info-details__item {
  padding: 10px 0;
}

.player-info-details__item--height {
  flex-basis: 33%;
}

@media (min-width: 992px) {
  .player-info-details__item--height {
    flex-basis: 19%;
  }
}

.player-info-details__item--weight {
  flex-basis: 33%;
}

@media (min-width: 992px) {
  .player-info-details__item--weight {
    flex-basis: 24%;
  }
}

.player-info-details__item--age {
  flex-basis: 33%;
}

@media (min-width: 992px) {
  .player-info-details__item--age {
    flex-basis: 15%;
  }
}

.player-info-details__item--college {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .player-info-details__item--college {
    flex-basis: 42%;
  }
}

.player-info-details__item--born {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .player-info-details__item--born {
    flex-basis: 58%;
  }
}

.player-info-details__item--position {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .player-info-details__item--position {
    flex-basis: 42%;
  }
}

.player-info-details__item--position {
  flex-basis: 33%;
}

@media (min-width: 992px) {
  .player-info-details__item--position {
    flex-basis: 22%;
  }
}

.player-info-details__item--foot {
  flex-basis: 50%;
}

@media (min-width: 992px) {
  .player-info-details__item--foot {
    flex-basis: 20%;
  }
}

.player-info-details__item--goals {
  flex-basis: 50%;
}

@media (min-width: 992px) {
  .player-info-details__item--goals {
    flex-basis: 50%;
  }
}

.player-info-details__item--assists {
  flex-basis: 50%;
}

@media (min-width: 992px) {
  .player-info-details__item--assists {
    flex-basis: 50%;
  }
}

.player-info-details__item--games {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .player-info-details__item--games {
    flex-basis: 100%;
  }
}

.player-info-details__title {
  font-size: 10px;
  color: #9ed5ff;
  line-height: 1.2em;
  margin-bottom: 0.1em;
  font-weight: 400;
}

.player-info-details__value {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.2em;
  font-weight: 700;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-info-details__value {
    font-size: 12px;
  }
}

.player-info-stats {
  overflow: hidden;
}

@media (min-width: 992px) {
  .player-info-stats {
    padding: 25px 0 0 0;
  }
}

.player-info-stats__item {
  float: left;
  width: 33.3%;
}

.player-info-stats__item .circular .circular__bar {
  width: 62px;
  height: 62px;
}

@media (min-width: 992px) {
  .player-info-stats__item .circular .circular__bar {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px 0 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-info-stats__item .circular .circular__bar {
    width: 44px;
    height: 44px;
  }
}

.player-info-stats__item .circular .circular__bar .circular__percents {
  color: #fff;
  font-size: 15px;
}

.player-info-stats__item .circular .circular__bar .circular__percents small {
  display: block;
  font-size: 8px;
  line-height: 1.2em;
  color: #9ed5ff;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-info-stats__item .circular .circular__bar .circular__percents {
    font-size: 12px;
  }
}

.player-info-stats__item .circular .circular__label {
  color: #9ed5ff;
}

@media (min-width: 992px) {
  .player-info-stats__item .circular .circular__label {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin: 0;
  }
}

.player-info-stats__item .circular .circular__label strong {
  color: #fff;
  display: block;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-info-stats__item .circular .circular__label {
    font-size: 9px;
  }
}

@media (min-width: 992px) {
  .player-info-stats__item--top-padding {
    padding: 25px 0 0 0;
  }
}

@media (min-width: 992px) {
  .player-info__item--stats {
    flex-basis: 29%;
    width: 297px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-info__item--stats {
    flex-basis: 36%;
  }
}

@media (min-width: 1200px) {
  .player-info__item--stats {
    width: 310px;
  }
}

@media (max-width: 991px) {
  .player-info__item--stats {
    height: 200px !important;
  }
  .player-info__item--stats .player-info-chart {
    width: 100%;
    height: auto;
  }
}

.player-info__item--stats .progress-stats {
  margin-bottom: 20px;
}

.player-info__item--stats .progress__label {
  color: #fff;
}

.player-info__item--stats .progress:not(.progress--battery) {
  background-color: rgba(255, 255, 255, 0.2);
}

.player-info__item--stats-inner {
  padding-top: 30px;
}

@media (min-width: 992px) {
  .player-info__item--stats-inner {
    padding-top: 40px;
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .player-info__item--stats-inner {
    padding-top: 70px;
    max-width: 100%;
  }
}

.player-info-chart {
  margin: 35px 0 0 0;
}

@media (min-width: 992px) {
  .player-info-chart {
    margin: 30px 0 0 0;
  }
}

@media (min-width: 1200px) {
  .player-info-chart {
    margin: 60px 0 0 0;
  }
}

/* Player Game Log */
.game-player-result thead > tr > th,
.game-player-result tbody > tr > td {
  vertical-align: middle;
  text-align: center;
}

.game-player-result thead > tr > th.game-player-result__vs,
.game-player-result tbody > tr > td.game-player-result__vs {
  text-align: left;
}

.game-player-result .game-player-result__date {
  text-align: left;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .game-player-result .game-player-result__date {
    padding-left: 23px;
  }
}

@media (min-width: 1200px) {
  .game-player-result thead > tr > th:last-child,
  .game-player-result tbody > tr > td:last-child {
    padding-right: 23px;
  }
}

.game-player-result .game-player-result__game {
  color: #31404b;
}

.game-player-result .game-player-result__game--win {
  color: #24d9b0;
}

.game-player-result .game-player-result__game--lose {
  color: #f34141;
}

/* Player Season Average */
.player-season-avg thead > tr > th,
.player-season-avg tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}

.player-season-avg thead > tr > th.player-season-avg__season,
.player-season-avg tbody > tr > td.player-season-avg__season {
  text-align: left;
}

@media (min-width: 1200px) {
  .player-season-avg thead > tr > th:first-child,
  .player-season-avg tbody > tr > td:first-child {
    padding-left: 23px;
  }
}

@media (min-width: 1200px) {
  .player-season-avg thead > tr > th:last-child,
  .player-season-avg tbody > tr > td:last-child {
    padding-right: 23px;
  }
}

/* Player General Stats */
.player-general-stats__card-content {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-general-stats__card-content {
    padding: 16px 16px;
  }
}

.player-general-stats__icon {
  margin-right: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-general-stats__icon {
    margin-right: 10px;
  }
}

.player-general-stats__body {
  flex: 1;
  display: flex;
  align-items: center;
}

.player-general-stats__value {
  font-size: 36px;
  line-height: 1.2em;
  font-style: normal;
  margin-bottom: 0;
  margin-right: .45em;
}

@media (min-width: 576px) and (max-width: 767px) {
  .player-general-stats__value {
    font-size: 26px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .player-general-stats__value {
    font-size: 24px;
  }
}

.player-general-stats__meta {
  flex: 1;
}

.player-general-stats__label {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.2em;
}

.player-general-stats__sublabel {
  font-size: 9px;
  line-height: 1.2em;
}

/* Event: Table Results */
.alc-event-results-table--fullwidth .alc-event-results-cell__player {
  width: 1px;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .alc-event-results-table--fullwidth .alc-event-results-cell__player {
    padding-right: 50px !important;
  }
}

/* Event: Tournament Brackets */
.tournament-bracket {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .tournament-bracket {
    flex-direction: row;
  }
}

.tournament-bracket__round {
  display: block;
  margin-left: -2px;
  flex: 1;
}

.tournament-bracket__round-title {
  text-align: center;
  padding-top: .75em;
  margin-bottom: .5em;
  text-transform: uppercase;
}

.tournament-bracket__list {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  padding: 0 0 1em 0;
  margin: 0 0 1em 0;
  transition: padding 0.2s ease-in-out, margin 0.2s ease-in-out;
}

@media (min-width: 992px) {
  .tournament-bracket__list {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.tournament-bracket__round:last-child .tournament-bracket__list {
  border: 0;
}

.tournament-bracket__item {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2% 0;
  width: 48%;
  transition: padding 0.2s linear;
}

.tournament-bracket__item:nth-child(odd) {
  margin-right: 2%;
}

.tournament-bracket__item:nth-child(even) {
  margin-left: 2%;
}

.tournament-bracket__item::after {
  transition: width 0.2s linear;
}

@media (max-width: 768px) {
  .tournament-bracket__item {
    width: 100%;
  }
  .tournament-bracket__item:nth-child(odd), .tournament-bracket__item:nth-child(even) {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .tournament-bracket__item {
    padding: 0.5em 1em;
    width: 100%;
  }
  .tournament-bracket__item:nth-child(odd), .tournament-bracket__item:nth-child(even) {
    margin: 0;
  }
  .tournament-bracket__item::after {
    position: absolute;
    right: 0;
    content: '';
    display: block;
    width: 1em;
    height: 45%;
    border-right: 2px solid #fff;
  }
  .tournament-bracket__item:nth-child(odd)::after {
    top: 50%;
    border-top: 2px solid #fff;
    transform: translateY(-1px);
  }
  .tournament-bracket__item:nth-child(even)::after {
    bottom: 50%;
    border-bottom: 2px solid #fff;
    transform: translateY(1px);
  }
  .tournament-bracket__round:first-child .tournament-bracket__item {
    padding-left: 0;
  }
  .tournament-bracket__round:last-child .tournament-bracket__item {
    padding-right: 0;
  }
  .tournament-bracket__round:last-child .tournament-bracket__item::after {
    display: none;
  }
  .tournament-bracket__round:nth-last-child(2) .tournament-bracket__item::after {
    border-radius: 0;
    border-right: 0;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__item {
    padding: 0.5em 1.5em;
  }
  .tournament-bracket__item::after {
    width: 1em;
  }
}

.tournament-bracket__match {
  display: flex;
  width: 100%;
  outline: none;
}

.tournament-bracket__match::before, .tournament-bracket__match::after {
  transition: all 0.2s linear;
}

@media (max-width: 767px) {
  .tournament-bracket__match {
    padding: 0.75em 0.5em;
  }
}

@media (min-width: 992px) {
  .tournament-bracket__match::before, .tournament-bracket__match::after {
    position: absolute;
    left: 0;
    z-index: 1;
    content: '';
    display: block;
    width: 1em;
    height: 10%;
    border-left: 2px solid #fff;
  }
  .tournament-bracket__match::before {
    bottom: 50%;
    border-bottom: 2px solid #fff;
    transform: translate(0, 1px);
  }
  .tournament-bracket__match::after {
    top: 50%;
    border-top: 2px solid #fff;
    transform: translate(0, -1px);
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__match::before, .tournament-bracket__match::after {
    width: 1em;
  }
  .tournament-bracket__match::before {
    transform: translate(0, 1px);
  }
  .tournament-bracket__match::after {
    transform: translate(0, -1px);
  }
}

.tournament-bracket__round:last-child .tournament-bracket__match::before, .tournament-bracket__round:last-child .tournament-bracket__match::after {
  border-left: 0;
}

.tournament-bracket__round:last-child .tournament-bracket__match::before {
  border-bottom-left-radius: 0;
}

.tournament-bracket__round:last-child .tournament-bracket__match::after {
  display: none;
}

.tournament-bracket__round:first-child .tournament-bracket__match::before, .tournament-bracket__round:first-child .tournament-bracket__match::after {
  display: none;
}

.tournament-bracket__content {
  display: flex;
  flex-wrap: wrap;
}

.tournament-bracket__content .tournament-bracket__team:first-child {
  width: 100%;
  order: 0;
}

.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__score {
  order: 0;
}

.tournament-bracket__content .tournament-bracket__team:last-child {
  width: 100%;
  order: 2;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tournament-bracket__content .tournament-bracket__team:last-child {
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__inner {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__inner {
    align-items: flex-start;
  }
}

.tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__code {
  order: 1;
}

.tournament-bracket__table {
  width: 100%;
}

.tournament-bracket__team {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 0;
  min-height: 56px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tournament-bracket__team {
    padding: 10px;
  }
}

@media (min-width: 1199px) {
  .tournament-bracket__team {
    padding: 13px;
  }
}

.tournament-bracket__team--winner,
.tournament-bracket__team--champ {
  background-color: #fff;
}

.tournament-bracket__team--champ {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}

.tournament-bracket__inner {
  display: flex;
  align-items: center;
  max-width: 70%;
  min-width: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tournament-bracket__inner .tournament-bracket__code {
    margin-top: 0.2em;
  }
}

.tournament-bracket__team-thumb {
  width: 28px;
  text-align: center;
  margin-right: 12px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tournament-bracket__team-thumb {
    margin-right: 5px;
  }
}

.tournament-bracket__team-name {
  font-size: 11px;
  line-height: 1.2em;
  margin-bottom: .1em;
}

.tournament-bracket__team-desc {
  display: block;
  font-size: 9px;
  line-height: 1.2em;
}

.tournament-bracket__score {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.tournament-bracket__number {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 4px;
  padding: 0 4px;
  background-color: #edeff4;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  border: 2px solid transparent;
  margin-left: auto;
}

.tournament-bracket__team--winner .tournament-bracket__number {
  border-color: #38a9ff;
  color: #31404b;
}

.tournament-bracket__champ-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border-width: 4px;
  border-color: #38a9ff;
}

.tournament-bracket__champ-icon .df-icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.25);
}

.tournament-bracket__medal {
  padding: 0 0.5em;
}

.tournament-bracket__medal--gold {
  color: #FFD700;
}

.tournament-bracket__medal--silver {
  color: #C0C0C0;
}

.tournament-bracket__medal--bronze {
  color: #CD7F32;
}

/* Staff */
@media (min-width: 992px) {
  .alc-staff-inner {
    padding: 0 0 0 18px;
  }
}

.alc-staff__photo {
  text-align: center;
}

@media (min-width: 768px) {
  .alc-staff__photo {
    text-align: left;
  }
}

.alc-staff__header {
  margin-bottom: 24px;
  padding: 25px 0 0 0;
}

.alc-staff__header-name {
  font-size: 26px;
  line-height: 1em;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .alc-staff__header-name {
    font-size: 14px;
  }
}

.alc-staff__header-last-name {
  display: block;
  font-size: 46px;
  line-height: 1em;
  color: #38a9ff;
}

@media (max-width: 479px) {
  .alc-staff__header-last-name {
    font-size: 1.5em;
  }
}

.alc-staff__header-role {
  font-size: 11px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-weight: 400;
}

.alc-staff-excerpt {
  margin-bottom: 46px;
}

.alc-staff-details {
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.5em;
}

.alc-staff-details__label {
  min-width: 40%;
  flex-basis: 40%;
  text-align: left;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: bold;
}

.alc-staff-details__value {
  min-width: 60%;
  flex-basis: 60%;
  text-align: right;
  font-size: 11px;
  color: #31404b;
  text-transform: none;
  margin-bottom: 20px;
}

.alc-staff-details__value img {
  margin-right: 0.5em;
}

/* Checkout Review Order */
.df-checkout-review-order-table .product-name {
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .df-checkout-review-order-table .product-name {
    padding-left: 23px;
  }
}

.df-checkout-review-order-table .product-total {
  width: 22%;
  text-align: right;
}

@media (min-width: 1200px) {
  .df-checkout-review-order-table .product-total {
    padding-right: 23px;
  }
}

.df-checkout-review-order-table .product-header {
  text-align: center;
  color: #9a9da2;
  font-style: normal;
  border-bottom: 1px solid #e4e7ed;
  border-top: 1px solid #e4e7ed;
  padding: 11px !important;
  font-weight: 400;
  font-size: 10px;
}

.df-checkout-review-order-table tbody .product-name {
  text-transform: uppercase;
  color: #31404b;
  font-weight: 700;
}

.df-checkout-review-order-table .cart_item:first-child .product-name,
.df-checkout-review-order-table .cart_item:first-child .product-total {
  padding-top: 30px;
}

.df-checkout-review-order-table .cart_item:last-child .product-name,
.df-checkout-review-order-table .cart_item:last-child .product-total {
  padding-bottom: 30px;
}

.df-checkout-review-order-table .cart_item .product-name,
.df-checkout-review-order-table .cart_item .product-total {
  border-top: none;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.df-checkout-review-order-table tfoot tr th {
  text-transform: uppercase;
  color: #31404b;
  font-weight: 700;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .df-checkout-review-order-table tfoot tr th {
    padding-left: 23px;
  }
}

.df-checkout-review-order-table tfoot tr th,
.df-checkout-review-order-table tfoot tr td {
  border-top: none;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: none;
}

.df-checkout-review-order-table tfoot tr td:last-child {
  width: 22%;
  text-align: right;
}

@media (min-width: 1200px) {
  .df-checkout-review-order-table tfoot tr td:last-child {
    padding-right: 23px;
  }
}

.df-checkout-review-order-table tfoot tr:first-child th,
.df-checkout-review-order-table tfoot tr:first-child td {
  border-top: 1px solid #e4e7ed;
  padding-top: 30px;
}

.df-checkout-review-order-table tfoot tr:nth-last-child(2) th,
.df-checkout-review-order-table tfoot tr:nth-last-child(2) td {
  padding-bottom: 30px;
}

.df-checkout-review-order-table tfoot tr.order-total th,
.df-checkout-review-order-table tfoot tr.order-total td {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
  margin-top: 20px;
}

.df-checkout-review-order-table tfoot tr.order-total .amount {
  color: #31404b;
}

.df-checkout-review-order-table tfoot.bordered > tr th,
.df-checkout-review-order-table tfoot.bordered > tr td {
  border-bottom: 1px solid #e4e7ed;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Checkout Payment */
.df-checkout-payment .place-order {
  padding: 24px 24px;
  border-top: 1px solid #e4e7ed;
}

.df_payment_methods-header {
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid #e4e7ed;
}

.df_payment_methods-title {
  font-size: 10px;
  line-height: 1.2em;
  font-weight: 400;
  font-style: normal;
  color: #9a9da2;
  margin: 0;
  padding: 0;
}

.df_payment_methods {
  list-style: none;
  padding: 24px 24px;
  margin: 0;
}

.df_payment_method {
  padding: 0;
  margin: 0 0 20px 0;
  box-shadow: none;
  background-color: transparent;
}

.df_payment_method:last-child {
  margin-bottom: 0;
}

.df_payment_method label {
  font-weight: bold;
}

.df_payment_method .payment_box {
  font-size: 14px;
  line-height: 22px;
  padding: 13px 0 0 24px;
}

.df_payment_method .payment_box p:last-child {
  margin-bottom: 0;
}

.df_payment_methods--bordered {
  padding: 0;
}

.df_payment_methods--bordered .df_payment_method {
  border-bottom: 1px solid #e4e7ed;
  padding: 18px 24px;
  margin: 0;
}

.df_payment_methods--bordered .df_payment_method .payment_box {
  padding-left: 0;
}

/* Account Navigation */
.df-account-navigation {
  border-radius: 4px;
  overflow: hidden;
}

.card .df-account-navigation {
  margin-top: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  margin-left: -24px;
}

.df-account-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

.card__header + .card__content .df-account-navigation {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.df-account-navigation__link > a {
  display: block;
  padding: 24px 24px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
  transition: background-color 0.3s ease;
}

.df-account-navigation__link > a:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.df-account-navigation__link > a > small {
  font-size: 8px;
  line-height: 1.5em;
  display: block;
  color: #9a9da2;
}

.df-account-navigation__link > a > span {
  color: #38a9ff;
}

.df-account-navigation__link + li {
  border-top: 1px solid #e4e7ed;
}

.df-account-navigation__link:last-child > a {
  border-radius: 0 0 4px 4px;
}

.df-account-navigation__link--active a,
.df-account-navigation .active {
  border-left: 6px solid #38a9ff;
  padding-left: 18px;
  background-color: #fafafb;
}

/* Shop Table */
.shop-table tbody > tr > td {
  vertical-align: middle;
}

@media (min-width: 992px) {
  .shop-table tbody > tr > td:first-child {
    padding-left: 23px;
  }
}

@media (min-width: 992px) {
  .shop-table thead > tr > th:first-child {
    padding-left: 23px;
  }
}

.shop-table td.product__photo,
.shop-table td.product__info,
.shop-table td.product__desc,
.shop-table td.product__price,
.shop-table td.product__availability,
.shop-table td.product__remove,
.shop-table td.product__size,
.shop-table td.product__color,
.shop-table td.product__quantity,
.shop-table td.product__total {
  font-size: 13px;
}

@media (min-width: 992px) {
  .shop-table td.product__photo,
  .shop-table td.product__info,
  .shop-table td.product__desc,
  .shop-table td.product__price,
  .shop-table td.product__availability,
  .shop-table td.product__remove,
  .shop-table td.product__size,
  .shop-table td.product__color,
  .shop-table td.product__quantity,
  .shop-table td.product__total {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.shop-table .product__price,
.shop-table .product__availability,
.shop-table .product__remove,
.shop-table .product__size,
.shop-table .product__color,
.shop-table .product__quantity,
.shop-table .product__total {
  text-align: center;
}

.shop-table .product__price,
.shop-table .product__availability,
.shop-table .product__size {
  color: #31404b;
}

.shop-table .product__info .product__cat {
  font-size: 8px;
  text-transform: uppercase;
  line-height: 1.2em;
  font-weight: 400;
}

.shop-table .product__info .product__cat + .product__name {
  padding: 0.2em 0 0.5em 0;
}

.shop-table .product__info .product__name {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: 0;
  text-transform: uppercase;
  font-style: normal;
  font-size: 12px;
}

@media (min-width: 992px) {
  .shop-table .product__info .product__name {
    font-size: 13px;
    font-size: 13px;
  }
}

.shop-table .product__info .product__name > a {
  color: #31404b;
  transition: color 0.3s ease;
}

.shop-table .product__info .product__name > a:hover {
  color: #1892ed;
}

.shop-table .product__info .product__ratings .fa-star {
  font-size: 10px;
  color: #ffdc11;
}

.shop-table .product__info .product__ratings .fa-star.empty {
  color: #dbdfe6;
}

.shop-table td.product__price {
  font-weight: 700;
}

.shop-table td.product__availability {
  font-weight: 700;
  text-transform: uppercase;
}

.shop-table .product__no-available {
  opacity: .6;
}

.shop-table td.product__size {
  font-weight: 700;
}

.shop-table td.product__total {
  font-weight: 700;
  color: #31404b;
}

@media (min-width: 992px) {
  .shop-table .product__remove {
    padding-right: 23px;
  }
}

.shop-table .product__remove-icon {
  color: #31404b;
  opacity: .6;
  transition: opacity 0.3s ease;
}

.shop-table .product__remove-icon:hover {
  opacity: 1;
}

.shop-table td.product__desc {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

@media (min-width: 768px) {
  .shop-table td.product__desc {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: 992px) {
  .shop-table td.product__photo {
    width: 10%;
  }
}

@media (min-width: 992px) {
  .shop-table td.product__info {
    width: 21%;
  }
}

@media (min-width: 992px) {
  .shop-table td.product__desc {
    width: 33%;
  }
}

@media (min-width: 992px) {
  .shop-table td.product__availability {
    width: 12%;
  }
}

.shop-table .product__thumb {
  overflow: hidden;
  border-radius: 0;
}

.shop-table .product__color-item {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #31404b;
  border-radius: 50%;
  position: relative;
}

.shop-table .product__color-item::before {
  content: "";
  display: block;
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
}

.shop-table .product__color-item--selected::before {
  border: 2px solid #31404b;
}

.shop-table .product__color-item--color-orange {
  background-color: #ff860f;
}

.shop-table .product__color-item--color-orange::before {
  border-color: #ff860f;
}

.shop-table .product__color-item--color-blue {
  background-color: #1e81f1;
}

.shop-table .product__color-item--color-blue::before {
  border-color: #1e81f1;
}

.shop-table .product__color-item--color-aqua {
  background-color: #1af1e4;
}

.shop-table .product__color-item--color-aqua::before {
  border-color: #1af1e4;
}

.shop-table .product__color-item--color-black {
  background-color: #383838;
}

.shop-table .product__color-item--color-black::before {
  border-color: #383838;
}

@media (min-width: 1200px) {
  .shipping-details-row .card {
    min-height: 380px;
  }
  .shipping-details-row .card .coupon-code-form .form-group--submit {
    padding-top: 70px;
  }
}

.shipping-details-row .df-checkout-payment .place-order {
  border-top: none;
}

.shipping-details-row .df-checkout-review-order-table tfoot tr:first-child th,
.shipping-details-row .df-checkout-review-order-table tfoot tr:first-child td {
  border-top: none;
}

.df-checkout-payment .place-order {
  border-top: none;
}

.coupon-code-form-inner {
  display: flex;
  align-items: center;
}

.coupon-code-form-inner label {
  white-space: nowrap;
  padding-right: 15px;
  margin-bottom: 0;
}

/* Shop Banner */
.shop-banner {
  border: 1px solid #e4e7ed;
  background-image: linear-gradient(to left top, #140078, #4dcbff);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .shop-banner {
    min-height: 260px;
  }
}

@media (min-width: 1200px) {
  .shop-banner {
    min-height: 320px;
  }
}

.shop-banner__content {
  position: relative;
  z-index: 2;
  padding: 30px 40px;
  color: #fff;
  text-align: center;
}

@media (min-width: 992px) {
  .shop-banner__content {
    width: 320px;
    padding: 32px 0 30px 40px;
  }
}

@media (min-width: 1200px) {
  .shop-banner__content {
    width: 395px;
    padding: 40px 0 40px 55px;
  }
}

.shop-banner__txt1 {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  line-height: 1.2em;
  margin-bottom: .75em;
}

@media (min-width: 992px) {
  .shop-banner__txt1 {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .shop-banner__txt1 {
    font-size: 14px;
  }
}

.shop-banner__title {
  font-size: 32px;
  line-height: 1.13em;
  color: #fff;
  border: 3px solid #fff;
  margin-bottom: .175em;
  padding: 0 .1em;
  display: inline-block;
}

@media (min-width: 992px) {
  .shop-banner__title {
    font-size: 48px;
    border-width: 4px;
  }
}

@media (min-width: 1200px) {
  .shop-banner__title {
    font-size: 64px;
    border-width: 6px;
  }
}

.shop-banner__subtitle {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  letter-spacing: .5em;
  line-height: 1.2em;
  margin-bottom: .75em;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .shop-banner__subtitle {
    font-size: 14px;
    letter-spacing: .5em;
  }
}

@media (min-width: 1200px) {
  .shop-banner__subtitle {
    font-size: 16px;
    letter-spacing: .85em;
  }
}

.shop-banner__subtitle::before, .shop-banner__subtitle::after {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-size: 14px;
  color: #38a9ff;
  font-weight: 900;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shop-banner__subtitle::before {
  margin-right: 1.25em;
}

.shop-banner__subtitle::after {
  margin-left: 0.75em;
}

.shop-banner__btn {
  margin-top: 1.85em;
}

@media (max-width: 991px) {
  .shop-banner__btn {
    font-size: 12px;
    padding: 10px 30px;
    margin-top: 1em;
  }
}

@media (min-width: 1200px) {
  .shop-banner__btn {
    margin-top: 1.85em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .shop-banner__btn {
    font-size: 12px;
    padding: 12px 40px;
    margin-top: 1em;
  }
}

.shop-banner__img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .shop-banner__img {
    max-width: 55%;
  }
}

.shop-banner__bg-txt {
  display: block;
  position: absolute;
  left: -30%;
  top: -17%;
  font-size: 230px;
  line-height: 1em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.03em;
  color: #fff;
  opacity: 0.06;
  pointer-events: none;
}

.shop-banner__discount {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  padding: 13px 11px 0 0;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.03em;
}

@media (max-width: 991px) {
  .shop-banner__discount {
    display: none;
  }
}

.shop-banner__discount::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 166px solid #4dcbff;
  border-left: 208px solid transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.shop-banner__discount-txt {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 14px;
  line-height: 1.2em;
}

.shop-banner__discount-price {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 48px;
  line-height: 1em;
}

/* Shop Filter */
.shop-filter {
  padding-left: 24px;
  display: flex;
  align-items: center;
  min-height: 72px;
}

@media (max-width: 991px) {
  .shop-filter {
    flex-wrap: wrap;
    padding-left: 0;
  }
}

.shop-filter__result {
  font-size: 11px;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 0;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .shop-filter__result {
    padding: 24px 24px 12px 24px;
  }
}

.shop-filter__params {
  list-style: none;
  padding: 0;
  margin: 0 20px 0 0;
}

@media (max-width: 991px) {
  .shop-filter__params {
    display: block;
    width: 100%;
    padding: 0 0 10px 14px;
    margin: 0;
    text-align: left;
  }
}

.shop-filter__control {
  padding-left: 14px;
  float: left;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .shop-filter__control {
    padding-left: 0;
  }
}

.shop-filter__control:first-child {
  padding-left: 0;
}

@media (max-width: 479px) {
  .shop-filter__control {
    padding: 5px 0 5px 0;
  }
}

.shop-filter__control select.form-control {
  border: none;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  height: 24px;
  padding: 4px 24px 4px 10px;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%2331404b' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-size: 6px 4px;
  background-position: right 50%;
  color: #31404b;
  white-space: nowrap;
}

.shop-filter__layout {
  height: 72px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .shop-filter__layout {
    display: block;
    flex-basis: 100%;
  }
}

.shop-filter__grid-layout,
.shop-filter__list-layout {
  float: left;
  width: 72px;
  height: 72px;
  border-left: 1px solid #e4e7ed;
}

@media (max-width: 992px) {
  .shop-filter__grid-layout,
  .shop-filter__list-layout {
    border-top: 1px solid #e4e7ed;
    width: 50%;
  }
}

/* Icon Grid Layout */
.icon-grid-layout {
  position: relative;
  display: block;
}

.icon-grid-layout__inner {
  display: block;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.icon-grid-layout__item {
  display: block;
  position: relative;
  width: 4px;
  height: 4px;
  font-size: 0;
  line-height: 0;
  background-color: #d7d8da;
  margin: 0 auto 3px auto;
  transition: all 0.5s ease;
}

.icon-grid-layout__item::before, .icon-grid-layout__item::after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #d7d8da;
  transition: all 0.5s ease;
}

.icon-grid-layout__item::before {
  left: -7px;
}

.icon-grid-layout__item::after {
  left: 7px;
}

.icon-grid-layout--active .icon-grid-layout__item {
  background-color: #38a9ff;
}

.icon-grid-layout--active .icon-grid-layout__item::before, .icon-grid-layout--active .icon-grid-layout__item::after {
  background-color: #38a9ff;
}

.icon-grid-layout:hover .icon-grid-layout__item {
  background-color: #38a9ff;
}

.icon-grid-layout:hover .icon-grid-layout__item::before, .icon-grid-layout:hover .icon-grid-layout__item::after {
  background-color: #38a9ff;
}

.icon-grid-layout:hover .icon-grid-layout__item:first-child {
  animation: icon-grid-layout-1 .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:first-child::before {
  animation: icon-grid-layout-1-before .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:first-child::after {
  animation: icon-grid-layout-1-after .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:nth-child(2)::before {
  animation: icon-grid-layout-2-before .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:nth-child(2)::after {
  animation: icon-grid-layout-2-after .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:nth-child(3) {
  animation: icon-grid-layout-3 .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:nth-child(3)::before {
  animation: icon-grid-layout-3-before .5s;
}

.icon-grid-layout:hover .icon-grid-layout__item:nth-child(3)::after {
  animation: icon-grid-layout-3-after .5s;
}

@keyframes icon-grid-layout-1 {
  50% {
    transform: translateY(-2px);
  }
}

@keyframes icon-grid-layout-1-before {
  50% {
    transform: translateX(-2px);
  }
}

@keyframes icon-grid-layout-1-after {
  50% {
    transform: translateX(2px);
  }
}

@keyframes icon-grid-layout-2-before {
  50% {
    transform: translateX(-2px);
  }
}

@keyframes icon-grid-layout-2-after {
  50% {
    transform: translateX(2px);
  }
}

@keyframes icon-grid-layout-3 {
  50% {
    transform: translateY(2px);
  }
}

@keyframes icon-grid-layout-3-before {
  50% {
    transform: translateX(-2px);
  }
}

@keyframes icon-grid-layout-3-after {
  50% {
    transform: translateX(2px);
  }
}

/* Icon List Layout */
.icon-list-layout {
  position: relative;
}

.icon-list-layout__inner {
  display: block;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.icon-list-layout__item {
  display: block;
  position: relative;
  width: 4px;
  height: 4px;
  font-size: 0;
  line-height: 0;
  background-color: #d7d8da;
  margin: 0 0 3px 0;
  transition: all 0.5s ease;
}

.icon-list-layout__item::before {
  content: "";
  display: block;
  position: absolute;
  width: 11px;
  height: 4px;
  background-color: #d7d8da;
  left: 7px;
  transition: all 0.5s ease-in-out;
}

.icon-list-layout--active .icon-list-layout__item {
  background-color: #38a9ff;
}

.icon-list-layout--active .icon-list-layout__item::before {
  background-color: #38a9ff;
}

.icon-list-layout:hover .icon-list-layout__item {
  animation: icon-list-layout 0.5s;
  background-color: #38a9ff;
}

.icon-list-layout:hover .icon-list-layout__item::before {
  background-color: #38a9ff;
  animation: icon-list-layout-before 0.5s;
}

.icon-list-layout:hover .icon-list-layout__item:nth-child(2) {
  animation-delay: 0.1s;
}

.icon-list-layout:hover .icon-list-layout__item:nth-child(2)::before {
  animation-delay: 0.1s;
}

.icon-list-layout:hover .icon-list-layout__item:nth-child(3) {
  animation-delay: 0.2s;
}

.icon-list-layout:hover .icon-list-layout__item:nth-child(3)::before {
  animation-delay: 0.2s;
}

@keyframes icon-list-layout {
  50% {
    transform: translateX(-3px);
  }
}

@keyframes icon-list-layout-before {
  50% {
    transform: translateX(6px);
  }
}

/* Products */
.products {
  list-style: none;
  margin: 0;
  padding: 0;
}

.product__item {
  overflow: hidden;
}

@media (min-width: 992px) {
  .product__content {
    padding: 35px 36px 30px 36px;
  }
}

.product__category {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: .75em;
}

.product__title {
  margin-bottom: 0;
  line-height: 1.2em;
  font-size: 16px;
}

@media (min-width: 992px) {
  .product__title {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .product__title {
    font-size: 22px;
  }
}

.product__title > a {
  color: #31404b;
  transition: color 0.3s ease;
}

.product__title > a:hover {
  color: #1892ed;
}

.product__ratings {
  font-size: 10px;
  line-height: 1.2em;
}

.product__title + .product__ratings {
  margin-top: 10px;
}

.product__ratings .fa {
  color: #ffdc11;
  margin-right: 3px;
}

.product__ratings .empty {
  color: #dbdfe6;
}

.product__price del {
  opacity: 0.4;
  font-size: 0.75em;
}

.product__price ins {
  text-decoration: none;
}

.product__excerpt {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .product__excerpt {
    margin-bottom: 46px;
  }
}

.product__excerpt--sm {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .product__excerpt--sm {
    margin-bottom: 26px;
  }
}

.product__footer .btn + .btn {
  margin-left: 7px;
}

.product__add-to-cart .icon-bag {
  font-size: 15px;
  line-height: 1.2em;
  vertical-align: middle;
}

.product__params {
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .product__params {
    margin-bottom: 56px;
  }
}

.product__params h6 {
  font-size: 11px;
  line-height: 1.2em;
  margin-bottom: 1em;
}

.product__param-item--color {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .product__param-item--color {
    width: 40%;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .product__param-item--color-lg {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .product__param-item--size {
    width: 25%;
    padding-right: 7%;
  }
}

@media (min-width: 1200px) {
  .product__param-item--size-lg {
    width: 75%;
    padding-right: 7%;
  }
}

@media (min-width: 1200px) {
  .product__param-item--quantity {
    width: 18%;
  }
}

.product__params--alt {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .product__params--alt {
    margin-bottom: 46px;
  }
}

.product__params--alt .product__param-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.product__params--alt .product__param-item label {
  margin: 0 20px 0 0;
  white-space: nowrap;
}

.product__params--alt .product__param-item .filter-color__item {
  margin-bottom: 4px;
}

.product__params--alt .product__param-item .form-control {
  margin-right: 30px;
}

.product__params--alt .product__param-item--color {
  width: 100%;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .product__params--alt .product__param-item--color {
    width: 100%;
    margin-bottom: 20px;
  }
}

.product__params--alt .product__param-item--size {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .product__params--alt .product__param-item--size {
    width: auto;
    padding-right: 0;
    margin-bottom: 0;
  }
}

.product__params--alt .product__param-item--quantity {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .product__params--alt .product__param-item--quantity {
    width: auto;
    margin-bottom: 0;
  }
}

.product__bg-letters {
  display: block;
  position: absolute;
  left: -0.35em;
  top: -0.2em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -0.03em;
  color: #fff;
  opacity: .1;
  font-size: 230px;
  pointer-events: none;
}

.onsale {
  display: block;
  position: absolute;
  z-index: 3;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  right: 0;
}

.onsale::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-left: 76px solid transparent;
  border-top: 76px solid #38a9ff;
  z-index: 1;
}

.onsale .onsale__inner {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Product Slider */
.product__slider.slick-slider {
  margin-bottom: 0;
}

.product__slider .product__slide {
  position: relative;
  height: 330px;
}

.product__slider .slick-dots {
  bottom: 13px;
}

.product__slider .slick-dots li {
  height: 6px;
  width: 6px;
  margin: 0 3px;
}

.product__slider .slick-dots li button {
  height: 6px;
  width: 6px;
  line-height: 6px;
  background-color: #fff;
  opacity: 0.4;
  transition: opacity 0.2s ease;
}

.product__slider .slick-dots li button:hover, .product__slider .slick-dots li button:focus {
  opacity: 1;
}

.product__slider .slick-dots li button:hover {
  background-color: #fff;
}

.product__slider .slick-dots li.slick-active button {
  background-color: #fff;
  opacity: 1;
}

.product__slide-img {
  position: relative;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 10px;
}

.product__slide-img img {
  margin: 0 auto;
}

.product__slide-img::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 150px;
  height: 10px;
  background: transparent;
  border-radius: 100px/50px;
  box-shadow: 0 40px 10px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

/* Product Slider - Soccer */
.product__slider-soccer-wrapper {
  padding: 60px 0 0 40px;
  overflow: hidden;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .product__slider-soccer-wrapper {
    margin-bottom: 36px;
  }
}

.product__slider-soccer {
  text-align: center;
  width: 250px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .product__slider-soccer {
    float: left;
  }
}

.product__slider-soccer img {
  display: inline-block;
}

/* Product Slider - Thumbs */
.product__slider-thumbs {
  margin-right: 20px;
  width: 62px;
  float: left;
  display: none;
}

@media (min-width: 992px) {
  .product__slider-thumbs {
    display: block;
  }
}

.product__slide-thumb {
  width: 60px;
  height: 60px;
  margin-bottom: 2px;
}

.product__slide-thumb:hover {
  cursor: pointer;
}

.product__slide-thumb img {
  vertical-align: top;
  opacity: .4;
  transition: opacity 0.3s ease;
}

.product__slide-thumb.slick-current img {
  opacity: 1;
}

.product__slide-thumb-holder {
  display: inline-block;
  border: 1px solid #e4e7ed;
}

/* Products List */
.products--list .product__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media (max-width: 991px) {
  .products--list .product__item {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.products--list .product__header {
  margin-bottom: 18px;
}

@media (min-width: 992px) {
  .products--list .product__header {
    margin-bottom: 24px;
  }
}

.products--list .product__header:not(.product__header--block) {
  display: flex;
  align-items: center;
}

.products--list .product__img {
  text-align: center;
}

@media (max-width: 991px) {
  .products--list .product__img {
    width: 100%;
  }
}

.products--list .product__price {
  margin-left: auto;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .products--list .product__price {
    font-size: 22px;
  }
}

.products--list .product__item--color-1 .product__img {
  background-image: linear-gradient(to left top, #fe2b00, #f7d500);
}

.products--list .product__item--color-2 .product__img {
  background-image: linear-gradient(to left top, #003e78, #33fff3);
}

.products--list .product__item--color-3 .product__img {
  background-image: linear-gradient(to left top, #00adbd, #7cffd0);
}

.products--list .product__item--color-4 .product__img {
  background-image: linear-gradient(to left top, #3f1464, #dd2f8d);
}

.products--list .product__item--color-5 .product__img {
  background-image: linear-gradient(to left top, #140078, #4dcbff);
}

.products--list .product__item--color-6 .product__img {
  background-image: linear-gradient(to left top, #006f3f, #bdff3d);
}

.products--list .product__img-holder {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media (min-width: 992px) {
  .products--list .product__img-holder {
    width: 250px;
  }
}

@media (min-width: 1200px) {
  .products--list .product__img-holder {
    width: 278px;
  }
}

.products--list .product__slide-img img {
  max-width: 64%;
}

@media (max-width: 479px) {
  .products--list .product__footer .btn {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
  }
  .products--list .product__footer .product__wish {
    float: left;
    width: 48%;
  }
  .products--list .product__footer .product__view {
    width: 48%;
    float: right;
  }
  .products--list .product__footer .btn + .btn {
    margin-top: 7px;
  }
}

.products--list .onsale {
  top: 0;
}

/* Product List - Small */
.products--list-sm .product__content {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 480px) {
  .products--list-sm .product__content {
    flex-wrap: nowrap;
  }
}

.products--list-sm .product__slider .product__slide {
  height: 240px;
}

@media (min-width: 768px) {
  .products--list-sm .product__slider .product__slide {
    height: 138px;
  }
}

@media (min-width: 992px) {
  .products--list-sm .product__img-holder {
    width: 130px;
  }
}

@media (min-width: 1200px) {
  .products--list-sm .product__img-holder {
    width: 140px;
  }
}

.products--list-sm .product__bg-letters {
  font-size: 106px;
}

.products--list-sm .product__header {
  display: block;
  margin-bottom: 0;
  padding-right: 10px;
}

@media (min-width: 480px) {
  .products--list-sm .product__header {
    min-width: 29%;
  }
}

.products--list-sm .product__title {
  font-size: 16px;
  margin-bottom: .25em;
}

.products--list-sm .product__ratings {
  padding-top: 7px;
  margin-bottom: 14px;
}

.products--list-sm .product__price {
  font-size: 16px;
}

.products--list-sm .product__excerpt {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 22px;
  padding: 20px 0;
}

@media (min-width: 480px) {
  .products--list-sm .product__excerpt {
    padding: 0 0 0 0;
    min-width: 45%;
  }
}

.products--list-sm .product__footer {
  width: 100%;
}

@media (min-width: 480px) {
  .products--list-sm .product__footer {
    min-width: 23%;
    text-align: center;
  }
}

.products--list-sm .product__footer .btn {
  float: none;
  width: 38px;
  display: inline-block;
  margin: 0 2px;
}

.products--list-sm .product__footer .btn + .btn {
  margin: 0 2px;
}

@media (min-width: 480px) {
  .products--list-sm .product__footer .btn {
    margin: 0 8px;
  }
  .products--list-sm .product__footer .btn + .btn {
    margin: 0 8px;
  }
}

/* Products List - Large */
@media (min-width: 992px) {
  .products--list-lg .product__content {
    padding: 60px 50px 54px 50px;
  }
}

.products--list-lg .product__header {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .products--list-lg .product__header {
    margin-bottom: 44px;
  }
}

.products--list-lg .product__category {
  font-size: 11px;
}

@media (min-width: 992px) {
  .products--list-lg .product__title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .products--list-lg .product__title {
    font-size: 30px;
  }
}

.products--list-lg .product__ratings {
  font-size: 13px;
}

.product__title + .products--list-lg .product__ratings {
  margin-top: 10px;
}

.products--list-lg .product__ratings .fa {
  margin-right: 3px;
}

.products--list-lg .product__ratings--sm {
  font-size: 10px;
}

.product__title + .products--list-lg .product__ratings--sm {
  margin-top: 13px;
}

.products--list-lg .product__ratings-label {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 700;
  color: #31404b;
  text-transform: uppercase;
  margin-left: 1em;
}

.products--list-lg .product__ratings-value {
  color: #38a9ff;
}

@media (min-width: 992px) {
  .products--list-lg .product__price {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .products--list-lg .product__price {
    font-size: 30px;
  }
}

.products--list-lg .product__header--block .product__price {
  margin-top: .5em;
  margin-top: .5em;
}

@media (min-width: 1200px) {
  .products--list-lg .product__header--block .product__price {
    font-size: 26px;
  }
}

.products--list-lg .product__excerpt h6 {
  font-size: 11px;
  line-height: 1.2em;
  margin-bottom: 1.75em;
}

@media (max-width: 479px) {
  .products--list-lg .product__footer .product__wish {
    float: none;
    width: 100%;
  }
}

.products--list-lg .product__wish-fab {
  display: block;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 38px !important;
  height: 38px;
  float: none;
}

@media (max-width: 479px) {
  .products--list-lg .product__wish-fab {
    width: 38px;
    height: 38px;
    right: 10px;
    top: 5px;
  }
}

.products--list-lg .product__bg-letters {
  font-size: 320px;
}

@media (min-width: 992px) {
  .products--list-lg .product__img-holder {
    width: 420px;
  }
}

@media (min-width: 1200px) {
  .products--list-lg .product__img-holder {
    width: 500px;
  }
}

.products--list-lg .product__slider .product__slide {
  height: 360px;
}

@media (min-width: 992px) {
  .products--list-lg .product__slider .product__slide {
    height: 578px;
  }
}

.products--list-lg .product__slider .slick-dots {
  bottom: 13px;
}

.products--list-lg .product__slider .slick-dots li {
  height: 8px;
  width: 8px;
  margin: 0 6px;
}

.products--list-lg .product__slider .slick-dots li button {
  height: 8px;
  width: 8px;
  line-height: 8px;
}

/* Products List - Condensed */
.products--list-condensed .product__item {
  margin-bottom: -1px;
  border-radius: 0;
  position: relative;
}

/* Products List - Alt (eSports) */
.products--list-alt .product__slide-img {
  position: static;
  transform: none;
  padding-bottom: 0;
}

.products--list-alt .product__slide-img img {
  max-width: 100%;
  margin: 0;
}

@media (max-width: 991px) {
  .products--list-alt .product__slide-img img {
    margin: 0 auto;
  }
}

.products--list-alt .product__slider .product__slide {
  height: auto;
}

@media (min-width: 992px) {
  .products--list-alt .product__slider .product__slide {
    height: auto;
  }
}

.products--list-alt .product__img-holder {
  position: relative;
}

.products--list-alt .product__slider-nav {
  position: absolute;
  left: 50%;
  bottom: 35px;
  width: 100px;
  height: 24px;
  background-color: rgba(43, 34, 54, 0.8);
  border-radius: 12px;
  text-align: center;
  transform: translate(-50%, 0);
}

.products--list-alt .product__slider-paging {
  font-size: 10px;
  line-height: 1.2em;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  padding: 7px 0;
}

.products--list-alt .product__slider-arrows .slick-prev,
.products--list-alt .product__slider-arrows .slick-next {
  top: 2px;
  background-color: transparent;
}

.products--list-alt .product__slider-arrows .slick-prev:hover::before,
.products--list-alt .product__slider-arrows .slick-next:hover::before {
  color: #38a9ff;
}

.products--list-alt .product__slider-arrows .slick-prev {
  left: 0;
}

.products--list-alt .product__slider-arrows .slick-next {
  right: 0;
}

.products--list-alt .product__excerpt {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .products--list-alt .product__excerpt {
    margin-bottom: 32px;
  }
}

/* Products Grid */
.products--grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -8px;
}

.products--grid .product__item {
  position: relative;
  flex-basis: 100%;
  margin: 8px;
}

@media (min-width: 480px) {
  .products--grid .product__item {
    flex-basis: calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (min-width: 768px) {
  .products--grid .product__item {
    flex-basis: calc(33.3% - 16px);
    max-width: calc(33.3% - 16px);
  }
}

@media (min-width: 768px) {
  .products--grid.products--grid-4 .product__item {
    flex-basis: calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (min-width: 992px) {
  .products--grid.products--grid-4 .product__item {
    flex-basis: calc(25% - 16px);
    max-width: calc(25% - 16px);
  }
}

.products--grid .product__item--color-1 {
  background-image: linear-gradient(to left top, #fe2b00, #f7d500);
}

.products--grid .product__item--color-2 {
  background-image: linear-gradient(to left top, #003e78, #33fff3);
}

.products--grid .product__item--color-3 {
  background-image: linear-gradient(to left top, #00adbd, #7cffd0);
}

.products--grid .product__item--color-4 {
  background-image: linear-gradient(to left top, #3f1464, #dd2f8d);
}

.products--grid .product__item--color-5 {
  background-image: linear-gradient(to left top, #140078, #4dcbff);
}

.products--grid .product__item--color-6 {
  background-image: linear-gradient(to left top, #006f3f, #bdff3d);
}

.products--grid .product__header {
  text-align: center;
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .products--grid .product__header {
    margin-bottom: 36px;
  }
}

.products--grid .product__category {
  color: #fff;
}

.products--grid .product__title {
  color: #fff;
  font-size: 14px;
}

.products--grid .product__title > a {
  color: #fff;
}

.products--grid .product__ratings .empty {
  color: #fff;
  opacity: .3;
}

.products--grid .product__price {
  position: absolute;
  right: 23px;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 700;
}

.products--grid .product__content {
  padding: 23px;
}

.products--grid .product__footer {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .products--grid .product__footer {
    flex-wrap: wrap;
  }
}

.products--grid-overlay .products--grid .product__footer {
  justify-content: center;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.products--grid .product__footer .product__add-to-cart {
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .products--grid .product__footer .product__add-to-cart {
    flex-basis: 100%;
    margin-bottom: 7px;
  }
}

.products--grid .product__footer .btn {
  padding-left: 0;
  padding-right: 0;
}

.products--grid .product__footer .btn-single-icon {
  flex-basis: 47px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .products--grid .product__footer .btn-single-icon {
    flex-basis: 48%;
    margin: 0;
  }
}

.products--grid .product__footer .btn-single-icon.btn-circle {
  flex-basis: 38px;
  margin: 0 10px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .products--grid .product__footer .btn-single-icon.btn-circle {
    flex-basis: 38px;
    margin: 0 11px;
  }
}

@media (min-width: 1200px) {
  .products--grid .product__footer .btn-single-icon.btn-circle {
    flex-basis: 38px;
    margin: 0 11px;
  }
}

.products--grid .product__slider .product__slide {
  height: 283px;
}

.products--grid .product__slider .slick-dots {
  text-align: left;
  bottom: auto;
  top: 10px;
  left: 23px;
}

.products--grid .product__slider .product__slide-img {
  transform: translate(-50%, -40%);
  padding-bottom: 0;
}

/* Products - Grid (Football) */
.products--grid-overlay .product__item .product__content {
  background-color: #fff;
  margin-bottom: 0;
  position: relative;
  padding: 21px 0 17px 0;
}

.products--grid-overlay .product__item .product__content .product__header {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .products--grid-overlay .product__item .product__content .product__header {
    margin-bottom: 0;
  }
}

.products--grid-overlay .product__item .product__content .product__price {
  position: static;
  display: inline-block;
}

.products--grid-overlay .product__item .product__content .product__ratings {
  display: inline-block;
  margin-right: 0.5em;
}

.products--grid-overlay .product__item .product__content .product__title {
  margin-bottom: .2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.products--grid-overlay .product__item .product__content .product__category {
  color: #9a9da2;
  margin-bottom: .25em;
}

.products--grid-overlay .product__item .product__content:hover .product__footer {
  transform: translateY(0%);
}

.products--grid-overlay .product__item .product__footer {
  justify-content: center;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.products--grid-overlay .product__item .product__slider .product__slide {
  height: 283px;
}

.products--grid-overlay .product__item .product__slider .slick-dots {
  padding-bottom: 10px;
  text-align: center;
  position: static;
}

.products--grid-overlay .product__item .product__btn-add-remove {
  position: absolute;
  right: 17px;
  top: 17px;
  z-index: 2;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.products--grid-overlay .product__item .product__btn-add-remove:hover {
  background-color: #38a9ff;
}

.products--grid-overlay .product__item .product__btn-add-remove:hover .product__close-icon {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.products--grid-overlay .product__item .product__btn-add-remove:hover .icon-heart {
  opacity: 0;
  transform: translate(-50%, 100%);
}

.products--grid-overlay .product__item .product__btn-add-remove i {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%);
}

.products--grid-overlay .product__item .product__btn-add-remove .icon-heart {
  opacity: 1;
}

.products--grid-overlay .product__item .product__btn-add-remove .product__close-icon {
  width: 10px;
  height: 10px;
  display: block;
  opacity: 0;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  transform: translate(-50%, -30px);
  background-position: 0 0;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath transform='translate(-701.062 -492.062)' fill='%23fff' d='M709.536,492.05l1.414,1.414-8.485,8.485-1.415-1.414Zm1.414,8.485-1.414,1.414-8.486-8.485,1.415-1.414Z'/%3E%3C/svg%3E");
}

/* Products - Grid (eSports) */
.products--grid-simple .product__img {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.products--grid-simple .product__img:hover .product__overlay {
  visibility: visible;
  opacity: 1;
}

.products--grid-simple .product__thumb {
  text-align: center;
}

.products--grid-simple .product__thumb img {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.products--grid-simple .product__price {
  display: inline-block;
  position: static;
}

.products--grid-simple .product__ratings {
  display: inline-block;
  margin: 0 10px 0 0;
}

.products--grid-simple .product__overlay {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease;
  background-color: rgba(0, 0, 0, 0.8);
}

.products--grid-simple .product__btns {
  width: 100%;
  padding: 0 14%;
}

.products--grid-simple .product__btns .btn + .btn {
  margin-top: 16px;
}

.products--grid-simple .product__content {
  padding: 30px 15px 22px 15px;
}

.products--grid-simple .product__header {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .products--grid-simple .product__header {
    margin-bottom: 0;
  }
}

.products--grid-simple .product__title {
  font-size: 14px;
  font-style: normal;
  margin-bottom: .25em;
}

.products--grid-simple .product__title > a:hover {
  color: #38a9ff;
}

/* Products Grid - Condensed */
.products--grid-condensed {
  margin: 0;
}

.products--grid-condensed .product__item {
  position: relative;
  flex-basis: 100%;
  margin: 0 -1px -1px 0;
  border-radius: 0;
}

@media (min-width: 480px) {
  .products--grid-condensed .product__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .products--grid-condensed .product__item {
    flex-basis: 33.3%;
    max-width: 33.3%;
  }
}

.products--grid-condensed .product__img {
  text-align: center;
}

@media (min-width: 768px) {
  .products--grid-condensed.products--grid-4 .product__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .products--grid-condensed.products--grid-4 .product__item {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.products--grid-condensed .product__header {
  text-align: left;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .products--grid-condensed .product__header {
    margin-bottom: 10px;
  }
}

.products--grid-condensed .product__header-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.products--grid-condensed .product__category,
.products--grid-condensed .product__ratings {
  flex-basis: 50%;
  max-width: 50%;
}

.products--grid-condensed .product__ratings {
  text-align: right;
}

.products--grid-condensed .product__price {
  text-align: right;
}

/* Products Grid - Light */
.products--grid-light .product__category {
  color: #9a9da2;
}

.products--grid-light .product__title {
  color: #31404b;
  font-size: 12px;
}

.products--grid-light .product__title > a {
  color: #31404b;
}

.products--grid-light .product__title > a:hover {
  color: #38a9ff;
}

.products--grid-light .product__ratings .empty {
  color: #dce0e7;
  opacity: 1;
}

.products--grid-light .product__price {
  position: static;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  font-size: 12px;
}

.alc-products-wishlist {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -11px;
  width: calc(100% + 22px);
}

.alc-product-wishlist__item {
  margin: 0 11px 24px 11px;
  width: 120px;
}

@media (max-width: 479px) {
  .alc-product-wishlist__item {
    width: 115px;
  }
}

.alc-product-wishlist__thumb {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: transparent;
  width: 120px;
  height: 120px;
  position: relative;
  margin-bottom: 15px;
}

@media (max-width: 479px) {
  .alc-product-wishlist__thumb {
    width: 95px;
    height: 95px;
  }
}

.alc-product-wishlist__thumb::before {
  content: "\e08a";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'simple-line-icons';
  font-size: 20px;
  line-height: 1.2em;
  color: #9a9da2;
  opacity: .1;
}

.alc-product-wishlist__thumb img {
  border-radius: 3px;
}

.alc-product-wishlist__close {
  position: absolute;
  right: -8px;
  top: -8px;
}

.alc-product-wishlist__title {
  font-size: 10px;
  line-height: 1.4em;
  font-style: normal;
  margin-bottom: .6em;
}

.alc-product-wishlist__title > a {
  color: #31404b;
}

.alc-product-wishlist__title > a:hover {
  color: #38a9ff;
}

.alc-product-wishlist__ratings {
  font-size: 10px;
  line-height: 1.2em;
  margin-bottom: 18px;
}

.alc-product-wishlist__ratings .fa {
  color: #ffdc11;
  margin-right: 2px;
}

.alc-product-wishlist__ratings .empty {
  color: #dbdfe6;
}

@media (min-width: 992px) {
  .alc-inventory__side {
    flex-basis: 29%;
    max-width: 29%;
  }
}

.alc-inventory__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  width: calc(100% + 20px);
}

.alc-inventory__item {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 10px 20px 10px;
  background-image: url("../images/esports/placeholder-cart-sm.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #e4e7ed;
}

@media (max-width: 479px) {
  .alc-inventory__item {
    width: 72px;
    height: 72px;
  }
}

.alc-inventory__item--active {
  border: none;
}

.alc-inventory__item--active .alc-inventory__item-thumb {
  border-width: 4px;
  border: 4px solid #38a9ff;
}

.alc-inventory__item-thumb {
  margin: 0;
  overflow: hidden;
  border-radius: 3px;
}

.alc-inventory__item-badges {
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
}

.alc-inventory__item-badges .badge {
  vertical-align: top;
  min-width: 22px;
  min-height: 22px;
  line-height: 22px;
  border-radius: 50%;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 10px;
}

.alc-inventory__item-badges .badge.badge-close:hover {
  cursor: pointer;
}

.alc-inventory__main {
  text-align: center;
}

@media (min-width: 992px) {
  .alc-inventory__main {
    border-left: 1px solid #e4e7ed;
    flex-basis: 71%;
    max-width: 71%;
  }
}

@media (max-width: 991px) {
  .alc-inventory__main-thumb {
    padding-bottom: 20px;
  }
}

.alc-inventory__main-thumb img {
  border-radius: 4px;
  border: 1px solid #e4e7ed;
}

.alc-inventory__main-header {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .alc-inventory__main-header {
    padding-bottom: 34px;
  }
}

.alc-inventory__main-title {
  font-style: normal;
  margin-bottom: .4em;
  font-size: 24px;
}

@media (min-width: 992px) {
  .alc-inventory__main-title {
    font-size: 32px;
  }
}

.alc-inventory__main-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alc-inventory__main-ratings {
  font-size: 10px;
  line-height: 1.2em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
}

.alc-inventory__main-ratings .fa {
  color: #ffdc11;
  margin-right: 3px;
}

.alc-inventory__main-ratings .empty {
  color: #dbdfe6;
}

.alc-inventory__main-price {
  display: inline-block;
  font-size: 20px;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #31404b;
}

.alc-inventory__main-excerpt {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  padding-bottom: 35px;
}

.alc-inventory__main-quantity {
  padding-bottom: 30px;
}

.alc-inventory__main-quantity .product-quantity-control {
  text-align: center;
}

.alc-inventory__main-meta {
  padding-bottom: 30px;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.2em;
  font-style: italic;
  text-transform: uppercase;
}

.alc-inventory__main-meta-item {
  padding: 0 7px;
}

.alc-inventory__main-meta-value {
  color: #31404b;
}

/* Filter Color */
.filter-color {
  list-style: none;
  padding: 0;
  margin: 0 0 -12px 0;
  overflow: hidden;
}

.filter-color__item {
  display: inline-block;
  width: 28px;
  height: 28px;
  overflow: hidden;
  margin: 0 3px 12px 3px;
}

.filter-color__item label {
  display: block;
}

.filter-color__item [class*="indicator"] {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: #e4e7ed;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 4px;
  top: 4px;
  margin-top: 0;
}

.filter-color__item [class*="indicator"]:hover {
  cursor: pointer;
}

.filter-color__item [class*="indicator"]::before {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: all 0.2s ease;
  transform: scale(0.8);
}

.filter-color__item input[type="checkbox"]:checked + [class*="indicator"],
.filter-color__item input[type="radio"]:checked + [class*="indicator"] {
  background-color: #e4e7ed;
  border-color: #e4e7ed;
  background-image: none !important;
}

.filter-color__item input[type="checkbox"]:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"]:checked + [class*="indicator"]::before {
  border-color: #e4e7ed;
  transform: scale(1);
}

.filter-color__item input[type="checkbox"].color-white + [class*="indicator"],
.filter-color__item input[type="radio"].color-white + [class*="indicator"] {
  background-color: #fff;
  border-color: #e4e7ed;
}

.filter-color__item input[type="checkbox"].color-white:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-white:checked + [class*="indicator"] {
  background-color: #fff;
  border-color: #fff;
}

.filter-color__item input[type="checkbox"].color-white:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-white:checked + [class*="indicator"]::before {
  border-color: #fff;
}

.filter-color__item input[type="checkbox"].color-violet + [class*="indicator"],
.filter-color__item input[type="radio"].color-violet + [class*="indicator"] {
  background-color: #6e53bc;
  border-color: #6e53bc;
}

.filter-color__item input[type="checkbox"].color-violet:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-violet:checked + [class*="indicator"] {
  background-color: #6e53bc;
  border-color: #6e53bc;
}

.filter-color__item input[type="checkbox"].color-violet:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-violet:checked + [class*="indicator"]::before {
  border-color: #6e53bc;
}

.filter-color__item input[type="checkbox"].color-blue + [class*="indicator"],
.filter-color__item input[type="radio"].color-blue + [class*="indicator"] {
  background-color: #1e81f1;
  border-color: #1e81f1;
}

.filter-color__item input[type="checkbox"].color-blue:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-blue:checked + [class*="indicator"] {
  background-color: #1e81f1;
  border-color: #1e81f1;
}

.filter-color__item input[type="checkbox"].color-blue:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-blue:checked + [class*="indicator"]::before {
  border-color: #1e81f1;
}

.filter-color__item input[type="checkbox"].color-light-blue + [class*="indicator"],
.filter-color__item input[type="radio"].color-light-blue + [class*="indicator"] {
  background-color: #00cbf6;
  border-color: #00cbf6;
}

.filter-color__item input[type="checkbox"].color-light-blue:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-light-blue:checked + [class*="indicator"] {
  background-color: #00cbf6;
  border-color: #00cbf6;
}

.filter-color__item input[type="checkbox"].color-light-blue:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-light-blue:checked + [class*="indicator"]::before {
  border-color: #00cbf6;
}

.filter-color__item input[type="checkbox"].color-cyan + [class*="indicator"],
.filter-color__item input[type="radio"].color-cyan + [class*="indicator"] {
  background-color: #1af1e4;
  border-color: #1af1e4;
}

.filter-color__item input[type="checkbox"].color-cyan:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-cyan:checked + [class*="indicator"] {
  background-color: #1af1e4;
  border-color: #1af1e4;
}

.filter-color__item input[type="checkbox"].color-cyan:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-cyan:checked + [class*="indicator"]::before {
  border-color: #1af1e4;
}

.filter-color__item input[type="checkbox"].color-aqua + [class*="indicator"],
.filter-color__item input[type="radio"].color-aqua + [class*="indicator"] {
  background-color: #14ee85;
  border-color: #14ee85;
}

.filter-color__item input[type="checkbox"].color-aqua:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-aqua:checked + [class*="indicator"] {
  background-color: #14ee85;
  border-color: #14ee85;
}

.filter-color__item input[type="checkbox"].color-aqua:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-aqua:checked + [class*="indicator"]::before {
  border-color: #14ee85;
}

.filter-color__item input[type="checkbox"].color-green + [class*="indicator"],
.filter-color__item input[type="radio"].color-green + [class*="indicator"] {
  background-color: #aff526;
  border-color: #aff526;
}

.filter-color__item input[type="checkbox"].color-green:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-green:checked + [class*="indicator"] {
  background-color: #aff526;
  border-color: #aff526;
}

.filter-color__item input[type="checkbox"].color-green:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-green:checked + [class*="indicator"]::before {
  border-color: #aff526;
}

.filter-color__item input[type="checkbox"].color-yellow + [class*="indicator"],
.filter-color__item input[type="radio"].color-yellow + [class*="indicator"] {
  background-color: #ffde00;
  border-color: #ffde00;
}

.filter-color__item input[type="checkbox"].color-yellow:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-yellow:checked + [class*="indicator"] {
  background-color: #ffde00;
  border-color: #ffde00;
}

.filter-color__item input[type="checkbox"].color-yellow:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-yellow:checked + [class*="indicator"]::before {
  border-color: #ffde00;
}

.filter-color__item input[type="checkbox"].color-orange + [class*="indicator"],
.filter-color__item input[type="radio"].color-orange + [class*="indicator"] {
  background-color: #ff860f;
  border-color: #ff860f;
}

.filter-color__item input[type="checkbox"].color-orange:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-orange:checked + [class*="indicator"] {
  background-color: #ff860f;
  border-color: #ff860f;
}

.filter-color__item input[type="checkbox"].color-orange:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-orange:checked + [class*="indicator"]::before {
  border-color: #ff860f;
}

.filter-color__item input[type="checkbox"].color-red + [class*="indicator"],
.filter-color__item input[type="radio"].color-red + [class*="indicator"] {
  background-color: #ed2546;
  border-color: #ed2546;
}

.filter-color__item input[type="checkbox"].color-red:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-red:checked + [class*="indicator"] {
  background-color: #ed2546;
  border-color: #ed2546;
}

.filter-color__item input[type="checkbox"].color-red:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-red:checked + [class*="indicator"]::before {
  border-color: #ed2546;
}

.filter-color__item input[type="checkbox"].color-black + [class*="indicator"],
.filter-color__item input[type="radio"].color-black + [class*="indicator"] {
  background-color: #383838;
  border-color: #383838;
}

.filter-color__item input[type="checkbox"].color-black:checked + [class*="indicator"],
.filter-color__item input[type="radio"].color-black:checked + [class*="indicator"] {
  background-color: #383838;
  border-color: #383838;
}

.filter-color__item input[type="checkbox"].color-black:checked + [class*="indicator"]::before,
.filter-color__item input[type="radio"].color-black:checked + [class*="indicator"]::before {
  border-color: #383838;
}

.filter-color__item.checkbox-inline {
  padding-left: 24px;
}

.filter-color__item input:disabled + [class*="indicator"] {
  background-color: #e4e7ed;
  border-color: #d8dce5;
}

/* Product Tabs */
.product-tabs .nav-product-tabs {
  border-bottom: 0;
}

.product-tabs .nav-product-tabs .nav-item {
  border-radius: 0;
}

.product-tabs .nav-product-tabs .nav-link {
  position: relative;
  margin-right: 0;
  border-radius: 0;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #9a9da2;
  line-height: 1.2em;
  padding: 28px 20px;
  transition: color .3s ease;
  border-top: none;
  margin-bottom: 0;
  border-bottom: 1px solid #e4e7ed;
}

@media (min-width: 992px) {
  .product-tabs .nav-product-tabs .nav-link {
    font-size: 13px;
  }
}

.product-tabs .nav-product-tabs .nav-link::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  background-color: #38a9ff;
  transition: height 0.3s ease;
}

.product-tabs .nav-product-tabs .nav-link > small {
  display: block;
  font-size: 8px;
  line-height: 1.2em;
  color: #9a9da2;
}

.product-tabs .nav-product-tabs .nav-link:hover {
  background-color: transparent;
  border-left: none;
  border-right: none;
  color: #31404b;
}

.product-tabs .nav-product-tabs .nav-link:hover > small {
  color: #9a9da2;
}

.product-tabs .nav-product-tabs .nav-link.active, .product-tabs .nav-product-tabs .nav-link.active:hover, .product-tabs .nav-product-tabs .nav-link.active:focus {
  border: 1px solid #e4e7ed;
  border-left: none;
  border-right: none;
  background-color: transparent;
  color: #31404b;
}

.product-tabs .nav-product-tabs .nav-link.active::before, .product-tabs .nav-product-tabs .nav-link.active:hover::before, .product-tabs .nav-product-tabs .nav-link.active:focus::before {
  height: 4px;
}

@media (min-width: 768px) {
  .product-tabs .nav-product-tabs .nav-link {
    border-radius: 0;
    border-right: none;
    border-top: none;
  }
  .product-tabs .nav-product-tabs .nav-item:first-child .nav-link {
    border-left: none !important;
  }
  .product-tabs .nav-product-tabs .nav-link.active,
  .product-tabs .nav-product-tabs .nav-link.active:hover,
  .product-tabs .nav-product-tabs .nav-link.active:focus {
    border-bottom-color: transparent;
    border-right: none;
    border-top: none;
  }
  .product-tabs .nav-product-tabs .nav-item + .nav-item .nav-link {
    border-left: 1px solid #e4e7ed !important;
  }
}

.product-tabs__section + .product-tabs__section {
  margin-top: 30px;
}

.product-tabs__header {
  padding-bottom: 2.5em;
}

.product-tabs__header h2 {
  margin-bottom: 0;
}

.product-tabs__header .ratings {
  font-size: 11px;
  padding: 13px 0 0 0;
}

.product-tabs__header .ratings .fa {
  margin-right: 3px;
}

.product-tabs__header .ratings-result {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  padding-left: 9px;
}

.product-tabs__header--sm h2 {
  font-size: 28px;
  display: flex;
  flex-wrap: wrap;
}

.product-tabs__header--sm .product-tabs__ratings {
  flex-grow: 1;
}

.product-tabs__header--sm .product-tabs__ratings .highlight {
  color: #38a9ff;
}

.product-tabs__header--sm .product-tabs__reviews {
  flex-shrink: 1;
}

#textarea-review {
  height: 162px;
}

/*
// #Info Block
// --------------------------------------------------
*/
.info-block {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-block__item {
  position: relative;
  min-height: 26px;
  padding: 2px 0 0 40px;
  margin-bottom: 34px;
  list-style: none;
  text-align: left;
}

.info-block__item:last-child {
  margin-bottom: 0;
}

.info-block__item .df-icon,
.info-block__item .df-icon-stack {
  position: absolute;
  left: 0;
  top: 0;
}

.info-block__item .df-icon--custom {
  height: auto;
  font-size: 28px;
  line-height: 1.2em;
  color: #c2ff1f;
}

.info-block__item .df-icon--custom img {
  vertical-align: top;
}

@media (max-width: 991px) {
  .header__primary .info-block__item {
    text-align: left;
    margin: 20px 0;
    padding: 2px 30px 16px 70px;
  }
  .header__primary .info-block__item.info-block__item--contact-primary {
    margin-top: 36px;
  }
  .header__primary .info-block__item.info-block__item--contact-secondary {
    margin-bottom: 0;
  }
  .header__primary .info-block__item .df-icon {
    left: 30px;
  }
  .header__primary .info-block__item .info-block__link {
    padding: 0;
    font-size: 9px;
    line-height: 1.2em;
    color: #6b6d70;
    border: none;
  }
  .header__primary .info-block__item .info-block__link::before {
    display: none;
  }
  .header__primary .info-block__item .info-block__link:hover {
    color: #fff;
  }
}

@media (min-width: 992px) {
  .header__primary .info-block__item {
    display: none;
  }
}

.info-block__item--nopadding {
  padding-left: 0;
}

.info-block__heading {
  font-size: 9px;
  line-height: 1.2em;
  margin-bottom: 5px;
  color: #fff;
  text-transform: uppercase;
}

.info-block__link {
  display: block;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  line-height: 1.2em;
  color: #6b6d70;
  vertical-align: top;
  transition: color 0.25s cubic-bezier(0.3, 0.1, 0.55, 1);
}

.info-block__link:hover {
  color: #fff;
}

.info-block__link-wrapper {
  display: block;
}

.info-block--header {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .info-block--header {
    display: none;
  }
}

.info-block--header .info-block__item {
  display: inline-block;
  margin: 7px 0 0 26px;
}

@media (min-width: 992px) {
  .info-block--header .info-block__item {
    padding-top: 20px;
    padding-bottom: 32px;
  }
  .info-block--header .info-block__item .df-icon {
    top: 24px;
    stroke: #c2ff1f;
  }
  .info-block--header .info-block__item .df-icon-stack--bag {
    top: 3px;
  }
  .info-block--header .info-block__item .df-icon-stack--bag .df-icon--bag-handle {
    top: 19px;
  }
  .info-block--header .info-block__item .info-block__heading {
    margin-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .info-block--header .info-block__item {
    margin-left: 20px;
  }
}

.info-block--header .info-block__item.has-children.js-info-block__item--onhover > .info-block__link-wrapper {
  padding-right: 16px;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onhover > .info-block__link-wrapper::after {
  content: "";
  position: absolute;
  right: 0;
  top: 29px;
  width: 6px;
  height: 4px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
  background-size: 6px 4px;
  background-repeat: no-repeat;
  background-position: center center;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onhover:hover .header-cart {
  transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onclick {
  padding-right: 0;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onclick .info-block__menu-icon {
  display: block;
  position: absolute;
  right: 0;
  top: 21px;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 7'%3E%3Cpath fill='%23fff' d='M-0.000,6.998 L-0.000,5.998 L8.000,5.998 L8.000,6.998 L-0.000,6.998 ZM-0.000,2.998 L8.000,2.998 L8.000,3.999 L-0.000,3.999 L-0.000,2.998 ZM-0.000,-0.002 L8.000,-0.002 L8.000,0.998 L-0.000,0.998 L-0.000,-0.002 Z'/%3E%3C/svg%3E");
  background-size: 8px 7px;
  background-repeat: no-repeat;
  background-position: right center;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onclick .info-block__menu-icon:hover {
  cursor: pointer;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onclick .info-block__menu-icon.active {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'%3E%3Cpath fill='%23fff' d='M8.037,1.673 L5.209,4.500 L8.036,7.327 L7.329,8.034 L4.502,5.207 L1.673,8.036 L0.966,7.329 L3.795,4.500 L0.965,1.670 L1.672,0.963 L4.502,3.793 L7.329,0.965 L8.037,1.673 Z'/%3E%3C/svg%3E");
  background-size: 9px 9px;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onclick .info-block__menu-icon.active ~ .header-cart {
  transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.info-block--header .info-block__item.has-children.js-info-block__item--onclick > .info-block__link-wrapper {
  margin-right: 20px;
}

.info-block--header .info-block__item--shopping-cart .info-block__heading {
  margin-bottom: 3px;
}

.info-block--header .info-block__cart-sum {
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2em;
  display: block;
  color: #c2ff1f;
}

.info-block--header .header-cart {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  width: 360px;
  background-color: #fff;
  opacity: 0;
  transform-origin: 0% 0%;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
  visibility: hidden;
  transition: transform 0.3s, opacity 0.3s;
}

.info-block--header .header-cart__item {
  position: relative;
  overflow: hidden;
  padding: 24px 24px 19px 24px;
  border-top: 1px solid #e4e7ed;
}

.info-block--header .header-cart__inner {
  overflow: hidden;
  padding: 5px 0 0 0;
}

.info-block--header .header-cart__product-thumb {
  float: left;
  margin: 0 12px 0 0;
  border-radius: 0;
}

.info-block--header .header-cart__product-thumb img {
  max-width: 100%;
  height: auto;
  transition: opacity 0.2s ease;
  border-radius: 0;
}

.info-block--header .header-cart__product-thumb img:hover {
  opacity: 0.7;
}

.info-block--header .header-cart__product-cat {
  display: block;
  font-size: 8px;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-bottom: .2em;
}

.info-block--header .header-cart__product-name {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: .5em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-style: normal;
}

.info-block--header .header-cart__product-name > a {
  color: #31404b;
  transition: color 0.3s ease;
}

.info-block--header .header-cart__product-name > a:hover {
  color: #38a9ff;
}

.info-block--header .header-cart__product-ratings {
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 2px;
}

.info-block--header .header-cart__product-ratings .fa {
  margin-right: 2px;
  color: #ffdc11;
}

.info-block--header .header-cart__product-ratings .fa.empty {
  color: #dbdfe6;
}

.info-block--header .header-cart__product-sum {
  font-size: 12px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
}

.info-block--header .header-cart__close {
  display: block;
  position: absolute;
  right: 18px;
  top: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #31404b;
  opacity: .4;
  font-size: 14px;
  transition: opacity 0.2s ease;
}

.info-block--header .header-cart__close:hover {
  opacity: 1;
  cursor: pointer;
}

.info-block--header .header-cart__item--subtotal {
  overflow: hidden;
  font-size: 11px;
  line-height: 1.3em;
  padding-top: 21px;
  padding-bottom: 21px;
  text-transform: uppercase;
  color: #31404b;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.info-block--header .header-cart__subtotal {
  float: left;
}

.info-block--header .header-cart__subtotal-sum {
  float: right;
  color: #31404b;
}

.info-block--header .header-cart__item--action {
  padding-top: 16px;
  padding-bottom: 16px;
}

.info-block--header .header-cart__item--action .btn {
  width: 48%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  margin-top: 0;
  transition: background-color 0.3s ease;
}

.info-block--header .header-cart__item--action .btn:first-child {
  float: left;
}

.info-block--header .header-cart__item--action .btn:last-child {
  float: right;
}

.info-block--header .header-cart--inventory {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 30px 60px 50px;
}

.info-block--header .header-cart--inventory .header-cart__item {
  width: 80px;
  height: 80px;
  margin: 0 20px 20px 0;
  padding: 0;
  border: 1px solid #e4e7ed;
  background-color: #1e2024;
  background-image: url("../images/esports/placeholder-cart-sm.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 0;
  overflow: visible;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--title {
  width: 100%;
  height: auto;
  border: none;
  margin-bottom: 0;
  padding-bottom: 37px;
  background: none;
  border: none;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--title h5 {
  margin-bottom: 0;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--subtotal {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 0;
  background: none;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 22px 0;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--action {
  width: 100%;
  height: auto;
  margin-bottom: 0;
  background: none;
  border: none;
  padding-top: 24px;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--action .btn {
  width: 100%;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--action .btn:first-child, .info-block--header .header-cart--inventory .header-cart__item.header-cart__item--action .btn:last-child {
  float: none;
}

.info-block--header .header-cart--inventory .header-cart__item.header-cart__item--action .btn + .btn {
  margin-top: 16px;
}

.info-block--header .header-cart--inventory .header-cart__product-thumb {
  margin: 0;
}

.info-block--header .header-cart--inventory .header-cart__badges {
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
}

.info-block--header .header-cart--inventory .header-cart__badges .badge {
  vertical-align: top;
  min-width: 22px;
  min-height: 22px;
  line-height: 22px;
  border-radius: 50%;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 10px;
}

.info-block--header .header-cart--inventory .header-cart__badges .badge.badge-close {
  background-color: #31404b;
}

.info-block--header .header-cart--inventory .header-cart__badges .badge.badge-close:hover {
  cursor: pointer;
}

.footer-widgets .info-block__item .df-icon {
  stroke: #c2ff1f;
}

.info-block--horizontal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

@media (min-width: 992px) {
  .info-block--horizontal {
    flex-wrap: nowrap;
    width: auto;
  }
}

.info-block--horizontal .info-block__item {
  width: 100%;
  padding-left: 50px;
}

@media (min-width: 768px) {
  .info-block--horizontal .info-block__item {
    width: 33.3%;
  }
}

@media (min-width: 992px) {
  .info-block--horizontal .info-block__item {
    width: auto;
    margin: 0 20px;
  }
}

@media (min-width: 1200px) {
  .info-block--horizontal .info-block__item {
    margin: 0 43px;
  }
}

@media (max-width: 991px) {
  .info-block--horizontal .info-block__item:last-child {
    margin-bottom: 34px;
  }
}

.info-block--horizontal .info-block__item--social {
  padding-left: 0;
}

/* Social Counter
/* ------------------------- */
.btn-social-counter {
  display: block;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  min-height: 65px;
  position: relative;
  overflow: hidden;
  background-clip: padding-box;
  padding: 20px 60px 18px 86px;
  transition: all 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.btn-social-counter + .btn-social-counter {
  margin-top: 10px;
}

.btn-social-counter:hover {
  color: #fff;
}

.btn-social-counter__icon {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 68px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
}

.btn-social-counter__icon .fa {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-social-counter__title {
  font-size: 11px;
  line-height: 1.2em;
  color: #fff;
  margin-bottom: 1px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .btn-social-counter__title {
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn-social-counter__title {
    font-size: 11px;
  }
}

.btn-social-counter__count {
  display: block;
  font-size: 10px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.btn-social-counter__add-icon {
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #fff;
  right: 23px;
  top: 50%;
  margin-top: -13px;
}

.btn-social-counter__add-icon::before, .btn-social-counter__add-icon::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-in-out;
}

.btn-social-counter__add-icon::before {
  width: 8px;
  height: 2px;
}

.btn-social-counter__add-icon::after {
  width: 2px;
  height: 8px;
}

.btn-social-counter:hover .btn-social-counter__add-icon::before, .btn-social-counter:hover .btn-social-counter__add-icon::after {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.btn-social-counter__name {
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
  line-height: 1em;
  text-transform: none;
  letter-spacing: -0.03em;
}

@media (min-width: 1199px) {
  .btn-social-counter__name {
    font-size: 28px;
  }
}

.btn-social-counter--fb .btn-social-counter__name {
  text-transform: lowercase;
}

/* Types */
.btn-social-counter--card {
  padding: 34px 60px 18px 23px;
  height: 150px;
  min-width: 304px;
}

@media (min-width: 992px) {
  .btn-social-counter--card {
    height: 200px;
  }
}

@media (min-width: 1199px) {
  .btn-social-counter--card {
    height: 216px;
  }
}

.post-grid__item .btn-social-counter--card {
  margin-bottom: 15px;
}

.btn-social-counter--card .btn-social-counter__footer {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 60px 30px 23px;
}

.btn-social-counter--card .btn-social-counter__footer .btn-social-counter__add-icon {
  top: 0;
  margin-top: 0;
}

/* Social Buttons Styles */
.btn-social-counter--fb {
  background-color: #3B5998;
}

.btn-social-counter--fb .btn-social-counter__icon {
  background-color: #344e86;
}

.btn-social-counter--fb:hover {
  background-color: #4264aa;
}

.widget-social--condensed .btn-social-counter--fb {
  background-color: #3B5998;
}

.widget-social--condensed .btn-social-counter--fb .btn-social-counter__count {
  background-color: #4264aa;
}

.widget-social--grid .btn-social-counter--fb {
  background-color: #3B5998;
}

.widget-social--grid .btn-social-counter--fb:hover {
  background-color: #4264aa;
}

.widget-social--grid .btn-social-counter--fb .btn-social-counter__add-icon {
  background-color: #4264aa;
}

.btn-social-counter--twitter {
  background-color: #1DA1F2;
}

.btn-social-counter--twitter .btn-social-counter__icon {
  background-color: #0d95e8;
}

.btn-social-counter--twitter:hover {
  background-color: #35abf3;
}

.widget-social--condensed .btn-social-counter--twitter {
  background-color: #1DA1F2;
}

.widget-social--condensed .btn-social-counter--twitter .btn-social-counter__count {
  background-color: #35abf3;
}

.widget-social--grid .btn-social-counter--twitter {
  background-color: #1DA1F2;
}

.widget-social--grid .btn-social-counter--twitter:hover {
  background-color: #35abf3;
}

.widget-social--grid .btn-social-counter--twitter .btn-social-counter__add-icon {
  background-color: #35abf3;
}

.btn-social-counter--gplus {
  background-color: #DC4E41;
}

.btn-social-counter--gplus .btn-social-counter__icon {
  background-color: #d83a2b;
}

.btn-social-counter--gplus:hover {
  background-color: #e06257;
}

.widget-social--condensed .btn-social-counter--gplus {
  background-color: #DC4E41;
}

.widget-social--condensed .btn-social-counter--gplus .btn-social-counter__count {
  background-color: #e06257;
}

.btn-social-counter--rss {
  background-color: #ffcc00;
}

.btn-social-counter--rss .btn-social-counter__icon {
  background-color: #ffbf13;
}

.btn-social-counter--rss:hover {
  background-color: #ffd11a;
}

.widget-social--condensed .btn-social-counter--rss {
  background-color: #ffbf13;
}

.widget-social--condensed .btn-social-counter--rss .btn-social-counter__count {
  background-color: #ffcc00;
}

.widget-social--grid .btn-social-counter--rss {
  background-color: #ffbf13;
}

.widget-social--grid .btn-social-counter--rss:hover {
  background-color: #ffd427;
}

.widget-social--grid .btn-social-counter--rss .btn-social-counter__add-icon {
  background-color: #ffd427;
}

.btn-social-counter--instagram {
  background-color: #E4405F;
}

.btn-social-counter--instagram .btn-social-counter__icon {
  background-color: #e12a4c;
}

.btn-social-counter--instagram:hover {
  background-color: #e75672;
}

.widget-social--condensed .btn-social-counter--instagram {
  background-color: #e75672;
}

.widget-social--condensed .btn-social-counter--instagram .btn-social-counter__count {
  background-color: #E4405F;
}

.widget-social--grid .btn-social-counter--instagram {
  background-color: #e12a4c;
}

.widget-social--grid .btn-social-counter--instagram:hover {
  background-color: #E4405F;
}

.widget-social--grid .btn-social-counter--instagram .btn-social-counter__add-icon {
  background-color: #E4405F;
}

.btn-social-counter--twitch {
  background-color: #6441A4;
}

.btn-social-counter--twitch .btn-social-counter__icon {
  background-color: #593a92;
}

.btn-social-counter--twitch:hover {
  background-color: #6f48b6;
}

.widget-social--condensed .btn-social-counter--twitch {
  background-color: #6441A4;
}

.widget-social--condensed .btn-social-counter--twitch .btn-social-counter__count {
  background-color: #6f48b6;
}

.btn-social-counter--youtube {
  background-color: #FF0000;
}

.btn-social-counter--youtube .btn-social-counter__icon {
  background-color: #e60000;
}

.btn-social-counter--youtube:hover {
  background-color: #ff1a1a;
}

.widget-social--condensed .btn-social-counter--youtube {
  background-color: #FF0000;
}

.widget-social--condensed .btn-social-counter--youtube .btn-social-counter__count {
  background-color: #ff1a1a;
}

/*
// #Social Links
// --------------------------------------------------
*/
.social-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.social-links__item {
  display: inline-block;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-right: 12px;
}

.social-links__item .fa {
  font-size: 12px;
  margin-right: 5px;
  color: #fff;
  transition: color 0.2s ease;
}

.social-links__item:last-child {
  margin-right: 0;
}

.social-links__link {
  display: inline-block;
  color: #6b6d70;
  transition: color 0.2s ease;
}

.social-links__link:hover {
  color: #fff;
}

.social-links__link:hover .fa {
  color: #38a9ff;
}

.social-links--main-nav {
  display: inline-block;
  padding-left: 30px;
}

.social-links--main-nav .social-links__item {
  margin-right: 19px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .social-links--main-nav .social-links__item {
    margin-right: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .social-links--main-nav {
    padding-left: 30px;
  }
}

.social-links--top-bar {
  padding-left: 0;
}

.social-links--top-bar .social-links__item {
  margin-right: 20px;
}

.social-links--top-bar .social-links__item .fa {
  font-size: 16px;
  opacity: .6;
}

.social-links--top-bar .social-links__link .fa {
  color: #9a9da2;
}

.social-links--top-bar .social-links__link:hover .fa {
  opacity: 1;
  color: #38a9ff;
}

.social-links--btn .social-links__item {
  margin: 0 0 0 0;
}

.social-links--btn .social-links__link {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  margin: 0 2px;
  transition: opacity 0.2s ease-in-out;
}

.social-links--btn .social-links__link:hover {
  opacity: 0.8;
}

.social-links--btn .social-links__link:hover .fa {
  color: #fff;
}

.social-links--btn .social-links__link .fa {
  margin: 0;
}

.social-links--btn .social-links__link--lg {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 0 6px;
}

.social-links--btn .social-links__link--fb {
  background-color: #4d6baa;
}

.social-links--btn .social-links__link--twitter {
  background-color: #3fcef2;
}

.social-links--btn .social-links__link--gplus {
  background-color: #e34d39;
}

.social-links--btn.social-links--btn-block .social-links__item {
  display: inline;
}

.social-links--btn.social-links--btn-block .social-links__link {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 12px;
  font-size: 9px;
  height: 24px;
  line-height: 24px;
}

.social-links--circle .social-links__item {
  margin-right: 20px;
}

@media (min-width: 992px) {
  .social-links--circle .social-links__item {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .social-links--circle .social-links__item {
    margin-right: 24px;
  }
}

.social-links--circle .social-links__item:last-child {
  margin-right: 0;
}

.social-links--circle .social-links__link {
  border: 2px solid rgba(154, 157, 162, 0.4);
  border-radius: 50%;
  width: 34px;
  height: 34px;
  text-align: center;
  transition: border-color 0.3s ease;
}

.social-links--circle .social-links__link .fa {
  margin-right: 0;
  font-size: 14px;
  line-height: 30px;
}

.social-links--circle .social-links__link:hover {
  border-color: #c2ff1f;
}

.social-links--circle .social-links__link:hover .fa {
  color: #fff;
}

.social-links--circle-filled .social-links__item {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .social-links--circle-filled .social-links__item {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .social-links--circle-filled .social-links__item {
    margin-right: 18px;
  }
}

.social-links--circle-filled .social-links__item:last-child {
  margin-right: 0;
}

.social-links--circle-filled .social-links__link {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  transition: opacity 0.3s ease;
}

.social-links--circle-filled .social-links__link .fa {
  margin-right: 0;
  font-size: 14px;
  line-height: 40px;
}

.social-links--circle-filled .social-links__link:hover {
  opacity: .8;
}

.social-links--circle-filled .social-links__link:hover .fa {
  color: #fff;
}

.social-links--circle-filled .social-links__link--facebook {
  background-color: #3B5998;
}

.social-links--circle-filled .social-links__link--twitter {
  background-color: #1DA1F2;
}

.social-links--circle-filled .social-links__link--gplus {
  background-color: #DC4E41;
}

.social-links--circle-filled .social-links__link--twitch {
  background-color: #6441A4;
}

/* Match Preview */
.match-preview__header {
  text-align: center;
  margin-bottom: 15px;
}

.match-preview__header--decor {
  background-image: linear-gradient(to right, transparent, transparent 2px, #e4e7ed 2px, #e4e7ed 4px);
  background-repeat: repeat-x;
  background-position: 0 0;
  background-size: 4px 100%;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 26px;
}

.match-preview__header-inner {
  display: inline-block;
  background-color: #fff;
  padding: 0 20px 5px 20px;
  vertical-align: middle;
}

.match-preview__header-inner .match-preview__title {
  margin-bottom: 0;
}

.match-preview__title {
  font-size: 14px;
  line-height: 1.2em;
  margin-bottom: .25em;
  text-transform: uppercase;
  font-style: normal;
}

.match-preview__title--lg {
  padding-top: .15em;
  margin-bottom: 1.25em;
}

@media (min-width: 768px) {
  .match-preview__title--lg {
    font-size: 26px;
  }
}

.match-preview__date {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.match-preview__info {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #9a9da2;
  font-weight: 400;
}

.match-preview__info + .match-preview__title {
  line-height: 1em;
}

.match-preview__content {
  overflow: hidden;
  padding: 0 0 30px 0;
}

.match-preview__team {
  overflow: hidden;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .match-preview__team {
    float: left;
    width: 37%;
    text-align: center;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .match-preview__team--second {
    float: right;
  }
}

.match-preview__team-logo {
  width: 46px;
  float: left;
  margin: 0 10px 0 0;
}

@media (min-width: 768px) {
  .match-preview__team-logo {
    height: 105px;
    margin: 0 0 10px 0;
    width: auto;
    float: none;
  }
}

.match-preview__team-logo img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .match-preview__team-logo img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.match-preview__team-name {
  font-size: 13px;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  padding: 12px 0 0 0;
  margin: 0;
}

@media (min-width: 768px) {
  .match-preview__team-name {
    padding: 0;
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .match-preview__team-name {
    font-size: 13px;
  }
}

.match-preview__team-info {
  font-size: 7px;
  line-height: 1.3em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

@media (min-width: 768px) {
  .match-preview__team-info {
    font-size: 8px;
  }
}

.match-preview__vs {
  margin: 0 0 16px 0;
}

@media (min-width: 768px) {
  .match-preview__vs {
    float: left;
    width: 26%;
    text-align: center;
    padding: 38px 0 0 0;
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .match-preview__vs {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.match-preview__conj {
  display: block;
  font-size: 18px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  font-weight: 700;
  letter-spacing: -0.02em;
  width: 53px;
  text-align: center;
  display: inline-block;
}

@media (min-width: 768px) {
  .match-preview__conj {
    font-size: 30px;
    width: auto;
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .match-preview__conj {
    font-size: 24px;
  }
}

.match-preview__match-info {
  font-size: 10px;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
}

@media (min-width: 768px) {
  .match-preview__match-info {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .match-preview__match-info {
    font-size: 8px;
  }
}

.match-preview__match-info--header {
  border-bottom: 1px solid #e4e7ed;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  padding: 10px 24px;
  margin-bottom: 26px;
  display: flex;
  font-weight: 400;
}

.match-preview__match-info--header .match-preview__match-place {
  flex-grow: 1;
  color: #31404b;
}

.match-preview__action--ticket {
  padding: 62px 24px 15px 24px;
  position: relative;
  margin-left: -25px;
  margin-right: -25px;
  overflow: hidden;
  background-image: linear-gradient(to right, transparent 3px, #e4e7ed 3px);
  background-repeat: repeat-x;
  background-size: 10px 1px;
  background-position: 0 22px;
}

@media (min-width: 768px) {
  .match-preview__action--ticket {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.match-preview__action--ticket::before, .match-preview__action--ticket::after {
  content: "";
  display: block;
  position: absolute;
  height: 45px;
  width: 45px;
  background-color: #edeff4;
  border: 1px solid #e4e7ed;
  border-radius: 50%;
  top: 0;
}

.match-preview__action--ticket::before {
  left: -22px;
}

.match-preview__action--ticket::after {
  right: -22px;
}

.match-preview__countdown .countdown__title {
  background-color: #f5f7f9;
  font-size: 11px;
  line-height: 1.2em;
  text-align: center;
  text-transform: uppercase;
  padding: 12px;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
  margin: 0;
}

.match-preview--alt .match-preview__content {
  display: table;
  width: 100%;
}

.match-preview--alt .match-preview__content .match-preview__team {
  display: table-cell;
  width: 50%;
  float: none;
  vertical-align: middle;
}

.match-preview--alt .match-preview__content .match-preview__team--first {
  text-align: left;
}

.match-preview--alt .match-preview__content .match-preview__team--second {
  text-align: right;
}

.match-preview--alt .match-preview__content .match-preview__team-logo {
  width: 32px;
  float: none;
  margin: 0 5px 0 0;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__content .match-preview__team-logo {
    width: auto;
  }
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__content .match-preview__team-logo {
    height: 50px;
    margin: 0 10px 0 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .match-preview--alt .match-preview__content .match-preview__team-logo {
    margin-right: 5px;
  }
}

.match-preview--alt .match-preview__content .match-preview__team--second .match-preview__team-logo {
  margin: 0 0 0 5px;
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__content .match-preview__team--second .match-preview__team-logo {
    margin: 0 0 0 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .match-preview--alt .match-preview__content .match-preview__team--second .match-preview__team-logo {
    margin-left: 5px;
  }
}

.match-preview--alt .match-preview__content .match-preview__team-name {
  font-size: 18px;
  line-height: 1.2em;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__content .match-preview__team-name {
    padding: 0;
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .match-preview--alt .match-preview__content .match-preview__team-name {
    font-size: 18px;
  }
}

.match-preview--alt .match-preview__content .match-prevew__team-details {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .match-preview--alt .match-preview__content .match-preview__team-info {
    display: none;
  }
}

.match-preview--alt .match-preview__content .match-preview__vs {
  display: table-cell;
  width: 50px;
  text-align: center;
  vertical-align: middle;
  float: none;
  padding: 0;
}

.match-preview--alt .match-preview__content .match-preview__conj {
  width: auto;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__content .match-preview__conj {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .match-preview--alt .match-preview__content .match-preview__conj {
    font-size: 12px;
  }
}

.match-preview--alt .match-preview__action {
  display: table;
  width: 100%;
}

.match-preview--alt .match-preview__match-info {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__match-info {
    display: table-cell;
  }
}

.match-preview--alt .match-preview__match-time {
  color: #31404b;
  letter-spacing: -0.02em;
  line-height: 1.5em;
}

@media (min-width: 768px) {
  .match-preview--alt .match-preview__match-time {
    font-size: 14px;
  }
}

.match-preview--alt .match-preview__match-time small {
  font-size: 0.75em;
}

.match-preview--alt .match-preview__action-btn {
  display: table-cell;
  width: 50%;
}

/* Game Results */
.game-result__teams-wrapper {
  position: relative;
}

.game-result__header {
  text-align: center;
  margin-bottom: 15px;
}

.game-result__title {
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: .25em;
  text-transform: uppercase;
  font-style: normal;
}

@media (min-width: 992px) {
  .game-result__title {
    font-size: 14px;
  }
}

.game-result__date {
  display: block;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .game-result__date {
    font-size: 10px;
  }
}

.game-result__header--alt {
  display: flex;
  font-size: 9px;
  line-height: 1.2em;
  font-weight: 400;
  text-transform: uppercase;
  justify-content: center;
  text-align: center;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
  padding: 10px 0 7px 0;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -25px;
  margin-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
}

@media (max-width: 991px) {
  .game-result__header--alt {
    flex-wrap: wrap;
  }
}

.game-result__header--alt .game-result__league {
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
  font-weight: 400;
  flex-basis: 25%;
  text-align: left;
}

.game-result__header--alt .game-result__date {
  font-weight: 400;
  flex-basis: 25%;
  text-align: right;
}

.game-result__header--alt .game-result__title {
  font-size: 9px;
  flex-basis: 50%;
  text-align: center;
}

.game-result__header--alt .game-result__title .highlight {
  color: #38a9ff;
}

.game-result__header--alt .game-result__goal {
  color: #31404b;
  text-transform: none;
  font-family: "Montserrat", sans-serif;
  padding: 0 10px;
}

@media (max-width: 991px) {
  .game-result__header--alt .game-result__goal {
    flex-basis: 100%;
    max-width: 100%;
    margin: 2px 0;
  }
}

.game-result__header--alt .game-result__goal .icon-svg {
  font-size: 8px;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.game-result__header--alt-compact {
  justify-content: flex-start;
}

.game-result__header--alt-compact .game-result__date {
  flex-basis: auto;
  text-align: left;
  font-size: 10px;
  line-height: 1.2em;
  font-weight: 700;
}

.game-result__header--alt-compact .game-result__title {
  flex-basis: auto;
  margin-left: auto;
  text-align: left;
  font-size: 10px;
  line-height: 1.2em;
  font-weight: 700;
  color: #9a9da2;
}

.game-result__content {
  overflow: hidden;
  margin: 0 0 30px 0;
  position: relative;
}

.game-result__content--visible {
  overflow: visible;
}

.game-result__team {
  overflow: hidden;
  padding-right: 70px;
}

@media (min-width: 992px) {
  .game-result__team {
    float: left;
    width: 37%;
    margin-bottom: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .game-result__team--vertical .game-result__team-logo {
    float: none !important;
    margin: 0 !important;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .game-result__team--vertical .game-result__team-info {
    padding: 11px 0 0 0;
    text-align: center;
  }
}

.game-result__team--vertical .game-result__team-name {
  font-style: normal;
}

@media (min-width: 992px) {
  .game-result__team--vertical .game-result__team-name {
    font-size: 14px;
  }
}

.game-result__team--vertical .game-result__team-desc {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  text-transform: none;
}

.game-result__team--second .game-result__team-logo {
  float: left;
  margin: 0 10px 0 0;
}

@media (min-width: 992px) {
  .game-result__team--second {
    float: right;
    text-align: right;
  }
}

@media (min-width: 992px) {
  .game-result__team--second .game-result__team-logo {
    margin: 0 0 0 25px;
    float: right;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .game-result__team--second .game-result__team-logo {
    float: none;
    margin: 0 0 10px 0;
  }
}

.game-result__team-logo {
  width: 46px;
  margin: 0 10px 0 0;
  float: left;
}

@media (min-width: 992px) {
  .game-result__team-logo {
    width: auto;
    height: 100px;
    margin: 0 25px 0 0;
    float: left;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .game-result__team-logo {
    float: none;
    text-align: center;
    margin: 0 0 10px 0;
  }
}

.game-result__team-logo img {
  position: relative;
}

@media (min-width: 992px) {
  .game-result__team-logo img {
    top: 50%;
    transform: translateY(-50%);
  }
}

.game-result__team-info {
  padding-top: 10px;
}

@media (min-width: 992px) {
  .game-result__team-info {
    padding-top: 42px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .game-result__team-info {
    padding-top: 0;
    text-align: center;
  }
}

.game-result__team-name {
  font-size: 13px;
  margin-bottom: 0.1em;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .game-result__team-name {
    font-size: 18px;
  }
}

.game-result__team-desc {
  font-size: 7px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .game-result__team-desc {
    font-size: 10px;
  }
}

.game-result__score-wrap {
  margin: 0 0 16px 0;
  text-align: right;
}

@media (min-width: 992px) {
  .game-result__score-wrap {
    float: left;
    width: 26%;
    text-align: center;
    padding: 33px 0 0 0;
    margin: 0;
  }
}

.game-result__score {
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
}

@media (min-width: 992px) {
  .game-result__score {
    font-size: 34px;
    line-height: 1.2em;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .game-result__score--sm {
    font-size: 36px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .game-result__score--sm {
    font-size: 24px;
  }
}

.game-result__score--sm .game-result__score-result--winner::before {
  display: none;
}

@media (min-width: 992px) {
  .game-result__score--lg {
    font-size: 44px;
  }
}

.game-result__score-result {
  position: relative;
}

@media (max-width: 991px) {
  .game-result__score-result--winner {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
  }
}

.game-result__score-result--winner::before {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 8px solid #38a9ff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transform: translateY(-50%);
}

.game-result__score-result--loser {
  color: #9a9da2;
}

@media (max-width: 991px) {
  .game-result__score-result--loser {
    display: block;
    position: absolute;
    bottom: 4px;
    right: 0;
  }
}

@media (max-width: 991px) {
  .game-result__score-dash {
    display: none;
  }
}

.game-result__score-label {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #31404b;
}

@media (max-width: 991px) {
  .game-result__score-label {
    line-height: 1em;
    font-size: 9px;
  }
}

.game-result__score-video-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  background-color: #9a9da2;
}

.game-result__score-video-icon:hover {
  background-color: #1892ed;
  color: #fff;
}

.game-result__score-video-icon .fa {
  margin-left: 2px;
}

.game-result__subtitle {
  font-size: 11px;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .game-result__subtitle {
    font-size: 13px;
  }
}

.game-result__stats {
  padding: 0 0 0 0;
}

@media (min-width: 992px) {
  .game-result__stats {
    padding: 0 20px 0 20px;
  }
}

.game-result__section {
  padding-top: 16px;
}

.game-result__section-decor {
  padding-bottom: 50px;
  background-image: linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px), linear-gradient(to right, rgba(0, 0, 0, 0.1) 8px, transparent 8px), linear-gradient(to right, rgba(0, 0, 0, 0.11) 8px, transparent 8px);
  background-repeat: repeat-x;
  background-size: 72px 24px, 72px 30px, 72px 34px, 72px 40px, 72px 37px, 72px 29px, 144px 30px, 216px 37px;
  background-position: 0 100%, 12px 100%, 24px 100%, 36px 100%, 48px 100%, 60px 100%, 12px 100%, 48px 100%;
}

.card .game-result__section-decor {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
}

@media (min-width: 1199px) {
  .game-result__table-stats {
    margin: 0 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .game-result__table-stats .table > thead > tr > th,
  .game-result__table-stats .table > thead > tr > td,
  .game-result__table-stats .table > tbody > tr > th,
  .game-result__table-stats .table > tbody > tr > td,
  .game-result__table-stats .table > tfoot > tr > th,
  .game-result__table-stats .table > tfoot > tr > td {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (min-width: 1199px) {
  .game-result__stats-team-1,
  .game-result__stats-team-2 {
    padding-top: 5px;
  }
}

.game-result__table-additional-stats .table > thead > tr > th,
.game-result__table-additional-stats .table > thead > tr > td,
.game-result__table-additional-stats .table > tbody > tr > th,
.game-result__table-additional-stats .table > tbody > tr > td,
.game-result__table-additional-stats .table > tfoot > tr > th,
.game-result__table-additional-stats .table > tfoot > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.game-result__table-additional-stats .table > thead > tr > th:first-child,
.game-result__table-additional-stats .table > thead > tr > td:first-child,
.game-result__table-additional-stats .table > tbody > tr > th:first-child,
.game-result__table-additional-stats .table > tbody > tr > td:first-child,
.game-result__table-additional-stats .table > tfoot > tr > th:first-child,
.game-result__table-additional-stats .table > tfoot > tr > td:first-child {
  text-align: left;
}

.game-result__table-stats--soccer th {
  text-align: center;
}

.game-result__table-stats--soccer td {
  text-align: center;
}

.game-result__table-stats--soccer tbody > tr > td {
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
}

.game-result__table-stats--soccer tbody > tr:first-child > td {
  padding-top: 20px;
}

.game-result__table-stats--soccer tbody > tr:last-child > td {
  padding-bottom: 20px;
}

.game-result__table-stats--soccer tbody > tr > td:first-child,
.game-result__table-stats--soccer tbody > tr > td:last-child {
  color: #31404b;
}

.game-timeline-wrapper {
  overflow: auto;
  padding-bottom: 20px;
}

.card .game-timeline-wrapper {
  margin-left: -24px;
  margin-right: -24px;
}

.game-timeline {
  position: relative;
  height: 140px;
  display: flex;
  width: 770px;
  padding-left: 24px;
  padding-right: 24px;
}

.game-timeline::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background-color: #e4e7ed;
}

.game-timeline__event {
  position: relative;
  text-align: center;
  display: block;
  width: 60px;
}

.game-timeline__event--22 {
  margin-left: 78px;
}

.game-timeline__event--36 {
  margin-left: 10px;
}

.game-timeline__event--ht {
  margin-left: 82px;
}

.game-timeline__event--59 {
  margin-left: 19px;
}

.game-timeline__event--68 {
  margin-left: 28px;
}

.game-timeline__event--84 {
  margin-left: 67px;
}

.game-timeline__team-1,
.game-timeline__team-2 {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.game-timeline__team-1 {
  bottom: 87px;
}

.game-timeline__team-1 .game-timeline__team-shirt {
  position: relative;
  top: -12px;
}

.game-timeline__team-2 {
  top: 85px;
}

.game-timeline__team-2 .game-timeline__team-shirt {
  margin-top: 12px;
}

.game-timeline__time {
  width: 26px;
  height: 26px;
  border: 1px solid #e4e7ed;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  line-height: 24px;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  font-weight: 700;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.game-timeline__event-info {
  font-size: 10px;
  line-height: 1.2em;
}

.game-timeline__event-name {
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  white-space: nowrap;
  line-height: 1.3em;
}

.game-timeline__event-desc {
  font-size: 7px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  white-space: nowrap;
}

/* Additional Stats - Box */
.game-result__section--box {
  display: flex;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
}

.game-result__item .card__subheader {
  margin: 0;
}

.game-result__item .game-result__subtitle {
  font-style: normal;
}

@media (min-width: 992px) {
  .game-result__item .game-result__subtitle {
    font-size: 12px;
  }
}

.game-result__item .game-result__content {
  padding: 24px 24px;
}

@media (min-width: 768px) {
  .game-result__item .game-result__content {
    border-right: 1px solid #e4e7ed;
  }
}

.game-result__item:last-child .game-result__content {
  border-right: none;
}

/* MVP Player */
.game-result__mvp {
  display: flex;
  align-items: center;
}

.game-result__mvp-player {
  display: flex;
  align-items: center;
}

@media (max-width: 479px) {
  .game-result__mvp-player-icon-holder {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .game-result__mvp-player-icon-holder {
    display: none;
  }
}

.game-result__mvp-player-icon {
  display: block;
  width: 40px;
  height: 40px;
  border: 4px solid #38a9ff;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  line-height: 32px;
  color: #31404b;
  font-size: 18px;
  text-indent: 1px;
}

.game-result__mvp-player-meta {
  font-size: 12px;
  line-height: 1.2em;
  flex-grow: 1;
}

.game-result__mvp-player-name {
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  display: block;
  margin-bottom: 0;
  line-height: 1.2em;
}

.game-result__mvp-player-team {
  display: block;
  font-size: 9px;
  line-height: 1.2em;
}

.game-result__mvp-stats {
  margin-left: auto;
}

.game-result__mvp-stats-list {
  display: flex;
  margin: 0;
}

.game-result__mvp-stats-item {
  text-align: center;
  font-size: 12px;
  line-height: 1.2em;
  padding: 0 7px;
}

.game-result__mvp-stats-value {
  display: block;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.game-result__mvp-stats-label {
  font-size: 9px;
  line-height: 1.2em;
  display: block;
}

/* Game Stats (additional) */
.game-result__stats-circular-bars {
  display: flex;
  align-items: center;
  margin: 0;
}

.game-result__stats-circular-bars .game-result__stats-circular-item {
  font-size: 12px;
  line-height: 1.2em;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.game-result__stats-circular-bars .game-result__stats-circular-item .circular {
  margin: 0 10px 0 0;
}

.game-result__stats-circular-bars .game-result__stats-value {
  display: block;
  color: #31404b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.game-result__stats-circular-bars .game-result__stats-label {
  font-size: 9px;
  line-height: 1.2em;
  display: block;
}

/* Main News Banner
/* ------------------------- */
.main-news-banner {
  background-color: #27313b;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  margin-bottom: 15px;
}

.main-news-banner .posts__title > a {
  color: #fff;
}

.main-news-banner .main-news-banner__highlight {
  color: #9fe900;
}

.main-news-banner__inner {
  padding: 20px;
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .main-news-banner__inner {
    padding: 35px 30px 35px 30px;
  }
}

@media (min-width: 1199px) {
  .main-news-banner__inner {
    padding: 55px 40px 55px 55px;
  }
}

/* With Image at the Left */
.main-news-banner--img-left::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: radial-gradient(at 0% 0%, rgba(255, 255, 255, 0.8), transparent);
  background-repeat: no-repeat;
  background-position: 0 0;
  mix-blend-mode: overlay;
}

@media (min-width: 992px) {
  .main-news-banner--img-left::before {
    background-image: radial-gradient(at 180px 0%, rgba(255, 255, 255, 0.8), transparent);
  }
}

.main-news-banner--img-left .main-news-banner__img {
  float: left;
  max-width: 50%;
  position: relative;
  z-index: 2;
}

.main-news-banner--img-left .main-news-banner__img::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 23%;
  background-color: #38a9ff;
  width: 50%;
}

.main-news-banner--img-left .main-news-banner__img img {
  position: relative;
  z-index: 2;
}

.main-news-banner--img-left .posts__excerpt {
  display: none;
}

@media (min-width: 768px) {
  .main-news-banner--img-left .posts__excerpt {
    display: block;
  }
}

.main-news-banner--img-left .posts__date {
  display: none;
}

@media (min-width: 768px) {
  .main-news-banner--img-left .posts__date {
    display: block;
  }
}

@media (max-width: 479px) {
  .main-news-banner--img-left .posts__more {
    padding-top: 10px;
  }
}

@media (max-width: 479px) {
  .main-news-banner--img-left .posts__more .btn {
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 10px;
    border-width: 1px;
  }
}

/* With Background */
.main-news-banner--bg {
  background-image: url("../images/samples/main-news-banner__bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.main-news-banner--bg::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent);
}

@media (min-width: 992px) {
  .main-news-banner--bg::before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent);
  }
}

.main-news-banner--bg .main-news-banner__inner {
  padding: 30px;
}

@media (min-width: 992px) {
  .main-news-banner--bg .main-news-banner__inner {
    padding: 30px 30px 30px 48%;
  }
}

@media (min-width: 1199px) {
  .main-news-banner--bg .main-news-banner__inner {
    padding: 41px 40px 41px 48%;
  }
}

.main-news-banner--bg .posts__date {
  color: rgba(255, 255, 255, 0.8);
}

/* with Soccer Ball */
.main-news-banner--soccer-ball {
  background-image: url("../images/soccer/samples/banner-soccer-ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

@media (max-width: 1199px) {
  .main-news-banner--soccer-ball {
    background-size: 40% auto;
  }
}

.main-news-banner--soccer-ball .main-news-banner__inner {
  padding-right: 20%;
}

@media (min-width: 992px) {
  .main-news-banner--soccer-ball .main-news-banner__inner {
    padding-right: 30%;
  }
}

@media (min-width: 1200px) {
  .main-news-banner--soccer-ball .main-news-banner__inner {
    padding-right: 30%;
  }
}

.main-news-banner--soccer-ball .posts__excerpt {
  padding-right: 20px;
}

@media (min-width: 992px) {
  .main-news-banner--soccer-ball .posts__excerpt {
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .main-news-banner--soccer-ball .posts__excerpt {
    padding-right: 60px;
  }
}

/* Featured Carousel
/* ------------------------- */
.featured-carousel .slick-slide::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #0e0f10;
  opacity: 0.9;
  z-index: 2;
  pointer-events: none;
  transition: all 0.3s ease;
}

.featured-carousel .slick-slide::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background-color: #1892ed;
  z-index: 1;
  transition: height 0.6s ease;
}

.featured-carousel .slick-slide.slick-current::before {
  opacity: 0.6;
  z-index: 1;
}

@media (min-width: 992px) {
  .featured-carousel .slick-slide.slick-current::after {
    height: 4px;
  }
}

@media (min-width: 1199px) {
  .featured-carousel .slick-slide.slick-current::after {
    height: 6px;
  }
}

.featured-carousel .slick-slide.slick-current .posts__inner {
  z-index: 2;
}

/* Team Filter */
.content-filter {
  background-color: #fff;
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .content-filter {
    border-bottom: 1px solid #e4e7ed;
  }
}

@media (max-width: 991px) {
  .content-filter .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.content-filter__toggle {
  display: none;
}

@media (max-width: 991px) {
  .content-filter__toggle {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  }
}

.content-filter__toggle::before {
  content: "\f141";
  line-height: 40px;
  color: #31404b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.content-filter__toggle--active::before {
  content: '\f00d';
}

.content-filter__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 992px) {
  .content-filter__list {
    flex-direction: row;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
  }
}

.content-filter__item {
  flex-grow: 1;
  text-align: center;
  border-bottom: 1px solid #e4e7ed;
}

@media (min-width: 992px) {
  .content-filter__item {
    border-bottom: none;
  }
  .content-filter__item + .content-filter__item {
    border-left: 1px solid #e4e7ed;
  }
}

@media (max-width: 991px) {
  .content-filter__item:not(.content-filter__item--active) {
    display: none;
  }
}

@media (max-width: 991px) {
  .content-filter__list--expanded .content-filter__item {
    display: block;
  }
}

.content-filter__link {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2em;
  color: #9a9da2;
  padding: 18px 20px 18px 20px;
  transition: color 0.3s ease-out;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .content-filter__link {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}

.content-filter__link small {
  display: block;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
  color: #9a9da2;
}

.content-filter__link:hover {
  color: #31404b;
}

.content-filter__link::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  opacity: 0;
  background-color: #38a9ff;
  transition: opacity height 0.2s ease-out;
}

.content-filter__item--active {
  background-color: transparent;
}

@media (max-width: 991px) {
  .content-filter__item--active {
    order: -1;
  }
}

.content-filter__item--active .content-filter__link {
  color: #31404b;
}

.content-filter__item--active .content-filter__link::before {
  height: 4px;
  opacity: 1;
}

.content-filter__item--active .content-filter__link:hover {
  cursor: default;
}

.content-filter--boxed {
  background-color: transparent;
  border-bottom: 0;
  margin-top: 30px;
}

@media (max-width: 575px) {
  .content-filter--boxed {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (min-width: 992px) {
  .content-filter--boxed {
    margin-top: 60px;
    margin-bottom: -20px;
  }
}

.content-filter--boxed .content-filter__inner {
  position: relative;
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.content-filter--boxed .content-filter__list {
  border-left: 0;
  border-right: 0;
}

.content-filter--boxed .content-filter__item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.content-filter--highlight-side .content-filter__link::before {
  content: "";
  left: 0;
  right: auto;
  bottom: 0;
  top: 0;
  height: auto;
  transition: opacity width 0.2s ease-out;
}

.content-filter--highlight-side .content-filter__item--active .content-filter__link::before {
  width: 6px;
}

.content-filter--highlight-side.content-filter--boxed .content-filter__item:first-child .content-filter__link::before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media (min-width: 992px) {
  .content-filter--label-left .content-filter__item {
    text-align: left;
  }
}

/*
// #Gallery
// --------------------------------------------------
*/
.gallery__item-inner {
  display: block;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.gallery__item-inner:hover {
  background-color: #1892ed;
  color: #fff;
}

.gallery__item-inner:hover .gallery__icon {
  color: #fff;
}

.gallery__item-inner:hover .gallery__name {
  color: #fff;
}

.gallery__item-inner:hover .gallery__date {
  color: #fff;
}

.gallery__item-inner:hover .gallery__btn-fab {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.gallery__item-inner:hover .gallery__thumb img {
  opacity: 0.2;
}

.gallery__thumb {
  position: relative;
  background-color: #1e2024;
}

.gallery__thumb img {
  transition: opacity 0.3s ease;
}

.gallery__btn-fab {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.1);
  opacity: 0;
  transition: all 0.3s ease;
}

.gallery__content {
  overflow: hidden;
}

@media (max-width: 767px) {
  .gallery__content {
    padding: 12px 12px;
  }
}

.gallery__icon {
  float: left;
  margin-right: 15px;
  font-size: 30px;
  line-height: 1em;
  color: #38a9ff;
  transition: color 0.3s ease;
}

@media (max-width: 479px) {
  .gallery__icon {
    font-size: 20px;
    margin-right: 8px;
  }
}

.gallery__icon--circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #38a9ff;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
}

.gallery__details {
  overflow: hidden;
}

.gallery__name {
  text-transform: uppercase;
  line-height: 1.2em;
  margin: 0;
  font-size: 12px;
  padding: .25em 0;
  transition: color 0.2s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
}

@media (min-width: 992px) {
  .gallery__name {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .gallery__name {
    font-size: 16px;
  }
}

.gallery__date {
  display: block;
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #9a9da2;
  line-height: 1.2em;
  transition: color 0.3s ease;
}

@media (min-width: 992px) {
  .gallery__date {
    font-size: 10px;
  }
}

.gallery__header {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.gallery__header .gallery__details {
  flex-grow: 1;
}

.gallery__header .gallery__like {
  font-size: 10px;
  line-height: 1.2em;
  color: #9a9da2;
  padding-left: 10px;
  white-space: nowrap;
}

.gallery__header .gallery__like i {
  font-size: 12px;
  line-height: 1em;
  margin-left: 0.3em;
  display: inline-block;
  vertical-align: middle;
}

.gallery__thumb-list {
  margin: 0;
  padding: 6px 0 0 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ed;
}

.gallery__thumb-item {
  position: relative;
}

.gallery__thumb-item:last-child::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #31404b;
  opacity: .9;
}

.gallery__thumb-item-number {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #fff;
}

@media (min-width: 992px) {
  .gallery__thumb-item-number {
    font-size: 36px;
  }
}

/*
// #Album
// --------------------------------------------------
*/
.album-wrapper {
  overflow: hidden;
  display: block;
}

.album.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.album--condensed {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.album--condensed .album__item {
  padding: 0;
}

.album__item {
  margin-bottom: 15px;
}

.album--condensed .album__item {
  padding: 6px;
  margin-bottom: 0;
}

.album__item-holder {
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #1e2024;
}

.album__item-holder:hover .album__thumb {
  opacity: 0.2;
}

.album__item-holder:hover .album__item-desc {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.album__item-holder:hover .album__item-desc:not(.album__item-desc--bottom-left) {
  transform: scale(1) translateY(-50%);
}

.album__item-holder:hover .album__item-meta {
  visibility: visible;
  opacity: 1;
}

.album__item-holder--color--primary {
  background-color: #38a9ff;
}

.album__item-holder--color--primary .album__item-date {
  color: #fff;
}

.album__item-link {
  display: block;
}

.album__thumb {
  opacity: 1;
  position: relative;
  transition: opacity 0.3s ease;
}

.album__thumb img {
  width: 100%;
  height: auto;
}

.album__item-desc {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 14%;
  transform: scale(0.95) translateY(-50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.album__item-desc--bottom-left {
  transform: scale(0.95);
  top: auto;
  bottom: 20px;
  text-align: left;
  padding: 0 10%;
}

@media (min-width: 992px) {
  .album__item-desc--bottom-left {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.album__item-icon {
  float: left;
  margin-right: 15px;
  font-size: 30px;
  line-height: 1em;
  color: #38a9ff;
  transition: color 0.3s ease;
}

@media (max-width: 479px) {
  .album__item-icon {
    font-size: 20px;
    margin-right: 8px;
  }
}

.album__item-desc-inner {
  overflow: hidden;
}

.album__item-title {
  color: #fff;
  line-height: 1em;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: .35em;
}

@media (min-width: 992px) {
  .album__item-title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .album__item-title {
    display: none;
  }
}

.album__item-date {
  display: block;
  font-size: 8px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  color: #9a9da2;
  margin-bottom: .9em;
}

@media (min-width: 992px) {
  .album__item-date {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .album__item-date {
    display: none;
  }
}

.album__item-btn-fab {
  position: relative;
  left: auto;
  top: auto;
  margin: 0 auto;
}

.album__item-meta {
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 20px;
  top: 15px;
  transition: opacity 0.3s ease;
}

@media (min-width: 992px) {
  .album__item-meta {
    right: 30px;
    top: 25px;
  }
}

.album__item-meta .meta__item {
  color: #fff;
}

.album__item-meta .meta__item > a {
  color: #fff;
}

/* Album - Load More Photos */
.album-load-more {
  padding: 30px 0 20px 0;
}

@media (min-width: 992px) {
  .album-load-more {
    padding: 80px 0 40px 0;
  }
}

.album-load-more .btn {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .album-load-more .btn {
    width: calc(33.3% + 60px);
  }
}

/* Album Title */
.album-heading {
  text-align: center;
  padding-bottom: 36px;
}

.album-heading__icon {
  margin: 0 auto 16px auto;
}

.album-heading__title {
  font-size: 16px;
  line-height: 1.2em;
  font-style: normal;
  margin-bottom: .2em;
}

.album-heading__subtitle {
  font-size: 10px;
  line-height: 1.2em;
  color: #9a9da2;
  text-transform: uppercase;
}

/*
// #Modal Login
// --------------------------------------------------
*/
.modal--login .modal-header {
  background-image: url("../images/login_register_bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 0;
}

@media (min-width: 992px) {
  .modal--login .modal-header {
    height: 90px;
  }
}

.modal--login .modal-header .close {
  width: 33px;
  height: 33px;
  padding: 0;
  line-height: 33px;
  border-radius: 50%;
  background-color: #31404b;
  color: #fff;
  opacity: 1;
  margin: 0;
  text-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transition: all 0.3s ease;
}

.modal--login .modal-header .close span {
  margin-left: 2px;
}

.modal--login .modal-header .close:hover {
  background-color: #1892ed;
}

.modal--login .modal-body {
  padding: 0;
}

.modal--login .modal-account-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal--login .modal-account__item {
  flex-basis: 100%;
  padding: 20px;
}

@media (min-width: 768px) {
  .modal--login .modal-account__item {
    flex-basis: 50%;
    padding: 30px 20px;
  }
  .modal--login .modal-account__item:nth-child(2) {
    border-left: 1px solid #e4e7ed;
  }
}

@media (min-width: 992px) {
  .modal--login .modal-account__item {
    flex-basis: 50%;
    padding: 50px 38px;
  }
}

.modal--login .modal-account__item--logo {
  background-image: url("../images/soccer/samples/login_register_bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  color: #fff;
  position: relative;
}

@media (max-width: 991px) {
  .modal--login .modal-account__item--logo {
    display: none;
  }
}

.modal--login .modal-account__item--logo a {
  color: #c2ff1f;
  font-weight: bold;
}

.modal--login .modal-account__item--logo a:hover {
  color: #fff;
}

.modal--login .modal-account__item-register-txt {
  position: absolute;
  left: 30px;
  right: 40px;
  top: 100%;
  transform: translateY(-100%) translateY(-30px);
}

.modal--login-only .modal-header {
  height: 0;
  padding: 0;
  background-image: none;
  border: none;
}

.nav-tabs-login-wrapper {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .nav-tabs-login-wrapper {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -30px;
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .nav-tabs-login-wrapper {
    margin-left: -38px;
    margin-right: -38px;
    margin-bottom: -50px;
    margin-top: 30px;
  }
}

.nav-tabs--login {
  border-top: 1px solid #e4e7ed;
}

.nav-tabs--login > li > a {
  border: none !important;
  border-radius: 0 !important;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: rgba(49, 64, 75, 0.4);
  padding-top: 15px;
  padding-bottom: 15px;
}

.nav-tabs--login > li > a:hover {
  background-color: #fff;
  color: #38a9ff;
}

.nav-tabs--login > li.active > a {
  color: #38a9ff;
  background-color: #fff;
}

.nav-tabs--login > li.active > a:hover {
  color: #38a9ff;
  background-color: #fff;
}

.nav-tabs--login > li + li {
  border-left: 1px solid #e4e7ed;
}

.modal-form h5 {
  margin-bottom: 1.7em;
}

@media (min-width: 992px) {
  .modal-form h5 {
    font-size: 18px;
  }
}

.modal-form .form-group {
  margin-bottom: 15px;
}

.modal-form .form-group--submit {
  margin: 30px 0;
  padding-top: 9px;
}

.modal-form .modal-form--note {
  font-size: 14px;
  line-height: 22px;
}

.modal-form .form-group--pass-reminder {
  font-size: 14px;
  overflow: hidden;
}

.modal-form .form-group--pass-reminder label {
  font-size: 10px;
}

.modal-form .form-group--pass-reminder a {
  color: #9a9da2;
}

.modal-form .form-group--pass-reminder a:hover {
  color: #1892ed;
}

@media (min-width: 992px) {
  .modal-form .form-group--pass-reminder a {
    float: right;
  }
}

.modal-form .form-note {
  padding-top: 15px;
  padding-bottom: 15px;
}

.modal-form--social {
  padding-top: 4px;
}

.modal-form--social h6 {
  font-size: 10px;
  line-height: 1.2em;
  text-align: center;
}

/**
 *
 * Sponsors
 *
 */
/* Sponsors Grid */
.sponsor-card {
  height: calc(100% - 16px);
}

.sponsor-card__header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 172px;
}

.sponsor-card__header::before {
  display: none;
}

.sponsor-card__content {
  flex-grow: 1;
}

.sponsor-card__excerpt {
  font-size: .86em;
  line-height: 1.83em;
  text-align: center;
}

.sponsor-card__social {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 4px;
}

.sponsor-card__social .social-links__item {
  margin: 0 14px;
}

.sponsor-card__social .social-links__item:last-child {
  margin-right: 14px;
}

.sponsor-card__social .social-links__item .fa {
  color: #9a9da2;
  font-size: 16px;
  margin-right: 0;
  opacity: .6;
}

.sponsor-card__social .social-links__link:hover .fa {
  color: #38a9ff;
  opacity: 1;
}

.sponsor-card__footer {
  border-top: 1px solid #e4e7ed;
  background-color: transparent;
  padding: 18px 10px;
  text-align: center;
}

.sponsor-card__link {
  font-size: 10px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #31404b;
  font-weight: 700;
  text-transform: uppercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-primary,
.text-color-primary {
  color: #38a9ff !important;
}

.text-transform-normal {
  text-transform: none !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.icon-svg {
  vertical-align: middle;
}

/* Widget: Soccer Lineup */
.widget-lineup .widget__content {
  text-align: center;
}

.soccer-lineup {
  background: url("../images/soccer/lineup-bg.png") 0 0 no-repeat;
  background-size: cover;
  max-width: 100%;
}

/* Widget: Commentary */
.commentary {
  list-style: none;
  padding: 0;
  margin: 0;
}

.commentary__item {
  font-size: 11px;
  line-height: 17px;
  font-family: "Montserrat", sans-serif;
  padding: 20px 24px 20px 80px;
  position: relative;
  letter-spacing: -0.02em;
}

.commentary__item + .commentary__item {
  border-top: 1px solid #e4e7ed;
}

.commentary__item > strong {
  color: #31404b;
  font-weight: 400;
}

.commentary__item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.commentary__time {
  color: #31404b;
  display: block;
  position: absolute;
  left: 23px;
  top: 50%;
  width: 24px;
  transform: translateY(-50%);
}

.commentary__icon {
  display: block;
  position: absolute;
  left: 49px;
  top: 50%;
  transform: translateY(-50%);
}

/* Widget: Lineup Table */
.lineup-table > thead > tr > th,
.lineup-table > tbody > tr > td {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (min-width: 1200px) {
  .lineup-table > thead > tr > th:first-child,
  .lineup-table > tbody > tr > td:first-child {
    padding-left: 23px;
  }
}

@media (min-width: 1200px) {
  .lineup-table > thead > tr > th:last-child,
  .lineup-table > tbody > tr > td:last-child {
    padding-right: 23px;
  }
}

.lineup-table .lineup__num {
  width: 15%;
}

.lineup-table .lineup__pos {
  width: 13%;
}

.lineup-table .lineup__name {
  width: 57%;
  color: #31404b;
}

.lineup-table .lineup__info {
  text-align: right;
}

.lineup-table .lineup__subheader {
  text-transform: uppercase;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .lineup-table .lineup__subheader {
    padding-left: 23px;
  }
}
